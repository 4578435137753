import React, { useState, useEffect, useMemo, useRef } from "react";
import { shallowEqual } from "react-redux";
import moment from "moment";
import { Link, useNavigate } from 'react-router-dom'

// api
import { getPaymentStatement, getStatement } from "../../api/statement"
import { setupAutoPay, updateAutopay, getLastPayment, getAutopay, unenrolledAutopay } from "../../api/payment"
import { getUserPaymentMethods } from "../../api/userPaymentMethods";
import { getAcitveClientMethods } from "../../api/paymentMethods";

// Store
import {
  initPaymentMethods,
  initUserPaymentMethod,
} from "../../features/payment/paymentSlice";

// components
import {
  Container,
  Row,
  Col,
  Alert,
  Card,
  Button,
  Form,
  OverlayTrigger,
  InputGroup,
  Popover
} from "react-bootstrap";
import Icon from "../../components/Common/Icons/Icons";
import { ConfirmModal } from "../../components/Common/Modal";
import { DatePicker } from "../../components/Common/DatePicker";
import { Controller, useFieldArray, useForm, FieldValues } from "react-hook-form";
import SelectPaymentMethod from '../MakePayment/SelectPaymentMethod'
import SelectedPaymentMethod from "../MakePayment/SelectedPaymentMethod";
import SelectedPayment from "../MakePayment/placeholders/SelectedPayment";

import { LoadingWaitModal } from "../../components/Common/Modal/LoadingWaitModal";
import AutoPayEnrollmentCard from "./AutoPayEnrollmentCard";
import AutoPayAccountInformation from "./AutoPayAccountInformation";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { returnToHome } from '../../util/returnToHome'
import { collectJsValid, checkCollectOnFirstClick } from '../../util/collectJsValid'

// Hooks
import { useAppDispatch, useAppSelector } from "../../app/hooks";


// Utils
import { formatMoney, checkNumberVal, formatDate, baseDateFormat, isAfterBusinessHours, allow2DigitsAfterDecimal } from "../../util/formatUtil";
import { userNameRegEx, zipCodeRegEx } from '../../util/regexUtil'
import { allowOneDot } from "../../util/formatUtil";

import { ROLE } from "../../routes/Roles";
import CVVHelpImage from "../../assets/Images/CVV-Code.png";
const cvvHelp = (
  <Popover id="popover-cvv">
    <Popover.Body>
      <img src={CVVHelpImage} />
    </Popover.Body>
  </Popover>
);


import { v4 as uuidv4 } from "uuid";
import { $toast } from "../../util/toastUtil";
import AccountInformation from "../MakePayment/AccountInformation";
import PaymentInfoPlaceholder from "../MakePayment/placeholders/paymentInfo";
import Skeleton from "react-loading-skeleton";
import { setisVaildCvv } from "../../features/account/accountSlice";

import PaymentActionButtonLoader from "../MakePayment/placeholders/PaymentActionButtonLoader";

// AutoPay
function AutoPay() {
  const schema = yup.object().shape({
    Additional_Payment: yup.number().when({
      is: () => {
        return !isReviewing && details.hasAdditionalPayment
      },
      then: (schema) => schema.max(20000, 'Recurring  Additional Payment cannot be more than $ 20000')
        .min(0, 'Recurring Additional Payment cannot be less than 0'),
    }),
    additionalPaymentList: yup.array().of(
      yup.object().shape({
        formattedValue: yup.string().nullable().test("required", "Please enter valid amount", (value, allValues) => isTypeRequired(value, allValues)),
      })),
    CVV_Code: yup.string().when('Payment_Method', {
      is: (value: string) => {
        return isReviewing && (getUserPaymentMethodTypeById(value) == 'Credit Card' ||
          getUserPaymentMethodTypeById(value) == 'Debit Card')
      },
      then: (schema) => schema.required('CVV is required').matches(/^\d{3,4}$/, 'CVV must be 3 or 4 digits')
    }),
    enterPayment: yup.object().shape({
      methodNickname: yup.string().when({
        is: () => {
          return !isReviewing && details.Payment_Method === 'Add'
        },
        then: (schema) => schema.required('Payment NickName is required')
      }),
      selectAccountType: yup.string().when({
        is: () => {
          return !isReviewing && details.Payment_Method === 'Add'
        },
        then: (schema) => schema.required('Please Select an Account Type')
      }),
      ach_type: yup.string().when({
        is: () => {
          if (details?.enterPayment?.selectAccountType !== 'ACH') {
            return false
          } else {
            return !isReviewing && details.Payment_Method === 'Add'
          }
        },
        then: (schema) => schema.required('Please Select an ACH Type')
      }),
      firstName: yup.string().when({
        is: () => {
          return !isReviewing && details.Payment_Method === 'Add' && (details?.enterPayment?.selectAccountType == 'Credit Card' || details?.enterPayment?.selectAccountType == 'Debit Card')
        },
        then: (schema) => schema.required('First Name is required'),
      }),
      lastName: yup.string().when({
        is: () => {
          return !isReviewing && details.Payment_Method === 'Add' && (details?.enterPayment?.selectAccountType == 'Credit Card' || details?.enterPayment?.selectAccountType == 'Debit Card')
        },
        then: (schema) => schema.required('Last Name is required'),
      }),
      zipCode: yup.string().when({
        is: () => {
          return !isReviewing && details.Payment_Method === 'Add' && (details?.enterPayment?.selectAccountType == 'Credit Card' || details?.enterPayment?.selectAccountType == 'Debit Card')
        },
        then: (schema) => schema.required('Zip Code is required').matches(zipCodeRegEx, 'Zip Code is invalid.')
      })
    })
  });
  const dispatch = useAppDispatch();
  const navigate = useNavigate()

  // form
  const formProps = useForm<any>({
    resolver: yupResolver(schema),
    defaultValues: {
      hasAdditionalPayment: false,
      additionalPaymentList: null,
      Additional_Payment: '0.00',
      SchedulePaymentDate: '',
      Payment_Method: "",
      CVV_Code: "",
      enterPayment: {
        type: "",
        firstName: "",
        lastName: "",
        zipCode: "",
        selectAccountType: "",
        paymentToken: "",
        methodNickname: ""
      }
    }
  });
  const { control, watch, unregister, setValue, trigger, handleSubmit, getValues, formState: { errors }, clearErrors, reset, resetField } = formProps;
  const { ...formData } = watch()

  const { Payment_Method } = formData
  useEffect(() => {
    //console.log('updateFiled by Payment_Method changed: ', Payment_Method)
    clearErrors()
    resetField("enterPayment")
    updateFiled();
  }, [Payment_Method])

  interface enterPaymentInterFace {
    type?: string,
    methodNickname?: string,
    firstName?: string,
    lastName?: string,
    zipCode?: string,
    paymentToken?: string,
    selectAccountType: string,
    ach_type?: string | undefined
  }

  interface formDateInterface {
    Payment_Method?: string;
    SchedulePaymentDate?: string,
    CVV_Code?: string,
    hasAdditionalPayment?: boolean,
    Additional_Payment?: string | number,
    enterPayment: enterPaymentInterFace,
    additionalPaymentList: [{
      attributeType?: any,
      //customerAttributeId?: any,
      dateValue?: any,
      decimalValue?: any,
      formattedValue?: any,
      isCustomField?: any,
      labelSequence: any,
      labelText: any,
      readonly: any,
      stringValue: any,
      isAmountFieldChecked: any,
    }],
  }

  const details: formDateInterface = useMemo(() => {
    return formData;
  }, [formData]);

  const additionalPaymentRef = useRef<HTMLInputElement>(null)
  useEffect(() => {
    if (details.hasAdditionalPayment) {
      if (details.Additional_Payment != undefined) {
        setAdditionalPayment(details.Additional_Payment)
      }
    } else {
      if (additionalPaymentRef && additionalPaymentRef.current) {
        additionalPaymentRef.current.value = '0.00'
      }
      setAdditionalPayment('0')
    }

  }, [details.hasAdditionalPayment, details.Additional_Payment])

  // State
  // interface statementInterface
  const [lastPayment, setLastPayment] = useState<any>(null)
  const [statement, setStatement] = useState({
    statementId: null,
    scheduledDate: null
  });
  const [paymentDetails, setPaymentDetails] = useState<any>(null);
  const [hasAutoPay, setHasAutoPay] = useState<boolean>(false);
  const [pastDueAmount, setPastDueAmount] = useState('');
  const [totalAmountDue, setTotalAmountDue] = useState('');
  const [additionalPayment, setAdditionalPayment] = useState<string | number>('0');
  const [currentMethod, setCurrentMethod] = useState<any>(null);

  const [loading, setLoading] = useState(false)
  const [pageInitSuccess, setPageInitSuccess] = useState(false)
  const [isReviewing, setIsReviewing] = useState(false)
  const [satementLoader, setStatementLoader] = useState(false);

  const [enrolltoggled, setenrolltoggled] = useState<boolean>(false);
  let lastPaymentResponse: any;

  // for datePicker
  const [schedulePaymentDate, setSchedulePaymentDate] = useState<Date | undefined>(isAfterBusinessHours() ?
    moment().add(1, 'days').toDate() : moment().toDate());
  const [presentDate] = useState(isAfterBusinessHours() ?
    moment().add(1, 'days').toDate() : moment().toDate())
  const [maxDate] = useState(moment().add(120, 'days').toDate())
  const [dueDate, setDueDate] = useState(moment().toDate());

  const isTypeRequired = (type: any, allValues: any) => {
    if ((allValues.parent.isAmountFieldChecked === true && (!allValues.parent.formattedValue || allValues.parent.formattedValue === "."))
      || allValues.parent.isAmountFieldChecked === true && allValues.parent.formattedValue === "0.00" && checkDecimal(type, allValues)) {
      return false
    } else {
      return true
    }
  }

  const checkDecimal = (value: any, allValues: any) => {
    let inputValue = value === '' || value != null ? allValues.parent.decimalValue : value;
    if (inputValue === "" || inputValue === ".") {
      return true;
    }
  }

  const handleContinue = () => {
    if (!schedulePaymentDate) {
      showToast("Must set schedule payment date before continuing.");
      setenrolltoggled(false);
      return
    }
    if (!hasAutoPay && (Number(totalAmountDue) + Number(additionalPayment) === 0)) {
      showToast('Total Amount cannot be 0');
      setenrolltoggled(false);
      return
    }
    if (!hasAutoPay && isNaN((Number(totalAmountDue) + Number(additionalPayment)))) {
      showToast('Total Amount is invalid');
      setenrolltoggled(false);
      return
    }
    if (!details.Payment_Method) {
      showToast('Please select payment method or add new payment method');
      setenrolltoggled(false);
      return
    }
    if (details.Payment_Method == 'Add') {
      checkCollectOnFirstClick(details?.enterPayment?.selectAccountType)
      if (!collectJsValid(details?.enterPayment?.selectAccountType)) {
        return
      }
      paymentRef.current.submit();
      setenrolltoggled(false);
      setLoading(true);
    } else {
      setIsReviewing(true)
    }
  }

  const handleOnInvalid = () => {
    if (details.Payment_Method == 'Add') {
      checkCollectOnFirstClick(details?.enterPayment?.selectAccountType);
      setenrolltoggled(false);
    }
  }

  const [savedPaymentInfo, setSavedPaymentInfo] = useState<any>({})

  const getSavedPaymentInfo = (info: any) => {
    setSavedPaymentInfo(info)
    setIsReviewing(true)
    setLoading(false)
  }

  const showToast = (msg: string, icon = "close", type = "danger", title = "Failed") => {
    $toast.showToast({
      id: uuidv4(),
      autohide: true,
      delay: 5000,
      icon,
      type,
      title,
      body: msg
    })
  }

  interface paymentInterface {
    paymentType?: string,
    amount?: any;
    paymentDate?: any;
    statementId?: number | null;
    userPaymentMethodId?: any;
    cvv?: string;
    paymentMethodForm?: any;
    paymentMethodId?: string;
  }

  // Methods
  // handle submit autopay
  const handleSubmitAutoPay = () => {
    const _payment: paymentInterface = {
      paymentType: getUserPaymentNickname(details.Payment_Method),
      amount: Number(amount),
      paymentDate: moment(schedulePaymentDate).toDate(),
      statementId: statement.statementId,
      userPaymentMethodId: details.Payment_Method,
      cvv: details.CVV_Code,
      paymentMethodId: currentMethod.paymentMethodId
    }

    if (details.Payment_Method == 'Add') {
      if (details.enterPayment?.selectAccountType === 'ACH') {
        _payment.paymentMethodForm = {
          paymentToken: savedPaymentInfo.token,
          nickname: details.enterPayment?.methodNickname?.trim(),
          accountType: details.enterPayment?.type,
          paymentMethodId: getPaymentMethodIdByType(savedPaymentInfo.showType),
          accountHolderType: details.enterPayment?.ach_type
        }
      } else {
        _payment.paymentMethodForm = {
          paymentToken: savedPaymentInfo.token,
          nickname: details.enterPayment?.methodNickname?.trim(),
          firstName: details.enterPayment?.firstName?.trim(),
          lastName: details.enterPayment?.lastName?.trim(),
          zipCode: details.enterPayment?.zipCode?.trim(),
          paymentMethodId: getPaymentMethodIdByType(savedPaymentInfo.showType)
        }
      }
      _payment.userPaymentMethodId = null
      _payment.cvv = ''
      _payment.paymentMethodId = getPaymentMethodIdByType(savedPaymentInfo.showType)
    }
    else {
      _payment.paymentMethodId = currentMethod.paymentMethodId
    }
    setLoading(true);
    setIsReviewing(false)
    setupAutoPay(_payment).then(async (res: any) => {
      // when success
      if (res.status === 200) {
        // refresh userPaymentMethods
        if (details.Payment_Method == 'Add') {
          let response = await getUserPaymentMethods();
          dispatch(initUserPaymentMethod(response.data));
        }
        const state = {
          amount: hasAutoPay ? lastPayment.amount : Number(amount) + Number(addPastAmount ? pastDue : 0.00),
          paymentDate: formatDate(schedulePaymentDate),
          paymentMethod: getUserPaymentNickname(details.Payment_Method)
        }
        switch (user.role.roleName) {
          case ROLE.Consumer:
            navigate("/consumer/autopay/summary", {
              replace: true,
              state
            });
            break;
          case ROLE.ClientSuperAdmin:
          case ROLE.ClientAdmin:
            navigate("/client/autopay/summary", {
              replace: true,
              state
            });
            break;
        }
      } else {
        showToast(res.response ? res.response.data : res.message);
        setenrolltoggled(false);
      }
    }).catch((error: any) => {
      showToast(error.response ? error.response.data : error.message);
      setenrolltoggled(false);
    }).finally(() => {
      setValue("CVV_Code", "")
      setLoading(false);
    })
  }

  interface updatePaymentInterface {
    paymentType?: string,
    userPaymentMethodId?: any;
    cvv?: string;
    paymentMethodForm?: any;
    paymentMethodId: string;
  }
  // handle update autopay
  const handleUpdateAutoPay = () => {
    const _update: updatePaymentInterface = {
      paymentType: getUserPaymentNickname(details.Payment_Method),
      userPaymentMethodId: details.Payment_Method,
      cvv: details.CVV_Code,
      paymentMethodId: currentMethod.paymentMethodId
    }
    if (details.Payment_Method == 'Add') {
      if (details.enterPayment?.selectAccountType === 'ACH') {
        _update.paymentMethodForm = {
          paymentToken: savedPaymentInfo.token,
          nickname: details.enterPayment?.methodNickname?.trim(),
          accountType: details.enterPayment?.type,
          accountHolderType: details.enterPayment?.ach_type,
          paymentMethodId: getPaymentMethodIdByType(savedPaymentInfo.showType)
        }
      } else {
        _update.paymentMethodForm = {
          paymentToken: savedPaymentInfo.token,
          nickname: details.enterPayment?.methodNickname?.trim(),
          firstName: details.enterPayment?.firstName?.trim(),
          lastName: details.enterPayment?.lastName?.trim(),
          zipCode: details.enterPayment?.zipCode?.trim(),
          paymentMethodId: getPaymentMethodIdByType(savedPaymentInfo.showType)
        }
      }
      _update.userPaymentMethodId = null
      _update.cvv = ''
      _update.paymentMethodId = getPaymentMethodIdByType(savedPaymentInfo.showType)
    } else {
      _update.paymentMethodId = currentMethod.paymentMethodId
    }
    setLoading(true);
    setIsReviewing(false)
    updateAutopay(_update).then(async (res: any) => {
      if (res.status === 200) {
        // refresh userPaymentMethods
        if (details.Payment_Method == 'Add') {
          let response = await getUserPaymentMethods();
          dispatch(initUserPaymentMethod(response.data));
        }
        const state = {
          amount: Number(amount) + Number(addPastAmount ? pastDue : 0.00),
          paymentDate: moment(lastPayment.scheduledDate).format('MM/DD/YYYY'),
          paymentMethod: getUserPaymentNickname(details.Payment_Method)
        }
        switch (user.role.roleName) {
          case ROLE.Consumer:
            navigate("/consumer/autopay/summary", {
              replace: true,
              state
            });
            break;
          case ROLE.ClientSuperAdmin:
          case ROLE.ClientAdmin:
            navigate("/client/autopay/summary", {
              replace: true,
              state
            });
            break;
        }
      } else {
        showToast(res.response ? res.response.data : res.message)
      }
    }).catch((error: any) => {
      showToast(error.response ? error.response.data : error.message)
    }).finally(() => {
      setValue("CVV_Code", "")
      setLoading(false);
    })
  }

  const { userPaymentMethod, paymentMethods } = useAppSelector(
    (state) => ({ ...state.payment }),
    shallowEqual
  );

  const { user, accountNumber, isVaildCvv } = useAppSelector(
    (state) => ({ ...state.account }),
    shallowEqual
  );

  const formatUserPaymantNickName = (userPaymentMethodId: string | undefined) => {
    let index = userPaymentMethod.findIndex(item => item.userPaymentMethodId == userPaymentMethodId)
    if (index != -1) {
      return userPaymentMethod[index].nickName
    } else {
      return ''
    }
  }

  const getUserPaymentNickname = (userPaymentMethodId: string | undefined) => {
    if (details.Payment_Method == 'Add') {
      return details.enterPayment?.methodNickname
    } else {
      return formatUserPaymantNickName(userPaymentMethodId)
    }
  }

  const getPaymentMethodIdByType = (type: string) => {
    const _index = paymentMethods.findIndex(item => item.paymentMethodName == type)
    if (_index != -1) {
      return paymentMethods[_index].paymentMethodId
    } else {
      return ''
    }
  }

  const getWebDisclaimers = (userPaymentMethodId: string | undefined) => {
    let paymentType = '';
    let disclaimers = '';
    if (details.Payment_Method == 'Add') {
      paymentType = details?.enterPayment?.selectAccountType;
    } else {
      let index = userPaymentMethod.findIndex(item => item.userPaymentMethodId == userPaymentMethodId)
      if (index != -1) {
        paymentType = userPaymentMethod[index].paymentMethodName
      }
    }

    if (paymentType && (paymentType.toLowerCase() == 'ach' || paymentType.toLowerCase() == 'debit card'))
      return user.achDisclaimerMessageVal;
    else if (paymentType && paymentType.toLowerCase() == 'credit card')
      return user.creditCardDisclaimerMessageVal;
    else
      return "";
  }

  const getUserPaymentMethodTypeById = (id: string) => {
    const _index = userPaymentMethod.findIndex(item => item.userPaymentMethodId == id)
    if (_index != -1) {
      return userPaymentMethod[_index].paymentMethodName
    } else {
      return ''
    }
  }

  // init page data
  const initPageData = async () => {
    setStatementLoader(true);
    // PaymentMethods
    if (!paymentMethods || paymentMethods.length === 0) {
      const allPaymentMethods = await getAcitveClientMethods();
      dispatch(initPaymentMethods(allPaymentMethods.data))
    }

    // User PaymentMethods
    let response = await getUserPaymentMethods();
    dispatch(initUserPaymentMethod(response.data));

    // get autopay
    let autopayResponse: any = await getAutopay();

    //enrolled autopay set autopay info to last payment
    if (autopayResponse.status == 200) {
      setHasAutoPay(true)
      // autopayResponse.data.scheuledDate = convertCSTTimeToLocal(autopayResponse.data.scheduledDate)
      setLastPayment(autopayResponse.data)

      // unenrolled set last payment
    } else if (autopayResponse.status == 204) {
      lastPaymentResponse = await getLastPayment()
      if (lastPaymentResponse.status == 200 || lastPaymentResponse.status == 204) {
        setLastPayment(lastPaymentResponse.data)
      } else {
        showToast(lastPaymentResponse.response ? lastPaymentResponse.response.data : lastPaymentResponse.message)
      }
    } else {
      showToast(autopayResponse.response ? autopayResponse.response.data : autopayResponse.message)
    }

    const initializeStatement = async () => {
      let response = await getStatement();
      return response.data;
    };

    // statement
      try{
        
        const data :any = lastPaymentResponse != null && Object.keys(lastPaymentResponse).length > 0 && lastPaymentResponse.status === 200 ? lastPaymentResponse.data.statement : await initializeStatement();
        
        data.scheduledDate = data.scheduledDate
        setStatement(data);
        setDueDate(moment(data.dueDate).toDate());

        if (moment(schedulePaymentDate).isAfter(moment(data.dueDate , 'YYYY-MM-DD'))) {
          setDueDateWarning(true)
        }

        data.paymentDetails = data.paymentDetails ? JSON.parse(data.paymentDetails) : null
        if (data.paymentDetails) {
          setPaymentDetails(data.paymentDetails)
          setPastDueAmount(data.paymentDetails.PaymentCategories.Mortgage.PastDueAmount)
          setTotalAmountDue((
            checkNumberVal(data.paymentDetails?.PaymentCategories?.Mortgage?.PastDueAmount) +
            checkNumberVal(data.paymentDetails?.PaymentCategories?.Mortgage?.PaymentDue) +
            checkNumberVal(data.paymentDetails?.PaymentCategories?.Mortgage?.YTDInterest) +
            checkNumberVal(data.paymentDetails?.PaymentCategories?.Mortgage?.Insurance) +
            checkNumberVal(data.paymentDetails?.PaymentCategories?.Mortgage?.Tax)
          ).toFixed(2))
        }
      }
      catch(error: any) {
        showToast(error.response ? error.response.data : error.message)
      }
      finally{

        if (paymentInfo.generalInformation) {
          const whichAccountAttributesFecthed = paymentInfo.generalInformation
            .filter((item: any) => item.attributeName === "AccountNumber")
            .map((item: any) => item.formattedValue);

          if (accountNumber !== whichAccountAttributesFecthed[0]) {
            dispatch(getPaymentStatement());
          }
        } else {
          dispatch(getPaymentStatement());
        }

        setStatementLoader(false);
        setPageInitSuccess(true)
      }
  }

  useEffect(() => {
    initPageData();
  }, [])

  const [isDueDateWarning, setDueDateWarning] = useState(false);

  //dueDateTooltip on calender
  const dueDateTooltip = (day: string, date: Date) => {
    const tooltipText = `Due Date`;
    if (moment(date).format("MM/DD/YYYY") == moment(dueDate).format("MM/DD/YYYY")) {
      return <span title={tooltipText}>{date.getDate()}</span>;
    } else {
      return date.getDate();
    }
  };

  const { hasAdditionalPayment } = watch();

  const [isEditPaymentMethod, setIsEditPaymentMethod] = useState(false);

  const changePaymentethod = (() => {
    setIsEditPaymentMethod(true);
  });

  const [reviewUnEnrollAutoPay, setReviewUnEnrollAutoPay] = useState(false);

  const handleUnEnrollAutoPay = (() => {
    setLoading(true);
    unenrolledAutopay().then((res: any) => {
      if (res.status === 200) {
        navigate(0);
      } else {
        showToast(res.response ? res.response.data : res.message)
      }
    }).catch((error: any) => {
      showToast(error.response ? error.response.data : error.message)
    }).finally(() => {
      setLoading(false);
    })
    setReviewUnEnrollAutoPay(false);
  })

  const [showCvv, setShowCvv] = useState(false);
  const paymentRef = useRef<any>(null);

  const { permissions } = useAppSelector(
    (state: any) => ({ ...state.permissions }),
    shallowEqual
  );


  const [amount, setAmount] = useState(0);
  const [totaldue, setTotalDue] = useState(0);
  const [pastDue, setPastDue] = useState(0);
  const [pastDate, setPastDate] = useState("");
  const [addPastAmount, setAddPastAmount] = useState(false);
  const [diffDays, setDiffDays] = useState(0);
  const paymentInfo = useAppSelector((state) => ({ ...state.onetimepayment.data }), shallowEqual);

  const paymentLoader = useAppSelector((state) => ({ ...state.onetimepayment }), shallowEqual);

  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
    control,
    name: "additionalPaymentList"
  });

  const updateFiled = () => {
    const _paymentInformation = !paymentInfo?.paymentInformation ? [] : paymentInfo.paymentInformation.map((item: any) => {
      return {
        ...item,
        isAmountFieldChecked: item.formattedValue || item.decimalValue ? true : false
      }
    })
    setValue("additionalPaymentList", _paymentInformation);
  }

  const additionalValue = watch((data: any) => {
    const paymentList = data?.additionalPaymentList;
    let totalValue = 0;
    paymentInfo.defaultInformation?.filter((a: any) => {
      if (a.labelText === "TotalDue") {
        totalValue = a.decimalValue;
        setTotalDue(a.formattedValue)
      }
      if (a.labelText === "PastDueAmount") {
        setPastDue(a.decimalValue)
      }
      if (a.labelText === "DueDate") {
        setPastDate(a.dateValue)
      }
    })
    const additionalAmount = paymentList?.filter((a: any) => a.isCustomField === true)
      .map((item: any) => item.decimalValue)
      .reduce((prev: any, curr: any) => {
        const total = Number(prev) + Number(curr);
        return total
      }, 0);
  })

  function createCustomFieldObj() {
    let temp: any = {};
    let tempdata = paymentInfo?.paymentInformation?.filter((item: any) => item.isCustomField);
    for (let i = 0; i < tempdata?.length; i++) {
      temp[tempdata[i].attributeName] = tempdata[i].decimalValue;
    }
    return temp;
  }

  const [formValues, setformValues] = useState(createCustomFieldObj());

  useEffect(() => {
    if (paymentLoader.status !== "loading" || !satementLoader) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth"
      });
    }
  }, [paymentLoader, satementLoader])

  const [listdata, setlistdata] = useState([])

  useEffect(() => {
    if(Object.keys(formValues).length === 0){
      setformValues(createCustomFieldObj())
    }
    let count = 0;
    for (const [key, value] of Object.entries(Object.keys(formValues).length > 0 ? formValues : createCustomFieldObj())) {
      if (!key.includes('toggle') && (formValues[key + ' toggle'] !== false)) {
        count += isNaN(Number(value != null ? value : 0)) ? 0 : Number(value != null ? value : 0);
      }
    }
    let totalamt = paymentInfo?.defaultInformation?.filter((item: any) => item.attributeName === 'TotalDue');
    setAmount(count + Number(totalamt?.length > 0 ? totalamt[0].decimalValue : 0));
    setlistdata(paymentInfo?.paymentInformation);
  }, [getValues()])

  useEffect(() => {
    if (enrolltoggled) {
      handleContinue();
      handleOnInvalid();
    }
  }, [enrolltoggled])

  useEffect(() => {
    // Switch the code based on either present date or payment date
    // const currentDate = seletedDate;
    const currentDate = new Date();
    const days = moment(currentDate).diff(moment(pastDate), 'days', false);
    setDiffDays(days);
    if (days > 30) {
      setAddPastAmount(true);
    } else {
      setAddPastAmount(false);
    }
    updateFiled();
    // added below code to clear formvalues when user swicth to multiple accounts
    setformValues({});
  }, [paymentInfo])

  useEffect(() => {
    window.CollectJS.configure({
      paymentSelector: "#PayButton",
      theme: "bootstrap",
      variant: "inline",
      styleSniffer: true,
      customCss: {
        "border-style": "none",
        "height": "24px"
      },
      invalidCss: {},
      validCss: {},
      placeholderCss: {},
      focusCss: {},
      timeoutDuration: 30 * 1000,


      /**
       * Which gets called if timeoutDuration has passed since we tried to submit the form
       */
      timeoutCallback: function () {
        if (isVaildCvv !== false) {
          setLoading(false);
          const errorMessage = "The tokenization didn't respond in the expected time. This could be due to an invalid or incomplete field or poor connectivity"
          $toast.danger({
            title: 'Method Not Saved',
            body: errorMessage
          })
          console.error(errorMessage);
        }
      },

      validationCallback: function (fieldName:any, valid:any, message:any) {
        if(window !== undefined){
        checkAllFiledValidation(fieldName, valid, message)
        }
      },

    });
  }, []);

  useEffect(() => {
    if (!isVaildCvv) {
      setLoading(false);
    }
  }, [isVaildCvv])

  function checkAllFiledValidation(fieldName: string, valid: any, message: string) {
    if (!valid) {
      let _invalidMessage = ""
      if (message == "Field is empty") {
        switch (fieldName) {
          case "ccnumber":
            _invalidMessage = "Card Number is required"
            break;
          case "ccexp":
            _invalidMessage = "Card Expiration Date is required"
            break;
          case "cvv":
            _invalidMessage = "CVV is required"
            dispatch(setisVaildCvv(false))

            break;
          case "checkname":
            _invalidMessage = "Name on Account is required"
            break;
          case "checkaccount":
            _invalidMessage = "Account Number is required"
            break;
          case "checkaba":
            _invalidMessage = "Routing Number is required"
            break;
          default:
            _invalidMessage = message
            break;
        }
      } else {
        _invalidMessage = message
      }
      let tagName: any = document.querySelector("#" + fieldName);
      if (fieldName === "cvv" || fieldName === "checkaccount" || fieldName === "checkaba") {
        let _errorSpan = tagName.parentNode.nextElementSibling
        if (_errorSpan) {
          tagName.parentNode.parentNode.removeChild(_errorSpan)
        }

        tagName.parentNode.classList.add("form-field-error")

        let _spanNode = document.createElement("span")
        let _textNode = document.createTextNode(_invalidMessage)

        _spanNode.appendChild(_textNode)
        _spanNode.className = "form-field-error-msg"

        tagName.parentNode.parentNode.appendChild(_spanNode)

      } else {
        let _errorSpan = tagName.nextElementSibling
        if (_errorSpan) {
          tagName.parentNode.removeChild(_errorSpan)
        }

        tagName.classList.add("form-field-error")

        let _spanNode = document.createElement("span")
        let _textNode = document.createTextNode(_invalidMessage)

        _spanNode.appendChild(_textNode)
        _spanNode.className = "form-field-error-msg"

        tagName.parentNode.appendChild(_spanNode)
      }
    } else {
      let tagName: any = document.querySelector("#" + fieldName);
      if (fieldName === "cvv" || fieldName === "checkaccount" || fieldName === "checkaba") {
        if (fieldName === "cvv") {
          dispatch(setisVaildCvv(true))
        }
        tagName.parentNode.classList.remove("form-field-error")

        let _errorSpan = tagName.parentNode.nextElementSibling
        if (_errorSpan) {
          tagName.parentNode.parentNode.removeChild(_errorSpan)
        }
      } else {
        tagName.classList.remove("form-field-error")

        let _errorSpan = tagName.nextElementSibling
        if (_errorSpan) {
          tagName.parentNode.removeChild(_errorSpan)
        }
      }
    }
  }

  return (
    <>
      <main id="mainContent">
        <LoadingWaitModal show={loading} size={"lg"}></LoadingWaitModal>
        <Container>
          {
            permissions?.canShowBreadcrumb && <div className="mb-3">
              <Link to="/client">Customers</Link> / <Link to="/client/accountinformation">Account Information</Link> / Manage Autopay
            </div>
          }
          {/* <LoadingWaitModal show={loading} size="lg" hide={() => {}} /> */}
          <h1 className="h2">Manage AutoPay</h1>
          {/* IF currently enrolled in autopay */}
          {//IF enrolled
            (hasAutoPay && pageInitSuccess)
            &&
            <Alert variant="success">
              <div className="d-flex align-items-center">
                <div className="me-3">
                  <Icon
                    name="checkbox-circle"
                    optionalClass="p-1 rounded-circle bg-light"
                    size={36}
                  />
                </div>
                <div>
                  <h2 className="h3 m-0">
                    You are currently <u>enrolled</u> in Auto Pay.
                  </h2>
                </div>
              </div>
            </Alert>
          }
          {// IF not enrolled
            (!hasAutoPay && pageInitSuccess)
            &&
            <Alert variant="warning">
              <div className="d-flex align-items-center">
                <div className="me-3">
                  <Icon
                    name="warning"
                    optionalClass="p-1 rounded-circle bg-light"
                    size={36}
                  />
                </div>
                <div>
                  <h2 className="h3 m-0">
                    You are currently <u>not enrolled</u> in Auto Pay.
                  </h2>
                </div>
              </div>
            </Alert>

          }
          <Row >
            <Col>
              {/* IF enrolled in autopay */}
              {
                (hasAutoPay && pageInitSuccess)
                &&
                <Alert variant="info">
                  <div className="d-flex align-items-center">
                    <div className="me-3">
                      <Icon
                        name="info"
                        optionalClass="p-1 rounded-circle bg-light"
                        size={36}
                      />
                    </div>
                    <div>
                      <p className="m-0 mt-2">
                        * Payments submitted after 7 p.m. (CST) M-F or on the weekends/holidays will be processed on the next business day. This also applies to scheduled payments that fall on weekends/holidays.
                      </p>
                    </div>
                  </div>
                </Alert>
              }
            </Col>
          </Row>
          <Row >
            <Col lg={6}>
              <AccountInformation
                satementLoader={satementLoader} />
              {paymentLoader.status === "loading" || satementLoader === true ?
                <Card>
                  <Card.Body>
                    <Card.Title as="h2" className="app-card-title">
                      <Icon name="calendar" />
                      Autopay
                    </Card.Title>
                    <Row>
                      <Col sm={1}>
                        <Skeleton width={40} height={20} />
                      </Col>
                      <Col sm={11}>
                        <Skeleton width={"100%"} height={20} />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
                :
                <AutoPayEnrollmentCard
                  setReviewUnEnrollAutoPay={setReviewUnEnrollAutoPay}
                  setenrolltoggled={setenrolltoggled}
                  enrolltoggled = {enrolltoggled}
                  hasAutoPay={hasAutoPay}
                />}
            </Col>
            <Col lg={6}>
              <Card className="mb-3 shadows-light">
                <Card.Body>
                  <Card.Title as="h2" className="app-card-title">
                    <Icon name="credit-card" />
                    Payment Information
                  </Card.Title>


                  {paymentLoader.status === "loading" || satementLoader === true ?
                    <PaymentInfoPlaceholder />
                    :
                    <>
                      {listdata?.map((item: any, index: any) => {
                        return (
                          item?.attributeName?.toLowerCase() !== 'pastdueamount' ?
                            <div key={index}>
                              {item.isCustomField === false ?
                                <div>
                                  <Row>
                                    <Col sm={6}>
                                      <p className="mb-1">{item.labelText}</p>
                                    </Col>
                                    <Col sm={6}>
                                      {item.attributeType === "Date" ?
                                        <p className="text-sm-end m-0">
                                          <strong>
                                            {item.dateValue ? <>
                                              {moment(item.dateValue).format("MM/DD/YYYY")}
                                            </>
                                              : "-"}
                                          </strong>
                                        </p> : <p className="text-sm-end m-0">
                                          <strong>
                                            {item.formattedValue ? item.formattedValue : "$ 0"}
                                          </strong>
                                        </p>}
                                    </Col>
                                  </Row>
                                  <hr />
                                </div> : <>
                                  <Row className="d-flex align-items-center" key={index}>
                                    <Controller
                                      name={`additionalPaymentList[${index}].isAmountFieldChecked`}
                                      control={control}
                                      render={({ field }) => (
                                        <Col sm={6}>
                                          {!item.readonly ? <Form.Check
                                            type="checkbox"
                                            label={item.labelText}
                                            className="mb-2"
                                            name={item.attributeName + " toggle"}
                                            disabled={item.readonly}
                                            checked={formValues[item.attributeName + " toggle"]}
                                            defaultChecked={!item.readonly}
                                            onChange={(e) => {
                                              field.onChange(e.target.checked);
                                              setformValues({ ...formValues, [e.target.name]: e.target.checked, [item.attributeName]: formValues[item.attributeName] ? formValues[item.attributeName] : item.decimalValue });
                                              if (!e.target.checked) {
                                                trigger("additionalPaymentList")
                                                setValue(`additionalPaymentList[${index}].formattedValue`, 0)
                                                setValue(`additionalPaymentList[${index}].decimalValue`, 0)                                                
                                              }
                                            }}
                                          /> :
                                            <label>{item.labelText}</label>
                                          }
                                        </Col>
                                      )}
                                    />
                                    <Controller
                                      name={`additionalPaymentList[${index}].formattedValue`}
                                      control={control}
                                      render={({ field }) => {
                                        return (
                                          <Col sm={6} className={item.readonly ? "mb-2 text-end" : "mb-2"}>
                                            {item.readonly ?
                                              <strong>{item.formattedValue == null || '' ? "$ 0.00" : item.formattedValue}</strong>
                                              :
                                              <>
                                                <InputGroup>
                                                  <InputGroup.Text id="currencyPrefix"
                                                    className={(errors?.additionalPaymentList as any) && (errors as any)?.additionalPaymentList[index]?.formattedValue?.type === "required" ? "form-field-error ms-sm-auto border-end-0" : "ms-sm-auto"}
                                                  >$</InputGroup.Text>
                                                  <Form.Control
                                                    type="text"
                                                    placeholder="0.00"
                                                    value={Object.keys(formValues).length > 0 ? formValues[item.attributeName] : item.decimalValue}
                                                    arial-label={`${item.labelText}`}
                                                    name={item.attributeName}
                                                    className={(errors?.additionalPaymentList as any) && (errors as any)?.additionalPaymentList[index]?.formattedValue?.type === "required" ? "form-field-error ms-sm-auto" : "ms-sm-auto"}
                                                    // disabled={(getValues(`additionalPaymentList[${index}].isAmountFieldChecked`) ? false : true) || item.readonly}
                                                    disabled={formValues[item.attributeName + ' toggle'] == null ? false : (formValues[item.attributeName + ' toggle'] ? false : true)}
                                                    onChange={(e: any) => {
                                                      let value = e.target.value;
                                                      if (item.attributeType.toLowerCase() !== 'money') {
                                                        value = value.replace(/[^\d^\.]+/g, "")
                                                          .replace(/^0+(\d)/, "$1")
                                                          .replace(/^\./, "0.")
                                                          .match(/^\d*(\.?\d{0,2})/g)[0] || "";
                                                      } else {
                                                        value = allowOneDot(value);
                                                      }
                                                      // allow only 2 digit's after decimal
                                                      if (value?.length > 0) {
                                                        value = allow2DigitsAfterDecimal(value);
                                                      }
                                                      value = Number(value) > 20000 ? '20000' : value;
                                                      field.onChange(value);
                                                      setformValues({ ...formValues, [e.target.name]: value });
                                                      setValue(`additionalPaymentList[${index}].decimalValue`, value)
                                                    }}
                                                    onBlur={(e) => {
                                                      let value = e.target.value
                                                      if (value === "0") {
                                                        field.onChange("");
                                                      }
                                                      setValue(`additionalPaymentList[${index}].formattedValue`, value)
                                                    }}
                                                  />
                                                </InputGroup>
                                                {(errors?.additionalPaymentList && ((errors as any)?.additionalPaymentList[index]?.formattedValue?.type === "required")) &&
                                                  <span className="form-field-error-msg text-end mt-1">{(errors as any)?.additionalPaymentList[index]?.formattedValue?.message}</span>
                                                }
                                              </>}
                                          </Col>
                                        )
                                      }}
                                    />
                                  </Row><hr /></>}
                            </div> : null
                        )
                      })}


                      <Row>
                        <Col sm={6}>
                          <p className="mb-1">Total Amount</p>
                        </Col>
                        <Col sm={6}>
                          <p className="text-sm-end m-0">
                            <strong>
                              {totaldue}
                            </strong>
                          </p>
                        </Col>
                      </Row>
                      <hr />
                      {hasAutoPay &&
                        (
                          <Row >
                            <Col>
                              <Alert variant="info">
                                <div className="d-flex align-items-center">
                                  <div className="me-3">
                                    <Icon
                                      name="info"
                                      optionalClass="p-1 rounded-circle bg-light"
                                      size={36}
                                    />
                                  </div>
                                  <div>
                                    <h3 className="h4 m-0">
                                      * Your preferred payment method will be charged on the
                                      scheduled date of <u>{statement.scheduledDate && moment(statement.scheduledDate).format("MMM DD")}</u>.
                                    </h3>
                                  </div>
                                </div>
                              </Alert>
                            </Col>
                          </Row>
                        )
                      }
                      {
                        !hasAutoPay &&
                        <Card>
					                <Card.Header className="bg-warning text-light">
                            <Row>
                              <Col sm={6} className="align-self-center">
                                <Form.Label htmlFor="schedulePaymentDate" className="mb-0 fw-normal size16-font">
                                  {isDueDateWarning && 
                                    <Icon name="warning" optionalClass="me-2" />
                                  } Schedule Payment Date*
                                </Form.Label>
                              </Col>
                              <Col sm={6}>
                                <Controller
                                  name="SchedulePaymentDate"
                                  control={control}
                                  render={({ field }) => (
                                    <div className="full-width">
                                      <DatePicker
                                        ariaDescribedBy="scheduledDateDisclaimer"
                                        selectedDate={schedulePaymentDate}
                                        minDate={presentDate}
                                        maxDate={maxDate}
                                        highlightDates={dueDate && [{ "datepicker-highlight-duedate": [moment(moment(dueDate, 'YYYY-MM-DD').format('MM/DD/YYYY')).toDate()] }]}
                                        renderDayContents={dueDateTooltip}
                                        onChange={(date: Date | undefined) => {
                                          if (date) {
                                            if (date && moment(date).isAfter(moment(dueDate, 'YYYY-MM-DD'))) {
                                              setDueDateWarning(true);
                                            } else {
                                              setDueDateWarning(false);
                                            }
                                          }
                                          else {
                                            setDueDateWarning(false);
                                          }
                                          field.onChange(formatDate(date, baseDateFormat));
                                          setSchedulePaymentDate(date);
                                        }}
                                        isDateRange={true}
                                      />
                                    </div>
                                  )}
                                />
                              </Col>
                            </Row>
                          </Card.Header>
                          <Card.Body>
                          {
                            isDueDateWarning &&
                              <Alert variant="warning p-2">
                                <div className="d-flex align-items-center">
                                  <div className="me-3">
                                    <Icon
                                      name="warning"
                                      optionalClass="p-1 rounded-circle bg-light"
                                      size={36}
                                    />
                                  </div>
                                  <div>
                                    Late fees may incur if payment is made after the billing due date.
                                  </div>
                                </div>
                              </Alert>
                          }
                            <Form.Text id="scheduledDateDisclaimer">
                              <React.Fragment>
                                <span className="text-warning">*</span>
                                 Payments submitted after 7 p.m. (CST) M-F or on the weekends/holidays will be processed on the next business day. This also applies to scheduled payments that fall on weekends/holidays.
                              </React.Fragment>
                            </Form.Text>
                          </Card.Body>
                        </Card>
                      }
                    </>
                  }
                </Card.Body>
                <Card.Footer>
                  <Row>
                    <Col sm={6}>
                      <p className="h3">
                        <strong>Total Amount Due</strong>
                      </p>
                    </Col>
                    <Col sm={6}>
                      {paymentLoader.status === "loading" || satementLoader === true ?
                        <div className="text-sm-end">
                          <Skeleton width={80} height={20} />
                        </div>
                        :
                        <p className="h3 text-sm-end">
                          {
                            // <strong>{formatMoney(
                            //   Number(amount) +
                            //   Number(addPastAmount ? pastDue : 0.00)
                            // )} </strong>
                            <strong>{formatMoney(
                              Number(amount)
                            )} </strong>
                          }
                        </p>}
                    </Col>
                  </Row>
                </Card.Footer>
              </Card>
              {
                satementLoader ? <SelectedPayment></SelectedPayment> :

                  (!hasAutoPay || isEditPaymentMethod) ?
                    <SelectPaymentMethod
                      ref={paymentRef}
                      props={
                        {
                          forceSaveNewPayment: true,
                          setShowCvv,
                          formProps,
                          getSavedPaymentInfo: getSavedPaymentInfo,
                          getErrorInfo: showToast,
                          selectedChanged: (selectedVal: any) => {
                            setCurrentMethod(selectedVal)
                          },
                          hasAutoPay,
                          userPaymentMethodId: lastPayment?.userPaymentMethodId
                        }
                      }
                    ></SelectPaymentMethod>
                    :
                    <SelectedPaymentMethod
                      selectedNickname={formatUserPaymantNickName(lastPayment?.userPaymentMethodId)}
                      changePaymentethod={changePaymentethod}
                    />

              }

              {paymentLoader.status === "loading" || satementLoader === true ?
                <PaymentActionButtonLoader /> : <>
                  {

                    <div>

                      <div className="text-end mb-5">
                        {
                          !isEditPaymentMethod ?
                            <Button variant="outline-primary" className="mx-2" onClick={() => { returnToHome(true) }}>
                              Back
                            </Button>
                            :
                            <Button variant="outline-primary" className="mx-2" onClick={() => {
                              setIsEditPaymentMethod(false);
                              clearErrors()
                              reset()
                            }
                            }>
                              Cancel
                            </Button>
                        }
                        {
                          // not enrolled autopay
                          (!hasAutoPay || isEditPaymentMethod) &&
                          <Button disabled={lastPayment?.userPaymentMethodId === details?.Payment_Method && hasAutoPay} variant="primary" className="ms-2" onClick={handleSubmit(handleContinue, handleOnInvalid)}>
                            {isEditPaymentMethod ? "Save Changes" : "Continue"}
                          </Button>
                        }
                        {
                          // not enrolled autopay
                          (hasAutoPay && !isEditPaymentMethod) &&
                          <Button variant="primary" className="ms-2" onClick={() => {
                            setReviewUnEnrollAutoPay(true)
                          }}>
                            Unenroll
                          </Button>
                        }
                      </div>

                    </div>
                  }</>}
            </Col>
          </Row>

        </Container>
      </main>
      {/* Review Modal for Entrollment */}
      <ConfirmModal
        show={isReviewing}
        title="Review AutoPay Enrollment"
        submitBtnText="Submit Enrollment"
        description="Please review the payment details and make any edits prior to submitting."
        onCancel={() => {
          setIsReviewing(false);
          setValue("CVV_Code", "");
          setenrolltoggled(false);
        }}
        onConfirm={handleSubmit(hasAutoPay ? handleUpdateAutoPay : handleSubmitAutoPay)}
        showFooter={true}
      >
        <React.Fragment>
          <Row>
            <Col sm={6}>
              <p className="mb-1">Recurring Payment Amount</p>
            </Col>
            <Col sm={6}>
              <p className="text-sm-end m-0">
                <strong>{formatMoney(Number(amount))}</strong>
              </p>
            </Col>
          </Row>
          <hr className="my-2" />
          <Row>
            <Col sm={6}>
              <p className="mb-1">Scheduled Payment Date</p>
            </Col>
            <Col sm={6}>
              <p className="text-sm-end m-0">
                {
                  hasAutoPay ?
                    <strong>{moment(lastPayment.scheduledDate).format("MM/DD/YYYY")}</strong>
                    :
                    <strong>{moment(schedulePaymentDate).format("MM/DD/YYYY")}</strong>
                }
              </p>
            </Col>
          </Row>
          <hr className="my-2" />
          <Row>
            <Col sm={6}>
              <p className="mb-1">Payment Nickname</p>
            </Col>
            <Col sm={6}>
              <p className="text-sm-end m-0">
                <strong>{
                  getUserPaymentNickname(details.Payment_Method)
                }</strong>
              </p>
            </Col>
          </Row>
          {
            showCvv &&
            <>
              <hr className="my-2" />
              <Row className="pt-2">
                <Col sm={7}>
                  <p className="my-2">CVV*</p>
                </Col>
                <Col sm={5}>
                  <Controller
                    name="CVV_Code"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <InputGroup>
                        <Form.Control
                          id="editMethodCVV"
                          placeholder="3 or 4 digits"
                          type="text"
                          value={field.value || ''}
                          maxLength={4}
                          className={errors.CVV_Code ? "form-field-error" : ""}
                          onChange={(e: any) => {
                            let _value = e.target.value.replace(/[^\d^]+/g, "")
                            field.onChange(_value)
                          }}
                        />
                        <InputGroup.Text>
                          <OverlayTrigger
                            placement="top"
                            overlay={cvvHelp}
                          >
                            <div>
                              <Icon name="question" size={20} />
                            </div>
                          </OverlayTrigger>
                        </InputGroup.Text>
                      </InputGroup>
                    )}
                  />
                </Col>
              </Row>
              <Row>
                {(errors.CVV_Code && ((errors as any)?.CVV_Code.type === "required" || (errors as any)?.CVV_Code.type === "matches")) &&
                  <span className="form-field-error-msg text-end mt-1">{(errors as any)?.CVV_Code?.message}</span>
                }
              </Row>
            </>
          }
          {/* IF ACH */}
          <div>
            <Form.Text>
              {getWebDisclaimers(details.Payment_Method)}
            </Form.Text>
          </div>
          {/* IF CC insert the text using API from the DB for CC disclaimer message */}
        </React.Fragment>
      </ConfirmModal>
      {/* Review Modal for Un Entrollment */}
      <ConfirmModal
        show={reviewUnEnrollAutoPay}
        title="Review AutoPay"
        submitBtnText="Yes, Proceed"
        onCancel={() => setReviewUnEnrollAutoPay(false)}
        onConfirm={handleUnEnrollAutoPay}
      >
        <React.Fragment>
          <Row>
            <Col sm={12}>
              <p className="mb-1">Are you sure, you want to unenroll autopay?</p>
            </Col>
          </Row>
        </React.Fragment>
      </ConfirmModal>
    </>
  );
}
export default AutoPay;
