import React from "react";
import { useAccount, useMsal } from "@azure/msal-react";
import moment from "moment";

import { useAppSelector } from "../../app/hooks";
import { shallowEqual } from "react-redux";

function UserGreet() {
  const { user } = useAppSelector(
    (state) => ({ ...state.account }),
    shallowEqual
  );

  let userName = '';
  if(user && (user.firstName || user.lastName)){
    userName = [user.firstName, user.lastName].join(' ').replace(/^,+|,+$/g, '');
  }

  return (
    user && (
    <div className="user-greet">
        <p className="m-0">Welcome {userName}</p>
          <small className="user-login-greet">
            <span className="size14-font">Last Visit: { user.lastLoginDate && moment(user.lastLoginDate).format("MM/DD/YYYY hh:mm A")}</span>
          </small>
      </div>
      )
  );
}

export default UserGreet;
