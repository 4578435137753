import Skeleton from "react-loading-skeleton";
import { Col, Form, Row } from "react-bootstrap";
import { Table as BSTable, InputGroup, Button } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import * as Yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import { replaceSpace, userNameRegEx, phoneNumberRegEx, replaceSpecialCharsAndNumbers, changeToUsMobileNumFormat } from '../../util/regexUtil';
import Select from 'react-select';
import { useEffect, useState } from "react";
import { getRolesForUser } from "../../api/roles";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { shallowEqual } from "react-redux";
import { IClientUser, ISelectOption } from "../../../types/custom-types";
import { ROLE } from '../../routes/Roles';
import { setRoles } from "../../features/dictionaries/commonDictionary";
import Icon from "../../components/Common/Icons/Icons";
import { createClientUser, getClientUser, getUserData, updatedClientUser } from "../../api/user";
import { filterValue, matchValue } from "../../service/configureClient";
import { convertCSTTimeToLocal, baseDateFormat, convertUtcToCst } from '../../util/formatUtil'
import moment from "moment-timezone";

type ClientUserFormType = {
    Id: string;
    UserName: string;
    FirstName: string;
    LastName: string;
    PhoneNumber?: string | undefined;
    ClientName: { value: string, label: string };
    Role: { value: string, label: string };
    CreateDate?: string | undefined,
    LastLoginDate?: string | undefined
};

const AddUsers = ({ setErrorInfo, setIsShowUserForm, setPreLoading, isEdit,setIsAddOrEditUserSaved, setErrorToast }: any) => {
    const [roleList, setRoleList] = useState<Array<ISelectOption>>([]);
    const dispatch = useAppDispatch();
    const [selectRole, setSelectRole] = useState("");
    const [canEditClient, setCanEditClient] = useState(false);
    const editUser = useAppSelector((state: any) => state.editUserData, shallowEqual);
    const validationSchema = Yup.object().shape({
        UserName: Yup.string().required('This is required').test(
            "len",
            "with string at least 1 characters and not more than 50",
            (val) => {
                if (!val) {
                    return false;
                }
                return val.length > 0 && val.length <= 50;
            }
        ),
        FirstName: Yup.string().required('This is required').test(
            "len",
            "with string at least 1 characters and not more than 30",
            (val) => {
                if (!val) {
                    return false;
                }
                return val.length > 0 && val.length <= 30;
            }
        ),
        LastName: Yup.string().required('This is required').test(
            "len",
            "with string at least 1 characters and not more than 30",
            (val) => {
                if (!val) {
                    return false;
                }
                return val.length > 0 && val.length <= 30;
            }
        ),
        PhoneNumber: Yup.string().when({
            is:(value:string)=>{
                return value
            },
            then:(schema) => schema.matches(phoneNumberRegEx, "Phone Number is invalid.")
        }),
        Role: Yup.object().test("required", "This is required", (value) => isRoleRequired(value)),
        ClientName: Yup.object().when('Role', {
          is: (val: any) => {
            if (
                ( val.label.toLocaleLowerCase() === "clientsuperadmin" ||
                  val.label.toLocaleLowerCase() === "clientadmin" ||
                  val.label.toLocaleLowerCase() === "clientbasic"
                ) &&
                accountInfo.user.role.description.toLocaleLowerCase().indexOf("venture") >= 0) {
                    return true
                } else {
                    return false
                }
            },
            then: Yup.object().test("required", "This is required", (value) => isRoleRequired(value)),
            otherwise: Yup.object()
        }),
    });

    // get user detail from Store
    const { role } = useAppSelector(
        (state) => ({ ...state.account }),
        shallowEqual
    );
    const roleName = role?.roleName as ROLE;

    const { roles } = useAppSelector(
        (state) => ({ ...state.commonDictionary }),
        shallowEqual
    );

    const isRoleRequired = (role: any) => {
        if (!role?.value)
            return false;
        else
            return true;
    };

    const customeList = useAppSelector((state: any) => state?.clientAccount?.clientName?.data, shallowEqual);

    const onSave = (data: ClientUserFormType) => {
        let clientId = null;
      if (data.Role.label.toLocaleLowerCase() !== ROLE.VentureAdmin.toLocaleLowerCase()){
          if(isEdit){
            if (editUser.data.role.roleName.toLocaleLowerCase() === ROLE.VentureAdmin.toLocaleLowerCase()){
              clientId = data.ClientName.value
            }
            else{
              clientId = editUser.data.clientId
            }
          }
          else{
            clientId = data?.ClientName?.value
            if (!clientId) {
              clientId = accountInfo?.user?.clientId;
            }
          }
        }


        const sendData: IClientUser = {
            UserId: data.Id || null,
            userName: data.UserName,
            firstName: data.FirstName,
            lastName: data.LastName,
            phoneNumber: data.PhoneNumber,
            roleId: data.Role.value,
            clientId: clientId
        }
        if (data.Id) {
            setPreLoading(true);
            setIsShowUserForm(false)
            updatedClientUser(sendData).then(res => {
                setIsShowUserForm(false);
                dispatch(getClientUser());
                setIsAddOrEditUserSaved(true)
            }).catch(err => {
                if (err.response?.data) {
                    let errorInfo = '';
                    if (typeof err.response.data === 'string') {
                        errorInfo = err.response.data
                    } else if (Array.isArray(err.response.data)) {
                        errorInfo = err.response.data.join('\n')
                    }
                    setErrorInfo(errorInfo);
                    setErrorToast(true);

                }
            }).finally(() => {
                setIsShowUserForm(false);
                setPreLoading(false);
            })
        } else {
            setPreLoading(true);
            setIsShowUserForm(false)
            createClientUser(sendData).then(res => {
                setIsShowUserForm(false);
                dispatch(getClientUser());
                setIsAddOrEditUserSaved(true)
            }).catch(err => {
                console.error(err)
                if (err.response?.data) {
                    let errorInfo = '';
                    if (typeof err.response.data === 'string') {
                        errorInfo = err.response.data
                    } else if (Array.isArray(err.response.data)) {
                        errorInfo = err.response.data.join('\n')
                    }
                    setErrorToast(true);
                    setErrorInfo(errorInfo);
                }
            }).finally(() => {
                setIsShowUserForm(false);
                setPreLoading(false);
            })
        }

    };

    const initialClientUserFormValues = {
        UserName: "",
        FirstName: "",
        LastName: "",
        PhoneNumber: "",
        ClientName: { value: '', label: 'Select Client' },
        Role: { value: '', label: 'Select Role' },
    };

    //Add/Edit Form
    const { control, watch, handleSubmit, reset, getValues, setValue, clearErrors, formState: { errors } } = useForm<ClientUserFormType>(
        {
            resolver: yupResolver(validationSchema),
            defaultValues: initialClientUserFormValues
        }
    );
    const accountInfo = useAppSelector((state: any) => state.account, shallowEqual);

    useEffect(() => {
        const roleList = roles.map((item: any) => {
            return {
                label: item.roleName,
                value: item.rolesId
            }
        })

        roleList.unshift({
            label: "Select Role",
            value: ""
        })

        setRoleList(roleList)
    }, [roles])

    useEffect(() => {
        if (editUser) {
            reset({
                Id: editUser.data.userId,
                UserName: editUser.data.email,
                FirstName: editUser.data.firstName,
                LastName: editUser.data.lastName,
                PhoneNumber: editUser.data.phone?editUser.data.phone:'',
                Role: filterValue(editUser.data.roleId, roleList),
                ClientName: filterValue(editUser.data.clientId, customeList),
                CreateDate: editUser.data.createDate ? convertUtcToCst(editUser.data.createDate, baseDateFormat) : '',
                LastLoginDate: editUser.data.lastLoginDate ? convertUtcToCst(editUser.data.lastLoginDate, baseDateFormat) : ''
            });
        }
    }, [editUser])

    useEffect(()=>{
        // console.log(customeList)
    })

    return (
        <>
        <div className="">
            {editUser.status === "loading" ?
                <div className="loader-section">
                    <div className="loader-spinner">
                        <div className="spinner-border text-secondary" role="status"></div>
                    </div>
                </div> : ""}
            <Form>
                <Row>
                    <Col sm={6}>
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="UserName">
                                Username
                            </Form.Label>
                            <Controller
                                name="UserName"
                                control={control}
                                render={({ field }) => (
                                    <Form.Control
                                        type="text"
                                        id="UserName"
                                        value={field.value || ''}
                                        maxLength={50}
                                        className={errors.UserName ? "form-field-error" : ""}
                                        placeholder="Username"
                                        onChange={(e) => {
                                            if (matchValue(editUser.data.email, accountInfo?.user?.email) === false) {
                                                field.onChange(e.target.value)
                                            }
                                        }
                                        }
                                        disabled={matchValue(editUser.data.email, accountInfo?.user?.email)}
                                    />
                                )}
                            />
                            {matchValue(editUser.data.email, accountInfo?.user?.email) && <span className="form-field-error-msg">Please contact your service administrator if the email address needs to be updated. </span>}
                            {/* {errors.UserName && <span className="form-field-error-msg">{errors?.UserName?.message?.toString()}</span>} */}
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="FirstName">
                                First Name
                            </Form.Label>
                            <Controller
                                name="FirstName"
                                control={control}
                                render={({ field }) => (
                                    <Form.Control
                                        type="text"
                                        id="FirstName"
                                        maxLength={30}
                                        value={field.value || ''}
                                        className={errors.FirstName ? "form-field-error" : ""}
                                        placeholder="First Name"
                                        onChange={(e) => field.onChange(e.target.value)}
                                    />
                                )}
                            />
                            {errors.FirstName && <span className="form-field-error-msg">{errors?.FirstName?.message?.toString()}</span>}
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="LastName">
                                Last Name
                            </Form.Label>
                            <Controller
                                name="LastName"
                                control={control}
                                render={({ field }) => (
                                    <Form.Control
                                        type="text"
                                        id="LastName"
                                        maxLength={30}
                                        value={field.value || ''}
                                        className={errors.LastName ? "form-field-error" : ""}
                                        placeholder="Last Name"
                                        onChange={(e) => field.onChange(e.target.value)}
                                    />
                                )}
                            />
                            {errors.LastName && <span className="form-field-error-msg">{errors?.LastName?.message?.toString()}</span>}
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="PhoneNumber">
                                Phone Number
                            </Form.Label>
                            <Controller
                                name="PhoneNumber"
                                control={control}
                                render={({ field }) => (
                                    <Form.Control
                                        type="text"
                                        id="PhoneNumber"
                                        maxLength={10}
                                        value={field.value || ''}
                                        className={errors.PhoneNumber ? "form-field-error" : ""}
                                        placeholder="Phone Number"
                                        onChange={(e) => field.onChange(changeToUsMobileNumFormat(e.target.value))}
                                    />
                                )}
                            />
                            {errors.PhoneNumber && <span className="form-field-error-msg">{errors?.PhoneNumber?.message?.toString()}</span>}
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="Role">
                                Role
                            </Form.Label>
                            <Controller
                                name="Role"
                                control={control}
                    render={({ field }) => (
                      <>
                        {setSelectRole(field.value?.label)}
                                        <Select options={roleList}
                                            className={errors.Role ? "form-field-error" : ""}
                                            defaultValue={field.value || '' }
                                            isDisabled={matchValue(editUser.data.email, accountInfo?.user?.email)}
                                            value={field.value}
                                            onChange={(role: any) => {
                                                if (matchValue(editUser.data.email, accountInfo?.user?.email) === false) {
                                                  field.onChange(role);
                                                  setSelectRole(role.label);

                                                  // Venture && Venture => Client
                                                  if(roleName === ROLE.VentureAdmin &&
                                                      role.label &&
                                                      role.label !== ROLE.VentureAdmin
                                                    ){
                                                    setCanEditClient(true);
                                                  }
                                                  else{
                                                    setSelectRole("");
                                                    setCanEditClient(false);
                                                  }
                                                }
                                            }}
                                        ></Select>
                                    </>
                                )}
                            />
                            {errors.Role && <span className="form-field-error-msg">{errors.Role?.message?.toString()}</span>}
                        </Form.Group>
                    </Col>
            </Row>
            { 
                    roleName === ROLE.VentureAdmin && selectRole && selectRole != ROLE.VentureAdmin ?
                        <Row>
                            <Col md={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label htmlFor="ClientName">
                                        Client
                                    </Form.Label>
                                    <Controller
                                        name="ClientName"
                                        control={control}
                                        render={({ field }) => (
                                            <Select options={customeList}
                                                isDisabled={!canEditClient}
                                                className={errors.ClientName ? "form-field-error" : ""}
                                                defaultValue={field.value}
                                                value={field.value}
                                                onChange={(client: any) => {
                                                    if (canEditClient) {
                                                        field.onChange(client);
                                                    }
                                                }}
                                            ></Select>
                                        )}
                                    />
                                    {errors.ClientName && <span className="form-field-error-msg">{errors?.ClientName?.message?.toString()}</span>}
                                </Form.Group>

                            </Col>
                        </Row> : ""}
                        {
                        isEdit ?
                        <Row>
                             <Col md={6}>
                                 <Form.Group className="mb-3">
                                     <Form.Label htmlFor="CreateDate">
                                      Create Date
                                     </Form.Label>
                                     <Controller
                                         name="CreateDate"
                                         control={control}
                                         render={({ field }) => (
                                             <Form.Control
                                                 type="text"
                                                 disabled
                                                 value={field.value || ""}
                                             ></Form.Control>
                                         )}
                                     />
                                 </Form.Group>

                             </Col>
                            <Col md={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label htmlFor="LastLoginDate">
                                    Last Login Date
                                    </Form.Label>
                                    <Controller
                                        name="LastLoginDate"
                                        control={control}
                                        render={({ field }) => (
                                            <Form.Control
                                                type="text"
                                                disabled
                                                value={field.value || ""}
                                            ></Form.Control>
                                        )}
                                    />
                                </Form.Group>

                            </Col>
                        </Row> : ""}
                <div className="text-end">
                    <Button
                        variant="outline-primary"
                        size="sm"
                        className="mb-3"
                        onClick={() => {
                            setIsShowUserForm(false)
                        }
                        }
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="primary"
                        size="sm"
                        className="mb-3 ms-3"
                        onClick={handleSubmit((data) => {
                            onSave(data);
                        })}
                    >
                        <Icon name="check-double" optionalClass="me-2" size={20}></Icon>
                        Save
                    </Button>
                </div>
            </Form>
        </div>
        </>
    );
};

export default AddUsers;
