import React, { useEffect, useRef } from "react";
import { forwardRef, useCallback, useState } from "react";

// Components
import { Form } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import Icon from "../Icons/Icons";

// Utils
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { baseDateFormat } from "../../../util/formatUtil";
import { $toast, toastMessage } from "../../../util/toastUtil";

interface IDatePicker {
  // DatePicker Props
  minDate?: Date | undefined;
  maxDate?: Date | undefined;
  selectedDate?: Date | undefined; // set default selected date
  placeholderText?: string | undefined;
  highlightDates?: Array<any> | undefined; // [date1, date2] or [{className1: [date1]}, {className2: [date2]}]
  renderDayContents?: any; // // control renders each 'day' items - Function

  // Range
  selectsStart?: boolean | undefined;
  selectsEnd?: boolean | undefined;
  startDate?: Date | undefined;
  endDate?: Date | undefined;

  // callBacks
  onChange: (newDate: Date | any) => void;
  onInvalidInput?: (e: any) => void;

  // Custom props
  containerClassName?: string;
  // aria-** https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA
  ariaDescribedBy?: string | undefined;
  isDateRange?: boolean | undefined;
}

export const DatePicker = forwardRef(
  (
    {
      minDate,
      maxDate,
      selectedDate,
      placeholderText,
      highlightDates,
      renderDayContents,
      selectsStart,
      selectsEnd,
      startDate,
      endDate,
      onChange,
      onInvalidInput,
      containerClassName,
      ariaDescribedBy,
      isDateRange
    }: IDatePicker,
    ref?:
      | ((instance: HTMLInputElement | null) => void)
      | React.RefObject<HTMLInputElement>
      | null
      | undefined
  ) => {
    if (
      (selectsStart && (!startDate || (!minDate && !maxDate))) ||
      (selectsEnd && (!endDate || (!minDate && !maxDate)))
    ) {
      console.error("Wrong props");
    }

    const _datePickerRef = useRef<any>();
    const _customInputRef = useRef<any>();

    const [isCalendarOpened, setIsCalendarOpened] = useState(false);
    const handleCalendarClose = () => setIsCalendarOpened(false);
    const handleCalendarOpen = () => setIsCalendarOpened(true);

    const _onChange = useCallback(
      (date: Date, event: any) => {
        onChange(date);
      },
      [onChange]
    );

    const _onChangeRaw = (e: any) => {
      // input change
      if (e.target.value) {
        let m = moment(e.target.value, baseDateFormat, true);

        if (!m.isValid()) {
          // show Toast
          $toast.danger({
            id: uuidv4(),
            show: true,
            autohide: true,
            delay: 3000,
            icon: "close",
            title: "Invalid input",
            body: toastMessage.validDate
          });

          onChange(undefined);
          if (onInvalidInput) {
            onInvalidInput(e);
          }
        } else {
          if (!isDateRange && ((maxDate && m.isAfter(moment(maxDate))) ||
            (minDate && m.isBefore(moment(minDate))))
          ) {
            $toast.danger({
              id: uuidv4(),
              show: true,
              autohide: true,
              delay: 3000,
              icon: "close",
              title: "Invalid input",
              body: toastMessage.invalidDateRange
            });
          }
          else if (isDateRange && (maxDate && m.isAfter(moment(maxDate)))) {
            $toast.danger({
              id: uuidv4(),
              show: true,
              autohide: true,
              delay: 3000,
              icon: "close",
              title: "Invalid input",
              body: toastMessage.exceeds120Days
            });
          }
          if (isDateRange && (minDate && m.isBefore(moment(minDate)))) {
            $toast.danger({
              id: uuidv4(),
              show: true,
              autohide: true,
              delay: 3000,
              icon: "close",
              title: "Invalid input",
              body: toastMessage.beforePaymentDate
            });
          }
          onChange(undefined);
          if (onInvalidInput) {
            onInvalidInput(e);
          }
        }
      }
    };

    const customInput = (
      <Form.Control className="ms-sm-auto datepicker-calendar" ref={ref} />
    );

    // const ExampleCustomInput = forwardRef((exProp: any, ref: any) => (
    //   <button className="example-custom-input" onClick={exProp.onClick} ref={ref}>
    //     {exProp.value}
    //   </button>
    // ));

    // This is causing issues to place value on outer div instead of input
    // const CustomInput = renderInput?.() || (
    // 	<>
    // 		<Icon optionalClass="input-calendar" name="calendar" color="#788690" />
    // 		<Form.Control id="dateRangePicker" ref={ref} className="ms-sm-auto" />
    // 	</>
    // );

    return (
      <div className={`datepicker-has-icon ${containerClassName}`}>
        <ReactDatePicker
          dateFormat={"MM/dd/yyyy"}
          ref={_datePickerRef}
          customInput={customInput}
          minDate={minDate}
          maxDate={maxDate ? maxDate : new Date("2999-12-31")}
          selected={selectedDate}
          placeholderText={placeholderText}
          highlightDates={highlightDates}
          renderDayContents={renderDayContents}
          selectsStart={selectsStart}
          selectsEnd={selectsEnd}
          startDate={startDate}
          endDate={endDate}
          onChange={_onChange}
          onChangeRaw={_onChangeRaw}
          onCalendarClose={handleCalendarClose}
          onCalendarOpen={handleCalendarOpen}
          onKeyDown={(e) => {
            // disable input
            if (
              e.key !== "/" &&
              e.key !== "Escape" &&
              e.key !== "Enter" &&
              e.key !== "Backspace" &&
              e.key !== "Delete" &&
              e.key !== "ArrowLeft" &&
              e.key !== "ArrowRight" &&
              Number.isNaN(Number(e.key))
            ) {
              e.preventDefault();
            }
          }}
          // aria
          ariaDescribedBy={ariaDescribedBy}
        />
        <Icon
          optionalClass="input-calendar"
          name="calendar"
          color="#788690"
          // onClick={() => {
          //   if (_datePickerRef.current) {
          //     const _datePicker = _datePickerRef.current as ReactDatePicker;
          //     _datePicker.setOpen(true);
          //   }
          // }}
        />
      </div>
    );
  }
);
