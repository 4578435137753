import React, { useState } from 'react';
import { ThemeProvider } from 'react-bootstrap';
import { shallowEqual } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { useAppSelector } from '../app/hooks';
import { PrimaryStyles, SecondaryStyles, Typeface } from '../components/Common/GlobalStyles/GlobalStyles';
import { SessionTimeout } from '../components/SessionTimeout/SessionTimeout';
import ClientAdminNav from "./Nav/ClientAdminNav";
import { getFontFamily } from '../util/fontfamily';

const ClientAdminLayout = (props: any) => {
  const primaryBrandColor = useAppSelector((state: any) => state?.account?.user?.primaryBrandColor, shallowEqual);
  const secondaryBrandColor = useAppSelector((state: any) => state?.account?.user?.secondaryBrandColor, shallowEqual);
  let typefaceFont = useAppSelector((state: any) => state?.account?.user?.typeface, shallowEqual)

  //console.log('FontFamily => ', typefaceFont);

  const theme = {
    primaryColor: !primaryBrandColor ? "#378523" : primaryBrandColor,
    secondary: !secondaryBrandColor ? "#255C96" : secondaryBrandColor,
    typeface: !typefaceFont ? "Lato" : getFontFamily(typefaceFont)
  };


  return (
    <ThemeProvider theme={theme}>
      <React.Fragment>
        <PrimaryStyles theme={theme} />
        <SecondaryStyles theme={theme} />
        <Typeface theme={theme} />
        <ClientAdminNav />
        {props.children ? props.children : <Outlet />}
        {/* <SessionTimeout /> */}
      </React.Fragment>
    </ThemeProvider>
  )
};

export default ClientAdminLayout;
