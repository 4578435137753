import React, { useEffect, useMemo, useState, useRef } from "react";

// Hooks
import { Controller, useForm } from "react-hook-form";
import { useFilterForm } from "../../hooks/useFilterForm";
import { shallowEqual, useSelector } from 'react-redux';
import { useAppDispatch, useAppSelector } from "../../app/hooks";

// components
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Accordion,
} from "react-bootstrap";
import {
  Table,
  TableFilter,
  TableHeader,
  FromToDateRangeFilter
} from "../../components/Common/Table";

import Icon from "../../components/Common/Icons/Icons";
import { DatePicker } from "../../components/Common/DatePicker";
import { ConfirmModal, LoadingWaitModal } from "../../components/Common/Modal";
import { ToastNotification } from "../../components/Common/Toast";

// utils
import moment from "moment";
// api
import { getRolesForUser } from "../../api/roles";
import { getClientUser, getUserData, statusToggle, userDelete } from "../../api/user";

// store
import {setRoles} from "../../features/dictionaries/commonDictionary"

// css
import breakPoints from "../../scss/abstracts/_variables.scss";


// interface
import {ISelectOption, IClientUser} from "../../../types/custom-types";
import { $toast, toastMessage } from "../../util/toastUtil";

import UserListsPlaceholder from "./placeholder/UserListsPlaceholder";
import AddUsers from "./addUsers";
import { resetEditUser } from "../../features/users/editUserData";
import { matchValue } from "../../service/configureClient";
import { ROLE } from "../../routes/Roles";
import Skeleton from "react-loading-skeleton";
import { convertCSTTimeToLocal, formatDate } from '../../util/formatUtil'
import { isArray } from "lodash";

function ClientUsers() {
  const dispatch = useAppDispatch();

  // available roles for create/edit user
  const [roleList, setRoleList] = useState<Array<ISelectOption>>([]);
  const { roles } = useAppSelector(
    (state) => ({ ...state.commonDictionary }),
    shallowEqual
  );
  const userDataList = useAppSelector((state: any) => state.usersList, shallowEqual);
  const accountInfo = useAppSelector((state: any) => state.account, shallowEqual);
  const [userData, setUserData] = useState([]);
  const [preLoading, setPreLoading] = useState(false);
  const [deleteUser, setDeletedUser]= useState("");
  const [statusChange, setStatuChange]= useState("");
  const [statusContent, setStatuContnent]= useState("");
  const [accountStatusToast, setAccountStatuContnent]= useState(false);

  const tableRef = useRef<any>()

  const filterFieldsChange = ()=>{
    tableRef?.current?.gotoFirstPage()
  }

  let usersSessionData = JSON.parse(`${sessionStorage.getItem("users")}`) ? JSON.parse(`${sessionStorage.getItem("users")}`) : [];
	let usersFilterData: any = {};
	if (isArray(usersSessionData)) {
		usersSessionData?.forEach((item: any) => {
			usersFilterData[item.id] = item.value;
		});
	}

  useEffect(()=>{
    dispatch(getClientUser());
    if(roles?.length === 0){
      getRolesForUser().then(res=>{
        if(res.status == 200 && res.data){
          dispatch(setRoles(res.data))
        }
      }).catch(err=>{
        // console.error(err)
      }).finally(()=>{
        // setIsLoading(false);
      })
    }
  }, [roles])

  useEffect(()=>{
    if (userDataList?.data) {
      const data = userDataList?.data?.map((item: any) => {
        return {
          clientId: item.userId,
          clientUserName: item.userName,
          clientFirstName: item.firstName,
          clientLastName: item.lastName,
          clientName: item.clientName,
          clientRole: item.roleName,
          clientStatus: item.status,
          clientLastLoginDate: item.lastLogin?formatDate(convertCSTTimeToLocal(item.lastLogin)):'',
        }
      })
      setUserData(data)
    }

  }, [userDataList])

  useEffect(()=>{
    const roleList = roles.map((item:any)=>{
      return{
        label: item.roleName,
        value: item.rolesId
      }
    })

    roleList.unshift({
      label: "Select Role",
      value: ""
    })

    setRoleList(roleList)
  },[roles])

  // get user detail from Store
  const { role } = useAppSelector(
    (state) => ({ ...state.account }),
    shallowEqual
  );
  const roleName = role?.roleName as ROLE;

  // Modals
  const [isShowUserForm, setIsShowUserForm] = useState(false);
  const [isUserDeleteConfirm, setIsUserDeleteConfirm] = useState(false);
  const [accountStatus, setAccounStatus] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [errorInfo, setErrorInfo]= useState("")
  const [errorToast, setErrorToast] = useState(false);

  // Toasts
  const [isAddOrEditUserSaved, setIsAddOrEditUserSaved] = useState(false);
  const [isUserDeleted, setIsUserDeleted] = useState(false);

  const minDate = undefined;
  const maxDate = moment().toDate();
  const [startDate, setStartDate] = useState<any>();
  const [endDate, setEndDate] = useState<any>();

  const initialTableSearch = {
    clientLastLoginDate: {
      from: usersFilterData?.clientLastLoginDate?.from ? moment(usersFilterData.clientLastLoginDate.from).toDate() : "",
      to: usersFilterData?.clientLastLoginDate?.to ? moment(usersFilterData.clientLastLoginDate.to).toDate() : ""
    },
    clientUserName: usersFilterData?.clientUserName ? usersFilterData.clientUserName : "",
    clientFirstName: usersFilterData?.clientFirstName ? usersFilterData.clientFirstName : "",
    clientLastName: usersFilterData?.clientLastName ? usersFilterData.clientLastName : "",
    clientName: usersFilterData?.clientName ? usersFilterData.clientName : "",
    clientRole: usersFilterData?.clientRole ? usersFilterData.clientRole : "",
    clientStatus: usersFilterData?.clientStatus ? usersFilterData.clientStatus : ""
  };

  const { filters, filterControl, registerFilter, clearFilter, getValuesFilter, resetFilterForm } = useFilterForm({
    defaultValues: initialTableSearch
  });

  useEffect(() => {
		setStartDate(usersFilterData?.clientLastLoginDate?.from ? moment(usersFilterData.clientLastLoginDate.from).toDate() : "");
		setEndDate(usersFilterData?.clientLastLoginDate?.to ? moment(usersFilterData.clientLastLoginDate.to).toDate() : "");
	}, [])

  useEffect(() => {
		sessionStorage.setItem('users', JSON.stringify(filters));
	}, [filters])


  const filterTypes = useMemo(
    () => ({
      fromToDateRangeFilter: FromToDateRangeFilter,
    }),
    []
  );

  const toggleStatus = async(id:any)=>{
    setAccounStatus(false)
    setPreLoading(true)
    statusToggle(id).then((res:any)=>{
        dispatch(getClientUser());
        setAccountStatuContnent(true)
    }).catch((err:any)=>{
      setErrorToast(true)
    }).finally(()=>{
      setPreLoading(false)
    })
  }

  const userDeleted = async(id:any)=>{
    setPreLoading(true)
    userDelete(id).then((res:any)=>{
      if(res.status == 200 && res.data){
        dispatch(getClientUser());
        setIsUserDeleted(true);
      }
    }).catch((err:any)=>{
      setErrorToast(true)
    }).finally(()=>{
      setPreLoading(false)
    })
  }

  const onConfirmDelete = () => {
    setIsUserDeleteConfirm(false);
    userDeleted(deleteUser);
  }

  const columns = useMemo(
    () => [
      {
        Header: ({ column, sorter }: { column: any, sorter: any }) => (
          <TableHeader column={column}>User Name</TableHeader>
        ),
        sortType: (rowA:any,rowB:any,columnId:string,desc:boolean)=>{
            return -rowB.values.clientUserName.localeCompare(rowA.values.clientUserName,"en", { sensitivity: 'accent' });
        },
        accessor: "clientUserName",
        Cell: (props: any) => {
         return <p>{props?.row?.original?.clientUserName}</p>
        }
      },
      {
        Header: ({ column, sorter }: { column: any, sorter: any }) => (
          <TableHeader column={column} sorter={sorter}>First Name</TableHeader>
        ),
        sortType: (rowA:any,rowB:any,columnId:string,desc:boolean)=>{
          return -rowB.values.clientFirstName.localeCompare(rowA.values.clientFirstName,"en", { sensitivity: 'accent' });
        },
        accessor: "clientFirstName",
      },
      {
        Header: ({ column, sorter }: { column: any, sorter: any }) => (
          <TableHeader column={column} sorter={sorter}>Last Name</TableHeader>
        ),
        sortType: (rowA:any,rowB:any,columnId:string,desc:boolean)=>{
          return -rowB.values.clientLastName.localeCompare(rowA.values.clientLastName,"en", { sensitivity: 'accent' });
        },
        accessor: "clientLastName",
      },
      {
        Header: ({ column, sorter }: { column: any, sorter: any }) => (
          <TableHeader column={column} sorter={sorter}>Client</TableHeader>
        ),
        sortType: (rowA:any,rowB:any,columnId:string,desc:boolean)=>{
          let aNm = rowA.values.clientName || '';
          let bNm = rowB.values.clientName || '';
          return -bNm.localeCompare(aNm,"en", { sensitivity: 'accent' });
        },
        accessor: "clientName",
      },
      {
        Header: ({ column, sorter }: { column: any, sorter: any }) => (
          <TableHeader column={column} sorter={sorter}>Role</TableHeader>
        ),
        sortType: (rowA:any,rowB:any,columnId:string,desc:boolean)=>{
          return -rowB.values.clientRole.localeCompare(rowA.values.clientRole,"en", { sensitivity: 'accent' });
        },
        accessor: "clientRole",
      },
      {
        Header: ({ column, sorter }: { column: any, sorter: any }) => (
          <TableHeader column={column} sorter={sorter}>Status</TableHeader>
        ),
        accessor: "clientStatus",
        Cell: (props: any) => {
          if (props?.row?.original?.clientStatus === "Active") {
            return <Button variant="outline-success" title="Click to De-Activate" disabled={matchValue(props?.row?.original?.clientUserName, accountInfo?.user?.email )} className="btn btn-sm"
              onClick={() => {
                if(matchValue(props?.row?.original?.clientUserName, accountInfo?.user?.email ) === false){
                  setAccounStatus(true)
                  setStatuChange(props?.row?.original?.clientId);
                  setStatuContnent(props?.row?.original?.clientStatus);
                }
              }}>Active</Button>
          } else {
            return <Button variant="outline-danger" title="Click to Activate" className="btn btn-sm"
              onClick={() => {
                if(matchValue(props?.row?.original?.clientUserName, accountInfo?.user?.email ) === false){
                setAccounStatus(true)
                setStatuChange(props?.row?.original?.clientId);
                setStatuContnent(props?.row?.original?.clientStatus);
                }
              }}>Inactive</Button>
          }

        }
      },
      {
        Header: ({ column, sorter }: { column: any, sorter: any }) => (
          <TableHeader column={column} sorter={sorter}>Last Login Date</TableHeader>
        ),
        accessor: "clientLastLoginDate",
        filter: "fromToDateRangeFilter"
      },
      {
        Header: () => (
          <div>Edit User</div>
        ),
        accessor: "clientEdit",
        id: "clientEditBtn",
        cellClassName: "text-center",
        Cell: (props: any) => <Button title="Click to Edit" variant="success" className="btn btn-sm"
          onClick={() => {
            setIsShowUserForm(true);
            setIsEdit(true);
            dispatch(getUserData(props?.row?.original?.clientUserName))
          }}><Icon name="edit"></Icon></Button>
      },
      {
        Header: () => (
          <div>Delete User</div>
        ),
        accessor: "clientDelete",
        id: "clientDeleteBtn",
        cellClassName: "text-center",
        Cell: (props: any) =>{
         return <Button title="Click to Delete" variant="danger" disabled={matchValue(props?.row?.original?.clientUserName, accountInfo?.user?.email )} className="btn btn-sm" onClick={() => { setIsUserDeleteConfirm(true),setDeletedUser(props?.row?.original?.clientId) }}><Icon name="delete"></Icon></Button>
        }

      }
    ],
    []
  );

  const tablet = useMemo(
    () => Number(breakPoints.tablet.replace("px", "")),
    []
  );

  const resetTableSearch = () => {
    const initialTableSearchData = {
      clientLastLoginDate: {
        from: "",
        to: ""
      },
      clientUserName: "",
      clientFirstName: "",
      clientLastName: "",
      clientName:"",
      clientRole: "",
      clientStatus: ""
    };
    setEndDate(undefined)
    setStartDate(undefined)
    resetFilterForm(initialTableSearchData)
    filterFieldsChange()
  } 
    
  return (
    <>
      <main id="mainContent">
        <LoadingWaitModal show={preLoading} size={"sm"}></LoadingWaitModal>
        <Container>
          <h1 className="h2">Users</h1>
          <div className="text-end mb-3">
          {userDataList.status === "loading" ? <Skeleton width={100} height={40} />:
            <Button variant="primary" onClick={() => {
              setIsShowUserForm(true);
              setIsEdit(false);
              dispatch(resetEditUser())
            }
            }>Create User</Button>}
          </div>
          <div id="clientUserTableFilters" className="app-panel-container">
            <div className="app-left-panel">
            {userDataList.status === "loading" ? <div className="mt-2"><Skeleton width={50} height={35} /></div> :
              <TableFilter autoShowMinWidth={tablet} onClear={resetTableSearch}>
                <Accordion
                  className="app-accordions" defaultActiveKey={['0','1','2','3','4','5','6']} alwaysOpen
                >
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>User Name</Accordion.Header>
                    <Accordion.Body>
                      <Form.Control {...registerFilter("clientUserName",{onChange: (e) => filterFieldsChange()})} />
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>First Name</Accordion.Header>
                    <Accordion.Body>
                      <Form.Control {...registerFilter("clientFirstName",{onChange: (e) => filterFieldsChange()})} />
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>Last Name</Accordion.Header>
                    <Accordion.Body>
                      <Form.Control {...registerFilter("clientLastName",{onChange: (e) => filterFieldsChange()})} />
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>Client</Accordion.Header>
                    <Accordion.Body>
                      <Form.Control {...registerFilter("clientName",{onChange: (e) => filterFieldsChange()})} />
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>Role</Accordion.Header>
                    <Accordion.Body>
                      <Form.Control {...registerFilter("clientRole",{onChange: (e) => filterFieldsChange()})} />
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>Status</Accordion.Header>
                    <Accordion.Body>
                      <Form.Control {...registerFilter("clientStatus",{onChange: (e) => filterFieldsChange()})} />
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="6">
                    <Accordion.Header>Last Login Date</Accordion.Header>
                    <Accordion.Body>
                      <Controller
                        name="clientLastLoginDate.from"
                        control={filterControl}
                        render={({ field }) => (
                          <div className="text-center">
                            <DatePicker
                              containerClassName="mb-1"
                              selectedDate={field.value}
                              placeholderText="MM/DD/YYYY"
                              maxDate={endDate ? endDate : maxDate}
                              onChange={(date: any) => {
                                if(date){
                                  field.onChange(date);
                                }else{
                                  field.onChange("");
                                }
                                setStartDate(date);
                                setEndDate(getValuesFilter("clientLastLoginDate.to"));
                                filterFieldsChange()
                              }}
                            />
                          </div>
                        )}
                      />
                      <Controller
                        name="clientLastLoginDate.to"
                        control={filterControl}
                        render={({ field }) => (
                          <div className="text-center">
                            <DatePicker
                              selectedDate={field.value}
                              placeholderText="MM/DD/YYYY"
                              minDate={startDate}
                              maxDate={maxDate}
                              onChange={(date: any) => {
                                if(date){
                                  field.onChange(date);
                                }else{
                                  field.onChange("");
                                }
                                setStartDate(getValuesFilter("clientLastLoginDate.from"));
                                setEndDate(date);
                                filterFieldsChange()
                              }}
                            />
                          </div>
                        )}
                      />
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </TableFilter>}
            </div>
            <div className="app-right-panel userList">
              {userDataList.status === "loading" ? <UserListsPlaceholder/> :
              <Table
                ref={tableRef}
                columns={columns}
                data={userData}
                filters={filters}
                filterTypes={filterTypes}
                sortable
                bordered
                hover
                responsive
                // @TODO: required with typescript but should not be undefined?
                //globalFilter={undefined}
                // @TODO: Testing functionality for admin component with expandable rows
                getSubRows={undefined}
                subComponent={undefined}
              />}
            </div>
          </div>
        </Container>
        <ConfirmModal
          show={isShowUserForm}
          size="lg"
          title={isEdit ? "Edit User" : "Add User"}
          submitBtnText="Save"
          description={isEdit ? "Edit user's profile account" : "Create user's profile account"}
          showFooter={false}
          onCancel={() => setIsShowUserForm(false)}
        >
         <AddUsers setErrorInfo={setErrorInfo} setErrorToast={setErrorToast} isEdit={isEdit} setIsShowUserForm={setIsShowUserForm} setPreLoading={setPreLoading} setIsAddOrEditUserSaved={setIsAddOrEditUserSaved}/>
        </ConfirmModal>
        <ConfirmModal
          show={isUserDeleteConfirm}
          size={undefined}
          title="Delete User"
          submitBtnText="Delete"
          description={undefined}
          onConfirm={onConfirmDelete}
          onCancel={() => setIsUserDeleteConfirm(false)}
        >
          <p>Are you sure you want to delete this user's account?</p>
        </ConfirmModal>
        <ConfirmModal
          show={accountStatus}
          size={undefined}
          title={`${statusContent === "Active" ? "Deactive Account" :"Active Account"}`}
          submitBtnText={`${statusContent === "Active" ? "Deactive Account" :"Active Account"}`}
          description={undefined}
          onConfirm={()=>{toggleStatus(statusChange)}}
          onCancel={() => setAccounStatus(false)}
        >
          <p>Are you sure you want to {`${statusContent === "Active" ? "de-active this account" :"active this account"}`}?</p>
        </ConfirmModal>
      </main>

      {/* User Edited Toast */}
      <ToastNotification
        autohide
        show={isAddOrEditUserSaved}
        delay={5000}
        icon="check-double"
        type="success"
        title="User Saved"
        position="top-center"
        body="User detail has been saved successfully."
        onClose={() => setIsAddOrEditUserSaved(false)}
      />
      {/* User Deleted Toast */}
      <ToastNotification
        autohide
        show={isUserDeleted}
        delay={5000}
        icon="check-double"
        type="success"
        position="top-center"
        title="User Deleted"
        body="User has been deleted successfully."
        onClose={() => setIsUserDeleted(false)}
      />
       {/* User status changes Toast */}
       <ToastNotification
        autohide
        show={accountStatusToast}
        delay={5000}
        icon="check-double"
        type="success"
        position="top-center"
        title="User Account Status"
        body={`Selected user account has been status changed successfully.`}
        onClose={() => setAccountStatuContnent(false)}
      />
      <ToastNotification
          autohide
          show={errorToast}
          delay={5000}
          icon="close"
          type="danger"
          title={"Failed!!!"}
          position="top-center"
          body={errorInfo || "Sorry for the inconvenience caused by internal server error. Please try again later."}
          onClose={() => setErrorToast(false)}
      />
    </>
  );
}

export default ClientUsers;
