import { del, get,post,put } from "../util/request";

import {IClientUser} from "../../types/custom-types";
import { createAsyncThunk } from "@reduxjs/toolkit";


export const getUserInfo = function () {
  return get(`/User`);
};

export const updateProfile = (
  userId:string,
  firstName:string,
  lastName:string,
  PhoneNumber:string,
  roleId: string,
  userName: string,
  clientId?: string
) => {
    return put("/User/UpdateProfile", {
      userId,
      firstName,
      lastName,
      PhoneNumber,
      roleId,
      userName
    });
};
export const updateLoginDate = (
  userId:string,
  lastLoginDate:any
) => {
  return put(`/User/updateLoginDate/${userId}`);
};

export const createClientUser = (user: IClientUser)=>{
  return post("/User", user)
}

export const updatedClientUser = (user: IClientUser)=>{
  return put("/User/Update", user)
}

export const getClientUser = createAsyncThunk("getClientUser/getClientUser", async () => {
  const response = await get(`/User/Grid/GetUsers`);
  return response.data;
});

export const getUserData = createAsyncThunk("editUser/editUser", async (email:string) => {
  const response = await get(`/User/${encodeURIComponent(email)}`);
  return response.data;
});

export const statusToggle = (id:any) => {
  return put(`/User/ToggleStatus/${id}`);
};

export const userDelete = (id:any) => {
  return del(`/User/${id}`);
};


export const getUsers = function () {
  return get(`/User`);
};

export const updateUserStatus = (
  userId:string
) => {
  return post("/User/ToggleStatus/"+userId);
};

export const GetPaymentTypeLastFourDigits = function (valutID: number) {
  return get(`/UserPaymentMethod/GetPaymentTypeLastFourDigits/${valutID}`);
};