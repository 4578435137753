import React, { useEffect,forwardRef,useImperativeHandle } from "react";
import { Table as BSTable, Form, InputGroup, Button } from "react-bootstrap";

import Icon from "../Icons/Icons";
import {
  useExpanded,
  useFilters,
  useSortBy,
  useTable,
  usePagination,
  FilterTypes,
  useGlobalFilter
} from "react-table";

export type GlobalFilterOptionsPatm = {
  enable?: boolean | undefined;
  placeholder?: string | undefined;
};

interface ITableParm {
  className?: string | undefined;
  columns: ReadonlyArray<any>;
  data: readonly object[];
  filters?: any[] | null | undefined;
  filterTypes?: FilterTypes<object>;
  sortable: boolean | undefined;
  bordered: boolean | undefined;
  hover: boolean | undefined;
  responsive?: boolean | string;
  getSubRows?:
    | ((originalRow: object, relativeIndex: number) => object[])
    | undefined;
  subComponent?: any | undefined;
  GlobalFilterOptions?: GlobalFilterOptionsPatm;
  initialSortState?: any[]
  hiddenColumns?: string[],
  initialFilters?: any[]
}

export const Table = forwardRef((
  {
  className = "",
  columns,
  data,
  filters,
  filterTypes,
  sortable,
  bordered,
  hover,
  responsive,
  getSubRows,
  GlobalFilterOptions,
  subComponent,
  initialSortState = [],
  hiddenColumns = [],
  initialFilters = []
}: ITableParm,ref)=>{
  const tableHooks: (
    | typeof useExpanded
    | typeof usePagination
    | typeof useFilters
    | typeof useSortBy
  )[] = [];

  if (filters) {
    tableHooks.push(useFilters);
  }
  if (sortable) {
    tableHooks.push(useSortBy);
  }
  tableHooks.push(useExpanded, usePagination);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    //rows,
    prepareRow,
    setAllFilters,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter }
  } = useTable(
    {
      columns,
      data,
      filterTypes,
      getSubRows,
      initialState: { 
        pageIndex: 0,
        sortBy: initialSortState,
        hiddenColumns: hiddenColumns,
        filters: initialFilters
      },
      autoResetPage:false
    },
    useGlobalFilter,
    ...tableHooks
  );

  useImperativeHandle(ref, () => ({
    gotoFirstPage() {
      gotoPage(0)
    }
  }));

  const [isMobile, setIsMobile] = React.useState(false);

  useEffect(() => {
    // Function to check if the screen size corresponds to a mobile device
    const checkMobile = () => {
      const isMobileDevice = window.innerWidth <= 768; // You can adjust the breakpoint as needed
      setIsMobile(isMobileDevice);
    };

    // Initial check
    checkMobile();

    // Add event listener for screen size changes
    window.addEventListener('resize', checkMobile);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', checkMobile);
    };
  }, []);

  useEffect(() => {
    let itemperPage = JSON.parse(`${sessionStorage.getItem('item per page')}`) != null ? JSON.parse(`${sessionStorage.getItem('item per page')}`) : 10;
    setPageSize(Number(itemperPage))
  }, []);

  useEffect(() => {
    if (filters?.length) {
      const handler = setTimeout(() => {
        setAllFilters(filters);
      }, 500);

      return () => clearTimeout(handler);
    }
  }, [filters, setAllFilters]);

  const classes = ["app-table", "align-middle", "table-sticky"];
  if (className) classes.push(className);

  return (
    <React.Fragment>
      {GlobalFilterOptions?.enable && (
        <Form.Group className="mb-3 shadows-light">
          <Form.Label className="visually-hidden" htmlFor="overviewSearch">
            Search Records
          </Form.Label>
          <InputGroup>
            <InputGroup.Text className="bg-white">
              <Icon name="search" size={20} />
            </InputGroup.Text>
            <Form.Control
              id="overviewSearch"
              className="border-start-0 border-end-0"
              placeholder={GlobalFilterOptions?.placeholder || "Search"}
              value={globalFilter || ""}
              onChange={(e) => {
                setGlobalFilter(e.target.value)
                gotoPage(0)
              }}
            />
          </InputGroup>
        </Form.Group>
      )}
      {pageOptions.length == 0 && <label>No records to show.</label>}
      {pageOptions.length > 0 && (
        <>
        <div className= {isMobile ? "table_container" : ""}>
          <BSTable
            className={classes.join(" ")}
            bordered={bordered}
            hover={hover}
            {...getTableProps()}
          >
            <thead className="header">
              {headerGroups.map((headerGroup, i) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column: any) => (
                    <th
                      key={`header-${i}`}
                      className={column.headerClassName}
                      aria-sort={
                        column.isSorted
                          ? column.isSortedDesc
                            ? "descending"
                            : "ascending"
                          : "none"
                      }
                      {...column.getHeaderProps(
                        column.getSortByToggleProps?.()
                      )}
                    >
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>

            <tbody {...getTableBodyProps()}>
              {page.map((row: any, i: number) => {
                prepareRow(row);
                return (
                  <React.Fragment key={`row-fragment-${i}`}>
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell: any, i: any) => {
                        return (
                          <td
                            key={`cell-${i}`}
                            className={cell.column.cellClassName}
                            {...cell.getCellProps()}
                          >
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                    {row.isExpanded &&
                      row.original.subData?.map((subData: any, index: number) =>
                        subComponent({ row, subData, index })
                      )}
                  </React.Fragment>
                );
              })}
            </tbody>
          </BSTable>
          </div>
          {/* Pagination part */}
          <div className="app-table-pagination">
            <Form.Group className="app-pagination-items">
              <Form.Label>Items per page:</Form.Label>
              <Form.Select
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                  sessionStorage.setItem('item per page', JSON.stringify(e.target.value));
                }}
              >
                {[10, 25, 50, 100].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group className="app-pagination-page">
              <Form.Label>Go to page:</Form.Label>
              <Form.Control
                type="number"
                min="1"
                max={pageOptions.length}
                value={pageIndex + 1}
                onChange={(e) => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0;
                  gotoPage(page);
                }}
              />
              <div>
                <strong>
                  {/* {pageIndex + 1} of {pageOptions.length} */}
                  of{" "}
                  {pageOptions.length == 0
                    ? pageOptions.length + 1
                    : pageOptions.length}
                </strong>
              </div>
            </Form.Group>
            <div className="app-pagination-buttons">
              <button
                className="btn btn-pagination"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
                title="First Page"
                aria-label="First Page"
              >
                <Icon name="arrow-double-left" size={18} />
              </button>
              <button
                className="btn btn-pagination"
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
                title="Previous Page"
                aria-label="Previous Page"
              >
                <Icon name="arrow-left" size={18} />
              </button>
              <button
                className="btn btn-pagination"
                onClick={() => nextPage()}
                disabled={!canNextPage}
                title="Next Page"
                aria-label="Next Page"
              >
                <Icon name="arrow-right" size={18} />
              </button>
              <button
                className="btn btn-pagination"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
                title="Last Page"
                aria-label="Last Page"
              >
                <Icon name="arrow-double-right" size={18} />
              </button>
            </div>
          </div>
        </>
      )}
    </React.Fragment>
  );
})