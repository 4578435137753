import moment, { Moment } from "moment";

export const baseDateFormat = "MM/DD/YYYY";

/**
 *
 * @param date - source date, if is undefined Or null Or "" or is invalid will return ""
 * @param outputFormat - output format of string type date
 * @param inputFormat - input format of string type input date
 * @returns formatted date
 */
export function baseFormatDate(
  date: string | Date | Moment | undefined | null,
  outputFormat: string,
  inputFormat?: string
) {
  if (!date) {
    return "";
  }

  if (typeof date === "string") {
    if (inputFormat) {
      const m = moment(date, inputFormat, true);
      if (m.isValid()) {
        return m.format(outputFormat);
      } else {
        return "";
      }
    } else {
      // whether standard time string
      if (date.split("T").length !== 2) {
        console.warn(
          `baseFormatDate: try to convert string to date but No format specified: ${date}`
        );
      }

      const m = moment(date);
      if (m.isValid()) {
        return m.format(outputFormat);
      } else {
        return "";
      }
    }
  } else {
    // console.log(`baseFormatDate: format ${typeof date} to date string`);
    const m = moment(date);
    if (m.isValid()) {
      return moment(date).format(outputFormat);
    } else {
      return "";
    }
  }
}

/**
 *
 * @param date - source date, if is undefined Or null Or "" or is invalid will return ""
 * @param inputFormat - input format of string type date
 * @returns formatted date - MM/DD/YYYY
 */
export function formatDate(
  date: string | Date | Moment | undefined | null,
  inputFormat?: string
) {
  return baseFormatDate(date, baseDateFormat, inputFormat);
}

/**
 *
 * @param date - source date, if is undefined Or null Or "" or is invalid will return ""
 * @param inputFormat - input format of string type date
 * @returns formatted date - MM/DD/YYYY HH:mm:ss A
 */
export function formatDateTime(
  date: string | Date | Moment | undefined | null,
  inputFormat?: string
) {
  return baseFormatDate(date, baseDateFormat + " HH:mm:ss", inputFormat);
}

/**
 *
 * @param date - source date, if is undefined Or null Or "" or is invalid will return ""
 * @param inputFormat - input format of string type date
 * @returns formatted date - MM/DD/YYYY HH:mm A
 */
export function formatDateTimeMinute(
  date: string | Date | Moment | undefined | null,
  inputFormat?: string
) {
  return baseFormatDate(date, baseDateFormat + " HH:mm", inputFormat);
}

/**
 * convert source to Moment object
 * @param date - source date, if is undefined will return now
 * @param inputFormat - input format of string type date
 * @returns - Moment object
 */
export function convertToMoment(
  date: string | Date | Moment,
  inputFormat?: string
) {
  if (!date) {
    console.warn("convertToMoment: input date is null");
  }

  if (typeof date === "string") {
    if (inputFormat) {
      return moment(date, inputFormat);
    } else {
      // whether standard time string
      if (date.split("T").length !== 2) {
        console.warn(
          `convertToMoment: try to convert string to date but No format specified: ${date}`
        );
      }

      return moment(date);
    }
  } else {
    // console.log(`format ${typeof date} to Moment`);
    return moment(date);
  }
}

/**
 *
 * @param numStr - input number
 * @returns - formatted number
 */
export function formatMoney(numStr: string | number) {
  if (!numStr || isNaN(Number(numStr))) {
    return "$ 0.00";
  }
  const moneyPrefix = "$";
  const moneyStr = moneyPrefix + " " + Number(numStr).toFixed(2);
  return moneyStr;
}

export function formatAccountNumber(accountNumber: string) {
  if (!accountNumber) {
    return "";
  }
  const length = accountNumber.length;
  const lastFourNumbers = accountNumber.slice(length - 4, length);
  return `******${lastFourNumbers}`;
}

export function convertCSTTimeToLocal(cstTime:Date):string{
   const _cstTime = moment.tz(cstTime,'America/Chicago')
   return _cstTime.clone().local().toISOString(true)
}

export function checkNumberVal(val:number|string|undefined){
  let _val = Number(val)
  if (isNaN(_val)) {
    return 0
  }
  return _val
}

export function allowOneDot(str: string) {
  if (str?.length > 0) {
    // Replace any non-numeric characters
    str = str.replace(/[^0-9\.]/g, "");
    // Ensure at most one dot
    var dotCount = 0;
    str = str.replace(/\./g, function (match) {
      dotCount++;
      return dotCount <= 1 ? match : "";
    });
    return str;
  }
}

export function isAfterBusinessHours() {
  let cstTime = moment().tz("America/Chicago").format('h:mm A');
  const time = moment(cstTime, 'h:mm A');
  const businessHour = moment('6:59 PM', 'h:mm A');
  return time.isAfter(businessHour);
};

export function allow2DigitsAfterDecimal(value: string) {
  const decimalIndex = value?.indexOf('.');
  if (decimalIndex !== -1) {
    const inputArray = value.split('');
    if (inputArray.length - decimalIndex > 3) {
      inputArray.splice(decimalIndex + 3);
    }
    value = inputArray.join('');
    return value;
  } else {
    return value;
  }
}

export function convertUtcToCst(dateString: string, baseDateFormat: string) {
  return moment.tz(dateString, 'YYYY-MM-DDTHH:mm:ss.SSSSSSS', 'UTC').tz('America/Chicago').format(baseDateFormat + ' h:mm:ss A')
}