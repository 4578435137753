const paymentStatus = {
  Accepted: 1,
  Declined: 2,
  Captured: 3,
  Pending: 4,
  Returned: 5,
  Settled: 6,
  Submitted: 7,
  Voided: 8,
  Blocked: 9,
  Cancelled: 10,
  Paid: 11,
  Scheduled: 12
};

const paymentStatusOptions = [
  {
    id: 1,
    label: "Accepted",
    value: paymentStatus.Accepted
  },
  {
    id: 2,
    label: "Declined",
    value: paymentStatus.Declined
  },
  {
    id: 3,
    label: "Pending",
    value: paymentStatus.Pending
  },
  {
    id: 4,
    label: "Cancelled",
    value: paymentStatus.Cancelled
  },
  {
    id: 5,
    label: "Scheduled",
    value: paymentStatus.Scheduled
  }
];

export { paymentStatus, paymentStatusOptions };
