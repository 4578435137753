//@ts-nocheck
import { FC, useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import Icon from "../../components/Common/Icons/Icons";
import {
  Container,
  Accordion,
  Card,
  Row,
  Col,
  Tab,
  Tabs
} from "react-bootstrap";
import PaymentMethods from "../Profile/PaymentMethods";
import ProfileInformation from "../Profile/ProfileInformation";

import Documents from "../ConsumerMain/Documents";
import BillingHistory from "../ConsumerMain/BillingHistory";
import PaymentsHistory from "../ConsumerMain/PaymentsHistory";
import { shallowEqual } from "react-redux";
import { ROLE } from "../../routes/Roles";
import { Link } from "react-router-dom";
import { $toast } from "../../util/toastUtil";

// Utils
import moment from "moment";
import {
  formatDate,
  formatMoney,
  formatAccountNumber
} from "../../util/formatUtil";
import { getAllMethods, getAcitveClientMethods } from "../../api/paymentMethods";
import {
  initPaymentMethods,
  initUserPaymentMethod
} from "../../features/payment/paymentSlice";
import { getUserPaymentMethods } from "../../api/userPaymentMethods";
import { LoadingWaitModal } from "../../components/Common/Modal/LoadingWaitModal";

const initializePaymentMethods = async (dispatch: any) => {
  let response = await getAcitveClientMethods();
  await dispatch(initPaymentMethods(response.data));
};

const initializeUserPaymentMethods = async (dispatch: any) => {
    let response = await getUserPaymentMethods();
    if (response.status === 200) {
      dispatch(initUserPaymentMethod(response.data));
    }
};
const ClientAccountInfo: FC = () => {
  const dispatch = useAppDispatch();
  const { role } = useAppSelector(
    (state) => ({ ...state.account }),
    shallowEqual
  );
  const { accountId,  accountType, profile } =
  useAppSelector((state) => ({ ...state.consumer }), shallowEqual);
  const { accountNumber} =
	useAppSelector((state) => ({ ...state.account }), shallowEqual);
  const initPageData = async () => {
    setLoading(true)
    try {
      await initializePaymentMethods(dispatch);
      if (role?.roleName == ROLE.Consumer || role?.roleName == ROLE.ClientAdmin || role?.roleName == ROLE.ClientSuperAdmin) {
        await initializeUserPaymentMethods(dispatch);
      }
    } catch (error:any) {
      $toast.showToast({
        autohide: true,
        delay: 5000,
        icon:"close",
        type:"danger",
        title:"Failed",
        body: error.response ? error.response.data : error.message
      })
    } finally {
      setLoading(false)
    }
  };
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    dispatch(initUserPaymentMethod([]))
    initPageData();
  }, []);
  const { permissions } = useAppSelector(
		(state) => ({ ...state.permissions }),
		shallowEqual
	  );
  return (
    <main id="mainContent">
			<LoadingWaitModal show={loading} size={"lg"}></LoadingWaitModal>
      <Container>
        {/* TODO: Hook up with Router Dom? */}
        {/* https://www.npmjs.com/package/use-react-router-breadcrumbs */}
        <div className="mb-3">
          <Link to="/client">Customers</Link> / Account Information
        </div>
        <h1 className="h2">Customer's Account Information</h1>
        <Row>
          <Col lg={6}>
            <Card className="app-card shadows-light mb-3">
              <Card.Body>
                <Card.Title as="h2" className="app-card-title">
                  <Icon name="folder" />
                  Account Information
                </Card.Title>
                <Row>
                  <Col lg={6}>
                    <p className="mb-1">Account Number:</p>
                  </Col>
                  <Col lg={6}>
                    <p className="mb-1">
                      {/* @TODO: admin configuration to mask account number */}
                      <strong>{accountNumber}</strong>
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <p className="mb-1">Loan/Service Type:</p>
                  </Col>
                  <Col lg={6}>
                    <p className="mb-1">
                      <strong>{accountType}</strong>
                    </p>
                  </Col>
                </Row>
                <div className="d-grid gap-3 d-md-flex mt-3">
                  {
                      permissions.canViewPayment
                      && 
                      <Link
                      to={`../makepayment`}
                      className="btn btn-primary"
                    >
                      One-time Payment
                    </Link>
                  }
                 {
                      permissions.canViewPayment
                      && 
                      <Link
                    to={`../pendingpayments`}
                    className="btn btn-secondary"
                  >
                    Pending Payments
                  </Link>
                  }
                  {
                      permissions.canViewPayment
                      && 
                      <Link to="../autopay" className="btn btn-outline-primary">
                      Manage AutoPay
                    </Link>
                  }
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Accordion className="app-accordions mb-3 shadows-light" defaultActiveKey={'0'}>
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <Icon name="user" />
              {profile.FirstName + " " + profile.LastName}
            </Accordion.Header>
            <Accordion.Body>
              <Row>
                <Col md={6}>
                  <h3 className="h4">Profile</h3>
                  <ProfileInformation userDetails={profile} />
                </Col>
                {
                  permissions.canModifyDeleteClientPaymentMethods && 
                  <Col md={6}>
                  <h3 className="h4">Payment Methods</h3>
                  <PaymentMethods setLoading={setLoading} />
                </Col>
                }
              
              </Row>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        {role?.roleName != ROLE.VentureAdmin && 
          <Tabs
          defaultActiveKey="payments"
          id="paymentDataTabs"
          className="app-tabs tabs-large-lg shadows-light"
        >
          <Tab eventKey="payments" title="Payments History">
            <PaymentsHistory/>
          </Tab>
          {/*<Tab eventKey="billing" title="Billing History">*/}
          {/*  <BillingHistory />*/}
          {/*</Tab>*/}
          {role?.roleName == ROLE.Consumer && (
            <Tab eventKey="documents" title="Documents">
              <Documents />
            </Tab>
          )}
        </Tabs>
        }
        
      </Container>
    </main>
  );
};
export default ClientAccountInfo;
