// Hooks
import { useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { shallowEqual } from "react-redux";
// Utils

// Component
import { Container } from "react-bootstrap";

function UserSignIn() {

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const domainHintName = searchParams.get("domain_hint");

  
  const { user, userAccounts, accountNumber, isAuthenticated } = useAppSelector(
    (state) => ({ ...state.account }),
    shallowEqual
  );

  const request = {
    domainHint: domainHintName ? domainHintName : ""
  };

  useEffect(() => {
    if (userAccounts.length >= 0) {
      // handle senario
      navigate("/", { replace: true });
    } else {
      // navigate("/", { replace: true });
    }
  }, [userAccounts]);

  return (
    <main id="mainContent">
      <Container>
        <h1 className="h2">Logging in...</h1>
      </Container>
    </main>
  );
}

export default UserSignIn;
