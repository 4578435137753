import React from "react";
import Icon from "../Icons/Icons";

interface ITableHeader {
	column: any;
	sorter?: any;
	children?: any;
	headerClassName?: string;
}

export function TableHeader({
	column,
	sorter,
	children,
	headerClassName="justify-content-between"
}: ITableHeader) {
	return (
		<button style={{ width: children === 'Payment Type' ? '300px' : '' }} type="button" className={`d-flex ${headerClassName} app-sorting-btn`}>
			{children}
			{sorter?.(column.isSorted, column.isSortedDesc) || (
				<Icon
					optionalClass="ms-2"
					name={
						column.isSorted
							? column.isSortedDesc
								? "arrow-down"
								: "arrow-up"
							: "sort"
					}
					size={20}
				/>
			)}
		</button>
	);
}
