export enum ROLE {
    VentureAdmin = 'VentureAdmin',
    ClientAdmin = 'ClientAdmin',
    Consumer = 'Consumer',
    ClientSuperAdmin = 'ClientSuperAdmin',
    ClientBasic = 'ClientBasic'
}

export enum UsersInGroup {
  Venture = 'Venture',
  Client = 'Client',
  Consumer = 'Consumer',
}
