import React from 'react';
import { Outlet } from 'react-router-dom';
import { SessionTimeout } from '../components/SessionTimeout/SessionTimeout';
import AdminNav from "./Nav/AdminNav";

const AdminLayout = (props:any) => {
  return (
    <React.Fragment>
      <AdminNav />
      {props.children ? props.children: <Outlet />}
      {/* <SessionTimeout /> */}
    </React.Fragment>
  )
};

export default AdminLayout