// Hooks
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";

// components
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { LoadingWaitModal } from "../../components/Common/Modal/LoadingWaitModal";

// util
import { baseDateFormat, formatDate, formatMoney, formatAccountNumber } from "../../util/formatUtil";

// Api
import { getUserAccountDetail } from "../../api/payment";

// Store
import { setAccountDetail } from "../../features/account/accountSlice";
import { shallowEqual } from "react-redux";

function AccountDetails(props: any) {
  const { showErrorToast, accountNumber } = props;

  const { accountDetail, isAuthenticated, accountId } = useAppSelector(
    (state) => ({ ...state.account }),
    shallowEqual
  );

  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);

  const getUserAccountDetailMethod = async (
    dispatch: Function,
    callBack: Function
  ) => {
    getUserAccountDetail({
      "Accountid": accountId,
    })
      .then((res: any) => {
        dispatch(setAccountDetail(res.data));
        callBack();
      })
      .catch((error: any) => {
        showErrorToast(error.response ? error.response.data : error.message);
        callBack();
      });
  };

  useEffect(() => {
    if (isAuthenticated) {
      setLoading(true);
      getUserAccountDetailMethod(dispatch, () => {
        setLoading(false);
      });
    }
  }, [accountNumber]);

  return (
    <>
      <p>
        <strong>Account Number: {accountNumber}</strong>
      </p>
      <Row>
        <Col md>
          <p className="mb-1">Amount Due</p>
          <p>
            <strong>
              {accountDetail?.amountDue ?
                formatMoney(
                  accountDetail?.amountDue
                ) : "$0.00"}
            </strong>
          </p>
        </Col>
        <Col md>
          <p className="mb-1">Due Date</p>
          <p>
            <strong>
              {accountDetail?.dueDate ?
                formatDate(accountDetail?.dueDate, baseDateFormat) : "MM/DD/YYYY"}
            </strong>
          </p>
        </Col>
        <Col md>
          <p className="mb-1">Last Payment Amount</p>
          <p className="mb-1">
            <strong>
              {accountDetail?.lastPaymentAmount ? formatMoney(accountDetail?.lastPaymentAmount) : "$0.00"}
            </strong>
          </p>
          {(accountDetail?.lastPaymentDate) && <p className="size12-font">Payment Date: {formatDate(accountDetail?.lastPaymentDate, baseDateFormat)}</p>}
        </Col>
      </Row>
      <div className="d-grid gap-3 d-md-flex">
        <Link
          to={`../makepayment`}
          className="btn btn-primary"
        >
          One-time Payment
        </Link>
        <Link
          to={`../pendingpayments`}
          className="btn btn-secondary"
        >
          Pending Payments
        </Link>
        <Link to="../autopay" className="btn btn-outline-primary">
          Manage AutoPay
        </Link>
      </div>
      <LoadingWaitModal show={loading} size={"lg"}></LoadingWaitModal>
    </>
  );
}
export default AccountDetails;
