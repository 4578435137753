import {
  createSlice
} from "@reduxjs/toolkit";
import { getPaymentBillingAttributes } from "../../api/configureClient"

interface initialStatePayment {
  data: any,
  status: string,
  error: string | undefined
}

const initialStatePayment: initialStatePayment = {
  data: [],
  status: "",
  error: ""
};

const paymentBillingAttributesSlice = createSlice({
  name: "paymentBillingAttributes",
  initialState: initialStatePayment,
  reducers: {
    setBillingAttrbues: (state, action) => {
      state.data = action.payload;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getPaymentBillingAttributes.pending, (state, action) => {
        state.status = 'loading'
        state.data = []
        state.error = ""
      })
      .addCase(getPaymentBillingAttributes.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.data = action.payload
        state.error = ""
      })
      .addCase(getPaymentBillingAttributes.rejected, (state, action) => {
        state.status = 'failed'
        state.data = ""
        state.error = action.error.message
      })
  }
});

export default  paymentBillingAttributesSlice.reducer;
