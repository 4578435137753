
export function replaceSpace(str:string,allowNumber=true){
     let _str = str
     .replace(/^\s/g,'') // first letter can not be space
     .replace(/\s{2,}/,'') //handle more than one space
     .replace(/[\'\"\\\/\b\f\n\r\t]/, '')
     .replace(/[\#\$\%\^\&\*\{\}\:\"\<\>\?\,\!\=\+\[\]\|\;]/, '')
     if(!allowNumber){
       return _str.replace(/\d/g,'')
     }
     return _str
}

export const userNameRegEx = /^[a-zA-Z ]+$/ //one space is valid

export const phoneNumberRegEx = /^[(]{0,1}[0-9]{3}[)]{0,1}[-]{0,1}[0-9]{3}[-]{0,1}[0-9]{4}$/

export const zipCodeRegEx = /^[0-9]{5}(?:-[0-9]{4})?$/

export const alphaCharactersWithSpaceRegExStr = '^[a-zA-Z]([a-zA-Z ]{0,1}[a-zA-Z])*$';
export const alphaCharactersWithSpaceRegExFun = ()=>{
  return new RegExp(alphaCharactersWithSpaceRegExStr);
}

export const guidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/;

export function replaceSpecialCharsAndNumbers(str: string, preventSpecialChars: boolean) {
  if (preventSpecialChars) {
    return str.replace(/[0-9]/g, '').replace(/[&\/\\#,+@!^_=()$~%.;'":*?<>{}]/g, '').replace(/[\[\]']+/g, '');
  }
  return str.replace(/[0-9]/g, '');
}

export function changeToUsMobileNumFormat(str: string) {
  return str.replace(/\D/g,'').replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
}