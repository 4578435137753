import React, { FC } from "react";
import { Form, Card, Col, Row } from "react-bootstrap";
import Icon from "../../components/Common/Icons/Icons";

type props = {
  hasAutoPay: Boolean,
  enrolltoggled: any,
  setReviewUnEnrollAutoPay: Function
  setenrolltoggled: Function
};

const AutoPayEnrollmentCard: FC<props> = ({ hasAutoPay, setReviewUnEnrollAutoPay, enrolltoggled, setenrolltoggled }) => {
  return (
    <Card className="mb-3 shadows-light">
      <Card.Body>
        <Card.Title as="h2" className="app-card-title">
          <Icon name="calendar" />
          Autopay
        </Card.Title>
        {
          hasAutoPay && <Row>
            <Col sm={1}>
              <div className="float-start">
                <Form.Check
                  type="switch"
                  id="configAutoPayEnrolled"
                  label=""
                  checked={true}
                  onChange={() => setReviewUnEnrollAutoPay(true)}
                />
              </div>
            </Col>
            <Col sm={11}>
              <p>
                You are <b>enrolled</b> in autopay
              </p>
            </Col>
          </Row>
        }
        {
          !hasAutoPay &&
          <Row>
            <Col sm={1}>
              <div className="float-start">
                <Form.Check
                  type="switch"
                  id="configAutoPay"
                  checked={enrolltoggled}
                  onChange={(e) => {
                    setenrolltoggled(e.currentTarget.checked)
                  }
                  }
                />
              </div>
            </Col>
            <Col sm={11}>
              <p>
                You are <strong>not enrolled</strong> in autopay
              </p>
            </Col>
          </Row>
        }
      </Card.Body>
    </Card>
  )
};

export default React.memo(AutoPayEnrollmentCard);