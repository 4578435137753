import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";

// Admin Layout
import AdminLayout from "../layout/AdminLayout";

// Admin
import AdminMain from "../pages/AdminUsers/AdminMain";
import ConfigureClient from "../pages/AdminConfigureClient/ConfigureClient";

// Client Layout
import ClientAdminLayout from "../layout/ClientAdminLayout";

// Client Admin
import ClientAdminMain from "../pages/ClientAdminMain";
import ClientAccountInformation from "../pages/ClientAccountInformation";
import ClientDocuments from "../pages/ClientDocuments";

// Consumer Layout
import ConsumerLayout from "../layout/ConsumerLayout";

// Client Facing
import ConsumerDashboard from "../pages/ConsumerDashboard";
import ConsumerMain from "../pages/ConsumerMain";
import AutoPay from "../pages/AutoPay";
import UnRolledAutoPay from "../pages/AutoPay/UnRolledAutoPay";
import MakePayment from "../pages/MakePayment";
import PendingPayments from "../pages/PendingPayments";
import PaymentSummary from "../pages/MakePayment/PaymentSummary";
import PdfViewer from "../pages/ConsumerMain/PdfViewer";

import { UserSignIn } from "../pages/SignIn";

// Global
import Logout from "../pages/Logout"
import Profile from "../pages/Profile";

// No Match
import NoMatch from "../NoMatch";
import UnAuthorized from "../pages/Common/UnAuthorized";

//Role Authentication
import { ROLE } from './Roles';
import PrivateRoute from './PrivateRoute';

//Landing Page
import Welcome from "../pages/Welcome/Welcome";

//Base Layout
import BaseLayout from "../layout/BaseLayout";
import AutoPaySummary from "../pages/AutoPay/AutoPaySummary";
import ClientUsers from "../pages/Users";
import ClientProfile from "../pages/Profile/ClientAdmin/ClientProfile";
import ConfigureClientSummary from "../pages/AdminConfigureClient/ConfigureClientSummary";
import { useLocation } from 'react-router-dom';
import { useEffect } from "react";

import { resetAccount } from "../features/account/accountSlice";
import { persistor } from "../app/store";
import { useAppDispatch } from "../app/hooks";
import { setisAuthenticated, setdecodedData } from "../features/account/accountSlice";
import { setIdToken } from "../features/account/accountSlice";
import { setDocuments } from "../features/document/documentSlice";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import jwt_decode from "jwt-decode";

const Routing = () => {

	const location = useLocation();
	const dispatch = useAppDispatch();
	const BASE_URL = process.env.REACT_APP_REDIRECTURISCCU;
	const navigate = useNavigate();

	async function getJwtToken(samlResponse: string) {

		axios.get(`${BASE_URL}/Authenticate/ValidateToken?token=${samlResponse}`)
			.then((response) => {
				try {
					const decoded = jwt_decode(response.data.jwt);
					if (decoded) {
						localStorage.setItem('token', response.data.jwt);
						dispatch(setdecodedData(decoded));
						dispatch(setisAuthenticated(true));
						dispatch(setIdToken(response.data.jwt));
					} else {
						navigate('/unauthorized', { replace: true });
					}
				} catch (error) {
					navigate('/unauthorized', { replace: true })
					console.error('Token Error: ', error);
				}
			})
			.catch((err) => {
				console.error(err);
				navigate('/unauthorized', { replace: true })
			})
	}

	useEffect(() => {
		const urlSearchParams = new URLSearchParams(location.search);
		const hasLogout = window.location.href.includes('logout');
		const mode = urlSearchParams.get('mode');
		const freshLogin = urlSearchParams.get('domain_hint');
		const samlResponse: string | undefined = urlSearchParams.get('token')?.toString();
		if (samlResponse != null) {
			if (samlResponse) {
				localStorage.clear();
				sessionStorage.clear();
				dispatch(setisAuthenticated(false));
				dispatch(resetAccount());
				dispatch(setDocuments([]));
				sessionStorage.setItem('samlResponseData', samlResponse);
				getJwtToken(samlResponse);
			}
			else {
				if (samlResponse === '') {
					if (persistor) {
						persistor.pause();
						persistor.flush().then(() => {
							return persistor.purge();
						});
					}
					localStorage.clear();
					sessionStorage.clear();
					navigate('/unauthorized', { replace: true })
				}
			}
		}

		const samlResponseData = sessionStorage.getItem('samlResponseData')
		if (samlResponseData == null && !hasLogout) {
			if (persistor) {
				persistor.pause();
				persistor.flush().then(() => {
					return persistor.purge();
				});
			}
			localStorage.clear();
			sessionStorage.clear();
			navigate('/unauthorized', { replace: true })
		}

		if (freshLogin) {
			if (mode?.toLowerCase() === 'pay') {
				localStorage.setItem("pay", "true");
			} else {
				localStorage.setItem("pay", "false");
			}
		}
	}, []);

	return (
		<Routes>
			<Route path='/' element={<BaseLayout />}>
				<Route index element={<Welcome />} />
			</Route>

			<Route path='/admin' element={
				<PrivateRoute roles={[ROLE.VentureAdmin]}>
					<AdminLayout />
				</PrivateRoute>
			}>
				<Route index element={<AdminMain />} />
				<Route path="/admin/configure-client" element={<ConfigureClient />} />
				<Route path="/admin/configure-client/:clientId" element={<ConfigureClient />} />
				<Route path="/admin/configure-client-summary" element={<ConfigureClientSummary />} />
				<Route path="/admin/users" element={<ClientUsers />} />
				<Route path="/admin/profile" element={<ClientProfile />} />
			</Route>

			<Route path='/consumer' element={
				<PrivateRoute roles={[ROLE.Consumer]}>
					<ConsumerLayout />
				</PrivateRoute>
			}>
				<Route index element={<ConsumerDashboard />} />
				<Route path='/consumer/accounts' element={<ConsumerDashboard />} />
				<Route path="/consumer/account-detail" element={<ConsumerMain />} />
				<Route path="/consumer/autopay" element={<AutoPay />} />
				<Route path="/consumer/autopay/unrolled" element={<UnRolledAutoPay />} />
				<Route path="/consumer/autopay/summary" element={<AutoPaySummary />} />
				<Route path="/consumer/makepayment" element={<MakePayment />} />
				<Route path="/consumer/makepayment/payment-summary" element={<PaymentSummary />} />
				<Route path="/consumer/pendingpayments" element={<PendingPayments />} />
				<Route path="/consumer/profile" element={<Profile />} />
			</Route>

			<Route path="/client" element={
				<PrivateRoute roles={[ROLE.ClientSuperAdmin, ROLE.ClientAdmin, ROLE.ClientBasic]}>
					<ClientAdminLayout />
				</PrivateRoute>
			}>
				<Route index element={<ClientAdminMain />} />
				<Route path="/client/accountinformation" element={<ClientAccountInformation />} />
				<Route path="/client/autopay" element={
					<PrivateRoute roles={[ROLE.ClientSuperAdmin, ROLE.ClientAdmin]}>
						<AutoPay />
					</PrivateRoute>
				} />
				<Route path="/client/autopay/summary" element={<AutoPaySummary />} />
				<Route path="/client/autopay/unrolled" element={<UnRolledAutoPay />} />
				<Route path="/client/pendingpayments" element={
					<PrivateRoute roles={[ROLE.ClientSuperAdmin, ROLE.ClientAdmin]}>
						<PendingPayments />
					</PrivateRoute>
				} />
				<Route path="/client/makepayment" element={
					<PrivateRoute roles={[ROLE.ClientSuperAdmin, ROLE.ClientAdmin]}>
						<MakePayment />
					</PrivateRoute>
				} />
				<Route path="/client/makepayment/:from" element={
					<PrivateRoute roles={[ROLE.ClientSuperAdmin, ROLE.ClientAdmin]}>
						<MakePayment />
					</PrivateRoute>
				} />
				<Route path="/client/makepayment/payment-summary" element={<PaymentSummary />} />
				<Route path="/client/documents" element={<ClientDocuments />} />
				<Route path="/client/users" element={
					<PrivateRoute roles={[ROLE.ClientSuperAdmin]}>
						<ClientUsers />
					</PrivateRoute>
				} />
				<Route path="/client/profile" element={<ClientProfile />} />
			</Route>

			<Route path="customer/sso/user_signin" element={<UserSignIn />} />
			{/* <Route path="admin/sso/user_signin" element={<AdminSignIn />} /> */}
			{/* <Route
				path="admin/sso/associate_signin_page"
				element={<AssociateSigninPage />}
			/> */}

			<Route path="logout" element={<Logout />} />
			<Route path="unauthorized" element={<UnAuthorized />} />
			<Route path="viewer" element={<PdfViewer />} />

			<Route path="*" element={<NoMatch />} />
		</Routes>
	);
};
export default Routing;
