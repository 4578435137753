// @ts-nocheck
import React, { useEffect, useRef, forwardRef } from "react";
// Components
import {
  Form,
  Popover,
  Card
} from "react-bootstrap";

import { Controller, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import Icon from "../../components/Common/Icons/Icons";

// Hooks
import { useAppSelector } from "../../app/hooks";
//Store
import { shallowEqual } from "react-redux";

// Utils
import { clientPaymentMethods } from "../../common/paymentMethods";
import { ROLE } from "../../routes/Roles";
import EnterPaymentInfoForm from "./EnterPaymentInfoForm";


const SelectPaymentMethod = forwardRef((props: any, ref) => {
  const {
    formProps,
    setShowCvv,
    forceSaveNewPayment,
    selectedChanged = (selectedVal: any) => { },
    getSavedPaymentInfo,
    getErrorInfo,
    hasAutoPay,
    userPaymentMethodId,
    parentLoading = false
  } = props.props;
  const { control, reset, getValues, clearErrors } = formProps;
  const { userPaymentMethod, paymentMethods } = useAppSelector(
    (state) => ({ ...state.payment }),
    shallowEqual
  );
  const checkRef = useRef<HTMLInputElement>(null);
  const { user } = useAppSelector(
    (state) => ({ ...state.account }),
    shallowEqual
  );
  const emitPaymentInfo = (info: tokenInfoInterface) => {
    getSavedPaymentInfo(info)
  };

  const emitErrorInfo = (error: string) => {
    getErrorInfo(error)
  };

  const getCheckRef = (index: number, item: any) => {
    if (hasAutoPay) {
      if (item.userPaymentMethodId == userPaymentMethodId) {
        return checkRef
      } else {
        return null
      }
    } else {
      if (index === 0) {
        return checkRef
      } else {
        return null
      }
    }

  }

  useEffect(() => {
    // checked first
    if (checkRef && checkRef.current) {
      checkRef.current.checked = true;
    }
    let filterPaymentMethod = [];
    if (userPaymentMethod.length > 0) {
      userPaymentMethod.forEach((item:any) => {
        if (paymentMethods.find((i:any)=> i.paymentMethodId === item.paymentMethodId))
          filterPaymentMethod.push(item);
      });
    }

    if (filterPaymentMethod.length > 0) {
      if (!hasAutoPay) {
        const firstMethod = filterPaymentMethod[0];
        reset({
          Payment_Method: firstMethod.userPaymentMethodId
        });

          if (
            firstMethod.paymentMethodId === clientPaymentMethods.creditCard ||
            firstMethod.paymentMethodId === clientPaymentMethods.debitCard
          ) {
            setShowCvv(true)
          }
          else {
            setShowCvv(false)
          }
          selectedChanged(firstMethod);
        }else{
          const _method = filterPaymentMethod.find(item=>item.userPaymentMethodId == userPaymentMethodId)
          reset({
            Payment_Method: _method?_method.userPaymentMethodId:''
          });

          if (
            _method?.paymentMethodId === clientPaymentMethods.creditCard ||
            _method?.paymentMethodId === clientPaymentMethods.debitCard
          ) {
            setShowCvv(true)
          }
          else {
            setShowCvv(false)
          }
          selectedChanged(_method);
        }
    }
  }, [userPaymentMethod]);

  return (
    <>
      <Card className="mb-3 shadows-light">
        <Card.Body>
          <Card.Title as="h2" className="app-card-title">
            <Icon name="credit-card" />
            Select Payment Method
          </Card.Title>
          <Controller
            name="Payment_Method"
            control={control}
            render={({ field }) => (
              <Form.Group className="mb-3">
                {(userPaymentMethod && userPaymentMethod.length > 0) &&
                  userPaymentMethod.filter((item:any) => paymentMethods.find((i:any)=> i.paymentMethodId === item.paymentMethodId)).length > 0 &&
                  userPaymentMethod.filter((item:any) => paymentMethods.find((i:any)=> i.paymentMethodId === item.paymentMethodId)).map((p, index) => (
                    <Form.Check
                      key={p.userPaymentMethodId}
                      type="radio"
                      ref={getCheckRef(index, p)}
                      id={`${p.paymentMethodName}-${index}`}
                      name="method"
                      aria-describedby="payMethodInformation"
                      label={p.nickName}
                      onChange={() => {
                        field.onChange(p.userPaymentMethodId);
                        if (
                          p.paymentMethodId === clientPaymentMethods.creditCard ||
                          p.paymentMethodId === clientPaymentMethods.debitCard
                        ) {
                          setShowCvv(true)
                        }
                        else {
                          setShowCvv(false)
                        }

                        selectedChanged(p);
                      }}
                    />
                  ))}

                <Form.Check
                  key="Add"
                  id="addNewMethod"
                  type="radio"
                  name="method"
                  label={forceSaveNewPayment ? "Add New Payment Method" : "New Payment Method"}
                  value={"Add"}
                  onChange={(e) => {
                    field.onChange("Add")
                    selectedChanged("Add New");
                    setShowCvv(false)
                    // setShowNewPayment("Add New");
                  }}
                />
              </Form.Group>
            )}
          />
          <div>
            {user.role.roleName == ROLE.Consumer &&
              (<Link to="../profile" className="btn btn-outline-primary">
                Manage Methods
              </Link>)}
            {(user.role.roleName == ROLE.ClientAdmin || user.role.roleName == ROLE.ClientSuperAdmin) && (
              <Link to="/client/accountinformation" className="btn btn-outline-primary">
                Manage Methods
              </Link>
            )}
          </div>
        </Card.Body>
      </Card>
      {getValues("Payment_Method") === "Add" && <EnterPaymentInfoForm emitPaymentInfo={emitPaymentInfo} emitErrorInfo={emitErrorInfo} forceSaveNewPayment={forceSaveNewPayment} formProps={formProps} ref={ref} />}
    </>
  );
})

export default SelectPaymentMethod;
