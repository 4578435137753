import {
  createSlice
} from "@reduxjs/toolkit";
import { getPaymentMethods } from "../../api/configureClient"
import { revertAllClientData } from "./configureAccountSlice";

interface initialStatePaymentMethods {
  data: any,
  status: string,
  error: string | undefined
}

const initialStatePaymentMethods: initialStatePaymentMethods = {
  data: [],
  status: "",
  error: ""
};

const paymentMethodsSlice = createSlice({
  name: "paymentMethods",
  initialState: initialStatePaymentMethods,
  reducers: {
    setBillingAttrbues: (state, action) => {
      state.data = action.payload;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getPaymentMethods.pending, (state, action) => {
        state.status = 'loading'
        state.data = []
        state.error = ""
      })
      .addCase(getPaymentMethods.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.data = action.payload
        state.error = ""
      })
      .addCase(getPaymentMethods.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
        state.data = ""
      })
      .addCase(revertAllClientData, () => initialStatePaymentMethods)
  }
});

export default  paymentMethodsSlice.reducer;
