import Skeleton from "react-loading-skeleton";
import { Col, Form, Row } from "react-bootstrap";

const AccountPlaceholder = () => {

    return (
        <Row>
            {[...Array(6)].map((value, index) => {
                return (
                    <Col key={index} className="mb-4" md={6}>
                        <div className="mb-1">
                            <Skeleton width={120} height={20} />
                        </div>
                        <div className="app-config-switch_type">
                            <Skeleton width={"100%"} height={40} />
                        </div>
                    </Col>
                )
            })}
        </Row>
    );
};

export default AccountPlaceholder;