// Hooks
import { useEffect, useMemo, useState, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useFilterForm } from "../../hooks";
import { useSearchParams } from "react-router-dom";

// Components
import { Accordion, Form, ToastContainer } from "react-bootstrap";
import { Controller } from "react-hook-form";
import { DatePicker } from "../../components/Common/DatePicker";
import {
  FromToDateRangeFilter,
  Table,
  TableFilter,
  TableHeader
} from "../../components/Common/Table";
import { LoadingWaitModal } from "../../components/Common/Modal/LoadingWaitModal";
import { ToastWrapper } from "../../components/Common/Toast/ToastWrapper";
import TogglePayType from "../../components/Common/TogglePayType/TogglePayType";

// Types
import { EPToastProps } from "../../../types/custom-types";

// Api
import { getPaymentHistory } from "../../api/payment";

// Utils
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { convertCSTTimeToLocal, formatDate } from "../../util/formatUtil";
import { ROLE } from "../../routes/Roles";

// scss
import breakPoints from "../../scss/abstracts/_variables.scss";

// Store
import { initPaymentHistory } from "../../features/payment/paymentSlice";
import { shallowEqual } from "react-redux";
import { isArray } from "lodash";

function PaymentsHistory() {
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);
  const [toasts, setToasts] = useState<Array<EPToastProps>>([]);

  // show the last 6 months history as a default.
  const [startDate, setStartDate] = useState<Date|undefined>(
    moment().subtract(6, "months").toDate()
  );
  const [endDate, setEndDate] = useState<Date|undefined>(new Date());

  const tableRef = useRef<any>()  

  const filterFieldsChange = ()=>{
    tableRef?.current?.gotoFirstPage()
  }

  let paymentHistorySessionData = JSON.parse(`${sessionStorage.getItem("payment history")}`) ? JSON.parse(`${sessionStorage.getItem("payment history")}`) : [];
  let paymentHistoryFilterData: any = {};
  if (isArray(paymentHistorySessionData)) {
    paymentHistorySessionData?.forEach((item: any) => {
      paymentHistoryFilterData[item.id] = item.value;
    });
  }

  const {
    filters,
    filterControl,
    registerFilter,
    clearFilter,
    getValuesFilter,
    resetFilterForm
  } = useFilterForm({
    defaultValues: {
      paymentType: paymentHistoryFilterData?.paymentType ? paymentHistoryFilterData.paymentType : "",
      paymentDate: {
        from: paymentHistoryFilterData?.paymentDate?.from ? moment(paymentHistoryFilterData.paymentDate.from).toDate() : startDate,
        to: paymentHistoryFilterData?.paymentDate?.to ? moment(paymentHistoryFilterData.paymentDate.to).toDate() : endDate
      },
      paymentStatus: paymentHistoryFilterData?.paymentStatus ? paymentHistoryFilterData.paymentStatus : "",
    }
  });

  useEffect(() => {
    sessionStorage.setItem('payment history', JSON.stringify(filters));
  }, [filters])
  
  const filterTypes = useMemo(
    () => ({
      fromToDateRangeFilter: FromToDateRangeFilter
    }),
    []
  );

  const columns = [
    {
      Header: ({ column }: any) => (
        <TableHeader column={column}>Statement Date</TableHeader>
      ),
      accessor: "statementDate",
      filter: "fromToDateRangeFilter",
      sortType: (rowA:any,rowB:any,columnId:string,desc:boolean)=>{
        const _rowA = moment(rowA.values.statementDate)
        const _rowB = moment(rowB.values.statementDate)
        if (desc) {
          return moment(_rowA).isAfter(moment(_rowB))? 1: -1
        }else{
          return moment(_rowA).isAfter(moment(_rowB))? 1: -1
        }
      },
    },
    {
      Header: ({ column }: any) => (
        <TableHeader column={column}>Due Date</TableHeader>
      ),
      accessor: "dueDate",
      filter: "fromToDateRangeFilter",
      sortType: (rowA:any,rowB:any,columnId:string,desc:boolean)=>{
        const _rowA = moment(rowA.values.dueDate)
        const _rowB = moment(rowB.values.dueDate)
        if (desc) {
          return moment(_rowA).isAfter(moment(_rowB))? 1: -1
        }else{
          return moment(_rowA).isAfter(moment(_rowB))? 1: -1
        }
      },
    },
    {
      Header: () => <div>Payment Date</div>,
      accessor: "paymentDate",
      filter: "fromToDateRangeFilter",
      disableSortBy: true
    },
    {
      Header: ({ column }: any) => (
        <TableHeader column={column}>Transaction Date</TableHeader>
      ),
      accessor: "transactionDate",
      filter: "fromToDateRangeFilter",
      sortType: (rowA:any,rowB:any,columnId:string,desc:boolean)=>{
        const _rowA = moment(rowA.values.transactionDate)
        const _rowB = moment(rowB.values.transactionDate)
        if (desc) {
          return moment(_rowA).isAfter(moment(_rowB))? 1: -1
        }else{
          return moment(_rowA).isAfter(moment(_rowB))? 1: -1
        }
      },
    },
    {
      Header: ({ column }: any) => (
        <TableHeader column={column}>Payment Date Raw</TableHeader>
      ),
      accessor: "paymentDateRaw",
    },
    {
      Header: ({ column }: any) => (
        <TableHeader column={column}>Payment Type</TableHeader>
      ),
      // Task 43094: Add Eye Icon Functionality to Payment Methods and Payment History Page
      Cell: (props:any) => {
				return(<>
					{props?.value} <TogglePayType optionalClass="ms-1" valutid={props?.row?.original?.paymentGatewayCustomerVaultId} />
				</>)
      },
      accessor: "paymentType",
    },
    {
      Header: ({ column }: any) => (
        <TableHeader headerClassName="justify-content-between" column={column}>
          Last Payment Amount
        </TableHeader>
      ),
      accessor: "paymentAmount"
    },
    {
      Header: ({ column }: any) => (
        <TableHeader headerClassName="justify-content-between" column={column}>
          Payment Status
        </TableHeader>
      ),
      accessor: "paymentStatus"
    },
    // for client admin user
    {
      Header: ({ column }: any) => (
        <TableHeader headerClassName="justify-content-between" column={column}>
          Payment Made By
        </TableHeader>
      ),
      accessor: "paymentMadeBy"
    },
    {
      Header: ({ column }: any) => (
        <TableHeader headerClassName="justify-content-between" column={column}>
          Transaction ID
        </TableHeader>
      ),
      accessor: "paymentTransactionId",
      Cell: (props:any) =>
				<div className="truncate-text" title={props?.value}>{props?.value}</div>
    }
  ];

  const { role, accountId } = useAppSelector(
    (state) => ({ ...state.account }),
    shallowEqual
  );

  const getPaymentHistoryMethod = async (
    dispatch: Function,
    callBack: Function
  ) => {
    try {
      const response = await getPaymentHistory({
        "Accountid": accountId,
      });
      if (response.status === 200) {
        const { data } = response
        data.map((item:any) => {
           item.paymentDate = item.paymentDate?convertCSTTimeToLocal(item.paymentDate):null
           return item
        });
        dispatch(initPaymentHistory(data));
      }
    } catch (ex) {
      console.error(ex);
    } finally {
      callBack();
    }
  };
  

  const { paymentHistory } = useAppSelector(
    (state) => ({ ...state.payment }),
    shallowEqual
  );

  const tablet = useMemo(
    () => Number(breakPoints.tablet.replace("px", "")),
    []
  );

  const searchParams = useSearchParams()[0];
  const accountNumbers = searchParams.get("accountNumber") + "";

  useEffect(() => {
    // filterPaymentHistoryMethod(dispatch, filterDada);
    // setLoading(true);
    getPaymentHistoryMethod(dispatch, () => {
      // setLoading(false)
    });
  }, [dispatch, accountNumbers]);

  const resetTableSearch = () => {
    const _startDate = moment().subtract(6, "months").toDate();
    setStartDate(_startDate);

    const _endDate = new Date();
    setEndDate(_endDate);

    const initialTableSearch = {
      paymentDate: {
        from: _startDate,
        to: _endDate
      },
      paymentType: "",
      paymentStatus: ""
    };
    sessionStorage.setItem('payment history', JSON.stringify(initialTableSearch));
    resetFilterForm(initialTableSearch);
    filterFieldsChange()
  };

  const showToast = (newToast: EPToastProps) => {
    // if(toasts.findIndex(x=>x.show && x.body === newToast.body)){
    //   return
    // }
    const toasts_copy = toasts.filter(
      (x) => x.show && x.body !== newToast.body
    );
    toasts_copy.push(newToast);

    setToasts(toasts_copy);
  };

  const hideToast = (id: string) => {
    const toasts_copy = toasts.filter((x) => x.show && x.id !== id);
    setToasts(toasts_copy);
  };

  const onInputInvalidDate = (event: any) => {
    //console.log("onInputInvalidDate: ", event);

    // showToast({
    //   id: uuidv4(),
    //   show: true,
    //   autohide: true,
    //   delay: 3000,
    //   icon: "close",
    //   type: "danger",
    //   title: "Invalid input",
    //   body: "Please enter the valid date (MM/DD/YYYY)"
    // });
  };

  const tableData = useMemo(()=>{
    if (paymentHistory && paymentHistory.length>0) {
      let _data:object[] = []
      paymentHistory.forEach((item:any) => {
        let _item = {
          paymentDate: formatDate(item.paymentDate),
          transactionDate: formatDate(item.transactionDate),
          transactionDateRaw: item.transactionDate,
          paymentType: item.paymentMethodName + " - " + `${item.paymentMethodNickName != null ? item.paymentMethodNickName : 'Unsaved Payment Nickname'}`,
          paymentAmount: "$" + item.amount.toFixed(2),
          paymentStatus: item.paymentStatusDisplay,
          paymentMadeBy: item.createdUserRoleName + "-" + item.createdUserName,
          paymentDateRaw: item.paymentDate,
          paymentGatewayCustomerVaultId: item.paymentGatewayCustomerVaultId,
          statementDate: formatDate(item.statement.statementDate),
          dueDate: formatDate(item.statement.dueDate),
          paymentTransactionId: item.paymentTransactionId,             
        };
        _data.push(_item)
      })
      return _data.sort((a:any, b:any) => moment(b.transactionDateRaw).diff(moment(a.transactionDateRaw))); 
    }else{
      return []
    }
  },[paymentHistory])

  return (
    <div id="billingTableFilters" className="app-panel-container">
      <div className="app-left-panel">
        <TableFilter autoShowMinWidth={tablet} onClear={resetTableSearch}>
          {/* showOnLoad */}
          <Accordion className="app-accordions" defaultActiveKey={['0','1','2']} alwaysOpen>
            <Accordion.Item eventKey="0">
              <Accordion.Header>Payment Date</Accordion.Header>
              <Accordion.Body>
                <Controller
                  name="paymentDate.from"
                  control={filterControl}
                  render={({ field }) => (
                    <div className="text-center">
                      <DatePicker
                        containerClassName="mb-1"
                        selectedDate={field.value}
                        placeholderText="MM/DD/YYYY"
                        maxDate={endDate}
                        onChange={(date: Date|undefined) => {
                          if(date){
                            field.onChange(date);
                          }else{
                            field.onChange("");
                          }
                          setStartDate(date);
                          setEndDate(getValuesFilter("paymentDate.to"));
                          filterFieldsChange()
                        }}
                        onInvalidInput={onInputInvalidDate}
                        ariaDescribedBy="scheduleDateDisclaimer"
                      />
                    </div>
                  )}
                />

                <Controller
                  name="paymentDate.to"
                  control={filterControl}
                  render={({ field }) => (
                    <div className="text-center">
                      <DatePicker
                        selectedDate={field.value}
                        ariaDescribedBy="scheduleDateDisclaimer"
                        placeholderText="MM/DD/YYYY"
                        minDate={startDate}
                        onChange={(date: Date | undefined) => {
                          if(date){
                            field.onChange(date);
                          }else{
                            field.onChange("");
                          }
                          setStartDate(getValuesFilter("paymentDate.from"));
                          setEndDate(date);
                          filterFieldsChange()
                        }}
                        onInvalidInput={onInputInvalidDate}
                      />
                    </div>
                  )}
                />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>Payment Type</Accordion.Header>
              <Accordion.Body>
                <Form.Control {...registerFilter("paymentType",{onChange: (e) => filterFieldsChange()})} />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>Payment Status</Accordion.Header>
              <Accordion.Body>
                <Form.Control {...registerFilter("paymentStatus",{onChange: (e) => filterFieldsChange()})} />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </TableFilter>
      </div>
      <div
        className="app-right-panel"
        style={{ maxHeight: "600px", overflowY: "auto" }}
      >
        <Table
          ref={tableRef}
          className={"paymentHistoryTable"}
          columns={columns}
          data={tableData}
          filters={filters}
          filterTypes={filterTypes}
          sortable
          bordered
          hover
          responsive
          getSubRows={undefined}
          subComponent={undefined}
          // initialSortState={[{ id: 'paymentDate',desc: false}]}
          hiddenColumns={role.roleName != ROLE.Consumer ? ['paymentDateRaw'] : ['paymentDateRaw','paymentMadeBy']}
          initialFilters={[{ id: 'paymentDate',value: {from: startDate,to: endDate}}]}
        />
      </div>
      <LoadingWaitModal show={loading} size={"lg"}></LoadingWaitModal>
      <ToastContainer
        containerPosition="fixed"
        className="p-3"
        position="top-center"
      >
        {toasts.length > 0 && (
          <>
            {toasts.map((item) => (
              <ToastWrapper
                key={item.id}
                id={item.id}
                autohide={item.autohide}
                show={item.show}
                delay={item.delay}
                icon={item.icon}
                type={item.type}
                title={item.title}
                body={item.body}
                onClose={() => hideToast(item.id)}
              />
            ))}
          </>
        )}
      </ToastContainer>
    </div>
  );
}
export default PaymentsHistory;
