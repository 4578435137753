import React, { FC, useEffect } from "react";
import { Alert, Form, Col, Row } from "react-bootstrap";
import { Controller, useFieldArray } from "react-hook-form";
import { shallowEqual } from "react-redux";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import Icon from "../../components/Common/Icons/Icons";
import { getPaymentMethods } from "../../api/configureClient";
import { useNavigate } from "react-router-dom";
import MethedPlaceholder from "./placeholders/methedPlaceholder";
import Skeleton from "react-loading-skeleton";

type props = {
    control: any,
    errors: any,
    setValue: any,
    getValues: any,
    trigger: any,
    setStep: any
};

const ConfigureMethods: FC<props> = ({ control, errors, setValue, getValues, trigger, setStep }) => {

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const paymentMethods = useAppSelector((state: any) => state.paymentMethods, shallowEqual);

    useEffect(() => {
        const clientId = getValues("step1.companyName.value")
        if(clientId) {
            dispatch(getPaymentMethods(clientId))
        }
    }, [])

    useEffect(() => {
        setValue("step4.paymentMethods", paymentMethods.data);
        checkEnabledAll();
    }, [paymentMethods])

    const { fields} = useFieldArray({
        control,
        name: "step4.paymentMethods"
    });

    const setEnableAll = (isActive: boolean) => {
        const paymentMethods = getValues(`step4.paymentMethods`);
        paymentMethods.map((item: any, i: boolean) => {
            setValue(`step4.paymentMethods[${i}].isActive`, isActive)
        })
        trigger("step4.enableAllMethods")
    }

    const checkEnabledAll = () => {
        const filterActive = getValues("step4.paymentMethods").filter((item: any) => {
            return item.isActive === true
        });
        if (filterActive.length === getValues("step4.paymentMethods").length)
            setValue(`step4.enableAllMethods`, true);
        else
            setValue(`step4.enableAllMethods`, false);
    }

    return (
        <>
        <h2 className="h3">Configure Payment Methods</h2>
            <fieldset>
                <legend className="h4">Payment Methods</legend>
                <Form.Group className="mb-3">
                    <p className="form-label">Enable/Disable all methods</p>
                    {paymentMethods.status === "loading" ?
                   <Skeleton count={1} width={100} height={20} />
                  :<Controller
                        name={"step4.enableAllMethods"}
                        control={control}
                        render={({ field }) => (
                            <Form.Check
                                type="switch"
                                id="enableAllMethods"
                                label={field.value == 'true' ? 'Disable  All' : 'Enable All'}
                                value={field.value}
                                checked={field.value}
                                className="flex-shrink-1 me-3 mt-2"
                                onChange={(e) => {
                                    const value = e.target.checked;
                                    setEnableAll(value);
                                    field.onChange(value);

                                }
                                }
                            />
                        )}
                    />}
                </Form.Group>
                {
                    errors.step4 && (errors.step4 as any).enableAllMethods && errors.step4 && (errors.step4 as any).enableAllMethods.type === "required" &&<Alert variant="danger">
                        <Icon
                        name="warning"
                        optionalClass="p-1 rounded-circle bg-light me-2"
                        size={36}
                        />
                        {errors.step4 && (errors.step4 as any).enableAllMethods?.message?.toString()}
                    </Alert>
                }
                {paymentMethods.status === "loading" ?
                    <MethedPlaceholder /> :
                    <Row>
                        {fields.map((item, index) => (
                            <Col key={item.id} md={6}>
                                <Form.Group className="app-config-switch app-config-item mb-3">
                                    <div className="app-config-switch_type">
                                        {getValues(`step4.paymentMethods[${index}].paymentMethodName`).toLowerCase()=='ach'?'ACH':getValues(`step4.paymentMethods[${index}].paymentMethodName`)}
                                        </div>
                                    <div className="app-config-switch_check">
                                        <Controller
                                            name={`step4.paymentMethods[${index}]`}
                                            control={control}
                                            render={({ field }) => (
                                                <>
                                                    <Form.Check
                                                        type="switch"
                                                        id={`step4.paymentMethods[${index}]`}
                                                        label={getValues(`step4.paymentMethods[${index}].isActive`) == true ? 'ON' : 'OFF'}
                                                        value={getValues(`step4.paymentMethods[${index}].isActive`)}
                                                        checked={getValues(`step4.paymentMethods[${index}].isActive`)}
                                                        className="flex-shrink-1 me-3 mt-2"
                                                        onChange={(e) => {
                                                            const value = e.target.checked;
                                                            setValue(`step4.paymentMethods[${index}].isActive`, value)
                                                            field.onChange(getValues(`step4.paymentMethods[${index}]`));
                                                            if (!value) {
                                                                setValue(`step4.enableAllMethods`, false)
                                                            } else {
                                                                checkEnabledAll();
                                                            }
                                                            trigger("step4.enableAllMethods")
                                                        }
                                                        }
                                                    />
                                                </>
                                            )}
                                        />
                                    </div>
                                </Form.Group>
                            </Col>
                        ))
                        }
                    </Row>}
            </fieldset>
        </>
    );
};

export default ConfigureMethods;
