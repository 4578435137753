
import { Col, Form, Row } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

const NotificationPlaceholder = () => {

    return (
        <>
            <Row>
                {[...Array(4)].map((value, index) => {
                    return (
                        <Col key={index} md={6}>
                            <Form.Group className="app-config-item app-config-switch p-3">
                                <div className="app-config-switch_check me-3">
                                    <Skeleton count={1} width={80} height={25} />
                                </div>
                                <div className="app-config-switch_type">
                                    <Skeleton className="mb-2" width={"100%"} height={20} />
                                    <Skeleton width={"100%"} height={40} />
                                </div>
                            </Form.Group>
                        </Col>
                    )
                })}
            </Row>

        </>
    );
};

export default NotificationPlaceholder;