import {useEffect} from "react"
import {useAppDispatch} from "../../app/hooks";

import { persistor } from "../../app/store";
import { resetAccount } from '../../features/account/accountSlice';

import { Container } from "react-bootstrap";

function ConsumerLogout() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if(persistor){
      persistor.pause();
      persistor.flush().then(() => {
        return persistor.purge();
      });
    }

    dispatch(resetAccount());
    sessionStorage.clear();
    localStorage.clear();
    //console.log('clear data');
  }, []);

  return (
    <main id="mainContent">
      <Container>
        <h1 className="h2">You have been successfully logged out, thank you.</h1>
      </Container>
    </main>
  );
}

export default ConsumerLogout;
