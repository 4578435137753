import {
  createAction,
  createSlice
} from "@reduxjs/toolkit";
import { clientAccount, accountType, configureLanguage, typeFace, getClientAccountInfo, getBrandColors } from "../../api/clientAccount";
import { getClientUser } from "../../api/user";


interface initialStateGeneral {
  data: any,
  status: string,
  error: string | undefined
}

const initialStateGeneral: initialStateGeneral = {
  data: [],
  status: "",
  error: ""
};


const getClientUserSlice = createSlice({
  name: "getClientUser",
  initialState: initialStateGeneral,
  reducers: {
    setBillingAttrbues: (state, action) => {
      state.data = action.payload;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getClientUser.pending, (state, action) => {
        state.status = 'loading'
        state.data = []
        state.error = ""
      })
      .addCase(getClientUser.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.data = action.payload
        state.error = ""
      })
      .addCase(getClientUser.rejected, (state, action) => {
        state.status = 'failed'
        state.data = []
        state.error = action.error.message
      })
  }
});

export default getClientUserSlice.reducer;
