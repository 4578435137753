import React,{ useMemo, useState,useEffect } from "react";
import { Container, Alert, Card, Row, Col, Button } from "react-bootstrap";
import { Link, useLocation  } from "react-router-dom";
import Icon from "../../components/Common/Icons/Icons"
import moment from "moment";
import { paymentStatus } from '../../common/paymentStatus';
import { useAppSelector } from "../../app/hooks";
import { shallowEqual } from "react-redux";
import { returnToHome } from '../../util/returnToHome'
import { formatMoney } from "../../util/formatUtil";


function PaymentSummary() {
	const { state } = useLocation()

    const [paymentDayIsWorkDay,setPaymentDayIsWorkDay] = useState(false)

	useEffect(() => {
		const _paymentDayIsWorkDay = !(moment(state?.paymentDate).weekday() === 6 || moment(state?.paymentDate).weekday() === 0)
		setPaymentDayIsWorkDay(_paymentDayIsWorkDay)
	}, [state])

	const { permissions } = useAppSelector(
		(state) => ({ ...state.permissions }),
		shallowEqual
	  );

  return (
    <main id="mainContent">
      <Container>
	  	{
          permissions?.canShowBreadcrumb && <div className="mb-3">
            <Link to="/client">Clients</Link> / <Link to="/client/accountinformation">Account Information</Link> / <Link to="/client/makepayment">One-Time Payment</Link> / Summary
          </div>
          }
        <h1 className="visually-hidden">Payment successful</h1>
        {/* If successful */}
        {(state?.paymentStatusId === paymentStatus?.Accepted || state?.paymentStatusId === paymentStatus?.Pending)
			&&
			<Alert variant="success">
					<div className="d-flex align-items-center">
						<div className="me-3">
							<Icon
								name="checkbox-circle"
								optionalClass="p-1 rounded-circle bg-light"
								size={36}
							/>
						</div>
						<div>
							<h2 className="h3 m-0">
								Your payment was successful!
							</h2>
						</div>
					</div>
				</Alert>
		}
        {/* End If */}
        {/* If Future dated */}
       {(state.paymentStatusId === 12 && paymentDayIsWorkDay)
		   &&
		   <Alert variant="success">
		   <div className="d-flex align-items-center">
			   <div className="me-3">
				   <Icon
					   name="checkbox-circle"
					   optionalClass="p-1 rounded-circle bg-light"
					   size={36}
				   />
			   </div>
			   <div>
				   <h2 className="h3 m-0">
					   Your payment will be processed on <u>{state?.paymentDate}</u>.
				   </h2>
			   </div>
		   </div>
	   </Alert>
	   }
        {/* End If */}
         {/* If declined */}
         { state?.paymentStatusId === paymentStatus?.Declined
			 &&
			 <Alert variant="danger">
					<div className="d-flex align-items-center">
						<div className="me-3">
							<Icon
								name="close-circle-line"
								optionalClass="p-1 rounded-circle bg-light"
								size={36}
							/>
						</div>
						<div>
							<h2 className="h3 m-0">
								Your payment was declined.
							</h2>
						</div>
					</div>
				</Alert>
		 }
        {/* End If */}
         {/* If blocked */}
         {/* {
			 <Alert variant="warning">
					<div className="d-flex align-items-center">
						<div className="me-3">
							<Icon
								name="warning"
								optionalClass="p-1 rounded-circle bg-light"
								size={36}
							/>
						</div>
						<div>
							<h2 className="h3 m-0">
								Your payment was blocked. Please call your client support or banking institution.
							</h2>
						</div>
					</div>
				</Alert>
		 } */}
        {/* End If */}
        {/* If Future dated */}
         {(state.paymentStatusId === 12 && !paymentDayIsWorkDay)
			 &&
			 <Alert variant="info">
					<div className="d-flex align-items-center">
						<div className="me-3">
							<Icon
								name="info"
								optionalClass="p-1 rounded-circle bg-light"
								size={36}
							/>
						</div>
						<div>
							<h2 className="h3 m-0">
								Your payment will be processed during normal business hours.
							</h2>
						</div>
					</div>
				</Alert>
		 }
        {/* End If */}
        <Row>
          <Col lg={12}>
            <Card className="mb-3 shadows-light">
              <Card.Body>
                <Card.Title as="h2" className="app-card-title">
                  Payment Details
                </Card.Title>
                {(state?.paymentStatusId === paymentStatus?.Accepted || state?.paymentStatusId === paymentStatus?.Pending)
                  &&
                  <Row>
                    <Col sm={3}>
                      <p className="mb-1">Payment TransactionId</p>
                    </Col>
                    <Col sm={9}>
                      <p>
                        <strong>{state?.paymentTransactionId}</strong>
                      </p>
                    </Col>
                  </Row>
                }
                {/* End If */}
                <Row>
                  <Col sm={3}>
                    <p className="mb-1">Payment Amount</p>
                  </Col>
                  <Col sm={9}>
                    <p>
                      <strong>{formatMoney(state?.amount)}</strong>
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col sm={3}>
                    <p className="mb-1">Payment Date</p>
                  </Col>
                  <Col sm={9}>
                    <p>
                      <strong>{state?.paymentDate}</strong>
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col sm={3}>
                    <p className="mb-1">Payment Nickname</p>
                  </Col>
                  <Col sm={9}>
                    <p>
                      <strong>{state?.nickname}</strong>
                    </p>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Button onClick={()=>{returnToHome()}} className="btn btn-outline-primary">
					<Icon name="arrow-left" optionalClass="me-2" size={20}></Icon>
					Back to Home
				</Button>
      </Container>
    </main>
  );
}

export default PaymentSummary;
