import {
  createSlice,
  createAsyncThunk,
  PayloadAction,
  Action
} from "@reduxjs/toolkit";

import {IUserRole} from "../../../types/custom-types";

interface InitialState {
  roles: Array<IUserRole>;
}

const initialState: InitialState = {
  roles: []
};

export const stateSlice = createSlice({
  name: "commonDictionary",
  initialState,
  reducers: {
    setRoles: (state, action) => {
      state.roles = action.payload;
    }
  }
});

export const {
  setRoles,
} = stateSlice.actions;

export default stateSlice.reducer;
