import React from "react";
import {
	Button,
	Modal,
	ModalBody,
	ModalFooter,
  ModalHeader
} from "react-bootstrap";

interface IConfirmParm {
  title: string;
  description?: string | undefined;
  onConfirm?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  submitBtnText?: string;
  cancelBtnText?: string;
  onCancel?: () => void;
  show: boolean;
  size?: "sm" | "lg" | "xl" | undefined;
  noCancelBtn?: boolean | undefined;
  children?: any | undefined;
  showFooter?: boolean | undefined;
  contentClassName?: string
  }

export const ConfirmModal = React.memo(
	({
 		title,
		description,
		onConfirm,
		submitBtnText,
		cancelBtnText = 'Cancel',
		onCancel,
		show,
		showFooter = true,
		size,
		noCancelBtn,
		children,
		contentClassName = ''
  }: IConfirmParm) => {
		return (
      <Modal show={show} onHide={onCancel} size={size} contentClassName={contentClassName}>
				<ModalHeader closeButton className="align-items-start">
					<div>
						<h1 className="h2">{title}</h1>
						{description}
					</div>
				</ModalHeader>
				<ModalBody>{children}</ModalBody>
				{showFooter && <ModalFooter>
					<div className="text-end">
           			 {!noCancelBtn && (
						<Button
							variant="outline-primary"
							className="mx-2"
							onClick={onCancel}
						>
							{cancelBtnText}
						</Button>
           			 )}

						<Button className="btn btn-primary ms-2" onClick={onConfirm}>
							{submitBtnText}
						</Button>
					</div>
				</ModalFooter>}
			</Modal>
		);
	}
);
