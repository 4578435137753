// @ts-nocheck
import React, { FC } from "react";
import { Card, Col, Row, Button } from "react-bootstrap";
import Icon from "../../components/Common/Icons/Icons";


type props = {
  changePaymentethod: Function,
  selectedNickname?: string
};

const SelectedPaymentMethod: FC<props> = ({ changePaymentethod, selectedNickname }) => {
  return (
    <Card className="mb-3 shadows-light">
      <Card.Body>
        <Card.Title as="h2" className="app-card-title">
          <Icon name="credit-card" />
          Selected Payment Method
          <div className="float-end">
            <Button
              variant="primary"
              size="sm"
              className="ms-3"
              title="Change Payment Method"
              // Pass which method and type to modal?
              onClick={() => {
                changePaymentethod();
              }
              }
            >
              <Icon name="edit" size={20} aria-label="Change Payment Method" title="Change Payment Method"></Icon>
            </Button>
          </div>
        </Card.Title>
        <Row>
          <Col md={4}>
            <p className="mb-1">Payment Nickname:</p>
            <p>
              <strong>{selectedNickname?selectedNickname:"<<nickName>>"}</strong>
            </p>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}
export default React.memo(SelectedPaymentMethod);
