import React, { useEffect } from "react";
// App Routers
import Routing from "./routes/Routing";
// App Styles
import "./scss/app.scss";
import "./js/bootstrap/index";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin, appInsights } from "./AppInsights";

// Components
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";
import Icon from "./components/Common/Icons/Icons";
import { LoadingWaitModal } from "./components/Common/Modal";

// Store
import { shallowEqual } from "react-redux";
import { useAppSelector, useAppDispatch } from "./app/hooks";
import { setGlobalToasts } from "./features/global/globalSlice";

function App() {
  const { isLoading, toasts } = useAppSelector(
    (state) => ({ ...state.global }),
    shallowEqual
  );

  const dispatch = useAppDispatch();

  const hideToast = (id: string)=> {
    const toasts_copy = toasts.filter((x) => x.show && x.id !== id);
    dispatch(setGlobalToasts(toasts_copy));
  }

  return (
    <React.Fragment>
      <LoadingWaitModal show={isLoading} size={"sm"}></LoadingWaitModal>

      <ToastContainer
        position={"top-center"}
        containerPosition="fixed"
        className="p-3"
      >
        {toasts && toasts.length > 0 && (
          <>
            {toasts.map((item) => (
              <Toast
                key={item.id}
                show={item.show}
                autohide={item.autohide}
                delay={item.delay}
                className={`app-toast toast-${item.type}`}
                onClose={() => {
                  hideToast(item.id);
                }}
              >
                {item.icon && item.icon.length > 0 && (
                  <div className="toast-icon">
                    <Icon
                      name={item.icon}
                      optionalClass="p-1 rounded-circle"
                      size={36}
                    />
                  </div>
                )}

                <div className="toast-msg">
                  <Toast.Header closeButton={true}>
                    <strong className="me-auto">{item.title}</strong>
                  </Toast.Header>

                  <Toast.Body>{item.body}</Toast.Body>
                </div>
              </Toast>
            ))}
          </>
        )}
      </ToastContainer>

      <Routing />
    </React.Fragment>
  );
}

export default withAITracking(reactPlugin, App);
