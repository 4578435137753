import {
    createAction,
    createSlice
  } from "@reduxjs/toolkit";  
  import { getPaymentStatement } from "../../api/statement"; 
  
  interface initialState {
    data: any,
    status: string,
    error: string | undefined
  }
  
  const initialState: initialState = {
    data: {generalInformation:[],paymentInformation:[],defaultInformation:[]},
    status: "",
    error: ""
  }; 
  
  const paymentStatusSlice = createSlice({
    name: "paymentStatus",
    initialState: initialState,
    reducers: { },
    extraReducers(builder) {
      builder
        .addCase(getPaymentStatement.pending, (state, action) => {
          state.status = 'loading'
          state.data = []
          state.error = ""
        })
        .addCase(getPaymentStatement.fulfilled, (state, action) => { 
          state.status = 'succeeded'          
          state.data = {
            generalInformation:action?.payload?.generalInformation?.sort((a:any, b:any) => a.labelSequence - b.labelSequence),
            paymentInformation:action?.payload?.paymentInformation?.sort((a:any, b:any) => a.labelSequence - b.labelSequence),
            defaultInformation:action?.payload?.defaultInformation,
          }
          state.error = ""
        })
        .addCase(getPaymentStatement.rejected, (state, action) => {
          state.status = 'failed'
          state.data = ""
          state.error = action.error.message
        }) 
    }
  });
  
  export default paymentStatusSlice.reducer;
  