import axios from 'axios';
import type { AxiosRequestConfig, AxiosInstance, AxiosResponse } from 'axios';
import type { InterceptorHooks, EPRequestConfig } from './type';

class EPRequest {
  interceptorHooks?: InterceptorHooks;
  instance: AxiosInstance;
  // In the future, we can expand this class, eg:
  // loading: boolean;

  constructor(config: EPRequestConfig) {
    this.interceptorHooks = config.interceptorHooks;
    this.instance = axios.create(config);

    this.setupInterceptor();
  }

  setupInterceptor(): void {
    this.instance.interceptors.request.use(
      this.interceptorHooks?.requestInterceptor as any,
      this.interceptorHooks?.requestInterceptorCatch
    );
    this.instance.interceptors.response.use(
      this.interceptorHooks?.responseInterceptor,
      this.interceptorHooks?.responseInterceptorCatch
    );
  }

  request(config: AxiosRequestConfig): Promise<AxiosResponse<any>> {
    return new Promise((resolve, reject) => {
      if (config.headers === undefined) {
        config.headers = {}
      }
      config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`
      this.instance
        .request(config)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  get(config: AxiosRequestConfig): Promise<AxiosResponse<any>> {
    return this.request({ ...config, method: 'GET' });
  }

  post(config: AxiosRequestConfig): Promise<AxiosResponse<any>> {
    return this.request({ ...config, method: 'POST' });
  }

  delete(config: AxiosRequestConfig): Promise<AxiosResponse<any>> {
    return this.request({ ...config, method: 'DELETE' });
  }

  put(config: AxiosRequestConfig): Promise<AxiosResponse<any>> {
    return this.request({ ...config, method: 'PUT' });
  }
}

export default EPRequest;
