import Skeleton from "react-loading-skeleton";
import { Card, Col, Form, Row } from "react-bootstrap"; 
import Icon from "../../../components/Common/Icons/Icons";

const SetupAutoPayLoader = () => {

    return (
        <Card className="mb-3">
            <Card.Body>
                <Card.Title as="h2" className="app-card-title">
                   <Icon name="calendar" />
                    SET-UP AUTOPAY
                </Card.Title>
                <Row>
                  <Col sm={6}>
                   <Skeleton width={"100%"} height={25} className="mb-2" />
                  </Col>
                  <Col sm={6}>                    
                    <Skeleton width={"100%"} height={25} className="mb-3" />
                  </Col>
                </Row>
                <Skeleton width={160} height={40} />
            </Card.Body>
        </Card>
    );
};

export default SetupAutoPayLoader;