export enum FontFamily {
    ArialHelvetica = 'Arial, Helvetica , sans-serif',
    CourierNew = 'Courier New , monospace',
    Garamond = 'Garamond , serif',
    Georgia = 'Georgia , serif',
    Tahoma = 'Tahoma , sans-serif',
    TimesNewRoman = 'Times New Roman , serif',
    TrebuchetMS = 'Trebuchet MS , sans-serif',
    Verdana = 'Verdana , sans-serif',
    Lato = 'Lato'
}


export function getFontFamily(typefaceFont: any) {

  let fontValue;
  typefaceFont = typefaceFont?.trim();
  switch (typefaceFont) {
    case 'Arial / Helvetica (sans-serif)':
      {
        fontValue = FontFamily.ArialHelvetica;
        break;
      }
    case 'Courier New (monospace)':
      {
        fontValue = FontFamily.CourierNew;
        break;
      }
    case 'Garamond (serif)':
      {
        fontValue = FontFamily.Garamond;
        break;
      }
    case 'Georgia (serif)':
      {
        fontValue = FontFamily.Georgia;
        break;
      }
    case 'Tahoma (sans-serif)':
      {
        fontValue = FontFamily.Tahoma;
        break;
      }
    case 'Times New Roman (serif)':
      {
        fontValue = FontFamily.TimesNewRoman;
        break;
      }
    case 'Trebuchet MS (sans-serif)':
      {
        fontValue = FontFamily.TrebuchetMS;
        break;
      }
    case 'Verdana (sans-serif)':
      {
        fontValue = FontFamily.Verdana;
        break;
      }
    case 'Lato':
    default:
      fontValue = FontFamily.Lato;
      break;
  };
  return fontValue;
}
