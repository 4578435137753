import {
  createSlice,
  createAsyncThunk,
  PayloadAction,
  Action
} from "@reduxjs/toolkit";

interface InitialState {
  documents: Array<any>;
}

const initialState: InitialState = {
  documents: []
};

export const stateSlice = createSlice({
  name: "docs",
  initialState,
  reducers: {
    setDocuments: (state, action) => {
      state.documents = action.payload;
    }
  }
});

export const {
  setDocuments,
} = stateSlice.actions;

export default stateSlice.reducer;
