import React from 'react';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from "history";
const browserHistory = createBrowserHistory();
var reactPlugin = new ReactPlugin();
var appInsight = process.env.REACT_APP__APPINSIGHTS_CONNSTRING;
var ai = new ApplicationInsights({
    config: {
        connectionString: appInsight, 
        enableAutoRouteTracking: true,
        extensions: [reactPlugin],
        extensionConfig: {
            [reactPlugin.identifier]: { history: browserHistory }
        }
    }
});
ai.loadAppInsights();

ReactPlugin.prototype.trackEvent = function (event, customProperties) {
    this.trackEvent(event, customProperties);
};

export default (Component: React.ComponentType<unknown>) => withAITracking(reactPlugin, Component);
export const appInsights = ai.appInsights;
export { reactPlugin }