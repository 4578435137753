import { Row } from "react-table";

import { baseDateFormat, convertToMoment } from "../../../util/formatUtil";

export function FromToDateRangeFilter(
  rows: Row[],
  ids: string[],
  filterValue: any
) {
  return rows.filter((r: Row) => {
    const start = filterValue?.from
      ? convertToMoment(filterValue.from, baseDateFormat)
      : undefined;
    const end = filterValue?.to
      ? convertToMoment(filterValue.to, baseDateFormat)
      : undefined;

    const date = r.values[ids[0]]
      ? convertToMoment(r.values[ids[0]], baseDateFormat)
      : undefined;

    // Both ‘From” & ‘To’ fields are required for a successful filter - #40119-Discussion
    // if (date) {
    //   if (start) {
    //     if (end) {
    //       return date.isBetween(start, end);
    //     } else {
    //       return date.isAfter(start);
    //     }
    //   } else {
    //     if (end) {
    //       return date.isBefore(end);
    //     } else {
    //       return true;
    //     }
    //   }
    // } else {
    //   if (!start && !end) {
    //     return true;
    //   }
    // }

    if ((start && !end) || (end && !start)) {
      return false;
    } else {
      if (!start && !end) {
        return true;
      } else {
        if (date) {
          return date.isBetween(start, end, "day", "[]");
        } else {
          return false;
        }
      }
    }
  });
}
