import {
  createAsyncThunk,
} from "@reduxjs/toolkit";

import { get, post, put } from "../util/request";

export const clientAccount = createAsyncThunk("configureAccount/clientAccount", async () => {
  const response = await get(`Client/Dropdown`);
  return response.data;
});

export const accountType = createAsyncThunk("configureAccount/accountType", async () => {
  const response = await get(`AccountType/Dropdown`);
  return response.data;
});

export const configureLanguage = createAsyncThunk("configureAccount/configureLanguage", async () => {
  const response = await get(`Language/Dropdown`);
  return response.data;
});

export const typeFace = createAsyncThunk("configureAccount/typeFace", async () => {
  const response = await get(`Typeface/Dropdown`);
  return response.data;
});

export const getClientAccountInfo = createAsyncThunk("configureAccount/getClientAccountInfo", async (clientId: string) => {
  const response = await get(`Client/${clientId}`);
  return response.data;
});

export const getBrandColors = createAsyncThunk("configureAccount/getBrandColor", async () => {
  const response = await get(`BrandColor/Dropdown`);
  return response.data;
});

export const saveClientAccountInfo = function(data: any){
  return put(`Client`, data);
};
