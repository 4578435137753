import {getPdfFile} from "../api/document"
import {openPdfFile} from "../util/file";
import { $toast,  toastMessage} from "../util/toastUtil";

const getPdfMethod = async (acctDtlId: string, callBack: Function) => {
  try {
    let response = await getPdfFile(acctDtlId);
    if (response.status === 200) {
      openPdfFile(response);
    }
  } catch (ex) {
    console.error("caught error: ", ex);
    $toast.danger({
      show:true,
      body:toastMessage.getDocumentFailed,
      icon: "warning",
      autohide: true,
      delay: 3000
    })
  } finally {
    callBack();
  }
};

export {
  getPdfMethod
}
