import { useCallback, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Icon from "../Icons/Icons";
import { useAppSelector } from "../../../app/hooks";
import { shallowEqual } from "react-redux";

interface TableFilterProps {
  enablerClassName?: string;
  togglerClassName?: string;
  children: JSX.Element;
  autoShowMinWidth?: number;
  onClear: () => void;
}

export function TableFilter({
  enablerClassName,
  togglerClassName,
  children,
  autoShowMinWidth,
  onClear
}: TableFilterProps) {
  const enablerClasses = ["filters-enabler"];
  const togglerClasses = ["filters-toggler"];
  if (enablerClassName) enablerClasses.push(enablerClassName);
  if (togglerClassName) enablerClasses.push(togglerClassName);

  const [isShown, setIsShown] = useState(false);
  const paymentSolution = localStorage.getItem("pay") === "true" ? true : false;

  useEffect(()=>{
    if(!paymentSolution){
      setIsShown(true);
    }

  },[paymentSolution])

  const onToggle = useCallback(() => {
    setIsShown(!isShown);
  }, [isShown]);
  return (
    <>
      {isShown ? (
        <>
          <div className={togglerClasses.join(" ")}>
            <Button
              variant="link"
              className="link-light"
              aria-label="Toggle Filter Display"
              onClick={onToggle}
            >
              <Icon name="arrow-left" size={16} /> Hide Filters
            </Button>
            {onClear && (
              <Button
                variant="btn-light"
                className="link-light"
                aria-label="Reset All Filters"
                onClick={onClear}
              >
                Reset Filters
              </Button>
            )}
          </div>
          {children}
        </>
      ) : (
        <div className={enablerClasses.join(" ")}>
          <Button
            variant="outline-primary"
            aria-label="Toggle Filter Display"
            title="Toggle Filter Display"
            onClick={onToggle}
          >
            <Icon optionalClass="d-none d-lg-block" name="arrow-right" />
            <Icon optionalClass="d-lg-none" name="fillter" />
          </Button>
        </div>
      )}
    </>
  );
}
