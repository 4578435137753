import React, { FC } from "react";
import { Container, Alert, Card, Row, Col, Button } from "react-bootstrap";
import { shallowEqual } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import Icon from "../../components/Common/Icons/Icons"
import { returnToHome } from '../../util/returnToHome'
import { formatMoney } from "../../util/formatUtil";

type props = {
  //Define
};

const AutoPaySummary: FC<props> = ({ }) => {
	const { state }: {state :any} = useLocation()

  const { permissions } = useAppSelector(
    (state:any) => ({ ...state.permissions }),
    shallowEqual
  );
  
  return (
    <main id="mainContent">
      <Container>
        {/* Enrolled */}
        {
          permissions?.canShowBreadcrumb && <div className="mb-3">
            <Link to="/client">Clients</Link> / <Link to="/client/accountinformation">Account Information</Link> / <Link to="/client/autopay">Autopay</Link> / Summary
          </div>
          }
        <Alert variant="success">
          <div className="d-flex align-items-center">
            <div className="me-3">
              <Icon
                name="checkbox-circle"
                optionalClass="p-1 rounded-circle bg-light"
                size={36}
              />
            </div>
            <div>
              <h2 className="h3 m-0">
                You are enrolled in AutoPay
              </h2>
            </div>
          </div>
        </Alert>
        <Row>
          <Col lg={6}>
            <Card className="mb-3 shadows-light">
              <Card.Body>
                <Card.Title as="h2" className="app-card-title">
                  Payment Details
                </Card.Title>
                <Row>
                  <Col sm={6}>
                    <p className="mb-1">Recurring Payment Amount</p>
                  </Col>
                  <Col sm={6}>
                    <p>
                      <strong>{formatMoney(state?.amount)}</strong>
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <p className="mb-1">Recurring Payment Date</p>
                  </Col>
                  <Col sm={6}>
                    <p>
                      <strong>{state?.paymentDate}</strong>
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <p className="mb-1">Payment Nickname</p>
                  </Col>
                  <Col sm={6}>
                    <p>
                      <strong>{state?.paymentMethod}</strong>
                    </p>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Button className="btn btn-outline-primary" onClick={()=>{returnToHome()}}>
          <Icon name="arrow-left" optionalClass="me-2" size={20}></Icon>
          Back to Home
        </Button>
      </Container>
    </main>
  );
}

export default React.memo(AutoPaySummary);
