export const filterValue = (nameKey:any, myArray:any,)=>{
    for (let i=0; i < myArray.length; i++) {
        if (myArray[i].value === nameKey) {
            return myArray[i];
        }
    }
}

export const matchValue = (valueOne:any, valueTwo:any) =>{
    return valueOne === valueTwo
}