import Skeleton from "react-loading-skeleton";
import { Col, Form, Row } from "react-bootstrap";

const PaymentActionButtonLoader = () => {

    return (
        <div className="mb-5 d-flex justify-content-end">
            <Skeleton width={160} height={40} className="me-3" />
            <Skeleton width={100} height={40} />
        </div>
    );
};

export default PaymentActionButtonLoader;