import { useCallback, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";

export function useFilterForm(defaultValues: any) {
  const { control, register, reset, watch, getValues } = useForm(defaultValues);
  let filters = watch() || {};
  const filterArray = useMemo(() => {
    return Object.keys(filters).reduce((acc: any, k: any) => {
      const value = filters[k];
      if (value) {
        acc.push({ id: k, value });
      }
      return acc;
    }, []);
  }, [filters]);

  const clearFilter = useCallback(() => {
    const defaultFilters = Object.keys(filters).reduce((acc: any, k: any) => {
      //console.log(acc);
      acc[k] = null;
      return acc;
    }, {});
    reset(defaultFilters, { keepValues: false });
  }, [filters, reset]);

  return {
    filters: filterArray,
    filterControl: control,
    registerFilter: register,
    clearFilter,
    getValuesFilter: getValues,
    resetFilterForm: reset
  };
}
