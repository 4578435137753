import React from "react";
import Icons from "../../../assets/icons.svg";

interface IconProps {
  name: string;
  color?: string;
  size?: number;
  optionalClass?: string;
  focus?: boolean;
  hidden?: boolean;
  onClick?: React.MouseEventHandler<SVGSVGElement> | undefined;
}

const Icon = ({
  name,
  color = "currentColor",
  size = 24,
  optionalClass = "",
  focus = false,
  hidden = true,
  onClick
}: IconProps) => (
  <svg
    className={`icon icon-${name} ${optionalClass}`}
    fill={color}
    width={size}
    height={size}
    focusable={focus}
    aria-hidden={hidden}
    onClick={onClick}
  >
    <use xlinkHref={`${Icons}#icon-${name}`} />
  </svg>
);

export default Icon;
