import React, { useEffect, useState } from 'react';
import { ThemeProvider, Button } from 'react-bootstrap';
import { shallowEqual } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { useAppSelector } from '../app/hooks';
import { PrimaryStyles, SecondaryStyles, Typeface } from '../components/Common/GlobalStyles/GlobalStyles';
import { SessionTimeout } from '../components/SessionTimeout/SessionTimeout';
import ConsumerNav from "./Nav/ConsumerNav";
import { getFontFamily } from '../util/fontfamily';
import Icon from '../components/Common/Icons/Icons';
import { useLocation } from 'react-router-dom';
import { epHistory } from '../routes/History';

const ConsumerLayout = (props: any) => {
  const primaryBrandColor = useAppSelector((state: any) => state?.account?.user?.primaryBrandColor, shallowEqual);
  const secondaryBrandColor = useAppSelector((state: any) => state?.account?.user?.secondaryBrandColor, shallowEqual);
  const typefaceFont = useAppSelector((state: any) => state?.account?.user?.typeface, shallowEqual);
  const userAccounts = useAppSelector((state: any) => state?.account?.userAccounts, shallowEqual);

  const theme = {
    primaryColor: !primaryBrandColor ? "#378523" : primaryBrandColor,
    secondary: !secondaryBrandColor ? "#255C96" : secondaryBrandColor,
    typeface: !typefaceFont ? "Lato" : getFontFamily(typefaceFont)
  };

  const location = useLocation();
  const [isIframe, setIsIframe] = useState(false);

  useEffect(() => {
    if (window.top !== window.self) {
      setIsIframe(true); //The page is in iFrame.
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <React.Fragment>
        <PrimaryStyles theme={theme} />
        <SecondaryStyles theme={theme} />
        <Typeface theme={theme} />
        {isIframe ? <>{
          (location.pathname === '/consumer/account-detail'  === true && userAccounts?.length > 1 ? <div className='container'>
            <Button variant="outline-primary" className='mb-4 mt-4' onClick={() => {
              epHistory.replace(`/consumer/accounts`)
            }}>
              <Icon name="arrow-left" optionalClass="me-2" size={20}></Icon>
              Back to Account Dashboard
            </Button>
          </div> : null)}
        </> : <ConsumerNav />}
        {props.children ? props.children : <Outlet />}
        <SessionTimeout />
      </React.Fragment>
    </ThemeProvider>
  )
};

export default ConsumerLayout;
