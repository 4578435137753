import React, { ReactElement } from 'react';
import { Viewer, OpenFile } from '@react-pdf-viewer/core';
import { getFilePlugin } from '@react-pdf-viewer/get-file';
import { defaultLayoutPlugin, ToolbarProps, ToolbarSlot } from '@react-pdf-viewer/default-layout';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

interface pdfViewerProps {
    pdfUrl: string
}

const CustomPDFViewer = ({ pdfUrl }: pdfViewerProps) => {
    const getFilePluginInstance = getFilePlugin({
        fileNameGenerator: (file: OpenFile) => {
            const fileName = file.name.substring(file.name.lastIndexOf('/') + 1);
            return `${fileName}.pdf`;
        },
    });
    const { DownloadButton } = getFilePluginInstance;

    const renderToolbar = (Toolbar: (props: ToolbarProps) => ReactElement) => (
        <Toolbar>
            {(slots: ToolbarSlot) => {
                const {
                    CurrentPageInput,
                    GoToNextPage,
                    GoToPreviousPage,
                    NumberOfPages,
                    Print,
                    Zoom,
                    ZoomIn,
                    ZoomOut,
                } = slots;
                return (
                    <div
                        style={{
                            alignItems: 'center',
                            display: 'flex',
                            width: '100%',
                        }}
                    >
                        <div className='row w-100 justify-content-between'>
                            <div className='col-4 d-flex'>
                                <div style={{ padding: '0px 2px' }}>
                                    <GoToPreviousPage />
                                </div>
                                <div className='d-none d-md-block' style={{ padding: '0px 2px', width: '3rem' }}>
                                    <CurrentPageInput />
                                </div>
                                <div className='d-none d-md-flex align-items-center mx-2' style={{ padding: '0px 2px' }}>
                                    / <NumberOfPages />
                                </div>
                                <div style={{ padding: '0px 2px' }}>
                                    <GoToNextPage />
                                </div>
                            </div>
                            <div className='col-4 d-flex justify-content-center'>
                                <div style={{ padding: '0px 2px' }}>
                                    <ZoomOut />
                                </div>
                                <div style={{ padding: '0px 2px' }}>
                                    <Zoom />
                                </div>
                                <div style={{ padding: '0px 2px' }}>
                                    <ZoomIn />
                                </div>
                            </div>

                            <div className='col-4 d-flex justify-content-end'>
                                <div style={{ padding: '0px 2px' }}>
                                    <DownloadButton />
                                </div>
                                <div style={{ padding: '0px 2px' }}>
                                    <Print />
                                </div>
                            </div>
                        </div>

                    </div>
                );
            }}
        </Toolbar>
    );

    let thumbNailWidth;

    if (window.innerWidth <= 768) {

    } else {
        thumbNailWidth = {
            thumbnailWidth: 200,
        };
    }

    const defaultLayoutPluginInstance = defaultLayoutPlugin({
        renderToolbar, sidebarTabs: (defaultTabs) => [defaultTabs[0]],
        thumbnailPlugin: thumbNailWidth
    });

    return (
        <Viewer
            fileUrl={pdfUrl}
            plugins={[defaultLayoutPluginInstance, getFilePluginInstance]}
        />
    );
};

export default CustomPDFViewer;

