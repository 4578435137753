import { shallowEqual } from "react-redux";
import { createGlobalStyle } from "styled-components";
import tinycolor from "tinycolor2";
import { useAppSelector } from "../../../app/hooks";

// createGlobalStyle creates a style tag inside the head tag with the output
// below allowing us to override variable colors set by Configure client.
// Insert from the DB the value saved from Configure client Primary and Secondary fields.

// ----------------------------------------------/
// TODO: Use any color the client wants?
// Tinycolor library
// https://www.npmjs.com/package/tinycolor2
// ----------------------------------------------/

// Get Color and return RGB value or RGBA value if alpha is used
function setColor(color:any, alpha?:string) {
  if (alpha) {
    tinycolor(color).toRgb();
    return tinycolor(color).setAlpha(parseFloat(alpha)).toRgbString();
  } else {
    return tinycolor(color).toRgbString();
  }
}

// Lighten color
// function lightenColor(color:any) {
//   return tinycolor(color).lighten().toString();
// }

// Set Button Text Color
function setReadableText(color:any) {
  return tinycolor.mostReadable(color, ["#f8fafc", "#ffffff"], {includeFallbackColors:true, level:"AAA", size:"large"}).toRgbString();
}
// ----------------------------------------------/
// END Tinycolor
// ----------------------------------------------/

// ----------------------------------------------/
// Primary Configuration Color
// Default: #378523
// ----------------------------------------------/
 
export interface DefaultTheme {
  primaryColor: string; 
}
export const PrimaryStyles = createGlobalStyle<{ theme: DefaultTheme }>`
  body {
    // Primary
    --vs-brand-primary:   ${props => props.theme.primaryColor};
    --vs-brand-primary80: ${props => setColor(props.theme.primaryColor, "0.8")};
    --vs-brand-primary60: ${props => setColor(props.theme.primaryColor, "0.6")};
    --vs-brand-primary40: ${props => setColor(props.theme.primaryColor, "0.4")};
    --vs-brand-primary20: ${props => setColor(props.theme.primaryColor, "0.2")};
  
    // Set the body color to black for any global primary style
    --bs-body-color: #000000;
  }

  // Override Primary styles
  .btn-primary {
    --bs-btn-color: ${props => setReadableText(props.theme.primaryColor)}; 
    --bs-btn-bg: var(--vs-brand-primary);
    --bs-btn-border-color: var(--vs-brand-primary);
    --bs-btn-hover-color: ${props => setReadableText(props.theme.primaryColor)};
    --bs-btn-hover-bg: var(--vs-brand-primary60);
    --bs-btn-hover-border-color: var(--vs-brand-primary60);
    --bs-btn-active-color: ${props => setReadableText(props.theme.primaryColor)};
    --bs-btn-active-bg: var(--vs-brand-primary80);
    --bs-btn-active-border-color: var(--vs-brand-primary80);
  }

  .btn-outline-primary {
    // --bs-btn-color: #000000;
    --bs-btn-border-color: var(--vs-brand-primary);
    --bs-btn-hover-color: ${props => setReadableText(props.theme.primaryColor)};
    --bs-btn-hover-bg: var(--vs-brand-primary);
    --bs-btn-hover-border-color: var(--vs-brand-primary);
    --bs-btn-active-color:  ${props => setReadableText(props.theme.primaryColor)};
    --bs-btn-active-bg:  var(--vs-brand-primary80);
    --bs-btn-active-border-color:  var(--vs-brand-primary80);
  }

  [aria-sort="ascending"] .app-sorting-btn::after, [aria-sort="descending"] .app-sorting-btn::after {
    background-color: ${props => setColor(props.theme.primaryColor)}; 
  }
  .form-check-input:checked {
    background-color: var(--vs-brand-primary);
    border-color: var(--vs-brand-primary);
  }

  .app-navbar .navbar-nav .nav-link.active {
    border-bottom-color: var(--vs-brand-primary);
  }
  .dropdown-menu {
    --bs-dropdown-link-active-bg: var(--vs-brand-primary);
  }
`;

// ----------------------------------------------/
// Secondary Configuration Color
// Default: #255C96
// ----------------------------------------------/
 
export interface DefaultThemeSecondary {
  secondary: string; 
}
export const SecondaryStyles = createGlobalStyle<{ theme: DefaultThemeSecondary }>`
  // Secondary 
  body {
    --vs-brand-secondary:  ${props => setColor(props.theme.secondary)};
    --vs-brand-secondary80: ${props => setColor(props.theme.secondary, "0.8")};
    --vs-brand-secondary60: ${props => setColor(props.theme.secondary, "0.6")};
    --vs-brand-secondary40: ${props => setColor(props.theme.secondary, "0.4")};
    --vs-brand-secondary20: ${props => setColor(props.theme.secondary, "0.2")};

    // Set the navigation bottom border
    --vs-navbar-border-b-color: var(--vs-brand-secondary);
  }
  // Override Secondary styles
  .btn-secondary {
    --bs-btn-color: ${props => setReadableText(props.theme.secondary)};  
    --bs-btn-bg: var(--vs-brand-secondary);
    --bs-btn-border-color: var(--vs-brand-secondary);
    --bs-btn-hover-color: ${props => setReadableText(props.theme.secondary)};  
    --bs-btn-hover-bg: var(--vs-brand-secondary60);
    --bs-btn-hover-border-color: var(--vs-brand-secondary60);
    --bs-btn-active-color: ${props => setReadableText(props.theme.secondary)};   
    --bs-btn-active-bg: var(--vs-brand-secondary80);
    --bs-btn-active-border-color: var(--vs-brand-secondary80);
  }
`;

// ----------------------------------------------/
// Typeface Configuration Font
// Default: Lato
// ----------------------------------------------/
//const font = "Lato";

export interface DefaultThemeTypeface{
  typeface: string;
}

export const Typeface = createGlobalStyle<{ theme: DefaultThemeTypeface }>`
  body {
    --vs-body-font-family:  ${props => props.theme.typeface };
  }
`;
