// @ts-nocheck
import React, { useState,useEffect } from "react";
import Icon from "../../../components/Common/Icons/Icons";
import { Container, Row, Col, Alert, Card, Button, Form, ToastContainer } from "react-bootstrap";
import { Link } from "react-router-dom";
import ClientProfileInformation from "./ClientProfileInformation";
import { LoadingWaitModal, ConfirmModal } from "../../../components/Common/Modal";
import * as Yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { getUserInfo,updateProfile } from "../../../api/user";
import { setUser, initUserDetails } from "../../../features/account/accountSlice";
import { shallowEqual } from "react-redux";
import { useAccount, useMsal } from "@azure/msal-react";

import { v4 as uuidv4 } from "uuid";
import { $toast } from "../../../util/toastUtil";

import { replaceSpace,userNameRegEx,phoneNumberRegEx, changeToUsMobileNumFormat, replaceSpecialCharsAndNumbers } from '../../../util/regexUtil'
import { returnToHome } from "../../../util/returnToHome";


export type ClientFormType = {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string
};

const initializeUserInfo = async (dispatch: any, email: any) => {
  let response = await getUserInfo();
  await dispatch(setUser(response.data));
  await dispatch(initUserDetails(JSON.parse(response.data.userDetails)));
};
const ClientProfile: React.FC = (props: any) => {
  const [loading, setLoading] = useState(false);
  const [saveloading, setSaveLoading] = useState(false);
  const dispatch = useAppDispatch();
  const { accounts } = useMsal();
  const account = useAccount(accounts?.[0] || {});
  const { user, role }: any = useAppSelector(
    (state) => ({ ...state.account }),
    shallowEqual
  );

  useEffect(() => {
    async function initData() {
      setLoading(true);
      const email = account?.idTokenClaims?.email;
      await initializeUserInfo(dispatch, email);
      setLoading(false);
    }
    if (account) {
      initData();
    }
  }, []);

  const { userDetails } = useAppSelector(
    (state) => ({ ...state.account }),
    shallowEqual
  );

  const intialClientFormValues = {
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: ""
  }

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('This is required'),
    lastName: Yup.string().required('This is required'),
    phoneNumber: Yup.string().when({
      is:(value)=> value,
      then:(schema) => schema.matches(phoneNumberRegEx, 'Phone Number is invalid.')
    })
  });

  const { control, watch, handleSubmit, reset, getValues, setValue, clearErrors, formState: { errors } } = useForm<ClientFormType>(
    {
      resolver: yupResolver(validationSchema),
      defaultValues: intialClientFormValues
    }
  );

  const [isEditProfile, setIsEditProfile] = useState(false);

   const onSave =(async(data: ClientFormType) => {
      setIsEditProfile(false);
      setLoading(true);
      /* ADD API logic to save profile information */
      try {
        await updateProfile(
                user.userId, 
                data.firstName.trim(), 
                data.lastName.trim(), 
                data.phoneNumber, 
                user.roleId, 
                user.email
              );
        await initializeUserInfo(dispatch, account?.idTokenClaims?.email);
        reset(intialClientFormValues)
        showToast(
          'Profile settings were saved successfully.',
          'check-double',
          'success',
          'Profile Saved'
        )
        setLoading(false);
      } catch (error:any) {
        const _msg = (error.response?.data?.title || error.response?.data) || error.message
        showToast(_msg)
        setLoading(false);
      }
    });
  
  const handleChangeMobile = (e, field) => {
    let _value = changeToUsMobileNumFormat(e.target.value)
    field.onChange(_value);
  };
  
  const handleChangeName = (e, field) => {
    let _value = e.target.value;
    field.onChange(_value);
  };
  
  const editProfile = async () => {
    setIsEditProfile(true);
    setValue("firstName",userDetails?.FirstName)
    setValue("lastName",userDetails?.LastName)
    setValue("phoneNumber",userDetails?.Phone)
  }

  const showToast = (msg:string,icon="close",type="danger",title="Failed") =>{
		$toast.showToast({
			id: uuidv4(),
			autohide: true,
			delay: 5000,
			icon,
			type,
			title,
			body: msg
		})
	}

  return (
    <main id="mainContent">
      <Container>
        <h1 className="h2">My Profile</h1>
        <div className="mb-3"> 
          <Button variant="outline-primary" className="mx-2" onClick={() => { returnToHome() }}>
            <Icon name="arrow-left" optionalClass="me-2" size={20}></Icon>
            Back to Home
          </Button>
        </div>
        <Row className="mt-3">
          <Col lg={6}>
            <Card className="app-card shadows-light mb-3">
              <Card.Body>
                <Row>
                  <Col>
                    <Card.Title as="h2" className="app-card-title">
                      <Icon name="account-circle" />
                      Profile Information
                    </Card.Title>
                  </Col>
                  <Col className="flex-grow-0 flex-shrink-1">
                    <Button
                      variant="success"
                      size="sm"
                      className="ms-3"
                      onClick={() => editProfile()}
                    >
                      <Icon name="edit" size={20} aria-label="Edit Profile Information"></Icon>
                    </Button>
                  </Col>
                </Row>
                <ClientProfileInformation loading={loading} createDate={user?.createDate} firstName={userDetails?.FirstName} lastName={userDetails?.LastName} phone={userDetails?.Phone} email={userDetails?.Email} userName = {user.userName} role={user.role?.roleName}/>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <LoadingWaitModal show={loading} size={"lg"}></LoadingWaitModal>
      <ConfirmModal
        show={isEditProfile}
        size="lg"
        title="Edit Profile"
        showFooter={true}
        submitBtnText="Save"
        description="Edit your profile information"
        onConfirm={handleSubmit(onSave)}
        onCancel={() => {
            reset(intialClientFormValues)
            setIsEditProfile(false)
          }
        }
      >
        <Form>
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label htmlFor="ClientFirstName">First Name</Form.Label>
                <Controller
                  name={"firstName"}
                  control={control}
                  render={({ field }) => (
                    <Form.Control
                      type="text"
                      id="ClientFirstName"
                      value={field.value}
                      className={errors.firstName ? "form-field-error" : ""}
                      placeholder="First Name"
                      maxLength={30}
                      onChange={(e)=> handleChangeName(e, field)}
                    />
                  )}
                />
                {(errors.firstName && (errors.firstName.type === "required" || errors.firstName.type === "matches")) && <span className="form-field-error-msg">{errors.firstName?.message?.toString()}</span>}
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label htmlFor="ClientLastName">Last Name</Form.Label>
                <Controller
                  name={"lastName"}
                  control={control}
                  render={({ field }) => (
                    <Form.Control
                      type="text"
                      id="ClientLastName"
                      value={field.value}
                      className={errors.lastName ? "form-field-error" : ""}
                      placeholder="Last Name"
                      maxLength={30}
                      onChange={(e)=> handleChangeName(e, field)}
                    />
                  )}
                />
                {(errors.lastName && (errors.lastName.type === "required" || errors.lastName.type === "matches")) && <span className="form-field-error-msg">{errors.lastName?.message?.toString()}</span>}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Form.Group className="mb-3">
                <Form.Label htmlFor="ClientPhoneNumber">Phone Number</Form.Label>
                <Controller
                  name={"phoneNumber"}
                  control={control}
                  render={({ field }) => (
                    <Form.Control
                      type="text"
                      id="ClientPhoneNumber"
                      value={field.value}
                      className={errors.phoneNumber ? "form-field-error" : ""}
                      placeholder="Phone Number"
                      maxLength={10}
                      onChange={(e)=> handleChangeMobile(e, field)}
                    />
                  )}
                />
                {(errors.phoneNumber && (errors.phoneNumber.type === "required" || errors.phoneNumber.type === "matches")) && <span className="form-field-error-msg">{errors.phoneNumber?.message?.toString()}</span>}
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </ConfirmModal>
    </main>
  );
}
export default ClientProfile;
