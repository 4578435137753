import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Icon from "../Common/Icons/Icons";
import { ConfirmModal } from "../Common/Modal/ConfirmModal";
import {
  addEventListeners,
  removeEventListeners
} from "./SessionTimeoutEventHandler";

import { setExpired } from "../../features/account/accountSlice";
import { useAppDispatch } from "../../app/hooks";
import { useAppSelector } from "../../app/hooks";
import { shallowEqual } from "react-redux";

interface ICountdown {
  seconds: number;
  minutes: number;
}

export const SessionTimeout = () => {
  const navigate = useNavigate();
  const [isWarningModalOpen, setWarningModalOpen] = useState(false);
  const dispatch = useAppDispatch();

  const REACT_APP_SessionTimeout = process.env.REACT_APP_SessionTimeout + "";
  const REACT_APP_SessionTimeoutPrompt =
    process.env.REACT_APP_SessionTimeoutPrompt + "";

  let REACT_APP_SessionTimeout_Min = 15; // Default value
  let REACT_APP_SessionTimeoutPrompt_Min = 5;

  try {
    REACT_APP_SessionTimeout_Min = Number(REACT_APP_SessionTimeout);
    REACT_APP_SessionTimeoutPrompt_Min = Number(REACT_APP_SessionTimeoutPrompt);
  } catch (error) {
    console.error("session timeout error: ", error);
  }

  const seconds = 0;
  const minutes = REACT_APP_SessionTimeoutPrompt_Min;

  let timerToShowWarning =
    (REACT_APP_SessionTimeout_Min - REACT_APP_SessionTimeoutPrompt_Min) * 60; //seconds

  if (timerToShowWarning < 1) {
    console.error("session timeout error");
    timerToShowWarning = 600;
  }

  timerToShowWarning = timerToShowWarning * 1000;

  const timerToEndWarning = minutes * 60 * 1000;
  const [time, setTime] = React.useState<ICountdown>({ minutes, seconds });

  useEffect(() => {
    if (isWarningModalOpen) {
      const timerId = setInterval(() => startCountDown(), 1000);
      return () => clearInterval(timerId);
    }
  });

  // to start the countdown timer
  const startCountDown = () => {
    if (time.minutes === 0 && time.seconds === 0) {
      reset({ minutes: 0, seconds: 0 });
    } else if (time.seconds === 0) {
      setTime({ minutes: time.minutes - 1, seconds: 59 });
    } else {
      setTime({ minutes: time.minutes, seconds: time.seconds - 1 });
    }
  };

  // to reset the countdown timer
  const reset = (time: ICountdown) => setTime(time);

  useEffect(() => {
    const createTimeout = () =>
      setTimeout(() => {
        setWarningModalOpen(true);
        dispatch(setExpired(true));
      }, timerToShowWarning);

    const callAutoLogout = () =>
      setTimeout(() => {
        onLogOutCall();
      }, timerToEndWarning + 2000);
    // 2000 should be added to sync with timer. don't remove it

    const listener = () => {
      if (!isWarningModalOpen) {
        clearTimeout(timeout);
        timeout = createTimeout();
        setTime({ seconds, minutes });
      }
    };

    let timeout = isWarningModalOpen ? callAutoLogout() : createTimeout();
    addEventListeners(listener);

    // reset timeout and time interval
    return () => {
      removeEventListeners(listener);
      clearTimeout(timeout);
      reset({ seconds: seconds, minutes: minutes });
    };
  }, [isWarningModalOpen]);

  const onLogOutCall = () => {
    sessionStorage.setItem('loggedOut','true');
    navigate(`/logout`, { replace: true });
  };

  const onStayLoggedIn = () => {
    setWarningModalOpen(false);
  };

  useEffect(()=>{  
    if(time.seconds === 0 && time.minutes === 0){
      onLogOutCall();
      setWarningModalOpen(false)
    }
  })

  return (
    <div>
      {isWarningModalOpen && (
        <>
          <ConfirmModal
            show={isWarningModalOpen}
            title="Session Timeout"
            description=""
            onCancel={() => {
              setWarningModalOpen(false);
            }}
            showFooter={false}
          >
            <div className="text-center mt-2">
              <Icon name="notification"></Icon>
              <p className="mt-3">
                Your session is going to expire. {/*{String(isWarningModalOpen)}*/}
              </p>
              <h4>
                {`${time.minutes.toString().padStart(2, "0")}:${time.seconds
                  .toString()
                  .padStart(2, "0")}`}
              </h4>
            </div>
            <div className="text-center my-4">
              <Button
                variant="outline-primary"
                size={undefined}
                className=""
                onClick={() => {
                  onLogOutCall();
                }}
              >
                Log Out
              </Button>
              <Button
                variant="primary"
                size={undefined}
                className="ms-3"
                onClick={() => {
                  onStayLoggedIn();
                }}
              >
                Stay Logged In
              </Button>
            </div>
          </ConfirmModal>
        </>
      )}
    </div>
  );
};
