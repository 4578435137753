import { store } from '../app/store'
import { epHistory } from '../routes/History'
import { ROLE } from "../routes/Roles";

export function returnToHome(redirectToAccountInformation?: boolean, hasAccountNumber?: boolean) {

   const { role } = store.getState().account;
   switch (role.roleName) {
      case ROLE.ClientAdmin:
      case ROLE.ClientBasic:
      case ROLE.ClientSuperAdmin:
         epHistory.replace(redirectToAccountInformation ? '/client/accountinformation' : '/client')
         break;

      case ROLE.Consumer:
            epHistory.replace(hasAccountNumber ? '/consumer' : '/consumer/account-detail')
         break;

      case ROLE.VentureAdmin:
         epHistory.replace(`/admin`)
         break;

      default:
         break;
   }
}
