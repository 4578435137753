import {
  createAction,
  createSlice
} from "@reduxjs/toolkit";
import { clientAccount, accountType, configureLanguage, typeFace, getClientAccountInfo, getBrandColors } from "../../api/clientAccount";


interface initialStateGeneral {
  clientAccountInfo: {
    data:any,
    status: string,
    error: string | undefined
  },
  clientName: {
    data:any,
    status: string,
    error: string | undefined
  },
  accountType: {
    data:any,
    status: string,
    error: string | undefined
  },
  languageList: {
    data:any,
    status: string,
    error: string | undefined
  },
  typeFace: {
    data:any,
    status: string,
    error: string | undefined
  },
  brandColor:{
    data: any,
    status: string,
    error: string | undefined
  }
  status: string,
  error: string | undefined
}

const initialStateGeneral: initialStateGeneral = {
  clientAccountInfo: {
    data:"",
    status: "",
    error: "",
  },
  clientName: {
    data:"",
    status: "",
    error: "",
  },
  accountType: {
    data:"",
    status: "",
    error: "",
  },
  languageList: {
    data:"",
    status: "",
    error: "",
  },
  typeFace: {
    data:"",
    status: "",
    error: "",
  },
  brandColor:{
    data: "",
    status: "",
    error: ""
  },
  status: "",
  error: ""
};

export const revertAllClientData = createAction('REVERT_ALL_CLIENTS_DATA')

const clientConfigureAccountSlice = createSlice({
  name: "clientConfigureAccount",
  initialState: initialStateGeneral,
  reducers: {
    setBillingAttrbues: (state, action) => {
      state.clientName = action.payload;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(clientAccount.pending, (state, action) => {
        state.clientName.status = 'loading'
        state.clientName.data = ""
        state.clientName.error = ""
      })
      .addCase(clientAccount.fulfilled, (state, action) => {
        state.clientName.status = 'succeeded'
        state.clientName.data = action.payload
        state.clientName.error = ""
      })
      .addCase(clientAccount.rejected, (state, action) => {
        state.clientName.status = 'failed'
        state.clientName.data = ""
        state.clientName.error = action.error.message
      })
      .addCase(accountType.pending, (state, action) => {
         state.accountType.status = 'loading'
         state.accountType.data = ""
         state.accountType.error = ""
      })
      .addCase(accountType.fulfilled, (state, action) => {
        state.accountType.status ='succeeded'
        state.accountType.data = action.payload
        state.accountType.error =""
      })
      .addCase(accountType.rejected, (state, action) => {
        state.accountType.status = 'failed'
        state.accountType.data = ""
        state.accountType.error = action.error.message
      })
      .addCase(configureLanguage.pending, (state, action) => {
         state.languageList.status = 'loading'
         state.languageList.data = ""
         state.languageList.error = ""
      })
      .addCase(configureLanguage.fulfilled, (state, action) => {
        state.languageList.status = 'succeeded'
        state.languageList.data = action.payload
        state.languageList.error = ""
      })
      .addCase(configureLanguage.rejected, (state, action) => {
        state.languageList.status = 'failed'
        state.languageList.data = ""
        state.languageList.error = action.error.message
      })
      .addCase(typeFace.pending, (state, action) => {
         state.typeFace.status = 'loading'
         state.typeFace.error = ""
         state.typeFace.data = ""
      })
      .addCase(typeFace.fulfilled, (state, action) => {
        state.typeFace.status = 'succeeded'
        state.typeFace.data = action.payload
        state.typeFace.error = ""
      })
      .addCase(typeFace.rejected, (state, action) => {
        state.typeFace.status = 'failed'
        state.typeFace.data = ""
        state.typeFace.error = action.error.message
      })
      .addCase(getClientAccountInfo.pending, (state, action) => {
        state.clientAccountInfo.status = 'loading';
        state.clientAccountInfo.data = "";
        state.clientAccountInfo.error = "";
     })
     .addCase(getClientAccountInfo.fulfilled, (state, action) => {
       state.clientAccountInfo.status = 'succeeded'
       state.clientAccountInfo.data = action.payload
       state.clientAccountInfo.error = ""
     })
     .addCase(getClientAccountInfo.rejected, (state, action) => {
       state.clientAccountInfo.status = 'failed'
       state.clientAccountInfo.data = ""
       state.clientAccountInfo.error = action.error.message
     })
     .addCase(getBrandColors.pending, (state, action) => {
      state.brandColor.status = 'loading';
      state.brandColor.data = "";
      state.brandColor.error = "";
    })
    .addCase(getBrandColors.fulfilled, (state, action) => {
      state.brandColor.status = 'succeeded'
      state.brandColor.data = action.payload
      state.brandColor.error = "";
    })
    .addCase(getBrandColors.rejected, (state, action) => {
      state.brandColor.status = 'failed'
      state.brandColor.data = ""
      state.brandColor.error = action.error.message
    })
    .addCase(revertAllClientData, (state, action) => {
      state.clientAccountInfo.data = ""
      state.clientAccountInfo.status = ""
      state.clientAccountInfo.error = ""
    })
  }
});

export default clientConfigureAccountSlice.reducer;
