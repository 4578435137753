import React from 'react';
import { Worker } from '@react-pdf-viewer/core';
import CustomPDFViewer from '../../components/Common/CustomPDFViewer/CustomPDFViewer';

const PDFViewer = () => {
  let pdfUrl: any = sessionStorage.getItem('bloburl');
  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.js">
      <div
        style={{
          height: `${window.innerHeight}px`,
          width: '100%',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        <CustomPDFViewer pdfUrl={pdfUrl} />
      </div>
    </Worker>
  );
};

export default PDFViewer;
