import React, { useEffect, useState } from "react";
import Icon from "../../components/Common/Icons/Icons";
import { Container, Card, Col, Row, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { shallowEqual } from "react-redux";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { setDocuments } from "../../features/document/documentSlice";

// Page styling
import "../../scss/pages/_dashboard.scss";

// loading box
import { LoadingWaitModal } from "../../components/Common/Modal/LoadingWaitModal";
import { setAccountId, setAccountNumber } from "../../features/account/accountSlice"


function ConsumerDashboard() {
  const hideAccountType = process.env.REACT_APP_HideAccountType
  const changeStatementsNoticesOrAccountNumber = process.env.REACT_APP_HideStatementsNoticesOrAccountNumber
  const hideEmailMemberNumber = process.env.REACT_APP_HideEmailMemberNumber
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const paymentSolution = localStorage.getItem("pay") === "true" ? true : false;

  const { user, userAccounts, accountNumber, isAuthenticated } = useAppSelector(
    (state) => ({ ...state.account }),
    shallowEqual
  );

  const navigate = useNavigate();

  useEffect(() => {
    if (userAccounts && userAccounts.length === 1) {
      const { accountId, accountNumber } = userAccounts[0];

      navigate(
        `/consumer/account-detail?accountId=${accountId}&accountNumber=${accountNumber}`, { replace: true }
      );
    }
  }, [])

  useEffect(() => {
    if (accountNumber !== '') {
      dispatch(setAccountNumber(''));
    }
  }, [])


  if (isAuthenticated) {
    return (
      <main id="mainContent">
        <Container>
          <h1 className="2">Account Dashboard</h1>
          <Card className="app-card shadows-light mb-3 maxw-768 mx-auto">
            <Card.Body>
              <Card.Title as="h2" className="app-card-title">
                <Icon name="account-circle" />
                Account Selection
              </Card.Title>
              <Row>
                <Col md={12}>
                  <p className="mb-1">
                    <strong>{hideEmailMemberNumber?.split(',').includes(user?.clientNumber) ? 'Member Number' : 'Email'}</strong>
                  </p>
                  <p>{user.email}</p>
                </Col>
              </Row>
              <ul className="list-unstyled">
                {userAccounts &&
                  userAccounts.map((p) => (
                    <li
                      key={p.accountId}
                      className="app-config-item app-account-selection_row mb-3"
                    >
                      <div className="account-selection-item">
                        <p className="mb-1">{changeStatementsNoticesOrAccountNumber?.split(',').includes(user?.clientNumber) ? 'Statements and Notices:' : 'Account Number:'}</p>
                        <p>
                          <strong>{p.accountNumber}</strong>
                        </p>
                      </div>
                      {/* hided  Account Type for space coast customer*/}
                      {!hideAccountType?.split(',').includes(user?.clientNumber) && <div className="account-selection-item">
                        <p className="mb-1">Account Type:</p>
                        <p>
                          <strong>{p.userAccountType}</strong>
                        </p>
                      </div>}
                      <div className="account-selection-item d-grid d-block">
                        <Button
                          onClick={() => {
                            dispatch(setAccountId(p.accountId))
                            dispatch(setAccountNumber(p.accountNumber))
                            dispatch(setDocuments([]));
                            navigate(`/consumer/account-detail`)
                          }}
                          id="accountPayButton1"
                          className='btn btn-lg btn-primary'
                        >
                          {paymentSolution ? 'Make Payment' : 'View Documents'}
                        </Button>
                      </div>
                    </li>
                  ))}
              </ul>
            </Card.Body>
          </Card>
        </Container>
        <LoadingWaitModal show={loading} size={"lg"}></LoadingWaitModal>
      </main>
    );
  }
  else if (sessionStorage.getItem('loggedOut') === 'true') {
    return (
      <main id="mainContent">
        <Container>
          <h1 className="h2">Logging Out...</h1>
        </Container>
      </main>
    );
  }
  else {
    return (
      <main id="mainContent">
        <Container>
          <h1 className="h2">No matchings accounts found.</h1>
        </Container>
      </main>
    );
  }
}

export default ConsumerDashboard;
