import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";


interface IRoleManagement {
  canCreateUser:boolean;
  canDeleteUser:true;
  canUpdateUser:boolean;
  managerRoleId:string;
  roleId:string;
  roleManagementId:number;
}

interface IRoleGroup {
  groupId: string,
  groupName: string,
  usersInGroup: string
}

interface InitialState {
  ssoAccount: object;
  user: any;
  userAccounts: Array<any>;
  userDetails: object;
  idToken: string;
  accToken: string;
  accountId: string;
  accountNumber: string;
  previousAccountNumber: string;
  isVaildCvv: any;  
  accountDetail: {
    dueDate?: string;
    amountDue?: string|number;
    lastPaymentAmount?: string|number;
    lastPaymentDate?: string;
  };
  accountGraphDetail: {
    accountNumber: string;
    total: string;
    paid: string;
    currentBalance: string;
  };
  role: {
    roleName: string,
    rolesId: string,
    roleManagements: Array<IRoleManagement>,
    roleGroup: IRoleGroup | undefined

  };
  clientLogo: string;
  expired: boolean;
  isAuthenticated: boolean;
  decodedData: object;
}

const initialState: InitialState = {
  ssoAccount: {},
  user: {},
  userAccounts: [],
  userDetails: {},
  idToken: "",
  accToken: "",
  accountId: "",
  accountNumber: "",
  previousAccountNumber: "",
  isVaildCvv: "",
  accountDetail: {
    amountDue: 0,
    dueDate: "",
    lastPaymentAmount: 0
  },
  accountGraphDetail: {
    accountNumber: "",
    total: "",
    paid: "",
    currentBalance: ""
  },
  role: {
    roleName: "",
    rolesId: "",
    roleManagements: [],
    roleGroup: undefined
  },
  clientLogo: "",
  expired: false,
  isAuthenticated : false,
  decodedData: {}
};

export const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    change: (state, action) => {
      state.ssoAccount = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload?.data ? action.payload?.data : action.payload;
    },
    setUserAccounts: (state, action) => {
      state.userAccounts = action.payload;
    },
    initUserDetails: (state, action) => {
      state.userDetails = action.payload;
    },
    setIdToken: (state, action) => {
      state.idToken = action.payload;
    },
    setAccToken: (state, action) => {
      state.accToken = action.payload;
    },
    setisVaildCvv: (state, action) => {
      state.isVaildCvv = action.payload;
    },
    setAccountNumber: (state, action) => {
      if (state.accountNumber !== action.payload) {
        state.previousAccountNumber = state.accountNumber;
        sessionStorage.removeItem('documents')
        sessionStorage.removeItem('payment history')
      }
      state.accountNumber = action.payload;
    },
    setAccountId: (state, action) => {
      state.accountId = action.payload;
    },
    setAccountDetail: (state, action) => {
      state.accountDetail = action.payload;
    },
    setAccountGraphDetail: (state, action) => {
      if (action.payload.accountNumber) {
        state.accountGraphDetail = action.payload;
      }
    },
    resetAccount: (state) => {
      state = initialState;
    },
    setRole: (state, action) => {
      state.role = action.payload;
    },
    setClientLogo: (state, action) => {
      state.clientLogo = action.payload;
    },
    setExpired: (state, action) => {
      state.expired = action.payload;
    },
    setisAuthenticated: (state, action) => {
      state.isAuthenticated = action.payload;
    },
    setdecodedData: (state, action) => {
      state.decodedData = action.payload;
    },
  }
});

export const {
  change,
  setUser,
  setUserAccounts,
  initUserDetails,
  setIdToken,
  setAccToken,
  setAccountId,
  setAccountNumber,
  setAccountDetail,
  setAccountGraphDetail,
  resetAccount,
  setRole,
  setClientLogo,
  setExpired,
  setisVaildCvv,
  setdecodedData,
  setisAuthenticated
} = accountSlice.actions;
export default accountSlice.reducer;
