import React from 'react';
import { Outlet } from 'react-router-dom';

const BaseLayout = (props: any) => {
  return (
    <React.Fragment>
      {props.children ? props.children : <Outlet />}
    </React.Fragment>
  )
};

export default BaseLayout;
