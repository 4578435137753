import React from "react";
import { Modal, ModalBody, ModalHeader } from "react-bootstrap";

interface IModal {
  show: boolean;
  hide?: (() => void) | undefined;
  size: "sm" | "lg" | "xl" | undefined;
}

export const LoadingWaitModal = React.memo((props: IModal) => {
  const { show, hide } = props;
  const size = props.size ? props.size : "sm";

  return (
    <Modal
      show={show}
      onHide={hide}
      size={size}
      centered
      animation={false}
      backdrop="static"
      keyboard={false}
      style={{zIndex:9999}}
    >
      <ModalHeader>
        <Modal.Title id="loadingWait">Processing, please wait...</Modal.Title>
      </ModalHeader>
      <ModalBody>
        <div className="text-center">
          <div className="lds-ring text-center">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
});
