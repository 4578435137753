import React, { FC } from "react";
import logo from "../../../assets/venture-solutions_horizontal-dark.svg";
import { Card } from "react-bootstrap";
import { shallowEqual } from "react-redux";
import { useAppSelector } from "../../../app/hooks";

type props = {
  title: string,
  message: string
};

const CardWithLogo: FC<props> = ({ title, message }) => {
  const  clientLogo = useAppSelector(
    (state: any) => state.account.clientLogo,
    shallowEqual
      );
  const  clientName = useAppSelector(
    (state: any) => state?.account?.user?.clientName,
    shallowEqual
      );
  return (
    <div className='startup-outer'>
      <Card className='startup-inner'>
        <Card.Body className='p-0'>
          {/*<Card.Title className="text-center">*/}
          {/*<img src={clientLogo ? `data:image/png;base64,${clientLogo}`: logo } width="125" alt={clientName || ''} />*/}
          {/*</Card.Title>*/}
          <Card.Text className="mt-4 text-center">
            <h2>{title}</h2>
            <p>{message}</p>
          </Card.Text>
        </Card.Body>
      </Card>
    </div>
  );
};

export default React.memo(CardWithLogo)
