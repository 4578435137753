// US Zipcode Validation

export function addhypen(str: string) {
    if ((/\D/).test(str) && str.length <= 10) {
        str = str.replace(/\D/g, "")
    }
    if (str.length > 10) {
        str = str.substring(0, 10);
    }
    else if (str.length > 5 && !str.includes('-')) {
        str = str.substring(0, 5) + '-' + str.substring(5);
    }
    return str;
}