import moment from "moment";

interface IRow {
  values: any;
}

export const sortDate = (
  rowA: IRow,
  rowB: IRow,
  columnId: string,
  desc: Boolean
) => {
  const dateA = moment(rowA.values[columnId], "MM/DD/YYYY");
  const dateB = moment(rowB.values[columnId], "MM/DD/YYYY");

  if (desc) {
    return dateA.isAfter(dateB) ? 1 : -1;
  } else {
    return dateA.isAfter(dateB) ? 1 : -1;
  }
};
