import { createSlice} from "@reduxjs/toolkit";
interface InitialState {
  userId: string;
  accountType: string;
  profile:object;
}

const initialState: InitialState = {
  userId:"",
  accountType: "",
  profile:{}
};

export const consumerSlice = createSlice({
  name: "consumer",
  initialState,
  reducers: {
    setUserId: (state, action) => {
      state.userId = action.payload;
    },
    setAccountType: (state, action) => {
      state.accountType = action.payload;
    },
    setProfile: (state, action) => {
        state.profile = action.payload;
      }
  }
});

export const {setUserId, setAccountType, setProfile } =
  consumerSlice.actions;
export default consumerSlice.reducer;
