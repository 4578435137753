import Skeleton from "react-loading-skeleton";
import { Card, Col, Form, Row } from "react-bootstrap"; 
import Icon from "../../../components/Common/Icons/Icons";

const SelectedPayment = () => {

    return (
        <Card className="mb-3">
            <Card.Body>
                <Card.Title as="h2" className="app-card-title">
                    <Icon name="credit-card" />
                    SELECT PAYMENT METHOD
                </Card.Title>
                <Skeleton width={"100%"} height={25} className="mb-2" />
                <Skeleton width={"100%"} height={25} className="mb-3" />
                <Skeleton width={160} height={40} />
            </Card.Body>
        </Card>
    );
};

export default SelectedPayment;