// @ts-nocheck
import React, { useState, useRef, useEffect } from "react";

// Components
import { Card, Col, Form, Row, InputGroup, Alert } from "react-bootstrap";
import { Controller, useFieldArray } from "react-hook-form";
import { DatePicker } from "../../components/Common/DatePicker";
import Icon from "../../components/Common/Icons/Icons";
import moment from "moment";
import { allow2DigitsAfterDecimal, formatMoney, isAfterBusinessHours } from "../../util/formatUtil";
import { useAppSelector } from "../../app/hooks";
import PaymentInfoPlaceholder from "./placeholders/paymentInfo";
import { shallowEqual } from "react-redux";
import Skeleton from "react-loading-skeleton";
import { allowOneDot } from "../../util/formatUtil";

function PaymentInformation(
    { formProps, paymentDetails, pastDue, setPastDue, addPastAmount, setAddPastAmount, lateDate, dueDate, setAmount, paymentSection, amount, parentLoading = false,setaccountInformationPaymentDate}
) {

  const [minDate] = useState<Date | undefined>(isAfterBusinessHours() ?
  moment().add(1, 'days').toDate() : moment().toDate());
  const [maxDate] = useState(moment().add(120, "days").toDate());
  const [displayLate, setDisplayLate] = useState(false);
  const [seletedDate, setSeletedDate] = useState<Date | undefined>(isAfterBusinessHours() ?
  moment().add(1, 'days').toDate() : moment().toDate());
  const [totaldue, setTotalDue] = useState(0);
  // const [pastDate, setPastDate] = useState(null);
  const [totalAmount, setTotalAmount] = useState(0);

  const { control, watch, setValue, unregister, getValues, trigger, formState: { errors } } = formProps;
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
    control,
    name: "additionalPaymentList"
  });

  const [diffDays, setDiffDays] = useState(0);

  const paymentInfo = useAppSelector((state) => ({ ...state.onetimepayment.data }), shallowEqual);
  const [formValues, setformValues] = useState(createCustomFieldObj());

  const loader = useAppSelector((state) => ({ ...state.onetimepayment }), shallowEqual);


  //dueDateTooltip on calender
  const dueDateTooltip = (day: string, date: Date) => {
    const tooltipText = `Due Date`;
    if (moment(date).format("MM/dd/yyyy") == moment(dueDate).format("MM/dd/yyyy")) {
      return <span title={tooltipText}>{date.getDate()}</span>;
    } else {
      return date.getDate();
    }
  };

  const { _additionalPaymentList } = watch((data: any) => {
    const paymentList = data?.additionalPaymentList;

    let totalValue = 0;
    paymentInfo.defaultInformation?.filter((a) => {
      if (a.labelText === "TotalDue") {
        totalValue = a.decimalValue;
        setTotalDue(a.formattedValue)
      }
      if (a.labelText === "PastDueAmount") {
        setPastDue(a.decimalValue)
      }
      // if (a.labelText === "DueDate") {
        // setPastDate(a.dateValue);
      // }
    })

    const additionalAmount = paymentList?.filter((a) => a.isCustomField === true)
      .map(item => item.decimalValue)
      .reduce((prev, curr) => {
        const total = Number(prev) + Number(curr);
        return total
      }, 0);

    // setTotalAmount(Number(totalValue) + Number(additionalAmount))

  })

  setaccountInformationPaymentDate(seletedDate)

  useEffect(() => {
    const _paymentInformation = !paymentInfo?.paymentInformation ? [] : paymentInfo.paymentInformation.map(item=>{
      return {
        ...item,
        isAmountFieldChecked: item.formattedValue || item.decimalValue ? true : false
      }
    })

    setValue("additionalPaymentList",_paymentInformation);

    let dueDate = paymentInfo?.defaultInformation?.filter((item)=> item.attributeName === "DueDate");

    // Switch the code based on either present date or payment date
    // const currentDate = seletedDate;
    const currentDate = new Date();
    const days = moment(moment(currentDate).format("MM/DD/YYYY")).diff(moment(dueDate?.length > 0 ? moment(dueDate[0].dateValue).format("MM/DD/YYYY") : undefined),'days');
    setDiffDays(days);
    if (days > 0) {
      setAddPastAmount(true);
    }

    if (seletedDate && dueDate  && moment(seletedDate).isAfter(moment(dueDate ,'YYYY-MM-DD'))) {
      setDisplayLate(true);
    }
    else {
      setDisplayLate(false);
    }
    // added below code to clear formvalues when user swicth to multiple accounts
    setformValues({});
  }, [paymentInfo])
  
  function createCustomFieldObj() {
    let temp: any = {};
    let tempdata = paymentInfo?.paymentInformation?.filter((item: any) => item.isCustomField || item.attributeName === 'PastDueAmount');
    for (let i = 0; i < tempdata?.length; i++) {
      temp[tempdata[i].attributeName] = tempdata[i].decimalValue;
      temp[tempdata[i].attributeName + " toggle"] = true;
    }
    return temp;
  }

  useEffect(() => {
    if(Object.keys(formValues).length === 0){
      setformValues(createCustomFieldObj())
    }
    let count = 0;
    for (const [key, value] of Object.entries(Object.keys(formValues).length > 0 ? formValues : createCustomFieldObj())) {
      if (!key.includes('toggle') && (formValues[key + ' toggle'] !== false)) {
        count += isNaN(Number(value != null ? value : 0)) ? 0 : Number(value != null ? value : 0);
      }
    }
    let totalamt = paymentInfo?.defaultInformation?.filter((item: any) => item.attributeName === 'TotalDue');
    let totalDueAmnt = count + Number(totalamt?.length > 0 ? totalamt[0].decimalValue : 0);
    setAmount(totalDueAmnt);
    setTotalAmount(totalDueAmnt);
  }, [getValues(),diffDays]);

  return (
    <Card className="mb-3 shadows-light">
      <Card.Body>
        <Card.Title as="h2" className="app-card-title">
          <Icon name="credit-card" />
          Payment Information
        </Card.Title>
        {parentLoading || loader.status === "loading" ?
          <PaymentInfoPlaceholder /> : <>            
              
            <hr />            
            {paymentInfo?.paymentInformation?.map((item: any, index: any) => {
              return (
                <div key={index}>
                  {item.isCustomField === false ?
                    <div>
                      {item?.attributeName?.toLowerCase() === 'pastdueamount' ?
                        <Row>
                          <Col sm={6}>
                            <Form.Check
                              type="checkbox"
                              label={"Past Due Amount"}
                              className="mb-2"
                              defaultChecked={addPastAmount}
                              checked={formValues[item.attributeName + " toggle"]}
                              name={item.attributeName + " toggle"}
                              disabled={diffDays > 0 ? true : false}
                              onChange={(e) => {
                                if (diffDays > 0) {
                                  setAddPastAmount(true);
                                } else {
                                  setAddPastAmount(e.target.checked);
                                  setformValues({ ...formValues, [e.target.name]: e.target.checked, [item.attributeName]: pastDue });
                                }
                                
                              }}
                            />
                          </Col>
                          <Col sm={6}>
                            <p className="text-sm-end m-0">
                              <strong>
                                $ {pastDue}
                              </strong>
                            </p>
                          </Col>
                        </Row>
                        :
                        <Row>
                          <Col sm={6}>
                            <p className="mb-1">{item.labelText}</p>
                          </Col>
                          <Col sm={6}>
                            {item.attributeType === "Date" ?
                              <p className="text-sm-end m-0">
                                <strong>
                                  {item.dateValue ? <>
                                    {moment(item.dateValue).format("MM/DD/YYYY")}
                                  </>
                                    : "-"}
                                </strong>
                              </p> : <p className="text-sm-end m-0">
                                <strong>
                                  {item.formattedValue ? item.formattedValue : "$ 0"}
                                </strong>
                              </p>}
                          </Col>
                        </Row>}
                      <hr />
                    </div> :
                    <>
                      <Row className="d-flex align-items-center" key={index}>
                        <Controller
                          name={`additionalPaymentList[${index}].isAmountFieldChecked`}
                          control={control}
                          render={({ field }) => (
                            <Col sm={6}>
                              {!item.readonly ?
                                <Form.Check
                                  type="checkbox"
                                  label={item.labelText}
                                  name={item.attributeName + " toggle"}
                                  className="mb-2"
                                  defaultChecked={true}
                                  checked={formValues[item.attributeName + " toggle"]}
                                  disabled={item.readonly}
                                  onChange={(e) => {
                                    field.onChange(e.target.checked);
                                    setformValues({ ...formValues, [e.target.name]: e.target.checked, [item.attributeName]: formValues[item.attributeName] ? formValues[item.attributeName] : item.decimalValue });
                                    if (!e.target.checked) {
                                      trigger("additionalPaymentList")
                                      setValue(`additionalPaymentList[${index}].formattedValue`, 0)
                                      setValue(`additionalPaymentList[${index}].decimalValue`, 0)
                                    }
                                  }}
                                /> :
                                <label>{item.labelText}</label>
                              }
                            </Col>
                          )}
                        />
                        <Controller
                          name={`additionalPaymentList[${index}].formattedValue`}
                          control={control}
                          render={({ field }) => (
                            <Col sm={6} className={item.readonly ? "mb-2 text-end" : "mb-2"}>
                              {item.readonly ?
                                <strong>{item.formattedValue == null ? "$ 0.00" : item.formattedValue}</strong>
                                :
                                <>
                                  <InputGroup>
                                    <InputGroup.Text id="currencyPrefix" className={(errors?.additionalPaymentList as any) && errors?.additionalPaymentList[index]?.formattedValue?.type === "required" ? "form-field-error ms-sm-auto border-end-0" : "ms-sm-auto"}>$</InputGroup.Text>
                                    <Form.Control
                                      type="text"
                                      placeholder="0.00"
                                      value={Object.keys(formValues).length > 0 ? formValues[item.attributeName] : item.decimalValue}
                                      arial-label={`${item.labelText}`}
                                      name={item.attributeName}
                                      className={(errors?.additionalPaymentList as any) && errors?.additionalPaymentList[index]?.formattedValue?.type === "required" ? "form-field-error ms-sm-auto" : "ms-sm-auto"}
                                      disabled={!formValues[item.attributeName + " toggle"]}
                                      onChange={(e: any) => {
                                        let value = e.target.value;
                                        if (item.attributeType.toLowerCase() !== 'money') {
                                          value = value.replace(/[^\d^\.]+/g, "")
                                            .replace(/^0+(\d)/, "$1")
                                            .replace(/^\./, "0.")
                                            .match(/^\d*(\.?\d{0,2})/g)[0] || "";
                                        } else {
                                          value = allowOneDot(value);
                                        }
                                        // allow only 2 digit's after decimal
                                        if (value?.length > 0) {
                                          value = allow2DigitsAfterDecimal(value)
                                        }
                                        value = Number(value) > 20000 ? '20000' : value;
                                        field.onChange(value);
                                        setformValues({ ...formValues, [e.target.name]: value });
                                        setValue(`additionalPaymentList[${index}].decimalValue`, value)
                                      }}
                                      onBlur={(e) => {
                                        let value = e.target.value.split('.')[0]
                                        if (value === "0" || value === "$ 0") {
                                          field.onChange("");
                                        }
                                        setValue(`additionalPaymentList[${index}].formattedValue`, value)
                                      }}
                                    />
                                  </InputGroup>
                                  {(errors?.additionalPaymentList && (errors?.additionalPaymentList[index]?.formattedValue?.type === "required")) &&
                                    <span className="form-field-error-msg text-end mt-1">{errors?.additionalPaymentList[index]?.formattedValue?.message}</span>
                                  }
                                </>
                              }
                            </Col>
                          )}
                        />
                      </Row>
                      <hr />
                    </>
                  }
                </div>
              )
            })}
            <Row>
              <Col sm={6}>
                <p className="mb-1">Total Amount</p>
              </Col>
              <Col sm={6}>
                <p className="text-sm-end m-0">
                  <strong>
                    {totaldue}
                  </strong>
                </p>
              </Col>
            </Row>
            <hr />
            <Card>
					    <Card.Header className="bg-warning text-light">
                <Row>
                  <Col sm={6} className="align-self-center">
                    <Form.Label
                      htmlFor="paymentDate"
                      className="mb-0 size16-font fw-normal"
                    >
                      {displayLate && 
                        <Icon name="warning" optionalClass="me-2" />
                      } Payment Date*
                    </Form.Label>
                  </Col>
                  <Col sm={6}>
                    {/* TODO: Payment date requirements, no past date and no greater than 120 days in future */}
                    <Controller
                      name="Payment_Date"
                      control={control}
                      render={({ field }) => (
                        <DatePicker
                          id="paymentDate"
                          ariaDescribedBy="paymentDateDisclaimer"
                          minDate={minDate}
                          maxDate={maxDate}
                          selectedDate={seletedDate}
                          highlightDates={dueDate && [{ "datepicker-highlight-duedate": [moment(moment(dueDate, 'YYYY-MM-DD').format('MM/DD/YYYY')).toDate()] }]}
                          renderDayContents={dueDateTooltip}
                          onChange={(date: Date | undefined) => {
                            //field.onChange(formatDateTime(date));
                            if (date && moment(date).isAfter(moment(dueDate, 'YYYY-MM-DD'))) {
                              setDisplayLate(true);
                              setformValues({ ...formValues, ['PastDueAmount toggle']: true, ['PastDueAmount']: pastDue });
                              setAddPastAmount(true);
                            } else {
                              setDisplayLate(false);
                              setAddPastAmount(true);
                            }
                            if (date) {
                              setSeletedDate(date);
                              field.onChange(date);
                            } else {
                              setSeletedDate("");
                              field.onChange("");
                            }
                            setDiffDays(moment(moment(date).format("MM/DD/YYYY")).diff(moment(moment(paymentInfo?.defaultInformation?.filter((item)=> item.attributeName === "DueDate")[0].dateValue).format("MM/DD/YYYY")),'days'))
                          }}
                          isDateRange={true}
                        />
                      )}
                    />
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body>
                {displayLate && 
                  <Alert variant="warning p-2" className="mt-2">
                    <div className="d-flex align-items-center">
                      <div className="me-3">
                        <Icon
                          name="warning"
                          optionalClass="p-1 rounded-circle bg-light"
                          size={36}
                          />
                      </div>
                      <div>
                        Late fees may incur if payment is made after the billing due date.
                      </div>
                    </div>
                  </Alert>
                }
                <Form.Text id="paymentDateDisclaimer" className="text-dark">
                  <React.Fragment>
                    <span className="text-warning">*</span> Payments submitted after 7 p.m. (CST) M-F or on the
                    weekends/holidays will not process until the next business day.
                    This also applies to scheduled payments that fall on
                    weekends/holidays.
                  </React.Fragment>
                </Form.Text>
              </Card.Body>
            </Card>
          </>
        }
      </Card.Body>
      <Card.Footer>
        <Row>
          <Col sm={6}>
            <p className="h3">
              <strong>Payment Amount</strong>
            </p>
          </Col>
          <Col sm={6}>
            <p className="h3 text-sm-end">
              {parentLoading || loader.status === "loading" ?
                <Skeleton width={120} height={20} /> :
                <strong>
                  {formatMoney(
                    Number(totalAmount)
                  )}

                </strong>}
            </p>
          </Col>
        </Row>
      </Card.Footer>
    </Card>
  );
}
export default PaymentInformation;


