import React from "react";
//import { Link } from "react-router-dom";
import Icon from "../../components/Common/Icons/Icons";
import { Row, Col, Card } from "react-bootstrap";
import { useAppSelector } from "../../app/hooks";
import { shallowEqual } from "react-redux";

function ProfileInformation(props) {

	const { user } = useAppSelector(
		(state) => ({ ...state.account }),
		shallowEqual
	  );

	const hideEmailMemberNumber = process.env.REACT_APP_HideEmailMemberNumber;
	return (
		<Card className="app-card shadows-light mb-3">
			<Card.Body>
				<Card.Title as="h2" className="app-card-title">
					<Icon name="account-circle" />
					Profile Information
				</Card.Title>
				<Row>
					<Col lg={6}>
						<p className="mb-1">
							Full Name
						</p>
					</Col>
					<Col lg={6}>
						<p>
							<strong>{props.userDetails?.FirstName +' '+ props.userDetails?.LastName}</strong>
						</p>
					</Col>
				</Row>
				<Row>
					<Col lg={6}>
						<p className="mb-1">
							Mailing Address
						</p>
					</Col>
					<Col lg={6}>
						<p>
							<strong>{props.userDetails?.MailingAddress?.Address}</strong>
						</p>
					</Col>
				</Row>
				<Row>
					<Col lg={6}>
						<p className="mb-1">
							Apt./Suite #
						</p>
					</Col>
					<Col lg={6}>
						<p>
							<strong>{props.userDetails?.MailingAddress?.Apartment}</strong>
						</p>
					</Col>
				</Row>
				<Row>
					<Col lg={6}>
						<p className="mb-1">
							City
						</p>
					</Col>
					<Col lg={6}>
						<p>
							<strong>{props.userDetails?.MailingAddress?.City}</strong>
						</p>
					</Col>
				</Row>
				<Row>
					<Col lg={6}>
						<p className="mb-1">
							State
						</p>
					</Col>
					<Col lg={6}>
						<p>
							<strong>{props.userDetails?.MailingAddress?.State}</strong>
						</p>
					</Col>
				</Row>
				<Row>
					<Col lg={6}>
						<p className="mb-1">
							Zip Code
						</p>
					</Col>
					<Col lg={6}>
						<p>
							<strong>{props.userDetails?.MailingAddress?.Zip}</strong>
						</p>
					</Col>
				</Row>
				<Row>
					<Col lg={6}>
						<p className="mb-1">
							Phone Number
						</p>
					</Col>
					<Col lg={6}>
						<p>
							<strong>{props.userDetails?.Phone}</strong>
						</p>
					</Col>
				</Row>
				<Row>
					<Col lg={6}>
						<p className="mb-1">
						{hideEmailMemberNumber?.split(',').includes(user?.clientNumber) ? 'Member Number' : 'Email'}
						</p>
					</Col>
					<Col lg={6}>
						<p>
							<strong>{props.userDetails?.Email}</strong>
						</p>
					</Col>
				</Row>
			</Card.Body>
		</Card>
	);
}
export default ProfileInformation;
