import { shallowEqual, useSelector } from 'react-redux';
import { Navigate, Route, useLocation } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { useAppSelector } from "../app/hooks";

import { ROLE } from './Roles';

import AccessDenied from '../pages/Common/AccessDenied';
import UnAuthorized from '../pages/Common/UnAuthorized';

const PrivateRoute = ({ children, roles }: { children: JSX.Element; roles: Array<ROLE>; }) => {
    //let location = useLocation();

    // get user detail from Store
    const { idToken, role, isAuthenticated } = useAppSelector(
        (state) => ({ ...state.account }),
        shallowEqual
    );

    // const {accounts, instance} = useMsal();
    // const loggedIn = instance.getActiveAccount() || accounts[0]

    const roleName = role?.roleName as ROLE;

    if (!isAuthenticated) {
        return <Navigate to="/" state={{ from: location }} />;
        //return <UnAuthorized />;
    }

    const userHasRequiredRole = roles.includes(roleName) ? true : false;

    if (isAuthenticated && !userHasRequiredRole) {
        return <AccessDenied />;
    }

    return children;
};

export default PrivateRoute;
