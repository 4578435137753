// Hooks
import { useEffect, useState, useMemo, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useFilterForm } from "../../hooks/useFilterForm";
import { Controller } from "react-hook-form";

// Utils
import moment from "moment";
import { formatDate } from "../../util/formatUtil";
import { getPdfMethod } from "../../common/getPdfMethod";

// Api
import { getPdfList } from "../../api/document";

// Components
import { Button, Accordion, Form } from "react-bootstrap";
import { DatePicker } from "../../components/Common/DatePicker";
import Icon from "../../components/Common/Icons/Icons";
import {
  FromToDateRangeFilter,
  Table,
  TableExpandToggleHeader,
  TableFilter,
  TableHeader
} from "../../components/Common/Table";
import { LoadingWaitModal } from "../../components/Common/Modal/LoadingWaitModal";
import { Typeahead } from 'react-bootstrap-typeahead';
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

// scss
import breakPoints from "../../scss/abstracts/_variables.scss";

// Store
import { setDocuments } from "../../features/document/documentSlice";
import { shallowEqual } from "react-redux";
import { $toast, toastMessage } from "../../util/toastUtil";
import { isArray } from "lodash";

function Documents({ activeTab }: any) {
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState<Date | undefined>(moment().subtract(24, "months").toDate());
  const [endDate, setEndDate] = useState<Date | undefined>(new Date());
  const [currentRow, setCurrentRow] = useState<any>({});
  const [getDocumentListMethodCalled, setgetDocumentListMethodCalled] = useState(false);
  const [filteredDocumentValue, setfilteredDocumentValue] = useState<any>();
  const [documentType, setdocumentType] = useState([]);
  const [openPdf, setopenPdf] = useState(false);
  const [currentOpenedDocument, setcurrentOpenedDocument] = useState('');

  const navigate = useNavigate();

  const { accountNumber, accountId } = useAppSelector(
    (state: any) => ({ ...state.account }),
    shallowEqual
  );

  const getDocumentListMethod = async (
    dispatch: Function,
    accountNum: string,
    callBack: Function
  ) => {
    try {
      let response = await getPdfList({
        "Accountid": accountId,
      });
      if (response.status === 200) {
        if (typeof response.data === "string") {
          let docs = JSON.parse(response.data);
          if (typeof docs === "string") {
            docs = JSON.parse(docs);
          }
          const docList = docs.mailItems;

          dispatch(setDocuments(docList));
        }
      }
    } catch (ex) {
      console.error("caught error: ", ex);
      $toast.danger({
        show: true,
        body: toastMessage.notFoundDocumentList,
        icon: "warning",
        autohide: true,
        delay: 3000
      })
    } finally {
      callBack();
    }
  };

  const tableRef = useRef<any>()
  const typeaheadRef = useRef<any>();

  const filterFieldsChange = () => {
    tableRef?.current?.gotoFirstPage();
    sessionStorage.setItem('documents', JSON.stringify(filters));
  }

  let documentsSessionData = JSON.parse(`${sessionStorage.getItem("documents")}`) ? JSON.parse(`${sessionStorage.getItem("documents")}`) : [];
  let documentsFilterData: any = {};
  if (isArray(documentsSessionData)) {
    documentsSessionData?.forEach((item: any) => {
      documentsFilterData[item.id] = item.value;
    });
  }

  const { filters, filterControl, registerFilter, clearFilter, getValuesFilter, resetFilterForm } =
    useFilterForm({
      defaultValues: {
        statementDate: {
          from: documentsFilterData?.statementDate?.from ? moment(documentsFilterData.statementDate.from).toDate() : startDate,
          to: documentsFilterData?.statementDate?.to ? moment(documentsFilterData.statementDate.to).toDate() : endDate
        },
        documentType: documentsFilterData?.documentType ? documentsFilterData.documentType : "",
      }
    });

  useEffect(() => {
    setStartDate(documentsFilterData?.statementDate?.from ? moment(documentsFilterData.statementDate.from).startOf('day').toDate() : startDate);
    setEndDate(documentsFilterData?.statementDate?.to ? moment(documentsFilterData.statementDate.to).endOf('day').toDate() : endDate);
  }, [])

  useEffect(() => {
    sessionStorage.setItem('documents', JSON.stringify(filters));
  }, [filters])


  const filterTypes = useMemo(
    () => ({
      fromToDateRangeFilter: FromToDateRangeFilter
    }),
    []
  );

  const columns = useMemo(
    () => [
      {
        Header: ({ column }: any) => (
          <TableHeader column={column}>Document Date</TableHeader>
        ),
        accessor: "statementDate",
        filter: "fromToDateRangeFilter",
        sortType: (rowA: any, rowB: any, columnId: string) => {
          const _rowA = moment(rowA.values.paymentDateRaw)
          const _rowB = moment(rowB.values.paymentDateRaw)
          return moment(_rowA).isAfter(moment(_rowB)) ? 1 : -1
        },
      },
      {
        Header: ({ column }: any) => (
          <TableHeader column={column}>Document Type</TableHeader>
        ),
        accessor: "documentType"
      },
      {
        Header: () => <div>View Document</div>,
        id: "view-document",
        Cell: (props: any) => (
          <Button
            variant="outline-primary"
            onClick={() => {
              viewClick(props);
            }}
          >
            View
          </Button>
        )
      }
    ],
    []
  );

  const tablet = useMemo(
    () => Number(breakPoints.tablet.replace("px", "")),
    []
  );

  const { documents } = useAppSelector(
    (state) => ({ ...state.document }),
    shallowEqual
  );

  function getUniqueDocumentType() {
    if (documents.length > 0) {
      const uniqueMailDescriptions: any = Array.from(new Set(documents.map(item => item.mailDescription)));
      setdocumentType(uniqueMailDescriptions);
    }
  }

  useEffect(() => {
    getUniqueDocumentType();
  }, [documents])

  useEffect(() => {
    if (activeTab === 'documents' && documents?.length === 0 && !getDocumentListMethodCalled) {
      setLoading(true);
      getDocumentListMethod(dispatch, accountNumber, () => {
        setLoading(false);
        setgetDocumentListMethodCalled(true);
      });
    }
  }, [dispatch, accountNumber, activeTab]);

  let filteredData = documents.filter(item => {
    const itemDate = moment(moment(item.statementDate).format('YYYY-MM-DD'));
    return (
      filteredDocumentValue?.length > 0 ? itemDate.isBetween(startDate, endDate, null, '[]') &&
      item.mailDescription === filteredDocumentValue[0]
      : itemDate.isBetween(startDate, endDate, null, '[]')
    );
  });

  filteredData = filteredData.sort((a, b): any => moment(a.statementDate).isAfter(moment(b.statementDate)) ? -1 : 1)

  const tableData = useMemo(() => {
    return filteredData.length > 0
      ? filteredData.map((item: any) => {
        return {
          statementDate: formatDate(item.statementDate),
          documentDate: formatDate(item.mailDate),
          documentType: item.mailDescription,
          documentId: item.productNumber,
          acctDtlId: item.mailLink.acctDtlId,
          parentNumber: item.mailLink.parentNumber
        };
      })
      : [];
  }, [documents, filters]);

  const viewClick = async (cell: any) => {
    setCurrentRow(cell?.row?.original);

    const acctDtlId = cell?.row?.original?.acctDtlId;
    setLoading(true);
    await getPdfMethod(acctDtlId, () => {
      setLoading(false);
    });
    navigate('/viewer')
  };

  const resetTableSearch = () => {
    setStartDate(moment().subtract(24, "months").toDate())
    setEndDate(new Date())
    const initialTableSearch = {
      statementDate: {
        from: moment().subtract(24, "months").toDate(),
        to: new Date()
      },
      documentType: ""
    };
    setfilteredDocumentValue([])
    typeaheadRef.current.clear()
    resetFilterForm(initialTableSearch);
    sessionStorage.setItem('documents', JSON.stringify(initialTableSearch));
    filterFieldsChange()
  }

  return (
    <div id="documentsTableFilters" className="app-panel-container">
      <div className="app-left-panel">
        <TableFilter autoShowMinWidth={tablet} onClear={resetTableSearch}>
          <Accordion className="app-accordions" defaultActiveKey={['0', '1']} alwaysOpen>
            {/* <Accordion.Item eventKey="0">
							<Accordion.Header>Account Number</Accordion.Header>
							<Accordion.Body>
								<Form.Control {...registerFilter("accountNumber")} />
							</Accordion.Body>
						</Accordion.Item> */}
            <Accordion.Item eventKey="0">
              <Accordion.Header>Document Date</Accordion.Header>
              <Accordion.Body>
                <Controller
                  name="statementDate.from"
                  control={filterControl}
                  render={({ field }) => (
                    <div className="text-center">
                      <DatePicker
                        containerClassName="mb-1"
                        selectedDate={field.value}
                        placeholderText="MM/DD/YYYY"
                        maxDate={endDate}
                        onChange={(date: Date | any) => {
                          if (date) {
                            field.onChange(date);
                          } else {
                            field.onChange("");
                          }
                          const dateAtMorning = new Date(date);
                          dateAtMorning.setHours(0);
                          dateAtMorning.setMinutes(0);
                          dateAtMorning.setSeconds(0);
                          setStartDate(dateAtMorning);
                          // setEndDate(getValuesFilter("statementDate.to"));
                          filterFieldsChange()
                        }}
                      />
                    </div>
                  )}
                />

                <Controller
                  name="statementDate.to"
                  control={filterControl}
                  render={({ field }) => (
                    <div className="text-center">
                      <DatePicker
                        selectedDate={field.value}
                        placeholderText="MM/DD/YYYY"
                        minDate={startDate}
                        onChange={(date: Date | any) => {
                          if (date) {
                            field.onChange(date);
                          } else {
                            field.onChange("");
                          }
                          // setStartDate(getValuesFilter("statementDate.from"));
                          const dateAtMidnight = new Date(date);
                          dateAtMidnight.setHours(23);
                          dateAtMidnight.setMinutes(59);
                          dateAtMidnight.setSeconds(59);
                          setEndDate(dateAtMidnight);
                          filterFieldsChange()
                        }}
                      />
                    </div>
                  )}
                />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>Document Type</Accordion.Header>
              <Accordion.Body>
                <Controller
                  name="statementDate.from"
                  control={filterControl}
                  render={({ field }) => (
                    <Typeahead
                      id="documentstype"
                      labelKey="name"
                      onChange={(selected) => {
                        setfilteredDocumentValue(selected);
                      }}
                      options={documentType}
                      placeholder={"Type text here to search"}
                      selected={filteredDocumentValue}
                      ref={typeaheadRef}
                    />
                  )} />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </TableFilter>
      </div>
      <div className="app-right-panel">
        <Table
          ref={tableRef}
          columns={columns}
          data={tableData}
          filters={filters}
          filterTypes={filterTypes}
          sortable
          bordered
          hover
          responsive
        />
      </div>
      <LoadingWaitModal show={loading} size={"lg"}></LoadingWaitModal>
    </div>
  );
}
export default Documents;
