import React, { useEffect } from "react";
import UserMenu from "../../components/Header/UserMenu";
import UserGreet from "../../components/Header/UserGreet";
import logo from "../../assets/venture-solutions_horizontal-dark.svg";
import { NavLink, Link } from "react-router-dom";
import { Container, Nav, Navbar } from "react-bootstrap";
import { shallowEqual } from "react-redux";
import { useAppSelector } from "../../app/hooks";

function ClientAdminNav() {
  const { permissions } = useAppSelector(
		(state) => ({ ...state.permissions }),
		shallowEqual
	  );

  const  clientLogo = useAppSelector(
      (state: any) => state.account.clientLogo,
      shallowEqual
        );
  const  clientName = useAppSelector(
    (state: any) => state?.account?.user?.clientName,
    shallowEqual
      );
      
  return (
    <header id="ClientHeader" className="mb-4">
      <Navbar className="app-navbar" expand="md" variant="light">
        <Container>
          <Navbar.Toggle aria-controls="appNavbar" />
          <Navbar.Brand as={Link} to="/client">
          <img src={clientLogo ? `data:image/png;base64,${clientLogo}`: logo } alt={clientName || ''} />
          </Navbar.Brand>
          <Navbar.Collapse id="appNavbar">
            <Nav>
              <NavLink to="/client"
                className={({ isActive }) =>
                  isActive && (location.pathname == "/client") ? "nav-link active" : "nav-link"
                }>
                Home
              </NavLink>
              <NavLink to="/client/documents"
                className={({ isActive }) =>
                  isActive && location.pathname == "/client/documents" ? "nav-link active" : "nav-link"
                }>
                Documents
              </NavLink>
              {permissions.canShowUsers &&
              <NavLink to="/client/users"
                className={({ isActive }) =>
                  isActive && (location.pathname == "/client/users") ? "nav-link active" : "nav-link"
                }>
                Users
              </NavLink>
            }
            </Nav>
          </Navbar.Collapse>
          <UserGreet />
          <UserMenu />
        </Container>
      </Navbar>
    </header>
  );
}

export default ClientAdminNav;
