import React, { useCallback, useState, useEffect } from "react";

//import { Link } from "react-router-dom";
import Icon from "../../components/Common/Icons/Icons";
import { Card, Button } from "react-bootstrap";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { shallowEqual } from "react-redux";
import {
  deleteUserPaymentMethods,
  getUserPaymentMethodById
} from "../../api/userPaymentMethods";

import { ConfirmModal } from "../../components/Common/Modal";

import AddPaymentMethodForm from "./AddPaymentMethodForm";
import EditPaymentMethodForm from "./EditPaymentMethodForm";
import { $toast } from "../../util/toastUtil";
import TogglePayType from "../../components/Common/TogglePayType/TogglePayType";

interface IPaymentMethodsProps {
  setLoading: (isLoading: boolean)=> void | undefined;
}

interface IUserPaymentMethodItem {
  id: string;
  Payment_Type: string;
  Method_Nickname: string;
  paymentGatewayCustomerVaultId: string;
}

function PaymentMethods(props: IPaymentMethodsProps) {
  const dispatch = useAppDispatch();

  const { paymentMethods } = useAppSelector(
    (state) => ({ ...state.payment }),
    shallowEqual
  );
  const { userPaymentMethod } = useAppSelector(
    (state) => ({ ...state.payment }),
    shallowEqual
  );

  const [userPaymentMethods, setUserPaymentMethods] = useState<Array<IUserPaymentMethodItem>>([]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<IUserPaymentMethodItem | null>();

  // create new userPaymentMethod
  const [isAdding, setIsAdding] = useState(false); // show/hide AddPaymentMethodForm
  const [isDeletePaymentMethod, setIsDeletePaymentMethod] = useState(false); // show/hide delete confirm modal

  //Edit Method
  const [isEditingPaymentMethod, setIsEditingPaymentMethod] = useState(false); // show/hide edit pop-up


  useEffect(() => {
    setUserPaymentMethods([]);
    userPaymentMethod.forEach((element) => {
      const id = element.userPaymentMethodId;
     
      if (paymentMethods.find((userMethod) => userMethod.paymentMethodId == element.paymentMethodId)) {
        const Payment_Type = paymentMethods.find(
          (userMethod) => userMethod.paymentMethodId == element.paymentMethodId
        ).paymentMethodName;
        const Method_Nickname = element.nickName;
        const paymentGatewayCustomerVaultId = element.paymentGatewayCustomerVaultId;
        const paymentMethodAttributes = element?.paymentMethodAttributes != null ? JSON.parse(element.paymentMethodAttributes) : null;
        setUserPaymentMethods((curr: any) => [
          ...curr,
          {
            id,
            Payment_Type,
            Method_Nickname,
            paymentGatewayCustomerVaultId,
            paymentMethodAttributes
          }
        ]);
      }
    });
  }, [userPaymentMethod]);

  const onRemovePaymentMethod = useCallback(
    (payment: any) => {
      props.setLoading(true);
      deleteUserPaymentMethods(payment.id).then((res:any) => {
        if (res.status && res.status == 200) {
          setUserPaymentMethods((curr) => curr.filter((m) => m.id !== payment.id));
          $toast.success({
            title:'Method Deleted',
            body:'Your payment method was deleted successfully.'
          })
        }else{
          showErrorToast(res.response?res.response.data:res.message)
        }
      }).catch((error:any) => {
        showErrorToast(error.response?error.response.data:error.message)
      }).finally(()=>{
        props.setLoading(false);
      });
    },
    [setUserPaymentMethods]
  );

  const showErrorToast = (msg:string)=>{
    $toast.danger({
      title:'Failed',
      body: msg
    })
  }

  const handleMethodEdit = (selectedMethod:IUserPaymentMethodItem)=>{
    // props.setLoading(true)
    // setIsAdding(false)
    // const _method = JSON.stringify(
    //   userPaymentMethod.filter((paymentMethod) => {
    //       return paymentMethod.userPaymentMethodId === p.id
    //   })[0]
    // );
    // let _userPaymentMethod = JSON.parse(_method)

    // getUserPaymentMethodById(_userPaymentMethod.userPaymentMethodId).then((res:any)=>{
    //   if (res.status == 200) {
    //     _userPaymentMethod.clientVault = res.data.clientVault
    //     setSelectedPaymentMethod(_userPaymentMethod);
    //     setIsEditingPaymentMethod(true);
    //   }else{;
    //     showErrorToast(res.response?res.response.data:res.message)
    //   }
    // }).catch((error:any)=>{
    //    showErrorToast(error.response?error.response.data:error.message)
    // }).finally(()=>{
    //   props.setLoading(false)
    // })

    const _selectedMethod = {...selectedMethod}
    setSelectedPaymentMethod(_selectedMethod);
    setIsEditingPaymentMethod(true);
  }

  return (
    <>
    <Card className="app-card mb-3 shadows-light">
      <Card.Body>
        <Card.Title as="h2" className="app-card-title">
          <Icon name="credit-card" />
          Payment Methods
        </Card.Title>
        <ul className="list-unstyled">
          {userPaymentMethods.map((p) => (
            <li key={p.id} className="app-config-item app-payment-method mb-3">
              <div className="payment-method-details">
                {Object.entries(p).map(([k, v]) => {``
                  const label = k.replace("_", " ");
                  if (v)
                    switch (label) {
                      case "Payment Type":
                        return (
                          <p key={k} className="mb-1">
                            {label}: <strong>{v as any as string}</strong>
                            <TogglePayType optionalClass="ms-1" valutid={p.paymentGatewayCustomerVaultId} />
                          </p>
                        );
                      case "Method Nickname":
                        return (
                          <p key={k} className="mb-1">
                            {'Payment Nickname'}: <strong>{v as any as string}</strong>
                          </p>
                        );
                      case "paymentMethodAttributes":
                        return (
                          <>
                          <p key={k} className="mb-1">
                            {'AccountType'}: <strong>{v.AccountType}</strong>
                          </p>
                          <p key={k} className="mb-1">
                            {'AccountHolderType'}: <strong>{v.AccountHolderType}</strong>
                          </p>
                          </>
                        );
                      default:
                        return null;
                    }
                })}
              </div>
              <div className="payment-method-action">
                <Button
                  variant="success"
                  size="sm"
                  className="ms-3"
                  title="Edit Payment Method"
                  // Pass which method and type to modal?
                  onClick={()=>{handleMethodEdit(p)}}
                  >
                  <Icon name="edit" size={20} aria-label="Edit Payment Method"></Icon>
                </Button>
                <Button
                  title="Remove Payment Method"
                  variant="danger"
                  size="sm"
                  className="ms-1"
                  onClick={() => {
                    setIsDeletePaymentMethod(true);
                    setSelectedPaymentMethod(p);
                   }
                  }
                >
                  <Icon name="delete" size={20} aria-label="Remove Payment Method"></Icon>
                </Button>
              </div>
            </li>
          ))}
        </ul>
        <Button
          variant="outline-primary"
          size="sm"
          className="mb-3"
          onClick={() => setIsAdding(!isAdding)}
        >
            <Icon name="add" optionalClass="me-2" size={20}></Icon>
          Add Method
        </Button>
        {isAdding && (
          <AddPaymentMethodForm
           setLoading={props.setLoading} setIsAdding={setIsAdding} />
        )}
      </Card.Body>
    </Card>
    {/* Edit Payment Method Modal */}

      <EditPaymentMethodForm
        selectedPaymentMethod={selectedPaymentMethod}
        setLoading={props.setLoading}
        setIsEditingPaymentMethod={setIsEditingPaymentMethod}
        setSelectedPaymentMethod={setSelectedPaymentMethod}
        isEditingPaymentMethod={isEditingPaymentMethod}
      />

    {/* Method Deleted Confirmation Modal */}
    <ConfirmModal
      show={isDeletePaymentMethod}
      title="Delete Payment Method"
      submitBtnText="Delete"
      showFooter={true}
      onConfirm={() => {
        setIsDeletePaymentMethod(false);
        onRemovePaymentMethod(selectedPaymentMethod);
      }}
      onCancel={() => {
          setIsDeletePaymentMethod(false);
          setSelectedPaymentMethod(null);
        }
      }
    >
      <p>Are you sure you want to delete payment nickname?</p>
    </ConfirmModal>
    </>
  );
}
export default PaymentMethods;
