import { get, post, del,put } from "../util/request";

export const getUserPaymentMethods = function () {
  return get(`/UserPaymentMethod`);
};
export const addUserPaymentMethods = function (userPayment: Object) {
  return post(`/UserPaymentMethod/create`, userPayment);
};
export const updateUserPaymentMethods = function (userPayment: Object) {
  return put(`/UserPaymentMethod/update`, userPayment);
};
export const deleteUserPaymentMethods = function (userPaymentMethodId: string) {
  return del(`/UserPaymentMethod/${userPaymentMethodId}`);
};
export const getUserPaymentMethodById = (userPaymentMethodId: string) => {
  return get(`/UserPaymentMethod/GetById/${userPaymentMethodId}`);
}

interface IUpdateNickNameParams{
  userPaymentMethodId: string;
  userPaymentMethodNickName: string;
}
export const updateUserPaymentMethodNickName = function (userPaymentMethod: IUpdateNickNameParams) {
  return put(`/UserPaymentMethod/updateNickName`, userPaymentMethod);
};
