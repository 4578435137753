// @ts-nocheck
import React, {
  useState,
  useEffect,
  useRef
} from "react";
import { Payment } from "../../components/Payment/Payment";

import {
  Button,
  Form,
  Col,
  Row,
} from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { shallowEqual } from "react-redux";
import moment from "moment";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { collectJsValid, checkCollectOnFirstClick, addEventForCollectJsFieldByAccountType } from '../../util/collectJsValid'

import Icon from "../../components/Common/Icons/Icons";
import { $toast } from "../../util/toastUtil";

import { addUserPaymentMethods } from "../../api/userPaymentMethods";
import { getUserPaymentMethods } from "../../api/userPaymentMethods";
import { initUserPaymentMethod, setCurrentPaymentMethodId } from "../../features/payment/paymentSlice";

import { userNameRegEx, replaceSpace, zipCodeRegEx, replaceSpecialCharsAndNumbers } from '../../util/regexUtil'
import { paymentMethodsOptions } from "../../common/paymentMethods";
import { addhypen } from "../../util/zipcodeValidation";

type PaymentMethodFormType = {
  Payment_Type: string;
  Method_Nickname: string;
  details: {
    Card_First_Name: string;
    Card_Last_Name: string;
    Card_Number: string;
    Card_CVV: string;
    Expiration_Date: string;
    Card_Zip_Code: string;
    accountType: string;
    Account_Holder_Name: string;
    Account_Number: string;
    Confirm_Account_Number: string;
    Routing_Number: string;
    ach_type: string;
  };
};

interface IAddPaymentMethodFormProp {
  setLoading?: (isLoading: boolean) => void | undefined;
  setIsAdding?: (isAdding: boolean) => void | undefined;
  setToastBody?: (content: any) => void | undefined;
  setIsMethodAddOrEditSaved?: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddPaymentMethodForm: React.FC<IAddPaymentMethodFormProp> = (props) => {
  const intialaPaymentMethodFormValues = {
    Payment_Type: "",
    Method_Nickname: "",
    details: {
      Card_First_Name: "",
      Card_Last_Name: "",
      Card_Number: "",
      Card_CVV: "",
      Expiration_Date: "",
      Card_Zip_Code: "",
      accountType: "",
      Account_Holder_Name: "",
      Account_Number: "",
      Confirm_Account_Number: "",
      Routing_Number: "",
      ach_type: "",
    }
  };

  const isFutureDate = (value: any) => {
    if (!value) {
      return true;
    }
    let today = new Date();
    let CurrentDate = moment(
      new Date(
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        new Date(today.getFullYear(), today.getMonth() + 1, 0).getDate()
      )
    );
    const yearStartwith = today.getFullYear().toString().substring(0, 2);
    const expiryValue = value.split("/");
    const newDate = yearStartwith + expiryValue[1];
    const visaDate = new Date(parseInt(newDate), expiryValue[0], 0);
    return CurrentDate < moment(visaDate);
  };

  const isConfirmAccountNumberSame = (confirmAccountNumber: any) => {
    if (!confirmAccountNumber) {
      return true;
    }
    const accountNumber: any = getValues("details.Account_Number");
    return (
      accountNumber &&
      confirmAccountNumber &&
      accountNumber === confirmAccountNumber
    );
  };

  const validationSchema = Yup.object().shape({
    Payment_Type: Yup.string().required("Payment Type is required"),
    Method_Nickname: Yup.string().required("Payment NickName is required"),
    details: Yup.object()
      .when("Payment_Type", {
        is: (value: any) => {
          return paymentMethods.filter((item) => item.paymentMethodName !== paymentMethodsOptions[2].label).find((i) => i.paymentMethodId === value)
        },
        then: Yup.object({
          Card_First_Name: Yup.string().required("First Name is required"),
          // .matches(userNameRegEx,'First Name is invalid.'),
          Card_Last_Name: Yup.string().required("Last Name  is required"),
          // .matches(userNameRegEx,'Last Name is invalid.'),
          // Card_Number: Yup.string()
          //   .required("This is required")
          //   .matches(/[\d| ]{16,22}/i, "This is invalid"),
          // Card_CVV: Yup.string()
          //   .required("This is required")
          //   .matches(/^[0-9]{3,4}$/, "This is invalid"),
          // Expiration_Date: Yup.string()
          //   .required("This is required")
          //   .matches(/^(0[1-9]|1[0-2])\/?([0-9]{2})$/, "this is invalid")
          //   .test("testCVVFutureDate", "This should be future date", (value) =>
          //     isFutureDate(value)
          //   ),
          Card_Zip_Code: Yup.string().required("Zip Code is required").matches(zipCodeRegEx, 'Zip Code is invalid.')
        })    
      })
      .when("Payment_Type", {
        is: (value: any) => {
          return paymentMethods.filter((item) => item.paymentMethodId === value).find((i) => i.paymentMethodName === 'ACH')
        },
        then: Yup.object({
          ach_type: Yup.string().required('ACH Type is required'),
        })      
      }),
  });

  const dispatch = useAppDispatch();

  const { paymentMethods, paymentMethodId } = useAppSelector(
    (state) => ({ ...state.payment }),
    shallowEqual
  );
  const { user } = useAppSelector(
    (state) => ({ ...state.account }),
    shallowEqual
  );

  const {
    control,
    watch,
    handleSubmit,
    reset,
    getValues,
    setValue,
    clearErrors,
    formState: { errors }
  } = useForm<PaymentMethodFormType>({
    resolver: yupResolver(validationSchema),
    defaultValues: intialaPaymentMethodFormValues
  });

  const { Payment_Type } = watch();
  const childRef = useRef(null);
  const [nickname, setNickName] = useState("");
  const [accountType, setAccountType] = useState("checking");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [zipCode, setZipCode] = useState("");

  useEffect(() => {
    setTimeout(() => {
      addEventForCollectJsFieldByAccountType('Credit Card')
    }, 200);
    setValue('details.accountType', 'checking')
  }, [])

  const nicknameRef = useRef(nickname);
  const firstnameRef = useRef(firstname);
  const lastnameRef = useRef(lastname);
  const zipCodeRef = useRef(zipCode);
  const accountTypeRef = useRef(accountType);
  nicknameRef.current = nickname;
  firstnameRef.current = firstname;
  lastnameRef.current = lastname;
  zipCodeRef.current = zipCode;
  accountTypeRef.current = accountType;

  const handleBeforeSubmit = () => {
    const _paymentType = paymentMethods.find(item => item.paymentMethodId == Payment_Type)
    if (!_paymentType) {
      $toast.danger({
        title: 'Method Not Saved',
        body: 'Payment type is invalid'
      })
      return
    }
    checkCollectOnFirstClick(_paymentType.paymentMethodName)
    if (!collectJsValid(_paymentType.paymentMethodName, false)) {
      return
    }
    childRef.current.submit()
  }

  const handleOnInvalid = () => {
    const _paymentType = paymentMethods.find(item => item.paymentMethodId == Payment_Type)
    if (!_paymentType) {
      $toast.danger({
        title: 'Method Not Saved',
        body: 'Payment type is invalid'
      })
      return
    }
    checkCollectOnFirstClick(_paymentType.paymentMethodName)
    if (!collectJsValid(_paymentType.paymentMethodName, false)) {
      return
    }
  }

  function getAccountType(userPaymentMethodId: string | undefined) {
    let index = paymentMethods.findIndex(item => item.paymentMethodId == userPaymentMethodId)
    if (index != -1) {
      return paymentMethods[index].paymentMethodName
    } else {
      return ''
    }
  }

  const finishSubmit = async (response: any) => {
    if (response) {
      let token = response.token;
      props.setLoading(true);
      let payMethodType = getAccountType(paymentMethodId);
      let userPaymentMethod = {
        paymentMethodId: paymentMethodId,
        nickName: nicknameRef.current.trim(),
        firstname: firstnameRef.current.trim(),
        lastname: lastnameRef.current.trim(),
        zipCode: zipCodeRef.current.trim(),
        accountType: payMethodType === 'ACH' ? getValues('details.accountType') : '',
        accountHolderType: payMethodType === 'ACH' ? getValues('ach_type') : '',
        payment: payMethodType === 'ACH' ? 'check' : 'creditcard',
        paymentToken: token
      };
      addUserPaymentMethods(userPaymentMethod)
        .then((res: any) => {
          if (res?.status == 200) {
            props.setIsAdding(false);
            reset({
              Payment_Type: paymentMethods[0].paymentMethodId
            });
            initializeUserPaymentMethods(dispatch, user.userId);
            $toast.success({
              title: "Method Saved",
              body: 'Your payment method was saved successfully.'
            })
          } else {
            $toast.danger({
              title: 'Method Not Saved',
              body: res.response ? res.response.data : res.message
            })
            props.setLoading(false);
          }
        })
        .catch((error) => {
          $toast.danger({
            title: 'Method Not Saved',
            body: error.response ? error.response.data : error.message
          })
          props.setLoading(false);
        });
    }
  };
  const cancel = () => {
    // clearInputs();
    props.setIsAdding(false);
  };
  // const clearInputs = () => {
  //   childRef.current.clearInputs();
  // };
  const initializeUserPaymentMethods = async (dispatch: any) => {
    let response = await getUserPaymentMethods();
    await dispatch(initUserPaymentMethod(response.data));
    props.setLoading(false);
  };

  const selectlist = [{
    "paymentMethodId": "",
    "paymentMethodName": "Select Payment Type"
  }].concat(paymentMethods);

  return (
    <Form className="app-user-info">
      <fieldset>
        <legend className="h3">Add Payment Method</legend>
        <Form.Group className="mb-3">
          <Form.Label htmlFor="methodType">Payment Type</Form.Label>
          <Controller
            name="Payment_Type"
            control={control}
            render={({ field }) => (
              <Form.Select
                id="methodType"
                onChange={(e) => {
                  // clearInputs();
                  setValue("Method_Nickname", "");
                  clearErrors();
                  field.onChange(e.target.value);
                  dispatch(setCurrentPaymentMethodId(e.target.value));
                  setTimeout(() => {
                    const _payment = paymentMethods.find(item => item.paymentMethodId == e.target.value);
                    addEventForCollectJsFieldByAccountType(_payment.paymentMethodName);
                  }, 200);
                }}
              >
                {selectlist.map((p, i) => (
                  <option
                    key={p.paymentMethodId}
                    value={p.paymentMethodId}
                  >
                    {p.paymentMethodName}
                  </option>
                ))}
              </Form.Select>
            )}
          />
        </Form.Group>
        {Payment_Type !== '' && <Form.Group className="mb-3">
          <Form.Label htmlFor="methodNickname" className="form-label-important">Payment Nickname</Form.Label>
          <Controller
            name="Method_Nickname"
            control={control}
            render={({ field }) => (
              <Form.Control
                type="text"
                id="methodNickname"
                placeholder="Payment NickName"
                value={field.value}
                maxLength={25}
                className={errors.Method_Nickname ? "form-field-error" : ""}
                onChange={(e) => {
                  const _value = e.target.value;
                  field.onChange(_value);
                  setNickName(_value);
                }}
              />
            )}
          />
          {errors.Method_Nickname &&
            errors.Method_Nickname.type === "required" && (
              <span className="form-field-error-msg">
                {errors.Method_Nickname?.message?.toString()}
              </span>
            )}
          {/* {errors.Method_Nickname &&
            errors.Method_Nickname.type === "matches" && (
              <span className="form-field-error-msg">
                {errors.Method_Nickname?.message?.toString()}
              </span>
            )} */}
        </Form.Group>}
        {/* IF bank account */}
        {paymentMethods.length > 0 &&
          paymentMethods.filter((item) => item.paymentMethodName === paymentMethodsOptions[2].label).length > 0 &&
          paymentMethods.filter((item) => item.paymentMethodName === paymentMethodsOptions[2].label).find((i) => i.paymentMethodId === Payment_Type) && (
            <>
              <p className="form-label-important">Type</p>
              <Form.Group className="mb-3">
                <Controller
                  name="details.accountType"
                  control={control}
                  render={({ field }) => (
                    <Form.Check
                      type="radio"
                      id="Checkings"
                      name="details.accountType"
                      label="Checking"
                      value={"checking"}
                      defaultChecked={true}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        setAccountType(e.target.value);
                      }}
                    />
                  )}
                />
                <Controller
                  name="details.accountType"
                  control={control}
                  render={({ field }) => (
                    <Form.Check
                      type="radio"
                      id="Savings"
                      name="details.accountType"
                      label="Savings"
                      value={"savings"}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        setAccountType(e.target.value);
                      }}
                    />
                  )}
                />
                {errors.details &&
                  (errors.details as any).accountType &&
                  errors.details &&
                  (errors.details as any).accountType.type === "required" && (
                    <span className="form-field-error-msg">
                      {errors.details &&
                        (errors.details as any).accountType?.message?.toString()}
                    </span>
                  )}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label htmlFor="methodType" className="form-label-important">ACH Type</Form.Label>
                <Controller
                  name="details.ach_type"
                  control={control}
                  render={({ field }) => (
                    <Form.Select
                      id="achType"
                      onChange={(e) => {
                        setValue("ach_type", e.target.value);
                        field.onChange(e.target.value);
                      }}
                    >
                      <option
                        key='Select ACH Type'
                        value=''
                      >
                        {'Select ACH Type'}
                      </option>
                      <option
                        key='personal'
                        value='personal'
                      >
                        {'Personal'}
                      </option>
                      <option
                        key='business'
                        value='business'
                      >
                        {'Business'}
                      </option>
                    </Form.Select>
                  )}
                />
                {(errors?.details?.ach_type && Object.keys(errors?.details?.ach_type).length > 1) &&
                  <span className="form-field-error-msg">
                    {errors?.details?.ach_type.message?.toString()}
                  </span>
                }
              </Form.Group>

              <Payment
                ref={childRef}
                finishSubmit={finishSubmit}
                setLoading={props.setLoading}
                cancel={cancel}
                showAccountName
                accountNameText="Name on Account"
                showRoutingNumber
                routingNumberText="ABA Routing Number"
                showAccountNumber
                accountNumberText="Bank Account Number"
                hideFooter={true}
              ></Payment>
              <div className="text-end">
                <Button
                  variant="outline-primary"
                  size="sm"
                  className="mb-3"
                  onClick={cancel}
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  size="sm"
                  className="mb-3 ms-3"
                  onClick={handleSubmit(handleBeforeSubmit, handleOnInvalid)}
                >
                  <Icon name="check-double" optionalClass="me-2" size={20}></Icon>
                  Save
                </Button>
              </div>
            </>
          )}
        {/* END IF */}
        {/* IF credit card */}
        {paymentMethods.length > 0 &&
          paymentMethods.filter((item) => item.paymentMethodName !== paymentMethodsOptions[2].label).length > 0 &&
          paymentMethods.filter((item) => item.paymentMethodName !== paymentMethodsOptions[2].label).find((i) => i.paymentMethodId === Payment_Type) && (
            <>
              <Form.Group className="mb-3">
                <Form.Label htmlFor="methodFirstName" className="form-label-important">First Name</Form.Label>
                <Controller
                  name="details.Card_First_Name"
                  control={control}
                  render={({ field }) => (
                    <Form.Control
                      type="text"
                      id="methodFirstName"
                      value={field.value || ""}
                      maxLength={50}
                      className={
                        errors.details && (errors.details as any).Card_First_Name
                          ? "form-field-error"
                          : ""
                      }
                      placeholder="First Name"
                      onChange={(e) => {
                        const _value = e.target.value;
                        field.onChange(_value);
                        setFirstname(_value);
                      }}
                    />
                  )}
                />
                {errors?.details &&
                  (errors?.details as any).Card_First_Name?.type && (
                    <span className="form-field-error-msg">
                      {errors?.details &&
                        (errors?.details as any).Card_First_Name?.message?.toString()}
                    </span>
                  )}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label htmlFor="methodLastName" className="form-label-important"> Last Name</Form.Label>
                <Controller
                  name="details.Card_Last_Name"
                  control={control}
                  render={({ field }) => (
                    <Form.Control
                      type="text"
                      id="methodLastName"
                      value={field.value || ""}
                      maxLength={50}
                      className={
                        errors.details && (errors.details as any).Card_Last_Name
                          ? "form-field-error"
                          : ""
                      }
                      placeholder="Last Name"
                      onChange={(e) => {
                        const _value = e.target.value;
                        field.onChange(_value);
                        setLastname(_value);
                      }}
                    />
                  )}
                />
                {
                  errors?.details &&
                  (errors?.details as any).Card_Last_Name?.type && (
                    <span className="form-field-error-msg">
                      {errors?.details &&
                        (errors?.details as any).Card_Last_Name?.message?.toString()}
                    </span>
                  )}
              </Form.Group>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label htmlFor="methodZipCode" className="form-label-important">Zip Code</Form.Label>
                    <Controller
                      name="details.Card_Zip_Code"
                      control={control}
                      render={({ field }) => (
                        <Form.Control
                          type="text"
                          id="methodZipCode"
                          value={field.value || ""}
                          maxLength={10}
                          className={
                            errors.details &&
                              (errors.details as any).Card_Zip_Code
                              ? "form-field-error"
                              : ""
                          }
                          placeholder="Zip Code"
                          onChange={(e) => {
                            const _value = addhypen(replaceSpace(e.target.value))
                            field.onChange(_value);
                            setZipCode(_value);
                          }}
                        />
                      )}
                    />
                    {
                      errors?.details &&
                      (errors?.details as any).Card_Zip_Code?.type && (
                        <span className="form-field-error-msg">
                          {errors?.details &&
                            (
                              errors?.details as any
                            ).Card_Zip_Code?.message?.toString()}
                        </span>
                      )}
                  </Form.Group>
                </Col>
              </Row>
              <Payment
                ref={childRef}
                setLoading={props.setLoading}
                finishSubmit={finishSubmit}
                cancel={cancel}
                showCcNumber
                ccNumberText="Card Number"
                showCvv={false}
                cvvText="CVV Code"
                showCcexp
                hideFooter={true}
                ccExpText="Expiration Date"
                isCvvRequired = {true}
              ></Payment>
              <div className="text-end">
                <Button
                  variant="outline-primary"
                  size="sm"
                  className="mb-3"
                  onClick={cancel}
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  size="sm"
                  className="mb-3 ms-3"
                  onClick={handleSubmit(handleBeforeSubmit, handleOnInvalid)}
                >
                  <Icon name="check-double" optionalClass="me-2" size={20}></Icon>
                  Save
                </Button>
              </div>
            </>
          )}
        {/* END IF */}
      </fieldset>

    </Form>
  );
};
export default AddPaymentMethodForm;
