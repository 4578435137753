//@ts-nocheck

// Hooks
import React, { useMemo, useState, useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useForm, Controller } from "react-hook-form";
import { shallowEqual } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

// Components
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';

import { Link } from "react-router-dom";
import { Container, Row, Col, Alert, Form, Button, Popover, OverlayTrigger, InputGroup } from "react-bootstrap";
import Icon from "../../components/Common/Icons/Icons";
import { ConfirmModal } from "../../components/Common/Modal";
import { LoadingWaitModal } from "../../components/Common/Modal/LoadingWaitModal";

import AccountInformation from "./AccountInformation";
import PaymentInformation from "./PaymentInformation";
import SelectPaymentMethod from "./SelectPaymentMethod";
import SetupAutoPay from "./SetupAutoPay";

import { ROLE } from "../../routes/Roles";
import CVVHelpImage from "../../assets/Images/CVV-Code.png";

import { zipCodeRegEx } from '../../util/regexUtil'
const cvvHelp = (
  <Popover id="popover-cvv">
    <Popover.Body>
      <img src={CVVHelpImage} />
    </Popover.Body>
  </Popover>
);


// Utils
import moment from "moment";
import { formatDate, formatMoney, formatAccountNumber, convertCSTTimeToLocal, isAfterBusinessHours } from "../../util/formatUtil";
import { collectJsValid, checkCollectOnFirstClick } from '../../util/collectJsValid';
import { returnToHome } from "../../util/returnToHome";

// Api
import { getLastPayment, createPayment } from "../../api/payment";
import { getUserPaymentMethods } from "../../api/userPaymentMethods";
import { getAcitveClientMethods } from "../../api/paymentMethods";
import { getStatementWithAutoPayEnroll, getPaymentStatement } from "../../api/statement";

// Store
import {
  initPaymentMethods,
  initUserPaymentMethod
} from "../../features/payment/paymentSlice";

import { v4 as uuidv4 } from "uuid";
import { $toast, toastMessage } from "../../util/toastUtil";
import SelectedPayment from "./placeholders/SelectedPayment";
import SetupAutoPayLoader from "./placeholders/setupAutoPayLoader";
import PaymentActionButtonLoader from "./placeholders/PaymentActionButtonLoader";
import PendingAlert from "./placeholders/PendingAlert";

function MakePayment() {
  const { user, accountNumber, isVaildCvv, accountId } = useAppSelector(
    (state) => ({ ...state.account }),
    shallowEqual
  );

  const { userPaymentMethod, paymentMethods, paymentMethodId } = useAppSelector(
    (state) => ({ ...state.payment }),
    shallowEqual
  );

  //Initialization functions
  const initializePayment = async () => {
    let response = await getLastPayment();
    return response.data;
  };

  const initializePaymentMethods = async () => {
    let response = await getAcitveClientMethods();
    return response.data;
  };

  const initializeStatementWithAutoPayEnroll = async () => {
    let response = await getStatementWithAutoPayEnroll();
    return response.data;
  };

  const schema = yup.object().shape({
    Additional_Payment: yup.number().when({
      is: () => {
        return !isReviewing && details.hasAdditionalPayment
      },
      then: (schema) => schema.max(20000, 'Additional Payment cannot be more than $ 20000')
        .min(0, 'Additional Payment cannot be less than 0'),
    }),
    additionalPaymentList: yup.array().of(
      yup.object().shape({
        formattedValue: yup.string().nullable().test("required", "Please enter valid amount", (value, allValues) => isTypeRequired(value, allValues)),
      })),
    Payment_Method: yup.string(),
    CVV_Code: yup.string().when('Payment_Method', {
      is: (value: string) => {
        return isReviewing && (getUserPaymentMethodTypeById(value) == 'Credit Card' ||
          getUserPaymentMethodTypeById(value) == 'Debit Card')
      },
      then: (schema) => schema.required('CVV is required').matches(/^\d{3,4}$/, 'CVV must be 3 or 4 digits')
    }),
    enterPayment: yup.object().shape({
      isSave: yup.boolean(),
      methodNickname: yup.string().when('isSave', {
        is: (value: boolean) => {
          return value && details.Payment_Method === 'Add' && !isReviewing
        },
        then: (schema) => schema.required('Payment NickName is required')
      }),
      selectAccountType: yup.string().when({
        is: () => {
          return !isReviewing && details.Payment_Method === 'Add'
        },
        then: (schema) => schema.required('Please Select an Account Type')
      }),
      ach_type: yup.string().when({
        is: () => {
          if (details?.enterPayment?.selectAccountType !== 'ACH') {
            return false
          } else {
            return !isReviewing && details.Payment_Method === 'Add'
          }
        },
        then: (schema) => schema.required('Please Select an ACH Type')
      }),
      firstName: yup.string().when({
        is: () => {
          return !isReviewing && details.Payment_Method === 'Add' && (details?.enterPayment?.selectAccountType == 'Credit Card' || details?.enterPayment?.selectAccountType == 'Debit Card')
        },
        then: (schema) => schema.required('First Name is required'),
        // .matches(userNameRegEx, 'First Name is invalid.')
      }),
      lastName: yup.string().when({
        is: () => {
          return !isReviewing && details.Payment_Method === 'Add' && (details?.enterPayment?.selectAccountType == 'Credit Card' || details?.enterPayment?.selectAccountType == 'Debit Card')
        },
        then: (schema) => schema.required('Last Name is required'),
        // .matches(userNameRegEx, 'Last Name is invalid.'),
      }),
      zipCode: yup.string().when({
        is: () => {
          return !isReviewing && details.Payment_Method === 'Add' && (details?.enterPayment?.selectAccountType == 'Credit Card' || details?.enterPayment?.selectAccountType == 'Debit Card')
        },
        then: (schema) => schema.required('Zip Code is required').matches(zipCodeRegEx, 'Zip Code is invalid.')
      })
    })
  });

  const isTypeRequired = (type: any, allValues: any) => {
    if ((allValues.parent.isAmountFieldChecked === true && !allValues.parent.formattedValue
      || allValues.parent.isAmountFieldChecked === true && allValues.parent.formattedValue === "0.00") && checkDecimal(type, allValues)) {
      return false
    } else {
      return true
    }
  }

  const checkDecimal = (value: any, allValues: any) => {
    let inputValue = value === '' || value != null ? allValues.parent.decimalValue : value;
    if (inputValue === "" || inputValue === ".") {
      return true;
    }
  }

  const dispatch = useAppDispatch()
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [isReviewing, setIsReviewing] = useState(false);
  const [payment, setPayment] = useState({});
  const [statement, setStatement] = useState<any>({});
  const [paymentDetails, setPaymentDetails] = useState<any>({});
  const [addPastAmount, setAddPastAmount] = useState(true);
  const [pastDue, setPastDue] = useState(null);
  const [amount, setAmount] = useState(0);
  const [currentMethod, setCurrentMethod] = useState<any>(null);
  const [hasAutoPay, setHasAutoPay] = useState<boolean>(false);
  const [initDataLoading, setInitDataLoading] = useState(false);
  let { from } = useParams();
  const [accountInformationPaymentDate, setaccountInformationPaymentDate] = useState('');

  const initData = async () => {
    
    setInitDataLoading(true);
    try {
      if (!paymentMethods || paymentMethods.length === 0) {
        const allPaymentMethods = await initializePaymentMethods()
        dispatch(initPaymentMethods(allPaymentMethods))
      }

      let userPaymentMethods = await getUserPaymentMethods();
      dispatch(initUserPaymentMethod(userPaymentMethods.data));
      let statement = await initializeStatementWithAutoPayEnroll();
      setPayment(statement.statement.lastPayment);

      if (statement?.isAutoPayEnrolled) {
        setHasAutoPay(true)
      }

      if (accountInfoLoader?.data?.generalInformation) {
        const whichAccountAttributesFecthed = accountInfoLoader?.data?.generalInformation
          .filter(item => item.attributeName === "AccountNumber")
          .map(item => item.formattedValue);

        if (accountNumber !== whichAccountAttributesFecthed[0]) {
          dispatch(getPaymentStatement());
        }
      } else {
        dispatch(getPaymentStatement());
      }

      setStatement(statement.statement);

      let paymentCategories = JSON.parse(statement?.statement.paymentDetails)
        .PaymentCategories;
      //console.log(paymentCategories);
      setPaymentDetails(paymentCategories.Mortgage);

    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      setInitDataLoading(false);
    }
  };

  useEffect(() => {
    initData();
  }, []);
  const formProps = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      hasAdditionalPayment: false,
      additionalPaymentList: null,
      Additional_Payment: amount ? amount : "0.00",
      Payment_Date: new Date(),
      Payment_Method:
        userPaymentMethod && userPaymentMethod.length
          ? userPaymentMethod[0].userPaymentMethodId
          : undefined,
      CVV_Code: "",
      enterPayment: {
        type: "",
        firstName: "",
        lastName: "",
        zipCode: "",
        methodNickname: ""
      }
    }
  });
  const { watch, setValue, control, handleSubmit, formState: { errors }, clearErrors, reset, resetField } = formProps;
  const { ...formData } = watch();
  const { Payment_Method } = formData
  useEffect(() => {
    clearErrors()
    resetField("enterPayment")
  }, [Payment_Method])

  const handleContinue = () => {
    //console.log(details)
    if (amount === 0) {
      showErrorToast('Payment Amount cannot be 0')
      return
    }
    if (isNaN(amount)) {
      showErrorToast('Payment Amount is invalid')
      return
    }
    if (!details.Payment_Method) {
      showErrorToast('Please select payment method or use new payment method')
      return
    }
    if (details.Payment_Method == 'Add') {
      checkCollectOnFirstClick(details?.enterPayment?.selectAccountType)
      if (!collectJsValid(details?.enterPayment?.selectAccountType, true)) {
        return
      }
      paymentRef.current.submit()
      setLoading(true)
    }
    if(!accountInformationPaymentDate){
      $toast.danger({
        id: uuidv4(),
        show: true,
        autohide: true,
        delay: 3000,
        icon: "close",
        title: "Invalid input",
        body: toastMessage.noPaymentDate
      });
    }
    else if (details.Payment_Method !== 'Add') {
      setIsReviewing(true)
    }
  }

  const handleOnInvalid = () => {
    if (details.Payment_Method == 'Add') {
      checkCollectOnFirstClick(details?.enterPayment?.selectAccountType)
    }
  }

  const [savedPaymentInfo, setSavedPaymentInfo] = useState<any>({})

  const getSavedPaymentInfo = (info: any) => {
    setSavedPaymentInfo(info)
    setIsReviewing(true)
    setLoading(false)
  }

  interface enterPaymentInterFace {
    type?: string,
    methodNickname?: string,
    firstName?: string,
    lastName?: string,
    zipCode?: string,
    paymentToken?: string,
    selectAccountType?: string | undefined,
    isSave?: boolean,
    ach_type?: string | undefined
  }
  interface formDateInterface {
    Payment_Method?: string;
    SchedulePaymentDate?: string,
    CVV_Code?: string,
    hasAdditionalPayment?: boolean,
    Additional_Payment?: string | number,
    Payment_Date?: any,
    enterPayment?: enterPaymentInterFace
  }

  const details: formDateInterface = useMemo(() => {
    return formData;
  }, [formData]);

  interface paymentInterface {
    paymentType?: string,
    amount?: any;
    paymentDate?: any;
    statementId?: number;
    paymentGatewayTransacationId?: string;
    paymentTransactionId?: string;
    userPaymentMethodId?: any;
    paymentToken?: string;
    cvv?: string;
    paymentMethodForm?: any;
    paymentMethodId?: string;
    paymentMethodName?: string;
  }

  const getUserPaymentMethodNameById = (id: string) => {
    const _index = paymentMethods.findIndex(item => item.paymentMethodId == id)
    if (_index != -1) {
      return paymentMethods[_index].paymentMethodName
    } else {
      return ''
    }
  }

  const handleSubmitPayment = () => {

    const _payment: paymentInterface = {
      paymentType: getUserPaymentNickname(details.Payment_Method),
      amount: Number(amount),
      paymentDate: details.Payment_Date ? details.Payment_Date : moment().toDate(),
      statementId: statement.statementId,
      userPaymentMethodId: details.Payment_Method,
      cvv: details.CVV_Code,
    }
    if (details.Payment_Method == 'Add') {
      if (details.enterPayment?.selectAccountType === 'ACH') {
        _payment.paymentMethodForm = {
          paymentToken: savedPaymentInfo.token,
          isSave: details.enterPayment?.isSave,
          accountType: details.enterPayment?.type,
          accountHolderType: details.enterPayment?.ach_type,
          paymentMethodId: paymentMethodId,
          paymentMethodName: getUserPaymentMethodNameById(paymentMethodId)
        }
      } else {
        _payment.paymentMethodForm = {
          paymentToken: savedPaymentInfo.token,
          isSave: details.enterPayment?.isSave,
          firstName: details.enterPayment?.firstName?.trim(),
          lastName: details.enterPayment?.lastName?.trim(),
          zipCode: details.enterPayment?.zipCode?.trim(),
          paymentMethodId: paymentMethodId,
          paymentMethodName: getUserPaymentMethodNameById(paymentMethodId)
        }
      }
      if (details.enterPayment?.isSave) {
        _payment.paymentMethodForm.nickname = details.enterPayment?.methodNickname?.trim()
      }
      _payment.userPaymentMethodId = null;
      _payment.cvv = "";
      _payment.paymentMethodId = paymentMethodId,
        _payment.paymentMethodName = getUserPaymentMethodNameById(paymentMethodId)
    }
    else {
      _payment.paymentMethodId = currentMethod.paymentMethodId;
      _payment.paymentMethodName = currentMethod.paymentMethodName;
    }

    setIsReviewing(false);
    setLoading(true);
    createPayment(_payment)
      .then(async (res: any) => {
        // when success
        if (res.status === 200) {
          // refresh userPaymentMethods
          if (details.Payment_Method == 'Add') {
            let response = await getUserPaymentMethods();
            await dispatch(initUserPaymentMethod(response.data));
          }
          const state = {
            amount: res.data.amount,
            paymentDate: res.data.paymentDate ? formatDate(convertCSTTimeToLocal(res.data.paymentDate)) : formatDate(convertCSTTimeToLocal(res.data.scheduledDate)),
            paymentStatusId: res.data.paymentStatusId,
            userPaymentMethodId: res.data.userPaymentMethodId,
            nickname: getUserPaymentNickname(details.Payment_Method),
            paymentTransactionId: res.data.paymentTransactionId
          };
          switch (user.role.roleName) {
            case ROLE.Consumer:
              navigate("/consumer/makepayment/payment-summary", {
                replace: true,
                state
              });
              break;
            case ROLE.ClientAdmin:
            case ROLE.ClientSuperAdmin:
              navigate("/client/makepayment/payment-summary", {
                replace: true,
                state
              });
              break;
          }

          // when error
        } else {
          showErrorToast(res.response ? res.response.data : res.message)
        }
      })
      .catch((error: any) => {
        showErrorToast(error.response ? error.response.data : error.message)
      })
      .finally(() => {
        setValue("CVV_Code", "")
        setLoading(false);
      });
  };

  const showErrorToast = (msg: string, icon = "close", type = "danger", title = "Failed") => {
    setLoading(false);
    $toast.showToast({
      id: uuidv4(),
      autohide: true,
      delay: 5000,
      icon,
      type,
      title,
      body: msg
    })
  }

  useEffect(() => {
    if (!isVaildCvv) {
      setLoading(false);
    }
  }, [isVaildCvv])

  const getUserPaymentNickname = (userPaymentMethodId: string | undefined) => {
    if (details.Payment_Method == 'Add') {
      if (details.enterPayment?.isSave) {
        return details.enterPayment?.methodNickname
      } else {
        return details?.enterPayment?.selectAccountType
      }
    } else {
      let index = userPaymentMethod.findIndex(item => item.userPaymentMethodId == userPaymentMethodId)
      if (index != -1) {
        return userPaymentMethod[index].nickName
      } else {
        return ''
      }
    }
  }

  const getWebDisclaimers = (userPaymentMethodId: string | undefined) => {
    let paymentType = '';
    let disclaimers = '';
    if (details.Payment_Method == 'Add') {
      paymentType = details?.enterPayment?.selectAccountType;
    } else {
      let index = userPaymentMethod.findIndex(item => item.userPaymentMethodId == userPaymentMethodId)
      if (index != -1) {
        paymentType = userPaymentMethod[index].paymentMethodName
      }
    }

    if (paymentType && (paymentType.toLowerCase() == 'ach' || paymentType.toLowerCase() == 'debit card'))
      return user.achDisclaimerMessageVal;
    else if (paymentType && paymentType.toLowerCase() == 'credit card')
      return user.creditCardDisclaimerMessageVal;
    else
      return "";
  }

  const getUserPaymentMethodTypeById = (id: string) => {
    const _index = userPaymentMethod.findIndex(item => item.userPaymentMethodId == id)
    if (_index != -1) {
      return userPaymentMethod[_index].paymentMethodName
    } else {
      return ''
    }
  }

  const [showCvv, setShowCvv] = useState(false);
  const paymentRef = useRef<any>(null);

  const { permissions } = useAppSelector(
    (state: any) => ({ ...state.permissions }),
    shallowEqual
  );

  const accountInfoLoader = useAppSelector((state) => ({ ...state.onetimepayment }), shallowEqual);

  return (
    <>
      <main id="mainContent">
        <Container>
          {
            permissions?.canShowBreadcrumb && <div className="mb-3">
              <Link to="/client">Customers</Link> / {!from && <React.Fragment><Link to="/client/accountinformation">Account Information</Link> /</React.Fragment>} Make a Payment
            </div>
          }
          <LoadingWaitModal show={loading} size="lg" hide={() => { }} />
          <h1 className="h2">Make a One-time Payment</h1>
          {
            user && user.enableHomeAndPaymentMessage &&
            <Alert variant="info">
              <Icon
                name="info"
                optionalClass="p-1 rounded-circle bg-light me-2"
                size={36}
              />
              <label>Questions? Call this number <Alert.Link href={`tel:${user.homeAndPaymentMessage}`}>{user.homeAndPaymentMessage}</Alert.Link>.  </label>
            </Alert>
          }
          <Form>
            <Row>
              <Col lg={6}>
                <AccountInformation
                  setLoading={setLoading}
                  accountNumber={formatAccountNumber(accountNumber)}
                  paymentDetails={paymentDetails}
                  payment={payment}
                  statement={statement}
                  parentLoading={initDataLoading}
                />
                {initDataLoading || accountInfoLoader.status === "loading" || statement.satementLoader === true ?
                  <SetupAutoPayLoader /> :
                  <SetupAutoPay
                    hasAutoPay={hasAutoPay}
                  />}
                {initDataLoading || accountInfoLoader.status === "loading" || statement.satementLoader === true ?
                  <PendingAlert /> :
                  <Alert variant="light" className="shadows-light">
                    <div className="d-flex justify-content-between">
                      <div className="d-flex">
                        <Icon
                          name="question"
                          optionalClass="p-1 rounded-circle bg-light me-2"
                          size={36}
                        />
                        <label>Select button to view any pending payments you may have</label>
                      </div>
                      <div>
                        <Link
                          to="../pendingpayments"
                          className="btn btn-secondary ms-2"
                        >
                          View Pending Payments
                        </Link>
                      </div>
                    </div>
                  </Alert>}
              </Col>
              <Col lg={6}>
                <PaymentInformation
                  formProps={formProps}
                  paymentDetails={paymentDetails}
                  lateDate={statement?.lateDate}
                  dueDate={statement?.dueDate && convertCSTTimeToLocal(statement?.dueDate)}
                  setAmount={setAmount}
                  amount={amount}
                  addPastAmount={addPastAmount}
                  setAddPastAmount={setAddPastAmount}
                  pastDue={pastDue}
                  setPastDue={setPastDue}
                  parentLoading={initDataLoading}
                  setaccountInformationPaymentDate={setaccountInformationPaymentDate}
                />
                {/* {accountInfoLoader.status === "loading" || statement.satementLoader === true ?   */}
                {/* <SelectedPayment/>: */}
                {
                  initDataLoading ? <SelectedPayment /> : <SelectPaymentMethod
                    ref={paymentRef}
                    props={
                      {
                        formProps: formProps,
                        forceSaveNewPayment: false,
                        getSavedPaymentInfo: getSavedPaymentInfo,
                        getErrorInfo: showErrorToast,
                        setShowCvv,
                        selectedChanged: (selectedVal: any) => {
                          setCurrentMethod(selectedVal)
                        },
                        parentLoading: initDataLoading
                      }
                    }
                  />
                }

                {/* <div className="mb-3 hide">
                  Or You may enter new payment information below.
                </div> */}
                {accountInfoLoader.status === "loading" || statement.satementLoader === true ?
                  <PaymentActionButtonLoader /> :
                  <div className="text-end mb-5">
                    <Button variant="outline-primary" onClick={() => {
                      // navigate("/consumer/accounts")
                      returnToHome(true)
                    }} className="mx-2">
                      Cancel
                    </Button>
                    <Button
                      className="btn btn-primary ms-2"
                      onClick={
                        handleSubmit(handleContinue, handleOnInvalid)
                      }
                    >
                      Continue
                    </Button>
                  </div>}
              </Col>
            </Row>
          </Form>
        </Container>
      </main>
      <ConfirmModal
        show={isReviewing}
        title="Payment Review"
        description="Please review the payment details and make any edits prior to submitting."
        submitBtnText="Submit Payment"
        size={undefined}
        onConfirm={handleSubmit(handleSubmitPayment)}
        onCancel={() => {
          setIsReviewing(false)
          setValue("CVV_Code", "")
        }}
        showFooter={true}
      >
        <Row>
          <Col sm={6}>
            <p className="mb-1">Payment Amount</p>
          </Col>
          <Col sm={6}>
            <p className="text-sm-end m-0"><strong> {formatMoney(
              Number(amount)
            )}</strong></p>
          </Col>
        </Row>
        <hr className="my-2" />
        <Row>
          <Col sm={6}>
            <p className="mb-1">Payment Date</p>
          </Col>
          <Col sm={6}>
            <p className="text-sm-end m-0">
              <strong>{formatDate(formData.Payment_Date ? formData.Payment_Date : moment().toDate())}</strong>
            </p>
          </Col>
        </Row>
        <hr className="my-2" />
        <Row>
          <Col sm={6}>
            <p className="mb-1">Payment Nickname</p>
          </Col>
          <Col sm={6}>
            <p className="text-sm-end m-0 overflow-hidden" style={{ textOverflow: 'ellipsis' }}>
              <strong title={getUserPaymentNickname(details.Payment_Method)}>
                {
                  getUserPaymentNickname(details.Payment_Method)
                }
              </strong>
            </p>
          </Col>
        </Row>
        {
          showCvv &&
          <>
            <hr className="my-2" />
            <Row className="pt-2">
              <Col sm={7}>
                <p className="my-2">CVV*</p>
              </Col>
              <Col sm={5}>
                <Controller
                  name="CVV_Code"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <InputGroup>
                      <Form.Control
                        id="editMethodCVV"
                        placeholder="3 or 4 digits"
                        type="text"
                        value={field.value || ''}
                        maxLength={4}
                        className={errors.CVV_Code ? "form-field-error" : ""}
                        onChange={(e: any) => {
                          let _value = e.target.value.replace(/[^\d^]+/g, "")
                          field.onChange(_value)
                        }}
                      />
                      <InputGroup.Text>
                        <OverlayTrigger
                          placement="top"
                          overlay={cvvHelp}
                        >
                          <div>
                            <Icon name="question" size={20} />
                          </div>
                        </OverlayTrigger>
                      </InputGroup.Text>
                    </InputGroup>
                  )}
                />
              </Col>
            </Row>
            <Row>
              {(errors.CVV_Code && (errors.CVV_Code.type === "required" || errors.CVV_Code.type === "matches")) &&
                <span className="form-field-error-msg text-end mt-1">{errors.CVV_Code?.message}</span>
              }
            </Row>
          </>
        }

        <hr className="my-2" />
        {/* IF ACH */}
        <div>
          <Form.Text>
            {getWebDisclaimers(details.Payment_Method)}
          </Form.Text>
        </div>
        {/* IF CC insert the text using API from the DB for CC disclaimer message */}
        {/* <hr className="my-2" />
        <Row>
          <Col sm={6}>
            <p className="mb-1">CVV Code</p>
          </Col>
          <Col sm={6}>
            <p className="text-sm-end m-0">{formData.CVV_Code}</p>
          </Col>
        </Row> */}
      </ConfirmModal>
    </>
  );
}
export default MakePayment;
