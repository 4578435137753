import { createAction, createSlice } from "@reduxjs/toolkit";
import { getUserData } from "../../api/user";


interface initialStateGeneral {
  data: any,
  status: string,
  error: string | undefined
}

const initialStateGeneral: initialStateGeneral = {
  data: [],
  status: "",
  error: ""
};

export const resetEditUser = createAction('REVERT_EDIT_USERS')
const editUserSlice = createSlice({
  name: "editUser",
  initialState: initialStateGeneral,
  reducers: {
    setBillingAttrbues: (state, action) => {
      state.data = action.payload;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getUserData.pending, (state, action) => {
        state.status = 'loading'
        state.data = []
        state.error = ""
      })
      .addCase(getUserData.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.data = action.payload
        state.error = ""
      })
      .addCase(getUserData.rejected, (state, action) => {
        state.status = 'failed'
        state.data = []
        state.error = action.error.message
      })
      .addCase(resetEditUser, (state, action) => {
        state.status = ''
        state.data = []
        state.error = ""
      })
  }
});

export default editUserSlice.reducer;
