// Hooks
import React, { useState } from "react";
import { useAppSelector } from "../../app/hooks";

// Components
import { Container, Card, Tabs, Tab, Alert } from "react-bootstrap";
import Icon from "../../components/Common/Icons/Icons";
import { LoadingWaitModal } from "../../components/Common/Modal/LoadingWaitModal";

import AccountDetails from "./AccountDetails";
import AccountGraph from "./AccountGraph";
import PaymentsHistory from "./PaymentsHistory";
import BillingHistory from "./BillingHistory";
import Documents from "./Documents";
import { $toast } from "../../util/toastUtil";
import {Button} from "react-bootstrap";
import { epHistory } from "../../routes/History";

// Store
import { shallowEqual } from "react-redux";

function ConsumerMain() {

  const { accountId, accountNumber, user, userAccounts } = useAppSelector(
    (state) => ({ ...state.account }),
    shallowEqual
  );

  const paymentSolution = localStorage.getItem("pay") === "true" ? true : false;

  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(paymentSolution ? "payments" : "documents");

  const showErrorToast = (message:string) =>{
		setLoading(false)
    $toast.danger({
      title: 'Failed',
      body: message
    })
	}
  return (
    <main id="mainContent">
      <Container>
        {userAccounts.length > 1 && window.top === window.self && <Button variant="outline-primary" className='mb-4 mt-4' onClick={() => {
          epHistory.replace(`/consumer/accounts`)
        }}>
          <Icon name="arrow-left" optionalClass="me-2" size={20}></Icon>
          Back to Account Dashboard
        </Button>}
        <h1 className="visually-hidden">Payment Dashboard</h1>
        {
          user && user.enableHomeAndPaymentMessage &&
          <Alert variant="info">
            <Icon
              name="info"
              optionalClass="p-1 rounded-circle bg-light"
              size={36}
            />{" "}
            <label>Questions? Call this number <Alert.Link href={`tel:${user.homeAndPaymentMessage}`}>{user.homeAndPaymentMessage}</Alert.Link>.  </label>
          </Alert>
        }
        {paymentSolution &&
          <div className="app-card-container">
            <div className="app-card mb-3">
              <Card className="shadows-light">
                <Card.Body>
                  <AccountDetails
                    accountNumber={accountNumber}
                    accountId={accountId}
                    showErrorToast={showErrorToast}
                  />
                </Card.Body>
              </Card>
            </div>
            {/*<div className="app-card mb-3 app-card-fixed invisible">

            // Don't remove this code. we may re-enable in next phase
            // remove class invisible from container
            // un-comment the block comment
            <Card className="shadows-light">
              <Card.Body>
                <AccountGraph accountNumber={accountNumber} />
              </Card.Body>
            </Card>
          </div>
            */}
          </div>
        }
        <Tabs
          defaultActiveKey={paymentSolution ? "payments" : "documents"}
          id="paymentDataTabs"
          className="app-tabs tabs-large-lg shadows-light"
          onSelect={(tabname)=>{
            setActiveTab(tabname ? tabname : '');
          }}
        >
          {paymentSolution && <Tab eventKey="payments" title="Payments History">
            <PaymentsHistory />
          </Tab>
          }
          {/*<Tab eventKey="billing" title="Billing History">*/}
          {/*  <BillingHistory showErrorToast={showErrorToast} />*/}
          {/*</Tab>*/}
          <Tab eventKey="documents" title="Documents">
            <Documents activeTab={activeTab} />
          </Tab>
        </Tabs>
        <LoadingWaitModal show={loading} size={"lg"}></LoadingWaitModal>
      </Container>
    </main>
  );
}
export default ConsumerMain;
