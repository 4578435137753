import React, { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { useAccount, useMsal } from "@azure/msal-react";
import moment from "moment-timezone";
import Skeleton from "react-loading-skeleton";

interface IUserDetails {
	firstName: string,
	lastName:string,
	phone:string,
	email:string,
	userName:string,
	role:string,
	createDate: Date,
	loading: Boolean,
}
const ClientProfileInformation:React.FC<IUserDetails> = (props)=> {
	const { accounts } = useMsal();
	const account = useAccount(accounts?.[0] || {});
	return (
		<React.Fragment>
			<p>Please contact your service administrator if the email address needs to be updated.  </p>
			<Row>
				<Col lg={6}>
					<p className="mb-1">
						Username
					</p>
				</Col>
				<Col lg={6}>
					{props.loading ? <Skeleton className="mb-3" width={"100%"} height={20} />:
					<p>
						<strong>{props.email}</strong>
					</p>}
				</Col>
			</Row>
			<Row>
				<Col lg={6}> 
					<p className="mb-1">
						First Name
					</p>
				</Col>
				<Col lg={6}>
				{props.loading ? <Skeleton className="mb-3" width={"100%"} height={20} />:
					<p>
						<strong>{props.firstName}</strong>
					</p>}
				</Col>
			</Row>
			<Row>
				<Col lg={6}>
					<p className="mb-1">
						Last Name
					</p>
				</Col>
				<Col lg={6}>
				{props.loading ? <Skeleton className="mb-3" width={"100%"} height={20} />:
					<p>
						<strong>{props.lastName}</strong>
					</p>}
				</Col>
			</Row>
			<Row>
				<Col lg={6}>
					<p className="mb-1">
						Phone Number
					</p>
				</Col>
				<Col lg={6}>
				{props.loading ? <Skeleton className="mb-3" width={"100%"} height={20} />:
					<p>
						<strong>{props.phone}</strong>
					</p>}
				</Col>
			</Row>
			<Row>
				<Col lg={6}>
					<p className="mb-1">
						Role
					</p>
				</Col>
				<Col lg={6}>
				{props.loading ? <Skeleton className="mb-3" width={"100%"} height={20} />:
					<p>
						<strong>{props.role}</strong>
					</p>}
				</Col>
			</Row>
			<hr></hr>
			<Row>
				<Col lg={6}>
					<p className="mb-1">
						Create Date
					</p>
				</Col>
				<Col lg={6}>
				{props.loading ? <Skeleton className="mb-3" width={"100%"} height={20} />:
					<p>
						<strong>{props.createDate && moment.utc(props.createDate).tz('America/Chicago').format("MM/DD/YYYY hh:mm A")}</strong>
					</p>}
				</Col>
			</Row>
			<Row>
				<Col lg={6}>
					<p className="mb-1">
						Last Login Date
					</p>
				</Col>
				<Col lg={6}>
				{props.loading ?<Skeleton className="mb-3" width={"100%"} height={20} />:
					<p>
						<strong>{ account?.idTokenClaims?.iat && moment.unix(account?.idTokenClaims?.iat).tz("America/Chicago").format("MM/DD/YYYY hh:mm A")}</strong>
					</p>}
				</Col>
			</Row>
		</React.Fragment>
	);
}
export default ClientProfileInformation;
