import { ToastContainer } from "react-bootstrap";
import Toast from "react-bootstrap/Toast";
import Icon from "../Icons/Icons";
//import ToastContainer from "react-bootstrap/ToastContainer";
import {IToastProps} from "../../../../types/custom-types"

function ToastNotification(props: IToastProps) {
  return (
    <ToastContainer containerPosition="fixed" className="p-3" position={props.position}>
      <Toast
        show={props.show}
        delay={props.delay ? props.delay : 3000}
        autohide={props.autohide}
        onClose={props.onClose}
        className={`app-toast toast-${props.type}`}
      >
        {props.icon && props.icon.length > 0 && (
          <div className="toast-icon">
              <Icon
                name={props.icon}
                optionalClass="p-1 rounded-circle"
                size={36}
              />
          </div>
        )}
        <div className="toast-msg">
          {props.title && props.title.length > 0 && (
            <Toast.Header closeButton={props.closeButton}>
              <strong className="me-auto">{props.title}</strong>
            </Toast.Header>
          )}
          <Toast.Body>{props.body}</Toast.Body>
        </div>
      </Toast>
    </ToastContainer>
  );
}

export { ToastNotification };
