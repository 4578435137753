import { get, put } from "../util/request";

export const getAllClients = function () {
    return get(`/Client/AllClients`);
};

export const activeClients = function (clientId:string) {
  return put(`/Client/${clientId}/Active`);
};

export const inactiveClients = function (clientId:string) {
  return put(`/Client/${clientId}/Inactive`);
};


export const getClientLogo = function (clientId: string) {
  return get(`/Client/${clientId}/logo`);
};
