import React from "react";
import UserMenu from "../../components/Header/UserMenu";
import UserGreet from "../../components/Header/UserGreet";
import logo from "../../assets/venture-solutions_horizontal-dark.svg";
import { NavLink, Link } from "react-router-dom";
import { Container, Nav, Navbar } from "react-bootstrap";
import { shallowEqual } from "react-redux";
import { useAppSelector } from "../../app/hooks";

function AdminNav() {
  const  clientLogo = useAppSelector(
    (state: any) => state.account.clientLogo,
    shallowEqual
      );
  const  clientName = useAppSelector(
    (state: any) => state?.account?.user?.clientName,
    shallowEqual
      );

  return (
    <header id="adminHeader" className="mb-4">
      <Navbar className="app-navbar" expand="md" variant="light">
        <Container>
          <Navbar.Toggle aria-controls="appNavbar" />
          <Navbar.Brand as={Link} to="/admin">
            {/* @TODO: Logo will be based on Client, Admin will use Venture */}
            <img src={clientLogo ? `data:image/png;base64,${clientLogo}`: logo } alt={clientName || ''} />
          </Navbar.Brand>
          <Navbar.Collapse id="appNavbar">
            <Nav>
              <NavLink to="/admin"
                className={({ isActive }) =>
                  isActive && (location.pathname == "/admin") ? "nav-link active" : "nav-link"
                }>
                Home
              </NavLink>
              <NavLink to="/admin/users"
                className={({ isActive }) =>
                  isActive && (location.pathname == "/admin/users") ? "nav-link active" : "nav-link"
                }>
                Users
              </NavLink>
            </Nav>
          </Navbar.Collapse>
          <UserGreet />
          <UserMenu />
        </Container>
      </Navbar>
    </header>
  );
}

export default AdminNav;
