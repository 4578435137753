import React, { useEffect } from "react";
import { Container, Alert, Card, Row, Col } from "react-bootstrap";
import { shallowEqual } from "react-redux";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import Icon from "../../components/Common/Icons/Icons"

function ConfigureClientSummary() {
  const accountInfo = useAppSelector((state: any) => state.clientAccount.clientAccountInfo.data, shallowEqual);
 
  return (
    <main id="mainContent">
      <Container>
        <h1 className="visually-hidden">Configure Client successful</h1>

         <Alert variant="info">
					<div className="d-flex align-items-center">
						<div className="me-3">
							<Icon
								name="info"
								optionalClass="p-1 rounded-circle bg-light"
								size={36}
							/>
						</div>
						<div>
							<h2 className="h3 m-0">
								You have been successfully configured the client.
							</h2>
						</div>
					</div>
				</Alert>
        {/* End If */}
        <Row>
          <Col lg={6}>
            <Card className="mb-3 shadows-light">
              <Card.Body>
                <Card.Title as="h2" className="app-card-title">
                  Client Details
                </Card.Title>
                <Row>
                  <Col sm={6}>
                    <p className="mb-1">Client Name</p>
                  </Col>
                  <Col sm={6}>
                    <p>
                      <strong>{accountInfo?.companyName?.label}</strong>
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <p className="mb-1">Industry</p>
                  </Col>
                  <Col sm={6}>
                    <p>
                    <strong>{accountInfo?.industry?.label}</strong>
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <p className="mb-1">Language</p>
                  </Col>
                  <Col sm={6}>
                    <p>
                    <strong>{accountInfo?.language?.label}</strong>
                    </p>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Link to="/admin" className="btn btn-outline-primary">
					<Icon name="arrow-left" optionalClass="me-2" size={20}></Icon>
					Back to Home
				</Link>
      </Container>
    </main>
  );
}

export default ConfigureClientSummary;
