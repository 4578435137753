import React, { useMemo, useCallback, useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Accordion,
} from "react-bootstrap";
import { Controller } from "react-hook-form";
import { DatePicker } from "../../components/Common/DatePicker";
import {
  FromToDateRangeFilter,
  Table,
  TableFilter,
  TableHeader
} from "../../components/Common/Table";
import { LoadingWaitModal } from "../../components/Common/Modal/LoadingWaitModal";
import { ConfirmModal } from "../../components/Common/Modal";
import { useFilterForm } from "../../hooks/useFilterForm";
import breakPoints from "../../scss/abstracts/_variables.scss";
import moment from "moment";

import { getAllClients, activeClients, inactiveClients } from '../../api/client'

import { v4 as uuidv4 } from "uuid";
import { $toast } from "../../util/toastUtil";
import { useAppDispatch } from "../../app/hooks";
import { revertAllClientData } from "../../features/configureClient/configureAccountSlice";
import UserListPlaceholder from "./placeholders/UserListPlaceholder";
import Skeleton from "react-loading-skeleton";

function AdminMain() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

// show the last 6 months history as a default.
const [startDate, setStartDate] = useState<Date|undefined>(
  moment().subtract(6, "months").toDate()
);
const [endDate, setEndDate] = useState<Date|undefined>(new Date());

	const [loading, setLoading] = useState(false);
  const [activeLoader, setActiveLoader] = useState(false);
  const [tableData, setTableData] = useState<Array<object>>([]);
	const [isShowConfirm, setIsShowConfirm] = useState(false)
	const [isActiveOption, setIsActiveOption] = useState(false)
	const [selectClientId, setSelectClientd] = useState('')

  const tableRef = useRef<any>()

  const initialTableSearch = {
    dateCreated: {
      from: startDate,
      to: endDate
    },
    clientName: "",
    status: "",
    indesutry: ""
  };

  const { filters, filterControl, registerFilter, resetFilterForm, getValuesFilter } = useFilterForm({
    defaultValues: initialTableSearch,
  });

  const filterTypes = useMemo(
    () => ({
      fromToDateRangeFilter: FromToDateRangeFilter,
    }),
    []
  );

  useEffect(()=>{
    // init clients
    initClients()
  }, [])

  const filterFieldsChange = ()=>{
    tableRef?.current?.gotoFirstPage()
  }

    const initClients = () => {
      setLoading(true)
      getAllClients().then(res=>{
         const { data } = res
         const _tableData:Array<object> = []
         data.forEach((item:any) => {
           const _tableItem = {
            dateCreated:moment(item.createDate).format('MM/DD/YYYY'),
            clientName:item.name,
            status:'',
            industry:item.industryType?.type,
            clientId:item.clientId,
            dateCreatedRaw:item.createDate
           }
           if (!item.isCompleted) {
            _tableItem.status = 'Incomplete'
           }else{
             if (item.isActive) {
              _tableItem.status = 'Active'
             }else{
              _tableItem.status = 'Inactive'
             }
           }
           _tableData.push(_tableItem)
         });
         setTableData(_tableData)
      }).catch((error:any)=>{
        showToast(error.response?error.response.data:error.message)
      }).finally(()=>{
        setLoading(false)
      })
  }

  const handleConfirm = (isActive:boolean)=>{
    setIsShowConfirm(false)
    setActiveLoader(true)
    if (isActive) {
      activeClients(selectClientId).then(res=>{
        showToast("The Client/Industry is active now.",
        "check-double",
        "success",
        "Success")
      initClients()
      }).catch((error)=>{
        showToast(error.response?error.response.data:error.message)
      }).finally(()=>{
        setActiveLoader(false)
      })
    }else{
      inactiveClients(selectClientId).then(res=>{
        showToast("The Client/Industry is inactive now.",
        "check-double",
        "success",
        "Success")
      initClients()
      }).catch((error)=>{
        showToast(error.response?error.response.data:error.message)
      }).finally(()=>{
        setActiveLoader(false)
      })
    }
  }

  const showToast = (msg:string,icon="close",type="danger",title="Failed") =>{
		$toast.showToast({
			id: uuidv4(),
			autohide: true,
			delay: 5000,
			icon,
			type,
			title,
			body: msg
		})
	}

  const memoTableData = useMemo(()=>tableData,[tableData])
  const columns = useMemo(
    () => [
      {
        Header: ({ column, sorter }: { column: any, sorter: any }) => (
          <TableHeader column={column} sorter={sorter}>Date Created</TableHeader>
        ),
        accessor: "dateCreated",
        filter: "fromToDateRangeFilter",
        sortType: (rowA:any,rowB:any,columnId:string,desc:boolean)=>{
          const _rowA = moment(rowA.values.dateCreatedRaw)
          const _rowB = moment(rowB.values.dateCreatedRaw)
          if (desc) {
            return moment(_rowA).isAfter(moment(_rowB))? 1: -1
          }else{
            return moment(_rowA).isAfter(moment(_rowB))? 1: -1
          }
        },
      },
      {
        Header: ({ column }: any) => (
          <TableHeader column={column}>Payment Date Raw</TableHeader>
        ),
        accessor: "dateCreatedRaw",
      },
      {
        Header: ({ column, sorter }: { column: any, sorter: any }) => (
          <TableHeader column={column} sorter={sorter}>Client Name</TableHeader>
        ),
        accessor: "clientName",
      },
      {
        Header: ({ column, sorter }: { column: any, sorter: any }) => (
          <TableHeader column={column} sorter={sorter}>Status</TableHeader>
        ),
        accessor: "status",
        Cell: (props: any) => {
          if (props?.row?.original?.status === "Active") {
            return <Button variant="outline-primary" title="Click to De-Activate" size="sm" onClick={() => {
              setIsActiveOption(false)
              setSelectClientd(props?.row?.original?.clientId)
              setIsShowConfirm(true)
            }}>Active</Button>
          }
          else if (props?.row?.original?.status === "Incomplete") {
            return <span className="text-warning size14-font">Incomplete</span>
          }
          else {
            return <Button variant="outline-danger" title="Click to Activate" size="sm" onClick={() => {
              setIsActiveOption(true)
              setSelectClientd(props?.row?.original?.clientId)
              setIsShowConfirm(true)
            }}>Inactive</Button>
          }
        }
      },
      {
        Header: ({ column, sorter }: { column: any, sorter: any }) => (
          <TableHeader column={column} sorter={sorter}>Industry</TableHeader>
        ),
        accessor: "industry",
      },
      {
        Header: () => (
          <div>Edit</div>
        ),
        id: "manageClients",
        Cell: (props: any) => <Button onClick={() => navigate(`/admin/configure-client/${props?.row?.original?.clientId}`, { replace: true })} variant="outline-primary">Edit</Button>
      },
    ],
    []
  );

  const tablet = useMemo(
    () => Number(breakPoints.tablet.replace("px", "")),
    []
  );

  const resetTableSearch = () => {
    const _startDate = moment().subtract(6, "months").toDate();
    setStartDate(_startDate);

    const _endDate = new Date();
    setEndDate(_endDate);

    const initialTableSearch = {
      dateCreated: {
        from: _startDate,
        to:  _endDate
      },
      clientName: "",
      status: "",
      industry: ""
    };
    resetFilterForm(initialTableSearch)
    filterFieldsChange()
  }

  return (
    <main id="mainContent">
			<LoadingWaitModal show={activeLoader} size={"lg"}></LoadingWaitModal>
      <ConfirmModal
          show={isShowConfirm}
          size="lg"
          title={isActiveOption ? "Active Client/Industry" : "Inactive Client/Industry"}
          submitBtnText="Yes"
          cancelBtnText="No"
          children={isActiveOption ? "Are you sure to make the Client/Industry active?" : "Are you sure to make the Client/Industry inactive?"}
          onCancel={() => setIsShowConfirm(false)}
          onConfirm={()=> handleConfirm(isActiveOption)}
        ></ConfirmModal>
      <Container>
        <h1>Overview</h1>
        <Row>
          <Col md={12} className="mb-3">
          {loading ? <Skeleton width={170} height={40} />:
            <Link onClick={()=>{dispatch(revertAllClientData())}} className="btn btn-primary" to="/admin/configure-client">
              Configure Customer
            </Link>}
          </Col>
        </Row>
        <div id="adminOverviewTableFilters" className="app-panel-container">
          <div className="app-left-panel">
          {loading ? <Skeleton className="mb-3" width={60} height={40} />:
            <TableFilter autoShowMinWidth={tablet} onClear={resetTableSearch}>
              <Accordion
                className="app-accordions"
              >
                {/* @TODO: Fix selectsRange to work in TSX */}
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Date Created</Accordion.Header>
                  <Accordion.Body>
                    <Controller
                      name="dateCreated.from"
                      control={filterControl}
                      render={({ field }) => (
                        <div className="text-center">
                          <DatePicker
                            containerClassName="mb-1"
                            selectedDate={field.value}
                            placeholderText="MM/DD/YYYY"
                            maxDate={endDate}
                            onChange={(date: Date|undefined) => {
                              if(date){
                                field.onChange(date);
                              }else{
                                field.onChange("");
                              }
                              filterFieldsChange()
                              setStartDate(date);
                              setEndDate(getValuesFilter("dateCreated.to"));
                            }}
                          />
                        </div>
                      )}
                    />
                    <Controller
                      name="dateCreated.to"
                      control={filterControl}
                      render={({ field }) => (
                        <div className="text-center">
                          <DatePicker
                            selectedDate={field.value}
                            placeholderText="MM/DD/YYYY"
                            minDate={startDate}
                            onChange={(date: Date|undefined) => {
                              if(date){
                                field.onChange(date);
                              }else{
                                field.onChange("");
                              }
                              filterFieldsChange()
                              setStartDate(getValuesFilter("dateCreated.from"));
                              setEndDate(date);
                            }}
                          />
                        </div>
                      )}
                    />
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>Client Name</Accordion.Header>
                  <Accordion.Body>
                    <Form.Control {...registerFilter("clientName",{onChange: (e) => filterFieldsChange()})} />
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>Status</Accordion.Header>
                  <Accordion.Body>
                    <Form.Control {...registerFilter("status",{onChange: (e) => filterFieldsChange()})} />
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>Industry</Accordion.Header>
                  <Accordion.Body>
                    <Form.Control {...registerFilter("industry",{onChange: (e) => filterFieldsChange()})} />
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </TableFilter>}
          </div>
          <div className="app-right-panel">
          {loading ? <UserListPlaceholder/>:
            <Table
              ref={tableRef}
              columns={columns}
              data={memoTableData}
              filters={filters}
              filterTypes={filterTypes}
              sortable
              bordered
              hover
              responsive
              GlobalFilterOptions={{ enable: true, placeholder: "Search by Client Name, Industry or Status" }}
              getSubRows={undefined}
              subComponent={undefined}
              hiddenColumns={['dateCreatedRaw']}
              initialSortState={[{id:"dateCreated",desc:true}]}
              initialFilters={[{ id: 'dateCreated',value: {from: startDate,to: endDate}}]}
            />}
          </div>
        </div>
      </Container>
    </main>
  );
}

export default AdminMain;
