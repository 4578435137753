import { store } from "../app/store";
import { setGlobalToasts } from "../features/global/globalSlice";

// Types
import { EPToastProps, EPToastUtilProps } from "../../types/custom-types";

import { v4 as uuidv4 } from "uuid";
import { baseDateFormat } from "./formatUtil";

const setDefaultValueForToast = (toast: EPToastUtilProps, type: string) => {
  if (!toast.id) {
    toast.id = uuidv4();
  }
  if (typeof toast.show === "undefined") {
    toast.show = true;
  }
  if (typeof toast.autohide === "undefined") {
    toast.autohide = true;
  }
  if (
    typeof toast.autohide === "undefined" &&
    typeof toast.delay === "undefined"
  ) {
    toast.delay = 3000;
  }
  if (typeof (toast.icon === undefined)) {
    switch (type) {
      case "success":
        toast.icon = "check-double";
        break;
      case "danger":
        toast.icon = "close";
        break;
      case "warning":
        toast.icon = "close";
        break;
    }
  }
};

class EPToast {
  // toasts: Array<any>;
  // dispatch: Function;
  // setGlobalToasts: Function;

  // constructor() {
  //   this.toasts = store.getState().global.toasts;
  //   this.dispatch = store.dispatch;
  //   this.setGlobalToasts = setGlobalToasts;
  // }

  showToast(newToast: EPToastProps) {
    // warn: non-serializable value cannot detected in an action
    // newToast.onClose = (e)=>{
    //   this.hideToast(newToast.id);
    //   if(typeof newToastOnClose === "function"){
    //     newToast.onClose = newToastOnClose;
    //   }
    // }

    if (!newToast.id) {
      newToast.id = uuidv4();
    }

    const toasts = store.getState().global.toasts;
    const dispatch = store.dispatch;

    const toasts_copy = toasts
      ? [...toasts].filter((x) => x.show && x.body !== newToast.body)
      : [];

    toasts_copy.push(newToast);

    dispatch(setGlobalToasts(toasts_copy));
  }

  success(toast: EPToastUtilProps, useDefault: boolean | undefined = true) {
    if (useDefault) {
      setDefaultValueForToast(toast, "success");
    }

    const newToast = toast as EPToastProps;
    newToast.type = "success";
    this.showToast(newToast);
  }

  danger(toast: EPToastUtilProps, useDefault: boolean | undefined = true) {
    if (useDefault) {
      setDefaultValueForToast(toast, "danger");
    }

    const newToast = toast as EPToastProps;
    newToast.type = "danger";
    this.showToast(newToast);
  }

  warning(toast: EPToastUtilProps, useDefault: boolean | undefined = true) {
    if (useDefault) {
      setDefaultValueForToast(toast, "warning");
    }

    const newToast = toast as EPToastProps;
    newToast.type = "warning";

    this.showToast(newToast);
  }

  hideToast(id: string) {
    const toasts = store.getState().global.toasts;
    const dispatch = store.dispatch;

    if (id) {
      const toasts_copy = toasts.filter((x) => x.show && x.id !== id);
      dispatch(setGlobalToasts(toasts_copy));
    }
  }

  clearToasts() {
    const dispatch = store.dispatch;
    dispatch(setGlobalToasts([]));
  }
}

const $toast = new EPToast();

const toastMessage = {
  validDate: `Please enter the valid date (${baseDateFormat})`,
  invalidDateRange: "'To' date should be greater than 'From' date.",
  exceeds120Days: "Cannot schedule a payment for more than 120 days in the future",
  beforePaymentDate: "Payment date selected cannot be a past date.",
  notFoundDocument: "Not found document for this record",
  notFoundDocumentList: "No Document(s) Found",
  getDocumentFailed: "Data is not available",
  serverError: "Oops! Something went wrong. Please try again later.",
  tokenExpired: "Token expired, please login again",
  noPaymentDate: "Must set payment date before continuing",
  noSelectedPayment: "No selected payment",
  savedUser: "User detail has been saved successfully.",
  deletedUser: "User has been deleted successfully.",
  roleError: "Failed to get the role information.",
  savedUserPaymentMethodSuccessful:
    "Your payment method was saved successfully.",
  savedUserPaymentMethodFailed:
    "Your payment method was not saved due to some issues.",
  loadUserPaymentMethodsForFailed: "Load payment methods failed!",
  getTokenFromResponseFailed:
    "Not found available token from the token response"
};

export default EPToast;

export { $toast, toastMessage };
