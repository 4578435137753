import React from "react";
import UserMenu from "../../components/Header/UserMenu";
import UserGreet from "../../components/Header/UserGreet";
import logo from "../../assets/venture-solutions_horizontal-dark.svg";
import { Container, Navbar } from "react-bootstrap";
import { shallowEqual } from "react-redux";
import { useAppSelector } from "../../app/hooks";
import { epHistory } from "../../routes/History";

function ConsumerNav() {

  const  clientLogo = useAppSelector(
    (state: any) => state.account.clientLogo,
    shallowEqual
      );
  const  clientName = useAppSelector(
    (state: any) => state?.account?.user?.clientName,
    shallowEqual
      );      

  return (
    <header id="UserHeader" className="mb-4">
      <Navbar className="app-navbar" expand="md" variant="light">
        <Container>
          {/* <Navbar.Toggle aria-controls="appNavbar" /> */}
          <Navbar.Brand
            onClick={() => {
              // epHistory.replace('/consumer/accounts')
            }}
          >
            {clientLogo && <img src={`data:image/png;base64,${clientLogo}`} alt={clientName || ''} />}
          </Navbar.Brand>

          <UserGreet />
          <UserMenu />
        </Container>
      </Navbar>
    </header>
  );
}

export default ConsumerNav;
