import { post, get, del } from "../util/request";

export const getLastPayment = function () {
  return get(`/Payment/LatestPayment`);
};

export const filterPaymentHistory = (filter: any) => {
  return post("/Payment/FilterHistory", filter);
};

export const getPaymentHistory = (headers?: any) => {
  return get(`/Payment/FilterHistory`, null, headers);
};

export const getBillingHistory = () => {
  return get(`/Statement/BillingHistory`);
};

export const getUserAccountDetail = function (headers?: any) {
  return get(`/Statement/AccountDetails`, {}, headers);
};

export const getUserAccountGraph = function () {
  return get(`/Payment/PieChartData`, {});
};

export const getPendingPayments = () => {
  return get(`/Payment/pendingPayments`);
};

export const updatePendingPaymentDate = (
  paymentTransactionId: string,
  scheduledDate: string
) => {
  return post("/Payment/updatePaymentDate", {
    paymentTransactionId,
    scheduledDate
  });
};

export const updatePendingPaymentStatus = (
  paymentTransactionId: string,
  paymentStatusId: number
) => {
  return post("/Payment/updatePaymentStatus/" + paymentTransactionId + "/Cancel");
};

export const setupAutoPay = (payment: Object) => {
  return post(`/Payment/setupAutoPay`, payment)
}

export const createPayment = (payment: Object) => {
  return post(`/Payment/createPayment`, payment)
}

export const updateAutopay = (updateParams: Object) => {
  return post(`/Payment/setupAutoPay/updateAutopay`, updateParams)
}

export const getAutopay = () => {
  return get(`/Payment/setupAutoPay/getAutopay`)
}

export const unenrolledAutopay = () => {
  return del(`/Payment/setupAutoPay/unenroll`)
}

