import {
  createAsyncThunk,
} from "@reduxjs/toolkit";
import { get, post, put } from "../util/request";

export const getGeneralBillingAttributes = createAsyncThunk("generalBillingAttributes/getGeneralBillingAttributes", async (clientId: string) => {
  const response = await get(`BillingAttribute/Client/${clientId}/generalinformation`);
  return response.data;
});

export const getPaymentBillingAttributes = createAsyncThunk("paymentBillingAttributes/getPaymentBillingAttributes", async (clientId: string) => {
  const response = await get(`BillingAttribute/Client/${clientId}/paymentinformation`);
  return response.data;
});

export const getPaymentMethods = createAsyncThunk("paymentBillingAttributes/getPaymentMethods", async (clientId: string) => {
  const response = await get(`ClientPaymentMethod/Client/${clientId}`);
  return response.data;
});

export const saveBillingAttributes = function(data: any, clientId: string){
  return post(`ClientBillingAttribute/Client/${clientId}`, data);
};

export const savePaymentMethods = function (data: any, clientId: string) {
  return put(`ClientPaymentMethod/Client/${clientId}`, data);
};

export const setClientAccountStatus = function (clientId: string) {
  return put(`Client/CompleteClientUpdate/${clientId}`);
};

export const getClientBillingAttribute = createAsyncThunk("paymentBillingAttributes/getClientBillingAttribute",
  async (clientId: string) => {
    const response = await get(`ClientBillingAttribute/Client/${clientId}`);
    return response.data;
  }
);

export const getConfigureNotifications = createAsyncThunk("paymentBillingAttributes/getConfigureNotifications", async (clientId: string) => {
  const response = await get(`Notification/ClientNotificationMethods/${clientId}`);
  return response.data;
});

export const saveClientNotification = function (data: any, clientId: string) {
  return post(`Notification`, data);
};
