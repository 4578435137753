import Skeleton from "react-loading-skeleton";
import { Card, Col, Form, Row } from "react-bootstrap";
import Icon from "../../../components/Common/Icons/Icons";

const PendingAlert = () => {

    return (
        <Card className="mb-3">
        <Card.Body> 
            <Row>
                <Col sm={1}>
                <Skeleton width={"100%"} height={25} />
                </Col>
                <Col sm={8}>
                <Skeleton width={"100%"} height={25} />
                </Col>
                <Col sm={3}>
                <Skeleton width={"100%"} height={25} />
                </Col>
            </Row> 
        </Card.Body>
    </Card>
    );
};

export default PendingAlert;