// Hooks
import { useMemo, useState, useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useFilterForm } from "../../hooks";
import { useSearchParams, useNavigate } from "react-router-dom";
import { Controller } from "react-hook-form";

// Components
import Icon from "../../components/Common/Icons/Icons";
import { Container, Button, Accordion, Alert, Form, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  FromToDateRangeFilter,
  Table,
  TableFilter,
  TableHeader
} from "../../components/Common/Table";
import { LoadingWaitModal, ConfirmModal } from "../../components/Common/Modal";
import { DatePicker } from "../../components/Common/DatePicker";
import { returnToHome } from '../../util/returnToHome'
import { ROLE } from "../../routes/Roles";

// Api
import {
  getPendingPayments,
  updatePendingPaymentDate,
  updatePendingPaymentStatus
} from "../../api/payment";

// Utils
import moment from "moment-timezone";
import { formatDate, baseDateFormat, convertCSTTimeToLocal } from "../../util/formatUtil";
import { $toast, toastMessage } from "../../util/toastUtil";

// Scss
import breakPoints from "../../scss/abstracts/_variables.scss";

// Store
import { setPendingPayments } from "../../features/payment/paymentSlice";
import { shallowEqual } from "react-redux";
import { isArray } from "lodash";


function PendingPayments() {

  // State
  const [loading, setLoading] = useState(false); // loading
  const [currentRow, setCurrentRow] = useState({
    // current row
    statementId: null,
    paymentTransactionId: null
  });
  const [editModalVisible, setEditModalVisible] = useState(false); // show the update paymentDate modal
  const [paymentDate, setPaymentDate] = useState<Date | undefined>(new Date()); // new paymentDate
  const [confirmModalVisible, setConfirmModalVisible] = useState(false); // show the update paymentStatus modal
  const [processStart, setProcessStart] = useState(moment()); // current day 19:00
  const [disableBtns, setDisableBtns] = useState(false); // after 19:00 disable btn

  const [toastVisible, setToastVisible] = useState(false);
  const [toastBody, setToastBody] = useState("");

  const navigate = useNavigate();
  // Store
  const { pendingPayments } = useAppSelector(
    (state) => ({ ...state.payment }),
    shallowEqual
  );

  const { role } = useAppSelector(
    (state) => ({ ...state.account }),
    shallowEqual
  );

  // load table data
  const getPendingPaymentMethod = async (
    dispatch: Function,
    callBack: Function
  ) => {
    try {
      let response: any = await getPendingPayments();
      if (response.status == 200) {
        response.data.map((item: any) => {
          item.scheduledDate = item.scheduledDate ? convertCSTTimeToLocal(item.scheduledDate) : null
          return item
        });
        dispatch(setPendingPayments(response.data));
        callBack();
      } else {
        setLoading(false)
        showErrorToast(response.response ? response.response.data : response.message)
      }
    } catch (ex) {
      console.error(ex);
      callBack();
    }
  };

  // update paymentDate
  const updatePendingPaymentDateMethod = async (
    dispatch: Function,
    postData: any,
    success: Function,
    failed: Function
  ) => {
    try {
      const { paymentDate, paymentTransactionId } = postData;
      let response: any = await updatePendingPaymentDate(
        paymentTransactionId,
        paymentDate
      );
      if (response && response.data === true) {
        success();
      } else {
        showErrorToast(response.response ? response.response.data : response.message)
        failed();
      }
    } catch (error: any) {
      showErrorToast(error.response ? error.response.data : error.message)
      failed();
    }
  };

  // update paymentStatus
  const updatePendingPaymentStatusMethod = async (
    dispatch: Function,
    postData: any,
    success: Function,
    failed: Function
  ) => {
    try {
      const { paymentStatusId, paymentTransactionId } = postData;
      let response: any = await updatePendingPaymentStatus(
        paymentTransactionId,
        paymentStatusId
      );
      if (response && response.data === true) {
        success();
      } else {
        showErrorToast(response.response ? response.response.data : response.message)
        failed();
      }
    } catch (error: any) {
      showErrorToast(error.response ? error.response.data : error.message)
      failed();
    }
  };

  // Effects
  const dispatch = useAppDispatch();
  useEffect(() => {
    // load table data
    setLoading(true);
    getPendingPaymentMethod(dispatch, () => {
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    const cstnowString = moment().tz("America/Chicago").format();
    const cstnow = moment(cstnowString);
    const dayProcessStart = cstnow.hours(19).minutes(0).seconds(0); //07:00 pm/'
    setProcessStart(dayProcessStart);
    if (cstnow.isAfter(dayProcessStart)) {
      setDisableBtns(true);
    }
  }, [pendingPayments]);

  // Edit payment
  const showEditModal = (cell: any) => {
    setPaymentDate(moment(cell?.row?.original?.scheduleDate).toDate());
    setCurrentRow(cell?.row?.original);
    const paymentTransactionId = cell?.row?.original?.paymentTransactionId;
    if (paymentTransactionId) {
      setEditModalVisible(true);
    }
  };

  const onScheduledDateChange = (date: Date | undefined) => {
    setPaymentDate(moment(date).toDate());
  };

  const editClose = () => {
    setEditModalVisible(false);
  };

  const savePaymentDate = () => {

    if (!paymentDate) {
      $toast.danger({
        show: true,
        body: toastMessage.noPaymentDate,
        icon: "warning",
        autohide: true,
      })

      return;
    }

    if (currentRow && currentRow.statementId) {
      setEditModalVisible(false);

      setLoading(true);
      updatePendingPaymentDateMethod(
        dispatch,
        {
          paymentTransactionId: currentRow.paymentTransactionId,
          statementId: currentRow.statementId,
          paymentDate
        },
        () => {
          setCurrentRow({ paymentTransactionId: null, statementId: null });
          getPendingPaymentMethod(
            dispatch,
            () => {
              setLoading(false);
              showToast("You have successfully changed your scheduled payment.");
            }
          );
        },
        () => {
          setCurrentRow({ paymentTransactionId: null, statementId: null });
          setLoading(false);
        }
      );
    } else {
      $toast.danger({
        show: true,
        body: toastMessage.noSelectedPayment,
        icon: "warning",
        autohide: true,
      })

      return;
    }
  };

  const showCancelModal = (cell: any) => {
    setCurrentRow(cell?.row?.original);

    const paymentTransactionId = cell?.row?.original?.paymentTransactionId;
    if (paymentTransactionId) {
      setConfirmModalVisible(true);
    }
  };

  const closeCancelModal = () => {
    setConfirmModalVisible(false);
  };

  const cancelPayment = () => {
    if (currentRow && currentRow.statementId) {
      setConfirmModalVisible(false);

      setLoading(true);
      updatePendingPaymentStatusMethod(
        dispatch,
        {
          paymentTransactionId: currentRow.paymentTransactionId,
          statementId: currentRow.statementId,
          paymentStatusId: 10
        },
        () => {
          setCurrentRow({ paymentTransactionId: null, statementId: null });
          getPendingPaymentMethod(
            dispatch,
            () => {
              setLoading(false);
              showToast("You have successfully canceled your scheduled payment.");
            }
          );
        },
        () => {
          setCurrentRow({ paymentTransactionId: null, statementId: null });
          setLoading(false);
        }
      );
    } else {
      console.error("no selected payment");
    }
  };

  const showToast = (message: string) => {
    setToastBody(message);
    setToastVisible(true);
  };

  const showErrorToast = (message: string) => {
    $toast.danger({
      title: 'Failed',
      body: message
    })
  };

  const onCloseToast = () => {
    setToastVisible(false);
  };

  /* Date Filter Search*/
  const [minDate] = useState(moment().toDate());
  const [maxDate] = useState(moment().add(120, "days").toDate());
  const [startDate, setStartDate] = useState<Date | undefined>(minDate);
  const [endDate, setEndDate] = useState<Date | undefined>(maxDate);

  let pendingPaymentSessionData = JSON.parse(`${sessionStorage.getItem("pendingpayment")}`) ? JSON.parse(`${sessionStorage.getItem("pendingpayment")}`) : [];
	let pendingPaymentFilterData: any = {};
	if (isArray(pendingPaymentSessionData)) {
		pendingPaymentSessionData?.forEach((item: any) => {
			pendingPaymentFilterData[item.id] = item.value;
		});
	}

  // Filter form
  const { filters, filterControl, registerFilter, clearFilter, getValuesFilter, resetFilterForm } = useFilterForm({
    defaultValues: {
      scheduleDate: {
        from: pendingPaymentFilterData?.scheduleDate?.from ? moment(pendingPaymentFilterData.scheduleDate.from).toDate() : minDate,
        to: pendingPaymentFilterData?.scheduleDate?.to ? moment(pendingPaymentFilterData.scheduleDate.to).toDate() : maxDate
      },
      status: pendingPaymentFilterData?.status ? pendingPaymentFilterData.status : "",
    }
  });
  const filterTypes = useMemo(
    () => ({
      fromToDateRangeFilter: FromToDateRangeFilter,
    }),
    []
  );

  const resetTableSearch = () => {

    const initialTableSearch = {
      scheduleDate: {
        from: minDate,
        to: maxDate
      },
      status: "",
    };
    sessionStorage.setItem('pendingpayment', JSON.stringify(filters));
    resetFilterForm(initialTableSearch);
    filterFieldsChange()
  }

  useEffect(() => {
		setStartDate(pendingPaymentFilterData?.scheduleDate?.from ? moment(pendingPaymentFilterData.scheduleDate.from).toDate() : minDate);
		setEndDate(pendingPaymentFilterData?.scheduleDate?.to ? moment(pendingPaymentFilterData.scheduleDate.to).toDate() : maxDate);
	}, [])

  useEffect(() => {
		sessionStorage.setItem('pendingpayment', JSON.stringify(filters));
	}, [filters])

  const columns = [
    {
      Header: ({ column }: any) => (
        <TableHeader column={column}>Statement Date</TableHeader>
      ),
      accessor: "statementDate",
      filter: "fromToDateRangeFilter",
      sortType: (rowA:any,rowB:any,columnId:string,desc:boolean)=>{
        const _rowA = moment(rowA.values.paymentDateRaw)
        const _rowB = moment(rowB.values.paymentDateRaw)
        if (desc) {
          return moment(_rowA).isAfter(moment(_rowB))? 1: -1
        }else{
          return moment(_rowA).isAfter(moment(_rowB))? 1: -1
        }
      },
    },
    {
      Header: ({ column }: any) => (
        <TableHeader column={column}>Due Date</TableHeader>
      ),
      accessor: "dueDate",
      filter: "fromToDateRangeFilter",
      sortType: (rowA:any,rowB:any,columnId:string,desc:boolean)=>{
        const _rowA = moment(rowA.values.paymentDateRaw)
        const _rowB = moment(rowB.values.paymentDateRaw)
        if (desc) {
          return moment(_rowA).isAfter(moment(_rowB))? 1: -1
        }else{
          return moment(_rowA).isAfter(moment(_rowB))? 1: -1
        }
      },
    },
    {
      Header: ({ column }: any) => (
        <TableHeader column={column}>Scheduled Date</TableHeader>
      ),
      accessor: "scheduleDate",
      filter: "fromToDateRangeFilter",
      sortType: (rowA:any,rowB:any,columnId:string,desc:boolean)=>{
        const _rowA = moment(rowA.values.scheduleDateRaw)
        const _rowB = moment(rowB.values.scheduleDateRaw)
        if (desc) {
          return moment(_rowA).isAfter(moment(_rowB))? 1: -1
        }else{
          return moment(_rowA).isAfter(moment(_rowB))? 1: -1
        }
      },
    },
    {
      Header: ({ column }: any) => (
        <TableHeader column={column}>Payment Date Raw</TableHeader>
      ),
      accessor: "scheduleDateRaw",
    },
    {
      Header: ({ column }: any) => (
        <TableHeader column={column}>Scheduled Amount</TableHeader>
      ),
      accessor: "scheduledamount",
      Cell: (props:any) =>
      <div>${props?.value}</div>
    },
    {
      Header: ({ column }: any) => (
        <TableHeader column={column}>
          Status
        </TableHeader>
      ),
      accessor: "status",
    },
    {
      Header: () => <div>Edit</div>,
      id: "edit",
      Cell: (props: any) => (
        <Button
          variant="outline-success"
          onClick={() => {
            showEditModal(props);
          }}
          disabled={
            props.row.original.status !== "Scheduled" ||
            (disableBtns &&
              moment(props.row.original.scheduleDate, "MM/DD/YYYY").isSame(
                processStart,
                "day"
              ))
          }
        >
          Edit
        </Button>
      )
    },
    {
      Header: () => <div>Cancel</div>,
      id: "cancellation",
      Cell: (props: any) => (
        <Button
          variant="outline-danger"
          onClick={() => {
            showCancelModal(props);
          }}
          disabled={
            props.row.original.status !== "Scheduled"
            ||
            (disableBtns &&
              moment(props.row.original.scheduleDate, "MM/DD/YYYY").isSame(
                processStart,
                "day"
              ))
          }
        >
          Cancel
        </Button>
      )
    }
  ];

  const tablet = useMemo(
    () => Number(breakPoints.tablet.replace("px", "")),
    []
  );

  const tableData = useMemo(() => {
    let _data: object[] = []
    pendingPayments.forEach((item: any) => {
      let _item = {
        paymentTransactionId: item.paymentTransactionId,
        statementId: item.statementId,
        scheduleDate: formatDate(moment(item.scheduledDate), baseDateFormat),
        paymentStatusId: item.paymentStatusId,
        status: item.status,
        scheduleDateRaw:item.scheduledDate,
        statementDate: formatDate(item.statementDate),
        dueDate: formatDate(item.dueDate),
        scheduledamount: item.amount
      }
      _data.push(_item)
    });
    return _data.sort((a:any, b:any) => moment(b.scheduleDateRaw).diff(moment(a.scheduleDateRaw)));
  }, [pendingPayments])

  const editChildren = (
    <>
      <Row>
        <Col md={10}>
          <Form.Group className="mb-3">
            <Form.Label htmlFor="changeScheduleDate">
              Scheduled Payment date
            </Form.Label>
            <DatePicker
              selectedDate={paymentDate}
              containerClassName="w-50"
              minDate={moment().toDate()}
              maxDate={moment().add(120, 'days').toDate()}
              onChange={(date: Date | undefined) => onScheduledDateChange(date)}
            />
          </Form.Group>
        </Col>
      </Row>
    </>
  );

  const cancelChildren = (
    <span>Are you sure you want to cancel your payment?</span>
  );

  const successPromptChildren = (
    <span>{toastBody}</span>
  );

  const { permissions } = useAppSelector(
    (state: any) => ({ ...state.permissions }),
    shallowEqual
  );

  const backToHome = (
    <Button
      variant="outline-primary"
      onClick={() => { returnToHome() }}
    >
      <Icon name="arrow-left" optionalClass="me-2" size={20}></Icon>
      Back
    </Button>
  );

  const backToPaymentDetails = (
        <Button
          variant="outline-primary"
          onClick={()=>{navigate(-1)}}
        >
          <Icon name="arrow-left" optionalClass="me-2" size={20}></Icon>
          Back to Home
        </Button>
  );

  const tableRef = useRef<any>()

  const filterFieldsChange = ()=>{
    tableRef?.current?.gotoFirstPage()
  }


  return (
    <Container>
      {
      permissions?.canShowBreadcrumb &&
      <div className="mb-3">
        <Link to="/client">Customers</Link> / <Link to="/client/accountinformation">Account Information</Link> / Pending Payments
      </div>
      }
      <h1 className="h2">Pending Payments</h1>
      <Alert variant="warning">
        <Icon
          name="warning"
          optionalClass="p-1 rounded-circle bg-light me-2"
          size={36}
        />
        If you have a scheduled payment, you cannot edit or cancel your payment after 7:00 p.m. (CST) on the scheduled payment date.
      </Alert>
      <div className="mb-3">
        {role?.roleName == ROLE.Consumer && backToHome}
        {(role?.roleName == ROLE.ClientAdmin || role?.roleName == ROLE.ClientSuperAdmin) && backToPaymentDetails}
      </div>
      <div id="PendingPayTableFilters" className="app-panel-container">
        {/* <div className="app-left-panel">
          <TableFilter autoShowMinWidth={tablet} onClear={resetTableSearch}>
            <Accordion
              defaultActiveKey={["0"]}
              alwaysOpen
              className="app-accordions"
            >
              <Accordion.Item eventKey="0">
                <Accordion.Header>Scheduled Date</Accordion.Header>
                <Accordion.Body>
                  <Controller
                    name="scheduleDate.from"
                    control={filterControl}
                    render={({ field }) => (
                      <div className="text-center">
                        <DatePicker
                          containerClassName="mb-1"
                          selectedDate={field.value}
                          placeholderText="MM/DD/YYYY"
                          maxDate={endDate}
                          onChange={(date: Date | undefined) => {
                            if (date) {
                              field.onChange(date);
                            } else {
                              field.onChange("");
                            }
                            setStartDate(date);
                            setEndDate(getValuesFilter("scheduleDate.to"));
                          }}
                        />
                      </div>
                    )}
                  />

                  <Controller
                    name="scheduleDate.to"
                    control={filterControl}
                    render={({ field }) => (
                      <div className="text-center">
                        <DatePicker
                          selectedDate={field.value}
                          placeholderText="MM/DD/YYYY"
                          minDate={startDate}
                          onChange={(date: Date | undefined) => {
                            if (date) {
                              field.onChange(date);
                            } else {
                              field.onChange("");
                            }
                            setStartDate(getValuesFilter("scheduleDate.from"));
                            setEndDate(date);
                            filterFieldsChange()
                          }}
                        />
                      </div>
                    )}
                  />
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>Status</Accordion.Header>
                <Accordion.Body>
                  <Form.Control {...registerFilter("status",{onChange: (e) => filterFieldsChange()})} />
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </TableFilter>
        </div> */}
        <div className="app-right-panel">
          <Table
            ref={tableRef}
            className={""}
            columns={columns}
            data={tableData}
            // filters={filters}
            // filterTypes={filterTypes}
            sortable
            bordered
            hover
            responsive
            // initialFilters={[{ id: 'scheduleDate',value: {from: minDate,to: maxDate}}]}
            // initialSortState={[{ id: 'scheduleDate',desc: true}]}
            hiddenColumns={['scheduleDateRaw']}
          />
        </div>
      </div>

      <LoadingWaitModal show={loading} size={"lg"}></LoadingWaitModal>

      <ConfirmModal
        title={"Change Scheduled Payment"}
        description={undefined}
        onConfirm={savePaymentDate}
        submitBtnText={"Save Changes"}
        onCancel={editClose}
        show={editModalVisible}
        children={editChildren}
        size={undefined}
        showFooter={true}
      ></ConfirmModal>

      <ConfirmModal
        title={"Cancel Scheduled Payment"}
        description={undefined}
        onConfirm={cancelPayment}
        submitBtnText={"Yes"}
        cancelBtnText={"No"}
        onCancel={closeCancelModal}
        show={confirmModalVisible}
        children={cancelChildren}
        size={undefined}
        showFooter={true}
      ></ConfirmModal>

      <ConfirmModal
        title={"Scheduled Payment"}
        description={undefined}
        onConfirm={onCloseToast}
        submitBtnText={"OK"}
        onCancel={onCloseToast}
        show={toastVisible}
        noCancelBtn={true}
        children={successPromptChildren}
        size={undefined}
        showFooter={true}
      ></ConfirmModal>
    </Container>
  );
}
export default PendingPayments;
