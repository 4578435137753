import React, { FC, useEffect, useState } from 'react';
import { Dropdown, Form, InputGroup } from 'react-bootstrap';

const BrandSelector = (props: any) => {

    let state = {
        background: props?.color?.label,
    }

    return (
        <React.Fragment>
            <InputGroup className={props.className}>
                <InputGroup.Text className="app-brand-selector">
                    <Dropdown>
                        <Dropdown.Toggle
                            className="btn-sm"
                            variant="link-dark"
                            id="dropdown-swatch"
                            aria-label="Select a Brand Color"
                        >
                            <span className="app-brand-color" style={state}></span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <div className="grid-color-selector">
                                {props?.brandColors && props?.brandColors.map((item: any, index: number) => (
                                    <div key={index} className="color-swatch">
                                        <button onClick={() => { props.onChangeColor(item); }} type="button" data-color={item.label} className={`btn swatch-btn ` + (item.value === props?.color?.label ? "active" : "")}>
                                            <span className="swatch-tile" style={{ background: item.label }}></span>
                                        </button>
                                    </div>
                                ))
                                }
                            </div>
                        </Dropdown.Menu>
                    </Dropdown>
                </InputGroup.Text>
                <Form.Control
                    id="configureBrand"
                    value={props?.color?.label ? props?.color?.label:""}
                    disabled
                    readOnly
                    className="border-start-0"
                />
            </InputGroup>
        </React.Fragment>
    )
}
export default BrandSelector;