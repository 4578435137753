import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store, persistor } from "./app/store";
import { PersistGate } from "redux-persist/integration/react";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";

import { HistoryRouter } from "./routes/HistoryRouter";
import { epHistory } from "./routes/History";
import { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const container = document.getElementById("root")!;
const root = createRoot(container);

root.render(
    // <SkeletonTheme baseColor="#ececec" highlightColor="#dadada">
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <HistoryRouter history={epHistory}>
            <App />
          </HistoryRouter>
        </PersistGate>
      </Provider>
    // </SkeletonTheme>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
