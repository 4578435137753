import {
    createSlice,
    createAsyncThunk,
    PayloadAction,
    Action
  } from "@reduxjs/toolkit";
  
  import {IPermissions} from "../../../types/custom-types";
  interface InitialState {
    permissions: IPermissions
  }
 
  const initialState: InitialState = {
    permissions: {
      canViewProfile: false,
      canShowBreadcrumb:  false,
      canShowUsers:  false,
      canModifyDeleteClientPaymentMethods:  false,
      canViewPayment:  false,
      canViewDocuments: false,
    }
  };
  
  export const stateSlice = createSlice({
    name: "permissions",
    initialState,
    reducers: {
      setPermissions: (state, action) => {
        state.permissions = action.payload;
      }
    }
  });
  
  export const {
    setPermissions,
  } = stateSlice.actions;
  
  export default stateSlice.reducer;
  