import { post, downloadFile } from "../util/request";

import { IDocsSearchFormType } from "../../types/custom-types";
export const getPdfList = (headers?: any) => {
  return post(`/DocView/GetPdfList`, {}, headers);
};

export const getPdfListForClient = ({
  clientName,
  accountNumber
}: IDocsSearchFormType) => {
  return post("DocView/GetPdfListForClient", { clientName, accountNumber });
};

export const getPdfFile = (acctDtlId: string) => {
  const data = {
    acctDtlId
  };
  return downloadFile("DocView/getPdfFile", data);
};
