import EPRequest from "../service/request";

// Get config
const BASE_URL = process.env.REACT_APP_ApiPath;

const epRequest = new EPRequest({
  baseURL: BASE_URL
});

function get(url: string, params?: any, headers?: any) {
  return epRequest.get({
    url,
    params,
    headers
  });
}

function post(url: string, data?: any, headers?: any) {
  return epRequest.post({
    url,
    data,
    headers
  });
}

function del(url: string, params?: any) {
  return epRequest.delete({
    url,
    params
  });
}

function put(url: string, data?: any) {
  return epRequest.put({
    url,
    data
  });
}

function downloadFile(url: string, data?: any) {
  return epRequest.post({
    url,
    data,
    responseType: "blob",
    timeout: 180 * 1000
  });
}

export { epRequest, get, post, del, put, downloadFile };

