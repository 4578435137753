import React, { FC, useEffect, useState } from "react";
import { Button, Col, Dropdown, Form, InputGroup, Row } from "react-bootstrap";
import { Controller } from "react-hook-form";
import { brandColors } from '../../components/Admin/brandColors';
import BrandSelector from "../../components/Admin/BrandSelector";
import clientLogo from "../../assets/venture-solutions_horizontal-dark.svg";
import { useAppDispatch, useAppSelector } from "../../app/hooks"; 
import { shallowEqual, useDispatch } from "react-redux";
import { getClientAccountInfo } from "../../api/clientAccount";
import { LoadingWaitModal } from "../../components/Common/Modal";
import Select from 'react-select';
import { useNavigate, useParams } from "react-router-dom";
import { readJsonConfigFile } from "typescript";
import AccountPlaceholder from "./placeholders/accountPlaceholder";
import Alert from "./../../assets/Icons/alert.svg";
import Skeleton from "react-loading-skeleton";
import { get } from "../../util/request";

type props = {
    control: any,
    errors: any,
    trigger: any,
    setValue: any,
    getValues: any
};

const ConfigureAccount: FC<props> = ({control, errors, trigger, setValue, getValues}) => {
    const accountInfo = useAppSelector((state: any) => state.clientAccount, shallowEqual);
    const clientAccountInfo = useAppSelector((state: any) => state.clientAccount.clientAccountInfo.data, shallowEqual);
    const navigate = useNavigate();  
    const dispatch = useAppDispatch();  
    let { clientId } = useParams();
    const [brandLogo, setBrandLogo]= useState<any>();
    const [brandLogoLoader, setBrandLogoLoader] = useState(false);
    const [brandSubColor, setBrandSubColor] = useState();

    useEffect(() => {
        if(clientId) {
            dispatch(getClientAccountInfo(clientId))
        }
    }, [clientId])

    useEffect(() => {
        initBrancSubColor();
    }, [accountInfo])

    useEffect(() => {     
        let clientAccountInfoData = Object.fromEntries(
            Object.entries(clientAccountInfo).filter(([_, value]) => value !== null)
          );      
        setValue("step1", clientAccountInfoData);
        setValue("step1.companyName", clientAccountInfoData.companyName);
        setValue("step1.industry", clientAccountInfoData.industry);
        setValue("step1.language", clientAccountInfoData.language);
        setValue("step1.typeface", clientAccountInfoData.typeface);
        setValue("step1.primaryBrandColor", clientAccountInfoData.primaryBrandColor);
        setValue("step1.secondaryBrandColor", clientAccountInfoData.secondaryBrandColor);
        setValue("step1.achDisclaimerMessage", clientAccountInfoData.achDisclaimerMessage);
        setValue("step1.creditCardDisclaimerMessage", clientAccountInfoData.creditCardDisclaimerMessage);
        initBrancSubColor();
        if (clientAccountInfo) {
        trigger("step1")
       }
    }, [clientAccountInfo])

    const getLogo = async()=>{ 
        setBrandLogoLoader(true);
        setBrandLogo("");
        await get(`Client/${clientId}/logo`).then((res:any)=>{ 
            if (res.status === 200) {    
                setBrandLogo(res.data); 
                setBrandLogoLoader(false);
            } else {    
                setBrandLogo("");
                setBrandLogoLoader(false);
            }
        }).catch((error: any) => { 
            setBrandLogo("");
            setBrandLogoLoader(false);
        }).finally(() => { 
            setBrandLogoLoader(false);
        })
    }

    useEffect(()=>{
        if(clientId){
            getLogo();  
        }
    },[clientId])

    const initBrancSubColor = async()=>{ 
       if (getValues("step1.primaryBrandColor") && accountInfo?.brandColor?.data) {
        let brandColor = accountInfo?.brandColor?.data.filter((x: any) => { return x.value == getValues("step1.primaryBrandColor").value});
        if (brandColor && brandColor.length > 0)
            setBrandSubColor(brandColor[0].brandSubColor);
       }
    }
 
    return (
        <>
          <h2 className="h3">Configure Account</h2>
            <p>
                Select the company name to map the existing information and to
                configure the client portal.
            </p>
            {accountInfo.clientAccountInfo.status === "loading" ? <AccountPlaceholder/> :
            <Row>
                <Col lg={6}>
                    <fieldset>
                        <legend className="h4">Client Information</legend>
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="configureCompanyName">
                                Client Name
                            </Form.Label>
                            <div className={`drop-down-status ${accountInfo.clientName.status === "loading" ||  accountInfo.clientName.status === "failed"? "disable" :""}`}>
                            { accountInfo.clientName.status === "failed" ? <img className="status-filed error" src={Alert} alt="alert" />  :""}
                            { accountInfo.clientName.status === "loading" ? <div className="spinner-border text-info status-filed loading" role="status"></div>:""}
                            <Controller
                                name={`step1.companyName`}
                                control={control}
                                render={({ field }) => (
                                    <>
                                    <Select options={accountInfo?.clientName?.data}
                                    className={errors.step1 && (errors.step1 as any).companyName ? "form-field-error" : ""}
                                    defaultValue={field.value}
                                    value={field.value}
                                    onChange={(data: any) => {
                                        field.onChange(data);
                                        navigate(`/admin/configure-client/${data.value}`, {replace: true})
                                    }}
                                    ></Select>
                                </>
                                )}
                                />
                            </div>
                            {errors.step1 && (errors.step1 as any).companyName && errors.step1 && (errors.step1 as any).companyName.type === "required" && <span className="form-field-error-msg">{errors.step1 && (errors.step1 as any).companyName?.message?.toString()}</span>}
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="configureIndustry">
                                Industry
                            </Form.Label>
                            <div className={`drop-down-status ${accountInfo.accountType.status === "loading" ||  accountInfo.accountType.status === "failed" ? "disable" :""}`}>
                            { accountInfo.accountType.status === "failed" ? <img className="status-filed error" src={Alert} alt="alert" />  :""}
                            { accountInfo.accountType.status === "loading" ? <div className="spinner-border text-info status-filed loading" role="status"></div>:""}
                            <Controller
                                name={`step1.industry`}
                                control={control}
                                render={({ field }) => (
                                <>
                                    <Select options={accountInfo?.accountType?.data}
                                    className={errors.step1 && (errors.step1 as any).industry ? "form-field-error" : ""}
                                    defaultValue={field.value}
                                    value={field.value}
                                    onChange={(value: any) => {
                                        field.onChange(value);
                                    }}
                                    ></Select>
                                </>
                                )}
                            /></div>
                            {errors.step1 && (errors.step1 as any).industry && errors.step1 && (errors.step1 as any).industry.type === "required" && <span className="form-field-error-msg">{errors.step1 && (errors.step1 as any).industry?.message?.toString()}</span>}
                            
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="configureLanguage">
                                Language
                            </Form.Label>
                            <div className={`drop-down-status ${accountInfo.languageList.status === "loading" || accountInfo.languageList.status === "failed" ? "disable" :""}`}>
                            { accountInfo.languageList.status === "failed" ? <img className="status-filed error" src={Alert} alt="alert" />  :""}
                            { accountInfo.languageList.status === "loading" ? <div className="spinner-border text-info status-filed loading" role="status"></div>:""}
                            <Controller
                                name={`step1.language`}
                                control={control}
                                render={({ field }) => (
                                <>
                                    <Select options={accountInfo?.languageList?.data}
                                    className={errors.step1 && (errors.step1 as any).language ? "form-field-error" : ""}
                                    defaultValue={field.value}
                                    value={field.value}
                                    onChange={(value: any) => {
                                        field.onChange(value);
                                    }}
                                    ></Select>
                                </>
                                )}
                            /></div>
                            {errors.step1 && (errors.step1 as any).language && errors.step1 && (errors.step1 as any).language.type === "required" && <span className="form-field-error-msg">{errors.step1 && (errors.step1 as any).language?.message?.toString()}</span>}
                        </Form.Group>
                        {/* <Form.Group className="mb-3">
                            <Form.Label htmlFor="apiSecurityKey">
                                API Security Key (NMI Portal)
                            </Form.Label>
                            <Controller
                                name={"step1.apiSecurityKey"}
                                control={control}
                                render={({ field }) => (
                                    <Form.Control
                                        type="text"
                                        value={field.value}
                                        id="apiSecurityKey"
                                        placeholder="API Security Key"
                                        className={errors.step1 && (errors.step1 as any).apiSecurityKey ? "form-field-error" : ""}
                                        onChange={(e) => field.onChange(e.target.value)}
                                    />
                                )}
                            />
                            {errors.step1 && (errors.step1 as any).apiSecurityKey && errors.step1 && (errors.step1 as any).apiSecurityKey.type === "required" && <span className="form-field-error-msg">{errors.step1 && (errors.step1 as any).apiSecurityKey?.message?.toString()}</span>}
                        </Form.Group> */}
                        <p><strong>Configurable Messages</strong></p>
                        <p className="form-label">Consumer Profile Message</p>
                        <Form.Group className="mb-3">
                            <Row>
                                <Col md={2}>
                                    <Controller
                                        name={"step1.isConsumerProfileMessageEnabled"}
                                        control={control}
                                        render={({ field }) => (
                                            <Form.Check
                                                type="switch"
                                                id="idConsumerProfileMessageEnabled"
                                                label={field.value == true ? 'ON' : 'OFF'}
                                                value={field.value}
                                                checked={field.value}
                                                className="flex-shrink-1 me-3 mt-2"
                                                onChange={(e) => {
                                                    const value = e.target.checked;
                                                    field.onChange(value);
                                                    trigger("step1.consumerProfileMessage")
                                                    }
                                                }
                                            />
                                        )}
                                    />
                                </Col>
                                <Col  md={10}>
                                    <Controller
                                        name={"step1.consumerProfileMessage"}
                                        control={control}
                                        render={({ field }) => (
                                            <Form.Control
                                                type="text"
                                                value={field.value}
                                                id="configureConsumerProfileMessage"
                                                placeholder="(XXX) XXX-XXXX"
                                                className={errors.step1 && (errors.step1 as any).consumerProfileMessage ? "form-field-error" : ""}
                                                onChange={(e) => field.onChange(e.target.value)}
                                            />
                                        )}
                                    />
                                    {errors.step1 && (errors.step1 as any).consumerProfileMessage && errors.step1 && (errors.step1 as any).consumerProfileMessage.type === "required" && <span className="form-field-error-msg">{errors.step1 && (errors.step1 as any).consumerProfileMessage?.message?.toString()}</span>}
                                </Col>
                            </Row>
                            <Form.Text>
                                Message: Contact Client Service to Update Your Profile Information <strong>(XXX) XXX-XXXX</strong>
                            </Form.Text>
                        </Form.Group>
                        <p className="form-label">Consumer Home Page and One Time Payment Message</p>
                        <Form.Group className="mb-3">
                            <Row>
                                <Col md={2}>
                                    <Controller
                                        name={"step1.isConsumerHomeOneTimeMessageEnabled"}
                                        control={control}
                                        render={({ field }) => (
                                            <Form.Check
                                                type="switch"
                                                id="consumerHomeOneTimeMessageRadio"
                                                label={field.value == true ? 'ON' : 'OFF'}
                                                value={field.value}
                                                checked={field.value}
                                                className="flex-shrink-1 me-3 mt-2"
                                                onChange={(e) => {
                                                    const value = e.target.checked;
                                                    field.onChange(value);
                                                    trigger("step1.consumerHomeOneTimeMessage")
                                                    }
                                                }
                                            />
                                        )}
                                    />
                                </Col>
                                <Col  md={10}>
                                    <Controller
                                        name={"step1.consumerHomeOneTimeMessage"}
                                        control={control}
                                        render={({ field }) => (
                                            <Form.Control
                                                type="text"
                                                value={field.value}
                                                id="configureConsumerHomeOneTimeMessage"
                                                placeholder="(XXX) XXX-XXXX"
                                                className={errors.step1 && (errors.step1 as any).consumerHomeOneTimeMessage ? "form-field-error" : ""}
                                                onChange={(e) => field.onChange(e.target.value)}
                                            />
                                        )}
                                    />
                                    {errors.step1 && (errors.step1 as any).consumerHomeOneTimeMessage && errors.step1 && (errors.step1 as any).consumerHomeOneTimeMessage.type === "required" && <span className="form-field-error-msg">{errors.step1 && (errors.step1 as any).consumerHomeOneTimeMessage?.message?.toString()}</span>}
                                </Col>
                            </Row>
                            <Form.Text>
                                Message: Questions? Call this number <strong>(XXX) XXX-XXXX</strong>
                            </Form.Text>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <p className="form-label">Mask Account Number?</p>
                            <Controller
                                name={"step1.isConsumerMaskAccountEnabled"}
                                control={control}
                                render={({ field }) => (
                                    <Form.Check
                                        type="switch"
                                        id="consumerMaskedAccountRadio"
                                        label={field.value == true ? 'ON' : 'OFF'}
                                        value={field.value}
                                        checked={field.value}
                                        className="flex-shrink-1 me-3 mt-2"
                                        onChange={(e) => {
                                            const value = e.target.checked;
                                            field.onChange(value);
                                            }
                                        }
                                    />
                                )}
                            />
                            <Form.Text>
                                Ex. "1234567890" would show "******7890"
                            </Form.Text>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <p className="form-label">Customize Consumer Review ACH/Debit Card Disclaimer Message?</p>
                            {/* <Row>
                                <Col md={2}>
                                    <Controller
                                        name={"step1.isReviewACHDisclaimerEnabled"}
                                        control={control}
                                        render={({ field }) => (
                                            <Form.Check
                                                type="switch"
                                                id="configureConsumerReviewACHDisclaimer"
                                                label={field.value == true ? 'ON' : 'OFF'}
                                                value={field.value}
                                                checked={field.value}
                                                className="flex-shrink-1 me-3 mt-2"
                                                onChange={(e) => {
                                                    const value = e.target.checked;
                                                    field.onChange(value);
                                                    trigger("step1.achDisclaimerMessage")
                                                    }
                                                }
                                            />
                                        )}
                                    />
                                </Col>
                                <Col md={10}> */}
                                    <Controller
                                        name={"step1.achDisclaimerMessage"}
                                        control={control}
                                        render={({ field }) => (
                                            <Form.Control
                                                as="textarea"
                                                rows={8}
                                                value={field.value}
                                                id="configureachDisclaimerMessage"
                                                // className={errors.step1 && (errors.step1 as any).achDisclaimerMessage ? "form-field-error" : ""}
                                                onChange={(e) => field.onChange(e.target.value)}
                                            />
                                        )}
                                    />
                                    {/* {errors.step1 && (errors.step1 as any).achDisclaimerMessage && errors.step1 && (errors.step1 as any).achDisclaimerMessage.type === "required" && <span className="form-field-error-msg">{errors.step1 && (errors.step1 as any).achDisclaimerMessage?.message?.toString()}</span>} */}
                                {/* </Col>
                            </Row>
                            <Form.Text>
                                Default: <br></br>
                                By submitting this payment, I authorize [Company Name] to debit the bank account or charge the debit card indicated on this web form. I understand that in the event this transaction is returned by my financial institution, I may be subject to a returned item fee. I also understand that this authorization will remain in full force and effect until I notify [Company Name] at [Company Phone Number] or by updating the payment schedule via the online portal.  Changes to the authorized and/or scheduled payment(s) must be completed by 7:00 p.m. CST of the scheduled payment date for the change to be effective.
                            </Form.Text> */}
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <p className="form-label">Customize Consumer Review Credit Card Disclaimer Message?</p>
                            {/* Bug 42278: Venture Admin - Configure customer missing fields in Configure Account and Configure Notifications tabs */}
                            {/* <Row>
                                <Col md={2}>
                                    <Controller
                                        name={"step1.isReviewCreditDisclaimerEnabled"}
                                        control={control}
                                        render={({ field }) => (
                                            <Form.Check
                                                type="switch"
                                                id="configureConsumerReviewCreditDisclaimer"
                                                label={field.value == true ? 'ON' : 'OFF'}
                                                value={field.value}
                                                checked={field.value}
                                                className="flex-shrink-1 me-3 mt-2"
                                                onChange={(e) => {
                                                    const value = e.target.checked;
                                                    field.onChange(value);
                                                    trigger("step1.creditCardDisclaimerMessage")
                                                    }
                                                }
                                            />
                                        )}
                                    />
                                </Col>
                                <Col md={10}> */}
                                    <Controller
                                        name={"step1.creditCardDisclaimerMessage"}
                                        control={control}
                                        render={({ field }) => (
                                            <Form.Control
                                                as="textarea"
                                                rows={8}
                                                value={field.value}
                                                id="configurecreditCardDisclaimerMessage"
                                                // className={errors.step1 && (errors.step1 as any).creditCardDisclaimerMessage ? "form-field-error" : ""}
                                                onChange={(e) => field.onChange(e.target.value)}
                                            />
                                        )}
                                    />
                                    {/* {errors.step1 && (errors.step1 as any).creditCardDisclaimerMessage && errors.step1 && (errors.step1 as any).creditCardDisclaimerMessage.type === "required" && <span className="form-field-error-msg">{errors.step1 && (errors.step1 as any).creditCardDisclaimerMessage?.message?.toString()}</span>} */}
                            {/* </Col>
                            </Row>
                            <Form.Text>
                                Default:<br></br>
                                By submitting this payment, I authorize [Company Name] to charge the credit card indicated on this web form. I understand that this authorization will remain in full force and effect until I notify [Company Name] at [Company Phone Number] or by updating the payment schedule via the online portal.  Changes to the authorized and/or scheduled payment(s) must be completed by 7:00 p.m. CST of the scheduled payment date for the change to be effective.
                            </Form.Text> */}
                        </Form.Group>
                    </fieldset>
                </Col>
                <Col lg={6}>
                    <fieldset>
                        <legend className="h4">System Information</legend>
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="primaryBrandColor">
                                Select Primary Brand Color (Hex)
                            </Form.Label>
                            <div className={`drop-down-status`}>
                            { accountInfo.brandColor.status === "failed" ? <img className="status-filed error" src={Alert} alt="alert" />  :""}
                            { accountInfo.brandColor.status === "loading" ? <div className="spinner-border text-info status-filed loading" role="status"></div>:""}
                            <Controller
                                name={"step1.primaryBrandColor"}
                                control={control}
                                render={({ field }) => (
                                    <BrandSelector 
                                        id="primaryBrandColor"
                                        brandColors={accountInfo?.brandColor?.data}
                                        color={field.value} 
                                        className={errors.step1 && (errors.step1 as any).primaryBrandColor ? "form-field-error" : ""}
                                        onChangeColor={(color:any) => {  
                                            setValue("step1.secondaryBrandColor", null);
                                            setBrandSubColor(color.brandSubColor)
                                            field.onChange(color)}} 
                                    />
                                )}
                            /></div>
                            {errors.step1 && (errors.step1 as any).primaryBrandColor && errors.step1 && (errors.step1 as any).primaryBrandColor.type === "required" && <span className="form-field-error-msg">{errors.step1 && (errors.step1 as any).primaryBrandColor?.message?.toString()}</span>}
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="secondaryBrandColor">
                                Select Secoundary Brand Color (Hex)
                            </Form.Label>
                            <div className={`drop-down-status`}>
                            { accountInfo.brandColor.status === "failed" ? <img className="status-filed error" src={Alert} alt="alert" />  :""}
                            { accountInfo.brandColor.status === "loading" ? <div className="spinner-border text-info status-filed loading" role="status"></div>:""}
                            <Controller
                                name={"step1.secondaryBrandColor"}
                                control={control}
                                render={({ field }) => (
                                    <BrandSelector 
                                        id="secondaryBrandColor"
                                        brandColors={brandSubColor}
                                        color={field.value} 
                                        className={errors.step1 && (errors.step1 as any).secondaryBrandColor ? "form-field-error" : ""}
                                        onChangeColor={(color:any) => { 
                                            field.onChange(color)}} 
                                    />
                                )}
                            /></div>
                            {errors.step1 && (errors.step1 as any).secondaryBrandColor && errors.step1 && (errors.step1 as any).secondaryBrandColor.type === "required" && <span className="form-field-error-msg">{errors.step1 && (errors.step1 as any).secondaryBrandColor?.message?.toString()}</span>}
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="configureTypeface">
                                Typeface
                            </Form.Label>
                            <div className={`drop-down-status ${accountInfo.typeFace.status === "loading" || accountInfo.typeFace.status === "failed" ? "disable" :""}`}>
                            { accountInfo.typeFace.status === "failed" ? <img className="status-filed error" src={Alert} alt="alert" />  :""}
                            { accountInfo.typeFace.status === "loading" ? <div className="spinner-border text-info status-filed loading" role="status"></div>:""}
                             <Controller
                                name={`step1.typeface`}
                                control={control}
                                render={({ field }) => (
                                <>
                                    <Select options={accountInfo?.typeFace?.data}
                                    className={errors.step1 && (errors.step1 as any).typeface ? "form-field-error" : ""}
                                    defaultValue={field.value}
                                    value={field.value}
                                    onChange={(value: any) => {
                                        field.onChange(value);
                                    }}
                                    ></Select>
                                </>
                                )}
                            /></div>
                            {/* {errors.step1 && (errors.step1 as any).typeface && errors.step1 && (errors.step1 as any).typeface.type === "required" && <span className="form-field-error-msg">{errors.step1 && (errors.step1 as any).typeface?.message?.toString()}</span>} */}
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <p className="form-label">Client Logo Preview</p>
                            {/* If customerSelected */}                    
                                {brandLogoLoader ?
                                    <Skeleton width={"100%"} height={140} /> :
                                    <div className="app-client-logo-preview">
                                        <img src={clientId ? brandLogo ? `data:image/png;base64,${brandLogo}`:clientLogo: clientLogo} alt="Logo" />
                                    </div>}
                            {/* End If */}
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="configureClientURL">
                                Client Landing Page URL
                            </Form.Label>
                            <Controller
                                name={"step1.landingPageURL"}
                                control={control}
                                render={({ field }) => (
                                    <Form.Control
                                        type="text"
                                        id="configureClientURL"
                                        value={field.value}
                                        placeholder="Client Landing Page URL"
                                        className={errors.step1 && (errors.step1 as any).landingPageURL ? "form-field-error" : ""}
                                        onChange={(e) => field.onChange(e.target.value)}
                                    />
                                )}
                            />
                            {errors.step1 && (errors.step1 as any).landingPageURL && errors.step1 && (errors.step1 as any).landingPageURL.type === "required" && <span className="form-field-error-msg">{errors.step1 && (errors.step1 as any).landingPageURL?.message?.toString()}</span>}
                            {errors.step1 && (errors.step1 as any).landingPageURL && errors.step1 && (errors.step1 as any).landingPageURL.type === "url" && <span className="form-field-error-msg">{errors.step1 && (errors.step1 as any).landingPageURL?.message?.toString()}</span>}
                        </Form.Group>
                        <p><strong>Doc View Setup</strong></p>
                        <div className="app-config-item p-3 mb-3">
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="configureDocViewCustNumber">
                                    Client Number
                                </Form.Label>
                                <Controller
                                    name={"step1.docViewCustNumber"}
                                    control={control}
                                    render={({ field }) => (
                                        <Form.Control
                                            type="text"
                                            value={field.value}
                                            id="configureDocViewCustNumber"
                                            placeholder="Client Number"
                                            maxLength={10}
                                            className={errors.step1 && (errors.step1 as any).docViewCustNumber ? "form-field-error" : ""}
                                            onChange={(e) => field.onChange(e.target.value)}
                                        />
                                    )}
                                />
                                {errors.step1 && (errors.step1 as any).docViewCustNumber && errors.step1 && (errors.step1 as any).docViewCustNumber.type === "required" && <span className="form-field-error-msg">{errors.step1 && (errors.step1 as any).docViewCustNumber?.message?.toString()}</span>}
                            </Form.Group>
                            {/* <Form.Group className="mb-3">
                                <Form.Label htmlFor="configureDocViewInitVector">
                                    Init Vector
                                </Form.Label>
                                <Controller
                                    name={"step1.docViewInitVector"}
                                    control={control}
                                    render={({ field }) => (
                                        <Form.Control
                                            type="text"
                                            value={field.value}
                                            id="configureDocViewInitVector"
                                            placeholder="Init Vector"
                                            className={errors.step1 && (errors.step1 as any).docViewInitVector ? "form-field-error" : ""}
                                            onChange={(e) => field.onChange(e.target.value)}
                                        />
                                    )}
                                />
                                {errors.step1 && (errors.step1 as any).docViewInitVector && errors.step1 && (errors.step1 as any).docViewInitVector.type === "required" && <span className="form-field-error-msg">{errors.step1 && (errors.step1 as any).docViewInitVector?.message?.toString()}</span>}
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="configureDocViewSecretKey">
                                    Secret Key
                                </Form.Label>
                                <Controller
                                    name={"step1.docViewSecretKey"}
                                    control={control}
                                    render={({ field }) => (
                                        <Form.Control
                                            type="text"
                                            value={field.value}
                                            id="configureDocViewSecretKey"
                                            placeholder="Secret Key"
                                            className={errors.step1 && (errors.step1 as any).docViewSecretKey ? "form-field-error" : ""}
                                            onChange={(e) => field.onChange(e.target.value)}
                                        />
                                    )}
                                />
                                {errors.step1 && (errors.step1 as any).docViewSecretKey && errors.step1 && (errors.step1 as any).docViewSecretKey.type === "required" && <span className="form-field-error-msg">{errors.step1 && (errors.step1 as any).docViewSecretKey?.message?.toString()}</span>}
                            </Form.Group> */}
                        </div>
                    </fieldset>
                </Col>
            </Row>      
            } 
        </>     
    );
};

export default ConfigureAccount;
