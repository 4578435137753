import React from "react";
import Icon from "../../components/Common/Icons/Icons";
import { LoadingWaitModal } from "../../components/Common/Modal/LoadingWaitModal";
import { Container, Row, Col, Alert, Button } from "react-bootstrap";
import ProfileInformation from "./ProfileInformation";
import PaymentMethods from "./PaymentMethods";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { getUserInfo } from "../../api/user";
import { shallowEqual } from "react-redux";
import { useEffect, useState } from "react";
import { setUser, initUserDetails } from "../../features/account/accountSlice";
import { getAllMethods, getAcitveClientMethods } from "../../api/paymentMethods";
import {
  initPaymentMethods,
  initUserPaymentMethod,
} from "../../features/payment/paymentSlice";
import { getUserPaymentMethods } from "../../api/userPaymentMethods";
import { Link } from "react-router-dom";
import { returnToHome } from "../../util/returnToHome";

const initializeUserInfo = async (dispatch: any, email: any) => {
  let response: any = await getUserInfo();
  await dispatch(setUser(response));
  await dispatch(initUserDetails(JSON.parse(response.data.userDetails)));
  return response;
};

const initializePaymentMethods = async (dispatch: any) => {
  let response = await getAcitveClientMethods();
  await dispatch(initPaymentMethods(response.data));
};

const initializeUserPaymentMethods = async (dispatch: any) => {
  try{
    let response = await getUserPaymentMethods();
    if(response.status === 200){
      dispatch(initUserPaymentMethod(response.data));
    }
  }
  catch(error){
    console.error('initUserPaymentMethod error: ', error)
  }

};

function ConsumerProfile() {
  const [loading, setLoading] = useState(false);
  const [showUserDetails, setShowUserDetails] = useState(false);
  const [showPayment, setShowPayment] = useState(false);
  const dispatch = useAppDispatch();
  const paymentSolution = localStorage.getItem("pay") === "true" ? true : false;

  const { accountNumber, user, userDetails, decodedData, isAuthenticated } = useAppSelector(
    (state) => ({ ...state.account }),
    shallowEqual
  );

  useEffect(() => {
    async function initData() {
      setLoading(true);
      try{
        // let userDetails = await initializeUserInfo(dispatch, (decodedData as any).MemberNumber);
        setShowUserDetails(true);
        if(paymentSolution){
        await initializePaymentMethods(dispatch);
        setShowPayment(true);
        await initializeUserPaymentMethods(dispatch);
        }
      }
      catch(error){
        console.error(error)
      }
      finally{
        setLoading(false);
      }

    }
    if (isAuthenticated) {
      initData();
    }
  }, []);

  return (
    <main id="mainContent">
      <Container>
        <LoadingWaitModal show={loading} size="lg" hide={() => { }} />
        <h1 className="h2">My Profile</h1>
        {
          user && user.enableProfileMessage &&
          <Alert variant="info">
            <Icon
              name="info"
              optionalClass="p-1 rounded-circle bg-light"
              size={36}
            />{" "}
            <label>Contact Customer Service to Update Your Profile Information <Alert.Link href={`tel:${user.profileMessage}`}>{user.profileMessage}</Alert.Link>.  </label>
          </Alert>
        }
        <div className="mb-3">
          <Button variant="outline-primary" onClick={() => { returnToHome(undefined, accountNumber == '' || null ? true : false) }}>
            <Icon name="arrow-left" optionalClass="me-2" size={20}></Icon>
            Back
          </Button>
        </div>
        <Row>
          <Col lg={6}>
            {showUserDetails && <ProfileInformation userDetails={userDetails} />}
          </Col>
          {paymentSolution && <Col lg={6}>{showPayment && <PaymentMethods setLoading={setLoading} />}</Col>}
        </Row>
      </Container>
    </main>
  );
}
export default ConsumerProfile;
