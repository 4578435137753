// Components
import { Card, Col, Row } from "react-bootstrap";
import { useAppSelector } from "../../app/hooks";
import Icon from "../../components/Common/Icons/Icons";
import moment from "moment";

// format util
import { formatDate, formatMoney } from "../../util/formatUtil";
import GeneralInfoPlaceholder from "./placeholders/generalInfo";

function AccountInformation(props: any) {

  const user = useAppSelector(
    (state) => ({ ...state.onetimepayment.data }),
  );
  const loader = useAppSelector(
    (state) => ({ ...state.onetimepayment }),
  );

  return (
    <Card className="mb-3 shadows-light">
      <Card.Body>
        <Card.Title as="h2" className="app-card-title">
          <Icon name="folder" />
          General Account Information
        </Card.Title>
        {props.parentLoading || loader.status === "loading" || props.satementLoader === true ?
        <GeneralInfoPlaceholder/>:
        <Row>
          {user?.generalInformation?.map((item: any, index:any) => {
            return (
              <Col lg={6} key={index}>
                <p className="mb-1">{item.labelText}</p>
                <p>
                  <strong>{item.attributeType === "Date" ? moment(item.formattedValue, 'YYYY-MM-DD').format('MM/DD/YYYY') : item.formattedValue}</strong>
                </p>
              </Col>
            )
          })}

          {/* <Col lg={6}>
            <p className="mb-1">Current Balance</p>
            <p>
              <strong>{formatMoney(props.paymentDetails.PrincipalBalance)}</strong>
            </p>
          </Col>
          <Col lg={6}>
            <p className="mb-1">Past Due Amount</p>
            <p>
              <strong>{formatMoney(props.paymentDetails.PastDueAmount)}</strong>
            </p>
          </Col>
          <Col lg={6}>
            <p className="mb-1">Payment Amount Due</p>
            <p>
              <strong>{formatMoney(props.paymentDetails.PaymentDue)}</strong>
            </p>
          </Col>
          <Col lg={6}>
            <p className="mb-1">Bill Due Date</p>
            <p>
              <strong>{formatDate(props.statement.dueDate)}</strong>
            </p>
          </Col>
          <Col lg={6}>
            <p className="mb-1">Last Payment Amount</p>
            <p>
              <strong>{formatMoney(props.payment.amount)}</strong>
            </p>
          </Col>
          <Col lg={6}>
            <p className="mb-1">Next Payment Due Date</p>
            <p>
              <strong>{formatDate(props.statement.scheduledDate)}</strong>
            </p>
          </Col> */}
        </Row>}
      </Card.Body>
    </Card>
  );
}
export default AccountInformation;
