//@ts-nocheck
import { useState,useMemo, useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { shallowEqual } from "react-redux";
import Icon from "../../components/Common/Icons/Icons"
import { useNavigate,Link } from "react-router-dom";
import { Container, Button, Accordion, Form, Card, Badge } from "react-bootstrap";
import { sortDate } from "../../components/Common/Table/DateSort";
import {
	FromToDateRangeFilter,
	Table,
	TableFilter,
	TableHeader,
} from "../../components/Common/Table";
import { DatePicker } from "../../components/Common/DatePicker";
import { useFilterForm } from "../../hooks/useFilterForm";
import { Controller } from "react-hook-form";
import breakPoints from "../../scss/abstracts/_variables.scss";
import { LoadingWaitModal } from "../../components/Common/Modal";

// Utils
import moment from "moment";
import { formatDate } from "../../util/formatUtil";
// Api
import { getAllUserAccounts } from "../../api/userAccount";

// Store
import {
	setAccountId,
	setAccountNumber
} from "../../features/account/accountSlice";
import {
	setAccountType,
	setProfile,
	setUserId
  } from "../../features/consumer/consumerSlice";

import { $toast } from "../../util/toastUtil";
import { isArray } from "lodash";

function ClientAdminMain() {

	const navigate = useNavigate()
	const dispatch = useAppDispatch()

	const [startDate, setStartDate] = useState<Date>(moment().subtract(6,'months').toDate());
	const [endDate, setEndDate] = useState<Date>(moment().toDate());
	const [maxDate, setMaxDate] = useState(moment().toDate());
	const { permissions } = useAppSelector(
		(state:any) => ({ ...state.permissions }),
		shallowEqual
	  );

	let clientSessionData = JSON.parse(`${sessionStorage.getItem("client")}`) ? JSON.parse(`${sessionStorage.getItem("client")}`) : [];
	let clientFilterData: any = {};
	if (isArray(clientSessionData)) {
		clientSessionData?.forEach((item: any) => {
			clientFilterData[item.id] = item.value;
		});
	}
	const initialTableSearch = {
		createDate: {
			from: clientFilterData?.createDate?.from ? moment(clientFilterData.createDate.from).toDate() : startDate,
			to: clientFilterData?.createDate?.to ? moment(clientFilterData.createDate.to).toDate() : endDate
		},
		clientName: clientFilterData?.clientName ? clientFilterData.clientName : "",
		accountNumber: clientFilterData?.accountNumber ? clientFilterData.accountNumber : "",
		accountType: clientFilterData?.userAccountType ? clientFilterData.userAccountType : "",
		accountStatus: clientFilterData?.accountStatus ? clientFilterData.accountStatus : ""
	};

	const { filters, filterControl, registerFilter, clearFilter, resetFilterForm, getValuesFilter } = useFilterForm({
		defaultValues: initialTableSearch
	});

	useEffect(() => {
		setStartDate(clientFilterData?.createDate?.from ? moment(clientFilterData.createDate.from).toDate() : startDate);
		setEndDate(clientFilterData?.createDate?.to ? moment(clientFilterData.createDate.to).toDate() : endDate);
	}, [])

	useEffect(() => {
		sessionStorage.setItem('client', JSON.stringify(filters));
	}, [filters])

	const [loading, setLoading] = useState(false);

	const [tableData, setTableData] = useState([]);
	const filterTypes = useMemo(
		() => ({
			fromToDateRangeFilter: FromToDateRangeFilter,
		}),
		[]
	);
	// link to Information
	const linkToInformation = (detail:any) => {
		dispatch(setAccountId(detail.accountId));
		dispatch(setUserId(detail.userId));
		dispatch(setAccountNumber(detail.accountNumber));
		dispatch(setAccountType(detail.accountType));
		dispatch(setProfile(JSON.parse(detail.userDetails)));
		navigate("/client/accountinformation", {
			replace: true
		  });
	};

	// makePayment
	const makePayment = (detail:any) => {
		dispatch(setUserId(detail.userId));
		dispatch(setAccountId(detail.accountId));
		dispatch(setAccountNumber(detail.accountNumber));
		dispatch(setAccountType(detail.accountType));
		dispatch(setProfile(JSON.parse(detail.userDetails)));
		navigate("/client/makepayment/quick", {
			replace: true
		  });
	};

	useEffect(() => {
		setLoading(true)
		getAllUserAccounts().then((res:any)=>{
			setTableData(res.data)
		}).catch((error:any)=>{
			showErrorToast(error.response?error.response.data:error.message)
		}).finally(()=>{
			setLoading(false)
		})
	  }, []);

	const showErrorToast = (msg:string)=>{
		$toast.danger({
			title: 'Failed',
			body: msg
		})
	}

	const columns = [
		{
			Header: ({ column }:any) => (
				<TableHeader column={column}>Customer Name</TableHeader>
			),
			accessor: "clientName"
		},
		{
			Header: ({ column }:any) => (
				<TableHeader column={column}>Account Number</TableHeader>
			),
			accessor: "accountNumber",
			Cell: (props:any) =>
			( props?.row?.original?.accountStatus=="Active" ?
			<Button variant="link"
			onClick={() =>linkToInformation(props?.row?.original)}>{props?.row?.original?.accountNumber}</Button>
			   :
			   <span className="account-status_inactive">{props?.row?.original?.accountNumber}</span>
			)},
		{
			Header: ({ column }:any) => (
				<TableHeader column={column}>Date Account Started</TableHeader>
			),
			accessor: "createDate",
			filter: "fromToDateRangeFilter",
			sortType: sortDate,
		},
		{
			Header: ({ column }:any) => (
				<TableHeader column={column}>Loan/Service Type</TableHeader>
			),
			accessor: "accountType"
		},
		{
			Header: ({ column }:any) =>(
				<TableHeader column={column}>Account Status</TableHeader>
			),
			accessor: "accountStatus",
			Cell: (props:any) => {
				if (props?.row?.original?.accountStatus=="Active") {
					return <span className="text-success">Active</span>
				} else {
					return <span className="text-danger">Inactive</span>
				}
			}
		},
		{
			Header: () => (
				<div className="text-start">Make Payment</div>
			),
			id: "viewDetails",
			cellClassName: "text-start",
			Cell: (props:any) =>
				 ( (props?.row?.original?.accountStatus=="Active" && permissions.canViewPayment) ?
					<Button variant="outline-primary"
					 onClick={
						() =>makePayment(props?.row?.original)
					 }
					>
						Make Payment
				    </Button>
					:
					<Button disabled variant="outline-danger">Make Payment</Button>
				 )
		},
	];

	const tablet = useMemo(
		() => Number(breakPoints.tablet.replace("px", "")),
		[]
	);

	const memoTableData = useMemo(()=>{
		let _data = []
		if (tableData && Array.isArray(tableData)) {
			tableData.forEach((item:any) => {
				let _item = {
					createDate: formatDate(item.createDate),
					clientName: item.clientName,
					accountNumber: item.accountNumber,
					accountId: item.accountId,
					accountType: item.userAccountType,
					accountStatus: item.accountStatus,
					userDetails:item.userDetails,
					userId:item.userId
				};
				_data.push(_item)
			})
			return _data
		}else{
			return []
		}
	},[tableData])

	const resetTableSearch = () => {
		const _start = moment().subtract(6,'months').toDate()
		const _end = moment().toDate()
		setEndDate(_end)
		setStartDate(_start)

		const initialTableSearch = {
			createDate: {
				from: _start,
				to: _end
			},
			clientName: "",
			accountNumber: "",
			accountType: "",
			accountStatus: ""
		};
		sessionStorage.setItem('client', JSON.stringify(initialTableSearch));
		resetFilterForm(initialTableSearch)
		filterFieldsChange()
	  }

	const tableRef = useRef<any>()

	const filterFieldsChange = ()=>{
		tableRef?.current?.gotoFirstPage()
	}

	return (
		<Container>
			<LoadingWaitModal show={loading} size={"lg"} />

			<h1 className="h2">Customers</h1>

			{/*
			POST MVP, we have to enable this section
			<div className="app-card-container">
				<div className="app-card mb-3">
					<Card className="shadows-light">
						<Card.Body>
							<PaymentAnalytics />
						</Card.Body>
					</Card>
				</div>
				<div className="app-card mb-3">
					<Card className="shadows-light">
						<Card.Body>
							<PaymentAnalytics />
						</Card.Body>
					</Card>
				</div>
			</div>
			*/}
			<div id="ClientsTableFilters" className="app-panel-container">
				<div className="app-left-panel">
					<TableFilter autoShowMinWidth={tablet} onClear={resetTableSearch}>
						<Accordion
							className="app-accordions" defaultActiveKey={['0','1','2','3','4']} alwaysOpen
						>
							<Accordion.Item eventKey="0">
								<Accordion.Header>Customer Name</Accordion.Header>
								<Accordion.Body>
									<Form.Control {...registerFilter("clientName",{onChange: (e) => filterFieldsChange()})} />
								</Accordion.Body>
							</Accordion.Item>
							<Accordion.Item eventKey="1">
								<Accordion.Header>Account Number</Accordion.Header>
								<Accordion.Body>
									<Form.Control {...registerFilter("accountNumber",{onChange: (e) => filterFieldsChange()})} />
								</Accordion.Body>
							</Accordion.Item>
							<Accordion.Item eventKey="2">
								<Accordion.Header>Date Account Started</Accordion.Header>
								<Accordion.Body>
									<Controller
										name="createDate.from"
										control={filterControl}
										render={({ field }) => (
										<div className="text-center">
											<DatePicker
											containerClassName="mb-1"
											selectedDate={field.value}
											placeholderText="MM/DD/YYYY"
											onChange={(date: Date | undefined) => {
													if(date){
														field.onChange(date);
													}else{
														field.onChange("");
													}
													setStartDate(date);
													setEndDate(getValuesFilter("createDate.to"));
													filterFieldsChange()
											}}
											/>
										</div>
										)}
									/>
									<Controller
										name="createDate.to"
										control={filterControl}
										render={({ field }) => (
										<div className="text-center">
											<DatePicker
											selectedDate={field.value}
											placeholderText="MM/DD/YYYY"
											minDate={startDate}
											onChange={(date: any) => {
												if(date){
													field.onChange(date);
												}else{
													field.onChange("");
												}
												setStartDate(getValuesFilter("createDate.from"));
												setEndDate(date);
												filterFieldsChange()
											}}
											/>
										</div>
										)}
									/>
								</Accordion.Body>
							</Accordion.Item>
							<Accordion.Item eventKey="3">
								<Accordion.Header>Loan/Service Type</Accordion.Header>
								<Accordion.Body>
									<Form.Control {...registerFilter("accountType",{onChange: (e) => filterFieldsChange()})} />
								</Accordion.Body>
							</Accordion.Item>
							<Accordion.Item eventKey="4">
								<Accordion.Header>Account Status</Accordion.Header>
								<Accordion.Body>
									<Form.Control {...registerFilter("accountStatus",{onChange: (e) => filterFieldsChange()})} />
								</Accordion.Body>
							</Accordion.Item>
						</Accordion>
					</TableFilter>
				</div>
				<div className="app-right-panel">
					<Table
              			ref={tableRef}
						columns={columns}
						data={memoTableData}
						filters={filters}
						filterTypes={filterTypes}
						sortable
						bordered
						hover
						responsive
						initialFilters={[{ id: 'createDate',value: {from: startDate,to: endDate}}]}
					/>
				</div>
			</div>
		</Container>
	);
}
export default ClientAdminMain;
