import type { AxiosResponse } from "axios";

export function downloadFile(res: AxiosResponse) {
  let blob = new Blob([res.data], { type: res.data.type });

  // Create blob url
  const blobURL = window.URL.createObjectURL(blob);

  const tempLink = document.createElement("a");
  tempLink.style.display = "none";
  tempLink.href = blobURL;
  tempLink.setAttribute("download", decodeURI(Date.now() + ".pdf"));

  // Some browsers do not support HTML5's download attribute
  if (typeof tempLink.download === "undefined") {
    tempLink.setAttribute("target", "_blank");
  }

  document.body.appendChild(tempLink);
  tempLink.click();
  document.body.removeChild(tempLink);

  // Release blob url
  window.URL.revokeObjectURL(blobURL);
}

export function openPdfFile(res: AxiosResponse) { 
  let blob = new Blob([res.data], { type: res.data.type });

  // Create blob url
  const blobURL = window.URL.createObjectURL(blob);
  sessionStorage.setItem('bloburl',blobURL)


  // const tempLink = document.createElement("a");
  // tempLink.style.display = "none";
  // tempLink.href = blobURL;
  // //tempLink.setAttribute("download", decodeURI(Date.now() + ".pdf"));

  // // Some browsers do not support HTML5's download attribute
  // //if (typeof tempLink.download === "undefined") {
  // tempLink.setAttribute("target", "_blank");
  // //}

  // document.body.appendChild(tempLink);
  // tempLink.click();
  // document.body.removeChild(tempLink);

  // // Release blob url
  // window.URL.revokeObjectURL(blobURL);

  // Open new tab
  //const newWindow = window.open();
  //if (newWindow) {
  //  const tempLink = document.createElement("a");
  //  tempLink.style.display = "none";
  //  tempLink.href = blobURL;
  //  tempLink.setAttribute("target", "_blank");

  //  document.body.appendChild(tempLink);
  //  tempLink.click();
  //  document.body.removeChild(tempLink);

  //  // Release blob url
  //  window.URL.revokeObjectURL(blobURL);
  //} else {
  //  alert("Can not open a new window");
  //}
}
