import Dropdown from "react-bootstrap/Dropdown";
import { Link } from "react-router-dom";
import Icon from "../Common/Icons/Icons";
import { useAppSelector } from "../../app/hooks";
import { shallowEqual } from "react-redux";
import { useNavigate } from "react-router-dom";

function UserMenu() {

  const { isAuthenticated } = useAppSelector(
    (state: any) => ({ ...state.account }),
    shallowEqual
  );

  const navigate = useNavigate();

  function handleLogout() {
    navigate('/logout', { replace: true });
    navigate(0)
  }

  return (
    isAuthenticated && (
      <Dropdown align="end" className="user-menu">
        <Dropdown.Toggle
          className="btn-sm"
          variant="link"
          id="dropdown-basic"
          aria-label="User Menu"
        >
          <Icon name="account-circle" size={32} />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {/* <div className="user-login-greet">
          <p className="size14-font">Last Visit: X/X/2022 8:03 AM</p>
        </div>
        <Dropdown.Divider /> */}
          <Dropdown.Item as={Link} to="profile">
            Profile
          </Dropdown.Item>
          <Dropdown.Item onClick={() => {
            sessionStorage.setItem('loggedOut', 'true');
            handleLogout()
          }}>
            Logout
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    )
  );
}

export default UserMenu;
