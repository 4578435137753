import Skeleton from "react-loading-skeleton";
import { Col, Form, Row } from "react-bootstrap";
import { Table as BSTable, InputGroup, Button } from "react-bootstrap";

const UserListsPlaceholder = () => {

    return (
        <Row> 
            <div className="px-1">
                <BSTable responsive>
                    <thead>
                        <tr>
                            <th>
                                <Skeleton width={120} height={35} />
                            </th>
                            <th>
                                <Skeleton width={120} height={35} />
                            </th>
                            <th>
                                <Skeleton width={120} height={35} />
                            </th>
                            <th>
                                <Skeleton width={120} height={35} />
                            </th>
                            <th> 
                                <Skeleton width={80} height={35} /> 
                            </th>
                            <th>
                                <Skeleton width={120} height={35} />
                            </th>
                            <th>
                                <Skeleton width={80} height={35} />
                            </th>
                            <th>
                                <div className="text-end">
                                    <Skeleton width={80} height={35} />
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {[...Array(8)].map((item:any, index:any) => {
                            return (
                                <tr key={index}>
                                    <td>
                                        <Skeleton width={120} height={25} />
                                    </td>
                                    <td>
                                        <Skeleton width={120} height={25} />
                                    </td>
                                    <td>
                                        <Skeleton width={120} height={25} />
                                    </td>
                                    <td>
                                        <Skeleton width={120} height={25} />
                                    </td>
                                    <td>
                                         <Skeleton width={80} height={25} />
                                    </td>
                                    <td> 
                                    <Skeleton width={120} height={25} />
                                    </td>
                                    <td>
                                    <Skeleton width={80} height={25} />
                                    </td>
                                    <td>
                                        <div className="text-end">
                                        <Skeleton width={80} height={25} />
                                        </div>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </BSTable>
            </div>
        </Row>
    );
};

export default UserListsPlaceholder;