import { Card, Col, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Icon from "../../components/Common/Icons/Icons";
import { useNavigate } from "react-router-dom";
import { unenrolledAutopay } from "../../api/payment";
import { $toast } from "../../util/toastUtil";
import { v4 as uuidv4 } from "uuid";
import { useAppSelector } from "../../app/hooks";
import { ROLE } from "../../routes/Roles";
import { shallowEqual } from "react-redux";
import { ConfirmModal } from "../../components/Common/Modal";
import { useState } from "react";

function SetupAutoPay({ hasAutoPay }: { hasAutoPay: boolean }) {

	const navigate = useNavigate();
	const [reviewUnEnrollAutoPay, setReviewUnEnrollAutoPay] = useState(false);

	const { user } = useAppSelector(
		(state) => ({ ...state.account }),
		shallowEqual
	);

	const showToast = (msg: string, icon = "close", type = "danger", title = "Failed") => {
		$toast.showToast({
			id: uuidv4(),
			autohide: true,
			delay: 5000,
			icon,
			type,
			title,
			body: msg
		})
	}

	const handleUnEnrollAutoPay = (() => {
		unenrolledAutopay().then((res: any) => {
			if (res.status === 200) {
				switch (user.role.roleName) {
					case ROLE.Consumer:
						navigate("/consumer/autopay/unrolled", {
							replace: true
						});
						break;
					case ROLE.ClientSuperAdmin:
					case ROLE.ClientAdmin:
						navigate("/client/autopay/unrolled", {
							replace: true
						});
						break;
				}
			} else {
				showToast(res.response ? res.response.data : res.message)
			}
		}).catch((error: any) => {
			showToast(error.response ? error.response.data : error.message)
		})
	})

	return (
		<>
			<Card className="mb-3 shadows-light">
				<Card.Body>
					<Card.Title as="h2" className="app-card-title">
						<Icon name="calendar" />
						Set-up Autopay
					</Card.Title>
					<Row>
						<Col sm={6}>
							{
								hasAutoPay &&
								<p>
									You are <strong>enrolled</strong> in autopay
								</p>
							}
							{
								!hasAutoPay &&
								<p>
									You are <strong>not enrolled</strong> in autopay
								</p>
							}
						</Col>
						{/* commented the below code to hide autopay enroll toggle button. task-id #43718*/}
						{/* <Col sm={6}>
							<Form.Check
								type="switch"
								id="configAutoPay"
								label={hasAutoPay ? 'Enrolled' : "Not Enrolled"}
								checked={hasAutoPay}
								readOnly
								aria-readonly
								onChange={() => {
									if (!hasAutoPay) {
										navigate('../autopay')
									} else {
										setReviewUnEnrollAutoPay(true);
									}
								}}
							/>
						</Col> */}
					</Row>
					<Link to="../autopay" className="btn btn-outline-primary">
						Manage AutoPay
					</Link>
				</Card.Body>
			</Card>
			<ConfirmModal
				show={reviewUnEnrollAutoPay}
				title="Review AutoPay"
				submitBtnText="Yes, Proceed"
				onCancel={() => setReviewUnEnrollAutoPay(false)}
				onConfirm={handleUnEnrollAutoPay}
			>
				<>
					<Row>
						<Col sm={12}>
							<p className="mb-1">Are you sure, you want to unenroll autopay?</p>
						</Col>
					</Row>
				</>
			</ConfirmModal></>

	);
}
export default SetupAutoPay;
