import { createAsyncThunk } from '@reduxjs/toolkit';
import { get } from '../util/request';

export const getStatement = function () {
    return get(`/Statement`)
}

export const getStatementWithAutoPayEnroll = function () {
    return get(`/Statement/GetStatementWithAutoPayEnroll`)
}

export const getPaymentStatement = createAsyncThunk("paymentStatus", async () => {
    const response = await get(`Statement/StatementWithBillingAttribute`);
    return response.data;
});
