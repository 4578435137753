import { Col, Form, Row } from "react-bootstrap";
import { Controller, useFieldArray } from "react-hook-form";
import React, { FC, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";

import { LoadingWaitModal } from "../../components/Common/Modal";
import NotificationPlaceholder from "./placeholders/notificationPlaceholder";
import Skeleton from "react-loading-skeleton";
import { getConfigureNotifications } from "../../api/configureClient";
import { shallowEqual } from "react-redux";

type props = {
    control: any,
    errors: any,
    setValue: any,
    getValues: any,
    trigger: any,
    setStep: any
};

const ConfigureNotifications: FC<props> = ({control, errors, setValue, getValues, trigger, setStep}) => {
    const dispatch = useAppDispatch();
    const getNotification = useAppSelector((state: any) => state.getClientNotification, shallowEqual)

    const { fields, append, remove, move } = useFieldArray({
      control,
      name: "step2.getNotification"
    });

    useEffect(()=>{
      const clientId = getValues("step1.companyName.value")
      if(clientId) {
        dispatch(getConfigureNotifications(clientId))
      }
    },[])

    useEffect(()=>{
      if(getNotification?.status === 'succeeded'){
      setValue("step2.getNotification", getNotification.data.clientNotificaitonItems);
      setValue("step2.clientId", getValues("step1.companyName.value"));
      setValue("step2.dmClientID", getNotification.data.dmClientId ? getNotification.data.dmClientId: '');
      setValue("step2.dmFromAddress", getNotification.data.fromAddress);
      setValue("step2.dmFromName", getNotification.data.fromName);
      }
    },[getNotification.status])


    return (
        <>
            <h2 className="h3">Configure Notifications</h2>
              <fieldset>
                <legend className="h4">Email Notifications</legend>
                <p>Configure which notifications will be sent out via Direct Messaging API. You have the option to enable individual email notifications and/or continue to the next page.</p>
                {/* <p className="form-label">Enable/Disable all email notifications</p> */}
                {getNotification.status === "loading" ?
                  <NotificationPlaceholder />  :
                  <Row>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                          <Form.Label htmlFor="configureDMClientID">
                              Client ID
                          </Form.Label>
                          <Controller
                              name={"step2.dmClientID"}
                              control={control}
                              render={({ field }) => (
                                  <Form.Control
                                      type="text"
                                      value={field.value}
                                      id="configureDMClientID"
                                      placeholder="Client ID"
                                      maxLength={50}
                                      className={errors.step2
                                        && (errors.step2 as any)?.dmClientID
                                        && errors.step2.dmClientID ? "form-field-error" : ""}
                                      onChange={(e) => {field.onChange(e.target.value);
                                        trigger("step2.dmClientID")}}
                                  />
                              )}
                          />
                          {errors.step2
                                && (errors.step2 as any)?.dmClientID
                                && <span className="form-field-error-msg">{errors.step2.dmClientID.message}</span>}
                      </Form.Group>
                      <Form.Group className="mb-3">
                          <Form.Label htmlFor="configureDMFromAddress">
                              From Address
                          </Form.Label>
                          <Controller
                              name={"step2.dmFromAddress"}
                              control={control}
                              render={({ field }) => (
                                  <Form.Control
                                      type="text"
                                      value={field.value}
                                      id="configureDMFromAddress"
                                      placeholder="From Address"
                                      maxLength={50}
                                      onChange={(e) => field.onChange(e.target.value)}
                                  />
                              )}
                          />
                          {errors.step2
                                && (errors.step2 as any)?.dmFromAddress
                                && <span className="form-field-error-msg">{errors.step2.dmFromAddress.message}</span>}
                      </Form.Group>
                      <Form.Group className="mb-3">
                          <Form.Label htmlFor="configureDMFromName">
                              From Name
                          </Form.Label>
                          <Controller
                              name={"step2.dmFromName"}
                              control={control}
                              render={({ field }) => (
                                  <Form.Control
                                      type="text"
                                      value={field.value}
                                      id="configureDMFromName"
                                      placeholder="From Name"
                                      maxLength={50}
                                      onChange={(e) => field.onChange(e.target.value)}
                                  />
                              )}
                          />
                          {errors.step2
                              && (errors.step2 as any)?.dmFromName
                              && errors.step2.dmFromName.type === "required"
                              && <span className="form-field-error-msg">{errors.step2.dmFromName.message}</span>}
                      </Form.Group>
                    </Col>
                  </Row>
                }
                {getNotification.status === "loading" ?
                <NotificationPlaceholder />  :
                <Row>
                  {fields?.map((item, index) => (
                    <Col lg={6} key={index}>
                      <div className="app-config-item mb-3 p-3">
                        <h3 className="size16-font">{getValues(`step2.getNotification[${index}].label`)}</h3>
                        <p id="configReturnPaymentDescribe">
                          {getValues(`step2.getNotification[${index}].description`)}

                        </p>

                        {getValues(`step2.getNotification[${index}].notificationSubscriptionItems`).map((subItem:any, subIndex:any) => (
                          <Form.Group className="mb-3" key={subIndex}>
                          <p className="form-label">{subItem.notificationTypeName}</p>
                          <Row>
                            <Col md={2}>
                              <Controller
                                name={`step2.getNotification[${index}].notificationSubscriptionItems[${subIndex}].enabled`}
                                control={control}
                                render={({ field }) => (
                                  <Form.Check
                                    type="switch"
                                    id={`idPaymentConfirmEnabled_${subItem.notificationTypeId}`}
                                    label={field.value == true ? 'ON' : 'OFF'}
                                    value={field.value}
                                    checked={field.value}
                                    className="me-3 mt-2"
                                    onChange={(e) => {
                                        const value = e.target.checked;
                                        field.onChange(value);
                                        trigger(`step2.getNotification[${index}].notificationSubscriptionItems`)
                                      }
                                    }
                                  />
                                )}
                              />
                            </Col>
                            <Col md={10}>
                              <Controller
                                name={`step2.getNotification[${index}].notificationSubscriptionItems[${subIndex}].notificationTemplateId`}
                                control={control}
                                render={({ field }) => (
                                  <Form.Control
                                    type="text"
                                    value={field.value == '00000000-0000-0000-0000-000000000000' ? '': field.value}
                                    id="configureACHPaymentConfirmID"
                                    placeholder="Template ID"
                                    maxLength={50}
                                    className={errors.step2
                                                && (errors.step2 as any)?.getNotification
                                                && errors.step2.getNotification[index]
                                                && (errors.step2.getNotification[index] as any)?.notificationSubscriptionItems
                                                && errors.step2.getNotification[index].notificationSubscriptionItems[subIndex]
                                                && errors.step2.getNotification[index].notificationSubscriptionItems[subIndex].notificationTemplateId ? "form-field-error" : ""}
                                    onChange={(e) => field.onChange(e.target.value)}
                                  />
                                )}
                              />


                              {errors.step2
                                && (errors.step2 as any)?.getNotification
                                && errors.step2.getNotification[index]
                                && (errors.step2.getNotification[index] as any)?.notificationSubscriptionItems
                                && errors.step2.getNotification[index].notificationSubscriptionItems[subIndex]
                                && errors.step2.getNotification[index].notificationSubscriptionItems[subIndex].notificationTemplateId
                                && errors.step2.getNotification[index].notificationSubscriptionItems[subIndex].notificationTemplateId.type === "required"
                                && <span className="form-field-error-msg">{errors.step2.getNotification[index].notificationSubscriptionItems[subIndex].notificationTemplateId.message}</span>}
                            </Col>
                          </Row>
                        </Form.Group>
                        ))}
                      </div>
                    </Col>
                  ))}
                </Row>}
              </fieldset>
        </>
    );
};

export default ConfigureNotifications;
