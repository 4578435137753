/**
 * Mapped form [dbo].[PaymentMethods]
 */
const clientPaymentMethods = {
  creditCard: '0b0b30cc-64ee-492a-a2dd-2642157da362',
  debitCard: '837a77a2-3101-4438-b42b-19c8b19d6af9',
  ach: '5cf6cdfb-7673-40be-b214-c52537948ee3'
};


const paymentMethodsOptions = [
  {
    id: 1,
    label: 'Credit Card',
    value: clientPaymentMethods.creditCard
  },
  {
    id: 2,
    label: 'Debit Card',
    value: clientPaymentMethods.debitCard
  },
  {
    id: 3,
    label: 'ACH',
    value: clientPaymentMethods.ach
  }
];

export { clientPaymentMethods, paymentMethodsOptions };
