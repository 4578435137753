import { get,post } from "../util/request";

export const getUserAccounts = function () {
  return get(`/UserAccount`);
};
export const getAllUserAccounts = function () {
  return get(`/UserAccount/GetAllUserAccounts`);
};

export const getUserAccountNumberByAccountId = function (accountId: string) {
  return get(`/UserAccount/AccountNumber/${accountId}`);
};