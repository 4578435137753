import Skeleton from "react-loading-skeleton";
import { Col, Form, Row } from "react-bootstrap";
import { Table as BSTable, InputGroup, Button } from "react-bootstrap";

const UserListPlaceholder = () => {

    return (
        <Row>
            <Skeleton className="mb-3" width={"100%"} height={40} />
            <div className="px-1">
                <BSTable responsive>
                    <thead>
                        <tr>
                            <th>
                                <Skeleton width={200} height={35} />
                            </th>
                            <th>
                                <Skeleton width={200} height={35} />
                            </th>
                            <th>
                                <Skeleton width={100} height={35} />
                            </th>
                            <th>
                                <Skeleton width={200} height={35} />
                            </th>
                            <th>
                                <div className="text-end">
                                    <Skeleton width={100} height={35} />
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {[...Array(8)].map((value, index:any) => {
                            return (
                                <tr key={index}>
                                    <td>
                                        <Skeleton width={200} height={35} />
                                    </td>
                                    <td>
                                        <Skeleton width={200} height={35} />
                                    </td>
                                    <td>
                                        <div  >
                                            <Skeleton width={100} height={35} />
                                        </div>
                                    </td>
                                    <td>
                                        <Skeleton width={200} height={35} />
                                    </td>
                                    <td>
                                        <div className="text-end">
                                            <Skeleton width={100} height={35} />
                                        </div>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </BSTable>
            </div>
        </Row>
    );
};

export default UserListPlaceholder;