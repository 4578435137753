import { Button, Modal } from 'react-bootstrap';

interface DialogBoxProps {
  showDialog: boolean
  cancelNavigation: any
  confirmNavigation: any
}

const NavigationPrompt: React.FC<DialogBoxProps> = ({
  showDialog,
  cancelNavigation,
  confirmNavigation,
}) => {
  return (
    <Modal show={showDialog}>
      <Modal.Header>
        <Modal.Title>Are you sure you want to leave this page?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>It looks like you are in the middle of writting something and you haven't saved all of your content.</p>
        <p>Save before you leave!</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={cancelNavigation}>
          Stay
        </Button>
        <Button variant="danger" onClick={confirmNavigation}>
          Leave
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default NavigationPrompt;