
import { Col, Form, Row } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

const MethedPlaceholder = () => {

    return (
        <Row>
           {[...Array(2)].map((value, index) => {
                return (
            <Col key={index} md={6}>
                <Form.Group className="app-config-item app-config-switch p-3">
                <div className="app-config-switch_type">
                        <Skeleton className="mb-2" width={"30%"} height={25} /> 
                    </div>
                    <div className="app-config-switch_check me-3">
                        <Skeleton count={1} width={80} height={25} />
                    </div>
                   
                </Form.Group>
            </Col>  )
            })}
        </Row>
    );
};

export default MethedPlaceholder;