export function collectJsValid(selectAccountType:string|undefined,checkCVV:boolean = true){
    if (!selectAccountType) {
        return false
    }
    if (selectAccountType === 'ACH') {
        const validNameOnAccount = document.querySelector("#checkname .CollectJSValid") !== null;
        const validAccountNumber = document.querySelector("#checkaccount .CollectJSValid") !== null;
        const validRoutingNumber = document.querySelector("#checkaba .CollectJSValid") !== null;
        if (validNameOnAccount && validAccountNumber && validRoutingNumber) {
          return true
        }else{
          return false 
        }
     }else{
        const validCardNumber = document.querySelector("#ccnumber .CollectJSValid") !== null;
        const validExpiration = document.querySelector("#ccexp .CollectJSValid") !== null;
        const validCvv = document.querySelector("#cvv .CollectJSValid") !== null;
        if (checkCVV) {
          if (validCardNumber && validExpiration && validCvv) {
            return true
          }else{
            return false 
          }
        }else{
          if (validCardNumber && validExpiration) {
            return true
          }else{
            return false 
          }
        }
     }
}

export function checkCollectOnFirstClick(selectAccountType:string){
  if (selectAccountType === 'Credit Card' || selectAccountType === 'Debit Card') {
    const ccnumberClass =  document.querySelector("#ccnumber #CollectJSInlineccnumber")?.getAttribute('class')
    const ccnumberHasValidationClass = ccnumberClass?.includes('CollectJSValid') || ccnumberClass?.includes('CollectJSInvalid')
    if (!ccnumberHasValidationClass) {
      appendErrorMsg('ccnumber','Card Number is required')
    }
    const cvvClass =  document.querySelector("#cvv #CollectJSInlinecvv")?.getAttribute('class')
    const cvvHasValidationClass = cvvClass?.includes('CollectJSValid') || cvvClass?.includes('CollectJSInvalid')
    if (!cvvHasValidationClass) {
      appendErrorMsg('cvv','CVV is required')
    }
    const ccexpClass =  document.querySelector("#ccexp #CollectJSInlineccexp")?.getAttribute('class')
    const ccexpHasValidationClass = ccexpClass?.includes('CollectJSValid') || ccexpClass?.includes('CollectJSInvalid')
    if (!ccexpHasValidationClass) {
      appendErrorMsg('ccexp','Card Expiration Date is required')
    }
  }else{
    const checknameClass =  document.querySelector("#checkname #CollectJSInlinecheckname")?.getAttribute('class')
    const checknameHasValidationClass = checknameClass?.includes('CollectJSValid') || checknameClass?.includes('CollectJSInvalid')
    if (!checknameHasValidationClass) {
      appendErrorMsg('checkname','Name on Account is required')
    }
    const checkabaClass =  document.querySelector("#checkaba #CollectJSInlinecheckaba")?.getAttribute('class')
    const checkabaHasValidationClass = checkabaClass?.includes('CollectJSValid') || checkabaClass?.includes('CollectJSInvalid')
    if (!checkabaHasValidationClass) {
      appendErrorMsg('checkaba','Routing Number is required')
    }
    const checkaccountClass =  document.querySelector("#checkaccount #CollectJSInlinecheckaccount")?.getAttribute('class')
    const checkaccountHasValidationClass = checkaccountClass?.includes('CollectJSValid') || checkaccountClass?.includes('CollectJSInvalid')
    if (!checkaccountHasValidationClass) {
      appendErrorMsg('checkaccount','Account Number is required')
    }
  }
}

function appendErrorMsg(fieldName:string,msg:string){
  if(fieldName==="cvv" || fieldName==="checkaccount" || fieldName==="checkaba"){
    const _errorSpan = ((document?.querySelector("#"+fieldName)?.parentNode) as HTMLElement)?.nextElementSibling
    if(_errorSpan){
      document.querySelector("#"+fieldName)?.parentNode?.parentNode?.removeChild(_errorSpan)
    }
    ((document.querySelector("#"+fieldName)?.parentNode) as HTMLElement)?.classList.add("form-field-error")
    const _spanNode = document.createElement("span")
    const _textNode = document.createTextNode(msg)
    _spanNode.appendChild(_textNode)
    _spanNode.className = "form-field-error-msg"
    document?.querySelector("#"+fieldName)?.parentNode?.parentNode?.appendChild(_spanNode)

}else{
  const _errorSpan = document.querySelector("#"+fieldName)?.nextElementSibling
    if(_errorSpan){
      document.querySelector("#"+fieldName)?.parentNode?.removeChild(_errorSpan)
    }
    document?.querySelector("#"+fieldName)?.classList.add("form-field-error")
    const _spanNode = document.createElement("span")
    const _textNode = document.createTextNode(msg)
    _spanNode.appendChild(_textNode)
    _spanNode.className = "form-field-error-msg"
    document?.querySelector("#"+fieldName)?.parentNode?.appendChild(_spanNode)
  }
}

export function addEventrForCollectJsField(){
  const frames = document.querySelectorAll(".form-control iframe.CollectJSInlineIframe")
  frames.forEach(element => {
    // focus
    if (element.id === 'CollectJSInlinecvv' || element.id === 'CollectJSInlinecheckaba' || element.id === 'CollectJSInlinecheckaccount') {
        element.addEventListener("focus", function (event) {
          const inputBox = ((((event?.target) as HTMLElement).parentNode) as HTMLElement ).parentNode;
          (inputBox as HTMLElement)?.classList.add('form-field-focus')
        });
    }else{
        element.addEventListener("focus", function (event) {
          const inputBox = ((event?.target) as HTMLElement).parentNode;
          (inputBox as HTMLElement)?.classList.add('form-field-focus')
        });
    }
    // blur
    if (element.id === 'CollectJSInlinecvv' || element.id === 'CollectJSInlinecheckaba' || element.id === 'CollectJSInlinecheckaccount') {
        element.addEventListener("blur", function (event) {
          const inputBox = ((((event?.target) as HTMLElement).parentNode) as HTMLElement ).parentNode;
          (inputBox as HTMLElement)?.classList.remove('form-field-focus')
        });
    }else{
        element.addEventListener("blur", function (event) {
          const inputBox = ((event?.target) as HTMLElement).parentNode;
          (inputBox as HTMLElement)?.classList.remove('form-field-focus')
        });
    }
  });
}

export function addEventForCollectJsFieldByAccountType(type:string){
   if (type == 'ACH') {
     const checkname = document.querySelector("iframe#CollectJSInlinecheckname")
     checkname?.addEventListener("focus", function (event) {
      const inputBox = ((event?.target) as HTMLElement).parentNode;
      (inputBox as HTMLElement)?.classList.add('form-field-focus')
     });
     checkname?.addEventListener("blur", function (event) {
      const inputBox = ((event?.target) as HTMLElement).parentNode;
      (inputBox as HTMLElement)?.classList.remove('form-field-focus')
    });

    const checkaba = document.querySelector("iframe#CollectJSInlinecheckaba")
    checkaba?.addEventListener("focus", function (event) {
      const inputBox = ((((event?.target) as HTMLElement).parentNode) as HTMLElement ).parentNode;
      (inputBox as HTMLElement)?.classList.add('form-field-focus')
    });
    checkaba?.addEventListener("blur", function (event) {
      const inputBox = ((((event?.target) as HTMLElement).parentNode) as HTMLElement ).parentNode;
      (inputBox as HTMLElement)?.classList.remove('form-field-focus')
    });

    const checkaccount = document.querySelector("iframe#CollectJSInlinecheckaccount")
    checkaccount?.addEventListener("focus", function (event) {
      const inputBox = ((((event?.target) as HTMLElement).parentNode) as HTMLElement ).parentNode;
      (inputBox as HTMLElement)?.classList.add('form-field-focus')
    });
    checkaccount?.addEventListener("blur", function (event) {
      const inputBox = ((((event?.target) as HTMLElement).parentNode) as HTMLElement ).parentNode;
      (inputBox as HTMLElement)?.classList.remove('form-field-focus')
    });
   }else{
    const cvv = document.querySelector("iframe#CollectJSInlinecvv")
    cvv?.addEventListener("focus", function (event) {
      const inputBox = ((((event?.target) as HTMLElement).parentNode) as HTMLElement ).parentNode;
      (inputBox as HTMLElement)?.classList.add('form-field-focus')
    });
    cvv?.addEventListener("blur", function (event) {
      const inputBox = ((((event?.target) as HTMLElement).parentNode) as HTMLElement ).parentNode;
      (inputBox as HTMLElement)?.classList.remove('form-field-focus')
    });

    const ccnumber = document.querySelector("iframe#CollectJSInlineccnumber")
    ccnumber?.addEventListener("focus", function (event) {
      const inputBox = ((event?.target) as HTMLElement).parentNode;
      (inputBox as HTMLElement)?.classList.add('form-field-focus')
     });
     ccnumber?.addEventListener("blur", function (event) {
      const inputBox = ((event?.target) as HTMLElement).parentNode;
      (inputBox as HTMLElement)?.classList.remove('form-field-focus')
    });

    const ccexp = document.querySelector("iframe#CollectJSInlineccexp")
    ccexp?.addEventListener("focus", function (event) {
      const inputBox = ((event?.target) as HTMLElement).parentNode;
      (inputBox as HTMLElement)?.classList.add('form-field-focus')
     });
     ccexp?.addEventListener("blur", function (event) {
      const inputBox = ((event?.target) as HTMLElement).parentNode;
      (inputBox as HTMLElement)?.classList.remove('form-field-focus')
    });
   }
}