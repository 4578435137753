import React from "react";
// import Icon from './components/icons/Icons'
import { Container } from "react-bootstrap";

function NoMatch() {
  return (
    <main>
      <Container>
        <div id="mainContent">
          <div className="app-panel-container">
            <div className="app-left-panel">
              <h1>Not Found!</h1>
            </div>
          </div>
        </div>
      </Container>
    </main>
  );
}

export default NoMatch;
