import React, { forwardRef, useEffect, useRef, useState } from "react";
import { shallowEqual } from "react-redux";
import {
  Card,
  Col,
  Form,
  Row,
  Popover
} from "react-bootstrap";
import { Controller } from "react-hook-form";
import Icon from "../../components/Common/Icons/Icons";
import AccountHelpImage from "../../assets/Images/Routing-Account-Number.png";
import CVVHelpImage from "../../assets/Images/CVV-Code.png";
import { Payment as PaymentForm } from "../../components/Payment";
import { useAppSelector, useAppDispatch } from "../../app/hooks";

import { addEventForCollectJsFieldByAccountType } from '../../util/collectJsValid'
import { replaceSpace, replaceSpecialCharsAndNumbers } from "../../util/regexUtil";
import { addhypen } from "../../util/zipcodeValidation";
import { setCurrentPaymentMethodId } from "../../features/payment/paymentSlice";

interface IformProps {
  formProps: any,
  forceSaveNewPayment: Boolean,
  emitPaymentInfo: Function,
  emitErrorInfo: Function
}
// Task 38697: UI - Consumer Payment No Payment Method Form
// Task 38695: React Dev - Consumer Payment No Payment Method Form
const EnterPaymentInfoForm = forwardRef(
  ({ formProps, emitPaymentInfo, emitErrorInfo, forceSaveNewPayment }: IformProps, ref) => {
    const {
      control,
      formState: { errors },
      setValue,
      getValues
    } = formProps;
    const { paymentMethods } = useAppSelector(
      (state) => ({ ...state.payment }),
      shallowEqual
    );

    const dispatch = useAppDispatch();

    const checkingRadioRef = useRef<HTMLInputElement>(null);

    // Handle showing the correct form for type
    const [showType, setShowType] = useState("");
    const handleShowType = (event: any) => {
      const getType = event.target.value;
      setShowType(getType);
      setTimeout(() => {
        addEventForCollectJsFieldByAccountType(getType)
      }, 200);
    };

    useEffect(() => {
      if (checkingRadioRef && checkingRadioRef.current) {
        checkingRadioRef.current.checked = true;
      }
      setValue("enterPayment.type", "checking");
    }, [showType]);

    const accountHelp = (
      <Popover id="popover-account">
        <Popover.Body>
          <img src={AccountHelpImage} />
        </Popover.Body>
      </Popover>
    );

    const cvvHelp = (
      <Popover id="popover-cvv">
        <Popover.Body>
          <img src={CVVHelpImage} />
        </Popover.Body>
      </Popover>
    );

    const finishSubmit = (info: any) => {
      info.showType = showType;
      emitPaymentInfo(info);
    };

    const errorCallback = (error: string) => {
      emitErrorInfo(error);
    }

    const [saveCard, setSaveMethod] = useState(false);

    return (
      <Card className="mb-3 shadows-light">
        <Card.Body>
          <Card.Title as="h2" className="app-card-title">
            <Icon name="credit-card" />
            Enter New Payment Information
          </Card.Title>
          {
            !forceSaveNewPayment &&
            <Form.Group className="mb-3">
              <Controller
                name="enterPayment.isSave"
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <Form.Check
                    type="checkbox"
                    name="saveCard"
                    id="saveCard"
                    label="Select if you want to save this payment method"
                    onChange={(e) => {
                      setSaveMethod(!saveCard);
                      field.onChange(e.target.checked)
                    }}
                  />
                )}
              />
            </Form.Group>
          }
          {
            (saveCard || forceSaveNewPayment) && <>
              <Form.Group className="mt-3">
                <Form.Label className="form-label-important" htmlFor="methodNickName">Payment NickName</Form.Label>
                <Controller
                  name="enterPayment.methodNickname"
                  control={control}
                  defaultValue={""}
                  rules={{ required: "Payment Nickname is required" }}
                  render={({ field }) => (
                    <Form.Control
                      id="methodNickName"
                      placeholder="Payment NickName"
                      type="text"
                      className={errors.enterPayment?.methodNickname ? "form-field-error" : ""}
                      value={field.value || ""}
                      onChange={(e) => field.onChange(e.target.value)}
                    />
                  )}
                />
              </Form.Group>
              <Row>
                {(errors.enterPayment?.methodNickname && errors.enterPayment?.methodNickname?.type === "required") &&
                  <span className="form-field-error-msg mt-1">{errors.enterPayment?.methodNickname?.message}</span>
                }
              </Row>
            </>
          }
          <Form.Group className="mt-3">
            <Form.Label htmlFor="newAccountType">Payment Type</Form.Label>
            <Controller
              name="enterPayment.selectAccountType"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <Form.Select
                  id="newAccountType"
                  className={errors.enterPayment?.selectAccountType ? "form-field-error" : ""}
                  onChange={(e) => {
                    handleShowType(e);
                    field.onChange(e.target.value);
                    let temppaymentMethodId: string = ''
                    const _index = paymentMethods.findIndex(item => item.paymentMethodName == e.target.value)
                    if (_index != -1) {
                      temppaymentMethodId = paymentMethods[_index].paymentMethodId
                    } else {
                      temppaymentMethodId = ''
                    }
                    dispatch(setCurrentPaymentMethodId(temppaymentMethodId));
                  }}
                >
                  <option value="">Select account type</option>
                  {
                    (paymentMethods && paymentMethods.length > 0) &&
                    paymentMethods.map(item => (<option key={item.paymentMethodId} value={item.paymentMethodName}>{item.paymentMethodName}</option>))
                  }
                </Form.Select>
              )
              }
            />

          </Form.Group>
          <Row>
            {(errors.enterPayment?.selectAccountType && errors.enterPayment?.selectAccountType?.type === "required") &&
              <span className="form-field-error-msg mt-1">{errors.enterPayment?.selectAccountType?.message}</span>
            }
          </Row>
          {/* IF Bank Account */}
          {
            showType === "ACH" && (
              <>
                <>
                  <p className="form-label-important mt-3">Type</p>
                  <Form.Group className="mt-3">
                    <Controller
                      name="enterPayment.type"
                      control={control}
                      render={({ field }) => (
                        <Form.Check
                          type="radio"
                          ref={checkingRadioRef}
                          id="Checkings"
                          name="enterPayment.type"
                          label="Checking"
                          value={"checking"}
                          onChange={(e) => field.onChange(e.target.value)}
                        />
                      )}
                    />
                    <Controller
                      name="enterPayment.type"
                      control={control}
                      render={({ field }) => (
                        <Form.Check
                          type="radio"
                          id="Savings"
                          name="enterPayment.type"
                          label="Savings"
                          value={"savings"}
                          onChange={(e) => field.onChange(e.target.value)}
                        />
                      )}
                    />
                    {errors.details &&
                      (errors.details as any).accountType &&
                      errors.details &&
                      (errors.details as any).accountType.type === "required" && (
                        <span className="form-field-error-msg">
                          {errors.details &&
                            (
                              errors.details as any
                            ).accountType?.message?.toString()}
                        </span>
                      )}
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="methodType" className="form-label-important">ACH Type</Form.Label>
                    <Controller
                      name="enterPayment.ach_type"
                      control={control}
                      render={({ field }) => (
                        <Form.Select
                          id="achType"
                          onChange={(e) => {
                            setValue("enterPayment.ach_type", e.target.value);
                            field.onChange(e.target.value);
                          }}
                        >
                          <option
                            key='Select ACH Type'
                            value=''
                          >
                            {'Select ACH Type'}
                          </option>
                          <option
                            key='personal'
                            value='personal'
                          >
                            {'Personal'}
                          </option>
                          <option
                            key='business'
                            value='business'
                          >
                            {'Business'}
                          </option>
                        </Form.Select>
                      )}
                    />
                    {errors.enterPayment?.ach_type && (getValues('ach_type') == null || getValues('ach_type') === '') &&
                      errors.enterPayment?.ach_type.type === "required" && (
                        <span className="form-field-error-msg">
                          {errors.enterPayment?.ach_type?.message?.toString()}
                        </span>
                      )}
                  </Form.Group>
                </>
                <PaymentForm
                  ref={ref}
                  finishSubmit={finishSubmit}
                  errorCallback={errorCallback}
                  cancel={() => { }}
                  setLoading={() => { }}
                  showAccountName={true}
                  accountNameText="Name On Account"
                  showRoutingNumber={true}
                  routingNumberText="ABA Routing Number"
                  showAccountNumber={true}
                  accountNumberText="Bank Account Number"
                  hideFooter={true}
                ></PaymentForm>
              </>)
          }
          {/* IF Credit Card Or Debit Card*/}
          {
            (showType === "Credit Card" || showType === "Debit Card") && (
              <>
                <Row>
                  <Col>
                    <Form.Group className="mt-3">
                      <Form.Label className="form-label-important" htmlFor="firstName">First Name</Form.Label>
                      <Controller
                        name="enterPayment.firstName"
                        control={control}
                        render={({ field }) => (
                          <Form.Control
                            id="firstName"
                            type="text"
                            className={errors.enterPayment?.firstName ? "form-field-error" : ""}
                            placeholder="First Name"
                            value={field.value || ""}
                            onChange={(e) => field.onChange(e.target.value)}
                          />
                        )}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  {(errors.enterPayment?.firstName && errors.enterPayment?.firstName.type) &&
                    <span className="form-field-error-msg mt-1">{errors.enterPayment?.firstName.message}</span>
                  }
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mt-3">
                      <Form.Label htmlFor="lastName" className="form-label-important">Last Name</Form.Label>
                      <Controller
                        name="enterPayment.lastName"
                        control={control}
                        render={({ field }) => (
                          <Form.Control
                            id="lastName"
                            type="text"
                            className={errors.enterPayment?.lastName ? "form-field-error" : ""}
                            placeholder="Last Name"
                            value={field.value || ""}
                            onChange={(e) => field.onChange(e.target.value)}
                          />
                        )}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  {(errors.enterPayment?.lastName && errors.enterPayment?.lastName.type) &&
                    <span className="form-field-error-msg mt-1">{errors.enterPayment?.lastName.message}</span>
                  }
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mt-3">
                      <Form.Label htmlFor="newZipCode" className="form-label-important">Zip Code</Form.Label>
                      <Controller
                        name="enterPayment.zipCode"
                        control={control}
                        render={({ field }) => (
                          <Form.Control
                            id="zipCode"
                            type="text"
                            className={errors.enterPayment?.zipCode ? "form-field-error" : ""}
                            placeholder="Zip Code"
                            value={field.value || ""}
                            onChange={(e) => {
                              field.onChange(addhypen(replaceSpace(e.target.value)))
                            }}
                          />
                        )}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  {(errors.enterPayment?.zipCode && errors.enterPayment?.zipCode.type) &&
                    <span className="form-field-error-msg mt-1">{errors.enterPayment?.zipCode.message}</span>
                  }
                </Row>

                <PaymentForm
                  ref={ref}
                  finishSubmit={finishSubmit}
                  errorCallback={errorCallback}
                  cancel={() => { }}
                  setLoading={() => { }}
                  showCcNumber={true}
                  ccNumberText="Card Number"
                  showCvv={true}
                  cvvText="CVV Code"
                  showCcexp={true}
                  ccExpText="Card Expiration Date"
                  hideFooter={true}
                ></PaymentForm>
              </>
            )}
        </Card.Body>
      </Card>
    );
  }
);
export default EnterPaymentInfoForm;
