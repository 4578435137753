import React, { FC } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { shallowEqual } from "react-redux";
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import Icon from "../../components/Common/Icons/Icons";

import {
    Container,
    Row,
    Col,
    Alert,
    Card,
    Button,
    Form,
    OverlayTrigger,
    InputGroup,
    Popover
  } from "react-bootstrap";

  import { returnToHome } from '../../util/returnToHome'

  type props = {
    //Define
  };
  

  const UnRolledAutoPay: FC<props> = ({ }) => {

    const { permissions } = useAppSelector(
        (state: any) => ({ ...state.permissions }),
        shallowEqual
      );

      const { accountNumber } = useAppSelector(
        (state) => ({ ...state.account }),
        shallowEqual
      );

    return (
        <main id="mainContent">
        <Container>
          {
            permissions?.canShowBreadcrumb && <div className="mb-3">
              <Link to="/client">Clients</Link> / <Link to="/client/accountinformation">Account Information</Link> / <Link to="/client/autopay">Autopay</Link> / Unrolled
            </div>
          }
          <h1 className="h2">AutoPay Unrolled</h1>
          {//IF enrolled
             <Alert variant="warning">
             <div className="d-flex align-items-center">
               <div className="me-3">
                 <Icon
                   name="warning"
                   optionalClass="p-1 rounded-circle bg-light"
                   size={36}
                 />
               </div>
               <div>
                 <h2 className="h3 m-0">
                   You have <u>unenrolled</u> in Auto Pay for Account {accountNumber}.
                 </h2>
               </div>
             </div>
           </Alert>
          }

        <Button className="btn btn-outline-primary" onClick={()=>{returnToHome()}}>
          <Icon name="arrow-left" optionalClass="me-2" size={20}></Icon>
          Back to Home
        </Button>
        </Container>
        </main>
    );

}

export default React.memo(UnRolledAutoPay);