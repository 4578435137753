import {
  createSlice
} from "@reduxjs/toolkit";
import { getGeneralBillingAttributes } from "../../api/configureClient"
import { revertAllClientData } from "./configureAccountSlice";


interface initialStateGeneral {
  data: any,
  status: string,
  error: string | undefined
}

const initialStateGeneral: initialStateGeneral = {
  data: [],
  status: "",
  error: ""
};

const generalBillingAttributesSlice = createSlice({
  name: "generalBillingAttributes",
  initialState: initialStateGeneral,
  reducers: {
    setBillingAttrbues: (state, action) => {
      state.data = action.payload;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getGeneralBillingAttributes.pending, (state, action) => {
        state.status = 'loading'
        state.data = []
        state.error = ""
      })
      .addCase(getGeneralBillingAttributes.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.data = action.payload
        state.error = ""
      })
      .addCase(getGeneralBillingAttributes.rejected, (state, action) => {
        state.status = 'failed'
        state.data = ""
        state.error = action.error.message
      })
  }
});

export default generalBillingAttributesSlice.reducer;
