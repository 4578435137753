import Skeleton from "react-loading-skeleton";

const ActionButtonPlaceholder = () => {

    return (
        <div className="d-flex w-100 justify-content-end mb-4">
            <Skeleton className="me-3" width={125} height={40} />
            <Skeleton className="me-3" width={170} height={40} />
            <Skeleton width={95} height={40} />
        </div>
    );
};

export default ActionButtonPlaceholder;