import {
  createAction,
  createSlice
} from "@reduxjs/toolkit";
import { getConfigureNotifications } from "../../api/configureClient";
import { revertAllClientData } from "./configureAccountSlice";

interface initialState {
  data: any,
  status: string,
  error: string | undefined
}

const initialState: initialState = {
  data: [],
  status: "idle",
  error: ""
};

export const revertAll = createAction('REVERT_ALL')

const getConfigureNotificationsSlice = createSlice({
  name: "getConfigureNotifications",
  initialState: initialState,
  reducers: { },
  extraReducers(builder) {
    builder
      .addCase(getConfigureNotifications.pending, (state, action) => {
        state.status = 'loading'
        state.data = []
        state.error = ""
      })
      .addCase(getConfigureNotifications.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.data = action.payload
        state.error = ""
      })
      .addCase(getConfigureNotifications.rejected, (state, action) => {
        state.status = 'failed'
        state.data = ""
        state.error = action.error.message
      })
      .addCase(revertAllClientData, () => initialState)
      .addCase(revertAll, () => initialState)
  }
});

export default getConfigureNotificationsSlice.reducer;
