import {
  createSlice,
  createAsyncThunk,
  PayloadAction,
  Action
} from "@reduxjs/toolkit";

// Types
import {EPToastProps} from "../../../types/custom-types"
interface InitialState {
  isLoading: boolean;
  toasts:Array<EPToastProps>;
}

const initialState: InitialState = {
  isLoading: false,
  toasts:[]
};

export const stateSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    setGlobalLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setGlobalToasts:(state, action)=>{
      state.toasts = action.payload;
    }
  }
});


export const { setGlobalLoading, setGlobalToasts } = stateSlice.actions;
export default stateSlice.reducer;
