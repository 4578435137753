import {
  createSlice,
  createAsyncThunk,
  PayloadAction,
  Action
} from "@reduxjs/toolkit";
interface InitialState {
  paymentMethods: Array<any>;
  userPaymentMethod: Array<any>;
  paymentHistory: Array<any>;
  pendingPayments: Array<any>;
  billingHistory: Array<any>;
  currentPayment: any;
  paymentMethodId: string;
}

const initialState: InitialState = {
  paymentMethods: [],
  userPaymentMethod: [],
  paymentHistory: [],
  pendingPayments: [],
  billingHistory: [],
  currentPayment: {},
  paymentMethodId: '',
};

export const stateSlice = createSlice({
  name: "state",
  initialState,
  reducers: {
    initPaymentMethods: (state, action) => {
      state.paymentMethods = action.payload;
    },
    initUserPaymentMethod: (state, action) => {
      state.userPaymentMethod = action.payload;
    },
    initPaymentHistory: (state, action) => {
      state.paymentHistory = action.payload;
    },
    initBillingHistory: (state, action) => {
      state.billingHistory = action.payload;
    },
    setPendingPayments: (state, action) => {
      state.pendingPayments = action.payload;
    },
    setCurrentPayment: (state, action) => {
      state.currentPayment = action.payload;
    },
    setCurrentPaymentMethodId: (state, action) => {
      state.paymentMethodId = action.payload;
    }
  }
});

export const {
  initPaymentMethods,
  initUserPaymentMethod,
  initPaymentHistory,
  initBillingHistory,
  setPendingPayments,
  setCurrentPayment,
  setCurrentPaymentMethodId
} = stateSlice.actions;

export default stateSlice.reducer;
