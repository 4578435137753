import Skeleton from "react-loading-skeleton";
import { Col, Form, Row } from "react-bootstrap";

const PaymentInfoPlaceholder = () => {

    return (
        <Col sm={12}>
            {[...Array(6)].map((value, index) => {
                return (
                    <div key={index}>
                        <Row className="mb-4" md={12}>
                            <div className="d-flex justify-content-between">
                                <Skeleton width={120} height={20} />
                                <Skeleton width={120} height={20} />
                            </div>
                        </Row>
                        <hr />
                    </div>
                )
            })}
        </Col>
    );
};

export default PaymentInfoPlaceholder;