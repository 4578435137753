import { configureStore, ThunkAction, Action,combineReducers} from '@reduxjs/toolkit';
import { persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER, } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import counterReducer from '../features/counter/counterSlice';
import consumerReducer from '../features/consumer/consumerSlice';
import accountReducer from '../features/account/accountSlice';
import paymentReducer from '../features/payment/paymentSlice';
import documentReducer from "../features/document/documentSlice"
import globalReducer from "../features/global/globalSlice";
import permissionsReducer from "../features/permissions/permissionsSlice";
import commonDictionary from '../features/dictionaries/commonDictionary';
import generalBillingAttributesReducer from '../features/configureClient/generalBillingAttributesSlice';
import paymentBillingAttributesReducer from '../features/configureClient/paymentBillingAttributesSlice';
import paymentMethodsReducer from '../features/configureClient/paymentMethodsSlice';
import getClientBillingAttribute from "../features/configureClient/getClientBillingAttributeSlice";
import getConfigureNotifications from '../features/configureClient/getClientNotification';
import clientConfigureAccount from '../features/configureClient/configureAccountSlice';
import getClientUserSlice from "../features/users/getUserSlice";
import editUserSlice from "../features/users/editUserData";
import paymentStatusSlice from "../features/onetimepayment/onetimepayment";

const reducers = combineReducers({
  counter: counterReducer,
  account: accountReducer,
  payment: paymentReducer,
  document: documentReducer,
  permissions: permissionsReducer,
  global: globalReducer,
  consumer:consumerReducer,
  commonDictionary:commonDictionary,
  generalBillingAttributes: generalBillingAttributesReducer,
  paymentBillingAttributes: paymentBillingAttributesReducer,
  paymentMethods: paymentMethodsReducer,
  getClientBilling: getClientBillingAttribute,
  getClientNotification: getConfigureNotifications,
  clientAccount: clientConfigureAccount,
  usersList : getClientUserSlice,
  editUserData: editUserSlice,
  onetimepayment: paymentStatusSlice
})

const persistConfig = {
  key: 'root',
  storage,
  blacklist: [
    'counter',
    'global',
    'commonDictionary',
    'generalBillingAttributes',
    'paymentBillingAttributes',
    'paymentMethods',
    'getClientBilling',
    'getClientNotification'
  ]
}
const persistedReducer = persistReducer(persistConfig, reducers)

export const store = configureStore({
  reducer:persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    }),
});

export const persistor = persistStore(store)

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
