
import { Col, Form, Row } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

const ConfigureattributesPlaceholder = () => {

    return (
        <>
            {[...Array(2)].map((value, index) => {
                return (
                    <Col md={12} key={index}>
                        <Form.Group className="app-config-item app-config-switch p-3">
                            <div>
                                <Skeleton width={30} height={40} />
                            </div>
                            <div className="app-config-switch_type">
                                <Skeleton width={"100%"} height={40} />
                            </div>
                            <div className="app-config-switch_type">
                                <Skeleton width={"100%"} height={40} />
                            </div>
                            <div>
                                <Skeleton width={100} height={40} />
                            </div>
                            <div className="app-config-switch_type">
                                <Skeleton width={"100%"} height={40} />
                            </div>
                            <div>
                                <Skeleton width={40} height={40} />
                            </div>
                        </Form.Group>
                    </Col>
                )
            })}
        </>
    );
};

export default ConfigureattributesPlaceholder;